import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { EclCmsService } from '@elevate/cms';
import { Environment } from '@environment/environment';

@Injectable({ providedIn: 'root' })
export class CmsService extends EclCmsService {
  constructor(environment: Environment) {
    super(environment.CmsConfiguration);
  }

  public getEntries(
    uid: string,
    returnAllEntries: boolean = false
  ): Observable<any> {
    return super.getEntries(uid).pipe(
      switchMap((res: any) => super.mapGlossary(res)),
      map(res => (returnAllEntries ? res : res[0] ?? res))
    );
  }

  public getByReferences(uid: string, refs: string[] = []): Observable<any> {
    return super
      .getEntriesWithReferences(uid, refs)
      .pipe(switchMap((res: any) => super.mapGlossary(res)));
  }

  public getSharedCmsContent(): Observable<any> {
    return this.getEntries('shared').pipe(
      map(data => {
        // temporary workaround until entries filtration in cmsService will be fixed
        return Array.isArray(data) ? data[0] : data;
      })
    );
  }
}
