import { Injectable } from '@angular/core';
import { CmsService } from '@core/cms/services/cms.service';
import { Banner, BannerType, BannersResponse, DisplayBanner } from '../models/banner.model';
import { BehaviorSubject, map, switchMap, tap } from 'rxjs';
import { BannerApi } from './banner.api';

@Injectable({
  providedIn: 'root'
})
export class BannerService {
  public activeBanners$ = new BehaviorSubject<Array<Banner>>([]);
  private cmsBanners: Banner[] = [];

  constructor(
    private cmsService: CmsService,
    private bannerApi: BannerApi,
  ) {
      this.cmsService.getByReferences('banners_container', ['banners'])
        .pipe(
          switchMap((cmsBanners: any) => {
            this.cmsBanners = cmsBanners[0]?.banners;
            return this.bannerApi.getPublicBanners();
          }),
          map((bannersResponse: BannersResponse) => bannersResponse.banners)
        )
        .subscribe((bannersToDisplay: DisplayBanner[]) => {
          this.updateBannersState(bannersToDisplay);
        });
  }

  public closeBanner(cmsBannerName: string) {
    const closedBanners = this.getClosedBannersNames();
    closedBanners.push(cmsBannerName);
    const activeBanners = this.activeBanners$.value.filter(
      banner =>
        !closedBanners.find(closedBannerName => closedBannerName === banner.bannerName)
    );
    localStorage.setItem('closedBanners', JSON.stringify(closedBanners));
    this.activeBanners$.next(activeBanners);
  }

  private updateBannersState(banners: DisplayBanner[]) {
    const closedBanners = this.getClosedBannersNames();
    const activeBanners = this.filterClosedBanners(banners, closedBanners)
      ?.map(banner => {
        const cmsBanner = this.cmsBanners?.find(
          cmsBannerItem => cmsBannerItem.bannerName === banner.bannerCmsName
        );
        return cmsBanner;
      })
      .filter(b => b)
      .sort(
        (firstEl, secondEl) =>
          this.getBannerTypePriority(firstEl.bannerType) -
          this.getBannerTypePriority(secondEl.bannerType)
      );

    this.activeBanners$.next(activeBanners);
  }

  private getClosedBannersNames(): string[] {
    const closedBannerNamesFromStorage = localStorage.getItem('closedBanners') ?? '[]';
    const closedBanners = JSON.parse(closedBannerNamesFromStorage) as string[];
    return closedBanners;
  }

  private filterClosedBanners(banners: DisplayBanner[], closedCmsBannerNames) {
    return banners.filter(
      banner =>
        !closedCmsBannerNames.find(
          (closedBannerName: string) => closedBannerName === banner.bannerCmsName
        )
    );
  }

  private getBannerTypePriority(type: BannerType) {
    switch (type) {
      case BannerType.Error:
        return 1;
      case BannerType.Alert:
        return 2;
      case BannerType.Informative:
        return 3;
      case BannerType.Success:
        return 4;
    }
  }
}
