import { PartnerOfferIds } from './application.api';
import { SelectedTermsModel } from './underwriting-decisions/choose-your-terms/choose-your-terms.model';
import {
  TrackedRequests,
  UnderwritingModel,
  UnderwritingOfferModel
} from './underwriting/underwriting.model';

export interface ApplicantIdentityModel {
  socialSecurityNumber?: string;
  firstName?: string;
  lastName?: string;
  suffix?: string;
  dateOfBirth?: string;
}

export interface ApplicantAddressModel {
  line1?: string;
  line2?: string;
  city?: string;
  stateCode?: string;
  zipCode?: string;
}

export interface ApplicantResidenceModel {
  key?: string;
  type?: string;
  address?: ApplicantAddressModel;
  monthlyAmount?: number;
}

export interface ApplicantBankAccountModel {
  key?: string;
  institution?: string;
  type?: string;
  accountNumber?: string;
  routingNumber?: string;
  source?: string;
  accountNumberLast4?: string;
}

export interface ApplicantPhoneModel {
  key?: string;
  number?: string;
  type?: string;
}

export interface ApplicantEmailModel {
  key?: string;
  address?: string;
  type?: string;
  status?: string;
}

export interface Disclosure {
  key: string;
  consentGiven: boolean;
  signature?: string;
  isIndependentDocument?: boolean;
}

export interface ApplicantIncomeModel {
  sources?: ApplicantIncomeSourceModel[];
}

export interface ApplicantIncomeSourceModel {
  key?: string;
  type?: string;
  frequency?: string;
  daysOfMonth?: {
    first?: number;
    second?: number;
  };
  netAmount?: number;
  nextPayDate?: string;
  paymentDate?: string;
  confirmedPayDates?: NextThreePayDates[];
}

export interface ApplicantEmploymentModel {
  key?: string;
  employer?: string;
  incomeSourceKey?: string;
  phone?: {
    number?: string;
    extension?: string;
  };
}

export interface NextThreePayDates {
  key: string;
  date: string;
  paycheckAmount: number;
}

export enum ApplicationStates {
  Approved = 'Approved',
  InReview = 'InReview',
  InformationNeeded = 'InformationNeeded',
  Declined = 'Declined',
  Booked = 'Booked',
  Accepted = 'Accepted'
}

export enum ApplicationFlow {
  OrganicPrequal = 'OrganicPrequal',
  Reapply = 'Reapply',
  Prequal = 'Prequal',
  PartnerPrepop = 'PartnerPrepop',
  PartnerPrequal = 'PartnerPrequal',
  Former = 'Former',
  Refi = 'Refinance'
}

export interface ApplicationData {
  id?: string;
  applicationFlow?: ApplicationFlow;
  acceptedTerms?: AcceptedTerms;
  sequenceApplicationId?: number;
  createdAt?: string;
  expiresAt?: string;
  partner?: PartnerOfferIds;
  brand?: string;
  product?: ProductModel;
  form?: ApplicationForm;
  offer?: UnderwritingOfferModel;
  selectedTerms?: SelectedTermsModel;
  state?: ApplicationStates;
  mostRecentDecision?: string;
  underwriting?: UnderwritingModel;
  trackedRequests?: TrackedRequests[];
  signedDate?: string;
  submitDate?: string;
  iovationSigAppended?: boolean;
  loan?: LoanInfo;
  abTesting?: AbTestingData[];
}

export interface AcceptedTerms {
  terms: Terms;
}

interface Terms {
  firstPaymentAt: string;
  paymentAmount: number;
  numberOfPayments: number;
  tilaApr: number;
  fundedAmount: number;
  effectiveAt: string;
  disbursementDate: string;
  interestRate: number;
  line: number;
  originationFee: number;
}
export interface AbTestingData {
  testName: string;
  segmentName: string;
}

export interface LoanInfo {
  customerId?: string;
  loanId?: string;
}
export enum BankDataProviders {
  None = 'NONE',
  Plaid = 'PLAID',
  Finicity = 'FINICITY'
}

export interface ProductModel {
  lenderCode?: string;
  id?: number;
  rollupId?: number;
  bankDataProvider?: BankDataProviders;
  stateLine?: StateLineModel;
}

export interface StateLineModel {
  minAmount?: number;
  maxAmount?: number;
}

export interface MarriedSpouseModel {
  status: MaritalStatusModel;
  spouseFirstName?: string;
  spouseLastName?: string;
  spouseEmailAddress?: string;
}

export interface ShortAppOffer {
  firstname?: string;
  lastname?: string;
  email?: string;
  state?: string;
  code?: string;
  addressLine1?: string;
  addressLine2?: string;
  postalCode?: string;
  creditLimit?: number;
  annualFee?: number;
  purchaseAPR?: number;
  transferAPR?: number;
  disclosureGroup?: string;
  creditCardAgreementVersion?: string;
}

export enum MaritalStatusModel {
  Married = 'Married',
  Unmarried = 'Single'
}

export enum FundMethodType {
  Paper = 'Paper',
  Electronic = 'Electronic'
}

export interface ApplicationForm {
  applicationId?: string;
  enrollmentCode?: string;
  continuePath?: string;
  disclosures?: Disclosure[];
  requestedAmount?: number;
  fundMethod?: FundMethodType;
  paymentMethod?: FundMethodType;
  autopay?: boolean;
  reEvaluateABTesting?: boolean;
  applicant?: {
    marital?: MarriedSpouseModel;
    recentlyMoved?: boolean;
    identity?: ApplicantIdentityModel;
    residences?: ApplicantResidenceModel[];
    electronicCommunicationAgreement?: boolean;
    bank?: {
      provider?: {
        token?: string;
        source?: string;
      };
      draftFromKey?: string;
      connectionResourceUrl?: string;
      accounts?: ApplicantBankAccountModel[];
    };
    phones?: ApplicantPhoneModel[];
    emails?: ApplicantEmailModel[];
    income?: ApplicantIncomeModel;
    employments?: ApplicantEmploymentModel[];
  };
  selectedTerms?: SelectedTermsModel;
  shortAppOffer?: ShortAppOffer;
}
