import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  IsSsnChangedData,
  IsSsnChangedModel,
  PartnerLeadIdModel,
  SendCodeRequestModel,
  VerifiedResponseModel,
  VerifyCodeRequestModel,
  VerifySSNPrepopRequestModel,
  VerifySSNRequestModel
} from './partner-verification.models';
import { InjectionToken } from '@angular/core';

export const PARTNER_VERIFICATION_SERVICE = new InjectionToken(
  'PartnerVerificationService'
);

export interface IPartnerVerificationService {
  getVerificationOptions(leadId: string): Observable<HttpResponse<any>>;

  getLast4PhoneNo(
    getPhoneNoData: PartnerLeadIdModel
  ): Observable<HttpResponse<any>>;

  getLeadIdLendingTree(
    gcid: string,
    trackingId: string
  ): Observable<HttpResponse<any>>;

  getLast4PhoneNoPrepop(leadId: string): Observable<HttpResponse<any>>;
  sendCode(sendCodeData: SendCodeRequestModel): Observable<any>;

  verifyCode(
    verificationCodeData: VerifyCodeRequestModel
  ): Observable<HttpResponse<VerifiedResponseModel>>;

  verifyLast4SSN(
    ssnVerificationData: VerifySSNRequestModel
  ): Observable<HttpResponse<VerifiedResponseModel>>;

  verifyLast4SSNPrepop(
    ssnVerificationData: VerifySSNPrepopRequestModel
  ): Observable<HttpResponse<VerifiedResponseModel>>;

  isSsnChanged(ssnChangedData: IsSsnChangedData): Observable<IsSsnChangedModel>;
}
