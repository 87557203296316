import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageFormGroup } from '@core/page-form-group/page-form-group';
import { requiredValidator } from '@elevate/forms';
import { ControlValidationMessage } from '../getting-started.content';

export interface ResumeVerificationInfo {
  verificationCode: string;
  sendCodeMethod: string;
}

@Injectable()
export class VerificationCodeFormGroup extends PageFormGroup {

  constructor() {
    super({});
  }

  public build(validationMessage: ControlValidationMessage): void {
    this.addControl(
      'verificationCode',
      new FormControl(null, {
        validators: [requiredValidator(validationMessage.requiredMessage)]
      })
    );
  }
}

@Injectable()
export class CommunicationTypeFormGroup extends PageFormGroup {

  constructor() {
    super({
      sendCodeMethod: new FormControl(null, {
        validators: [requiredValidator('Required')]
      })
    });
  }
}
