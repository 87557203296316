import {
  AfterViewInit,
  Component,
  Inject,
  NgZone,
  OnInit,
  SecurityContext,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  ECCustomDateAdapter,
  ECCustomDateParserFormatter
} from '@core/datepicker/EDCustomDatepickerAdapter.service';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbModal,
  NgbModalRef
} from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';

import { StateMessageService } from '@core/state-message/state-message-service';
import { OnDestroy } from '@angular/core';
import { LoadingModalService } from '../../loading-modal/loading-modal.service';
import {
  AppendDeviceRequest,
  ApplicationApi,
  CreateApplicationRequest,
  StartOption,
  StartOptionsRequest,
  StartOptionsResponse
} from '../../application.api';
import moment from 'moment';
import {
  GettingStartedBaseComponent,
  GettingStartedPageBase
} from '@application/getting-started/getting-started-base.component';
import { ResumeModalComponent } from '@application/getting-started/resume-modal/resume-modal.component';
import { GettingStartedFormGroup } from '@application/getting-started/getting-started.form';
import { GettingStartedValidator } from '@application/getting-started/getting-started.validator';
import { GoToSigninModalComponent } from '@application/getting-started/goto-signin-modal/goto-signin-modal.component';
import { NoOptionModalComponent } from '@application/getting-started/no-option-modal/no-option-modal.component';
import { PartnerRegisterActionsService } from '@core/partner-register-actions/partner-register-actions.service';
import {
  IPartnerVerificationService,
  PARTNER_VERIFICATION_SERVICE
} from '@core/partner/partner-verification.interface';
import { PartnerVerificationModalComponent } from '../partner-verification-modal/partner-verification-modal.component';
import {
  IsSsnChangedModel,
  PartnerApplicantModel,
  PartnerLeadIdModel,
  VerifiedResponseModel
} from '@core/partner/partner-verification.models';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TextInputComponent } from '@elevate/ui-components';
import { Environment } from '@environment/environment';
import { GoogleAnalytics } from '@core/google-analytics/googleanalytics.service';
import { UserInactivityStatusService } from '@core/inactivity-module/user-inactivity.service';
import { StateEligibilityService } from '@application/getting-started/state-eligibility/state-eligibility.service';
import { AppInsightsService } from '@core/app-insights/app-insights.service';
import { CmsPageContentService } from '@core/cms/services/cms-page-content.service';
import { CmsGettingStarted } from '@application/getting-started/getting-started.content';
import { TodayCardHandleService } from '@core/brand-handle-services/today-card-handle.service';
import { firstValueFrom, lastValueFrom, Subscription } from 'rxjs';
import { ConfigurationService } from '@core/configuration/configuration.service';
import {
  AbTestingData,
  ApplicationFlow,
  ApplicationForm,
  Disclosure
} from '@application/application';
import { ApplicationFlowService } from '@core/application-flow/application-flow.service';
import { CurrencyPipe } from '@angular/common';
import { ApplicationDataService } from '@application/application.service';
import { GettingStarted } from '@application/getting-started/getting-started';
import {
  SessionStorageKeys,
  SessionStorageObjects,
  SessionStorageService
} from '@core/session-storage/session-storage.service';
import { ValidationMessagesError } from '@elevate/forms';
import { CookieEnums, CookieService } from '@core/cookie/cookie-service';
import { ConsentsComponent } from '@application/consents/consents.component';
import { ConsentHelper } from '@application/consents/consent.helper';
import { NeuroIdService } from '@core/neuro-id/neuro-id.service';
import { CookieAttributes } from 'js-cookie';
import { IovationService } from '@core/iovation/iovation.service';
import { UnderwritingApiService } from '@application/underwriting/underwriting-api.service';

@Component({
  templateUrl: '../../getting-started/getting-started.component.html',
  styleUrls: ['../../getting-started/getting-started.component.scss'],
  providers: [
    GettingStartedFormGroup,
    GettingStartedValidator,
    {
      provide: NgbDateAdapter,
      useClass: ECCustomDateAdapter
    },
    {
      provide: NgbDateParserFormatter,
      useClass: ECCustomDateParserFormatter
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class PartnerLandingComponent extends GettingStartedBaseComponent
  implements OnInit, OnDestroy, AfterViewInit, GettingStartedPageBase {
  @ViewChild('consentsComponent') private consentsComponent: ConsentsComponent;
  @ViewChild('consentToCommunicationComponent')
  private consentToCommunicationComponent: ConsentsComponent;
  @ViewChild('ssn') private elementRef: TextInputComponent;
  @ViewChild('email') private emailInput: TextInputComponent;

  public cmsPartnerPageContent: any; // content object of "Prequalification: Getting Started" Content type for Partner page
  public pageSubTitle: string;
  public pageContent: SafeHtml;
  public showDebugHud = 'partner';
  public recaptchaIsHidden = true;
  public resumeCookie: string;
  public ssnHasBeenDeleted = false;
  public leadId: string;
  public partnerModalRef: NgbModalRef;
  public gcid: string;
  public gcidCookieValue: string;
  public audId: string;
  public offerId: string;
  public partnerIds: PartnerLeadIdModel;
  public disclosurePageContent: SafeHtml;
  public applicantInfo: VerifiedResponseModel;
  private verificationResponse;
  private getLeadIdResponse;
  private phoneNumberLast4Response;
  private nextPage: string;
  public isPrePop = false;
  private selectedApplicationFlow: ApplicationFlow;
  public formChangesSubscription: Subscription;
  public abTestingFromRedirect?: AbTestingData[];
  public stateFromRedirect?: string;
  continueVerificationModal = true;

  constructor(
    public form: GettingStartedFormGroup,
    public route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public stateMessageService: StateMessageService,
    public modalService: NgbModal,
    private router: Router,
    private applicationApi: ApplicationApi,
    private activatedRoute: ActivatedRoute,
    private applicationDataService: ApplicationDataService,
    public ngZone: NgZone,
    @Inject(PARTNER_VERIFICATION_SERVICE)
    private partnerService: IPartnerVerificationService,
    private partnerRegisterActionsService: PartnerRegisterActionsService,
    public loadingService: LoadingModalService,
    private cmsPageContentService: CmsPageContentService,
    public environment: Environment,
    public googleanalytics: GoogleAnalytics,
    private userAppStarted: UserInactivityStatusService,
    private neuroIdService: NeuroIdService,
    public validators: GettingStartedValidator,
    public stateEligibilityService: StateEligibilityService,
    public appInsightsService: AppInsightsService,
    public todayCardHandleService: TodayCardHandleService,
    public configurationService: ConfigurationService,
    @Inject('window') public window: Window,
    public applicationFlowService: ApplicationFlowService,
    public currencyPipe: CurrencyPipe,
    public cookieService: CookieService,
    private iovationService: IovationService,
    private underwriting: UnderwritingApiService,
    public sessionStorageService: SessionStorageService
  ) {
    super(
      form,
      modalService,
      stateMessageService,
      validators,
      stateEligibilityService,
      googleanalytics,
      appInsightsService,
      environment,
      route,
      todayCardHandleService,
      configurationService,
      currencyPipe,
      loadingService,
      window
    );

    const application = this.applicationDataService.getApplication();
    if (Object.keys(application).length > 0) {
      setTimeout(() => {
        if (application.applicationFlow === ApplicationFlow.PartnerPrepop) {
          this.isPrePop = true;
        }
        const applicationForm = application.form;
        if (
          applicationForm.applicant.identity.socialSecurityNumber.includes(
            'XXXXX'
          )
        ) {
          let last4ssn = applicationForm.applicant.identity.socialSecurityNumber.slice(
            -4
          );
          applicationForm.applicant.identity.socialSecurityNumber = `55555${last4ssn}`;
        }
        this.form.patchValue(this.createGettingStartedData(applicationForm));
        this.applicantInfo = this.sessionStorageService.getObject(
          SessionStorageObjects.partnerOfferInfo
        );
        this.selectedApplicationFlow = application.applicationFlow;
        this.nextPage = applicationForm.continuePath;
        this.updateCmsContentWithApplicantInfo;
        this.setFormTriggers();
      });
    } else {
      this.activatedRoute.queryParams.subscribe(async (params: Params) => {
        this.leadId = params['lid'];
        this.gcid = params['gcid'];
        this.audId = params['aud_id'];
        this.offerId = params['oid'];
        if (params['redirect']) {
          const redirectDataObject: any = JSON.parse(
            atob(decodeURIComponent(params['redirect']))
          );
          this.googleAnalytics.redirectionFromLegacy(redirectDataObject?.state);
          if (redirectDataObject?.abTesting) {
            this.abTestingFromRedirect = redirectDataObject?.abTesting;
            this.stateFromRedirect = redirectDataObject?.state;
          }
        } else if (params['segmentName']) {
          this.abTestingFromRedirect = [
            { testName: params['testName'], segmentName: params['segmentName'] }
          ];
        }
        this.selectedApplicationFlow = this.offerId
          ? ApplicationFlow.PartnerPrequal
          : ApplicationFlow.PartnerPrepop;

        if (!this.offerId) {
          this.isPrePop = true;
        }

        this.gcidCookieValue = this.cookieService.getCookieValue(
          CookieEnums.gcid
        );
        this.gcid = this.gcid ? this.gcid : this.gcidCookieValue;
        const cookieAttributes: CookieAttributes = {
          domain: this.environment.setCookieConfig.gcid.domain,
          expires: this.environment.setCookieConfig.gcid.expiryInDays
        };

        if (this.gcid && this.gcid != this.gcidCookieValue) {
          this.cookieService.set(CookieEnums.gcid, this.gcid, cookieAttributes);
        }

        this.sessionStorageService.setItem(
          SessionStorageKeys.applicationGCID,
          this.gcid
        );

        var gtagdta: any = {
          lead_id: this.leadId,
          aud_id: this.audId,
          gcid: this.gcid,
          offer_id: this.offerId,
          full_referral_url: document.referrer,
          userType: this.selectedApplicationFlow
        };
        this.googleanalytics.setGoogleTagManagerVariables(gtagdta);

        if (this.audId) {
          try {
            this.getLeadIdResponse = await lastValueFrom(
              this.partnerService.getLeadIdLendingTree(this.gcid, this.audId)
            );
            this.leadId = this.getLeadIdResponse.body.leadId;
          } catch {
            this.ngZone.run(() => this.router.navigate(['/getting-started']));
            return;
          }
        }
        this.partnerIds = {
          leadId: this.leadId,
          offerId: this.offerId
        };
        this.partnerRegisterActionsService.registerPartnerAction(
          this.partnerIds,
          'click'
        );
        this.loadingService.open();
        try {
          this.verificationResponse = await lastValueFrom(
            this.partnerService.getVerificationOptions(this.leadId)
          );

          await lastValueFrom(
            this.partnerService.getLast4PhoneNoPrepop(this.leadId)
          ).then(res => {
            this.phoneNumberLast4Response = res;
            setTimeout(() => {
              if (this.continueVerificationModal) {
                this.openVerificationModal();
              }
            }, 1000);
          });
        } catch {
          this.ngZone.run(() => this.router.navigate(['/getting-started']));
        }
      });
    }
  }

  public async ngOnInit(): Promise<void> {
    super.ngOnInit();

    this.initiateCmsPageContent();

    this.resumeCookie = this.cookieService.get(CookieEnums.ResumeByAppId);
  }

  public ngAfterViewInit(): void {
    if (!this.isPrePop) {
      this.formConfig.ssnConfig.hidden = 'always';
    }
  }

  public async onFocusOut(event: any): Promise<void> {
    this.googleAnalytics.formInteractionEvent({
      step_name: event.target.id
    });
  }

  public onAddressSuggestionError(event: any) {
    this.appInsightsService.trackException(event);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.continueVerificationModal = false;
    if (this.partnerModalRef) {
      this.partnerModalRef.close();
    }
    if (this.formChangesSubscription != null) {
      this.formChangesSubscription.unsubscribe();
    }
  }

  public fillPartnerForm(partnerInfo: PartnerApplicantModel): void {
    this.form.patchValue({
      firstname: partnerInfo.firstName,
      lastname: partnerInfo.lastName,
      suffix: partnerInfo.suffix,
      dob: moment(partnerInfo.dateOfBirth, 'YYYY-MM-DD').format('MM/DD/YYYY'),
      ssn: `55555${partnerInfo.last4ssn}`,
      addressLine1: partnerInfo.address.line1,
      addressLine2: partnerInfo.address.line2,
      city: partnerInfo.address.city,
      state: partnerInfo.address.stateCode,
      postalCode: partnerInfo.address.zipCode,
      mobile: partnerInfo.mobileNumber.replace(/\D/g, ''),
      email: partnerInfo.email
    });
    this.setFormTriggers();
  }

  public setFormTriggers() {
    const formValue = this.form.value;
    this.onStateUpdate(formValue.state);
    this.form.get('dob').markAsTouched({ onlySelf: true });
    this.userAppStarted.setUserApplicationStatus(true);
    if (!this.isPrePop) {
      this.elementRef.setDisabledState(true);
    } else {
      if (this.form.get('ssn').value.includes('55555')) {
        this.formConfig.ssnConfig.keepHidden = true;
      }
    }
  }

  public async submit(): Promise<void> {
    if (!this.applicationFlowService.getApplicationFlows()) {
      await this.applicationFlowService.setApplicationFlows();
    }

    if (super.isStateNotEligible()) {
      return;
    }

    this.form.showValidationErrors();

    if (!this.form.valid) {
      return;
    }
    this.loadingService.open();
    this.nextPage = this.applicationFlowService.getContinuePath(
      null,
      this.selectedApplicationFlow
    );
    const existingData = this.createGettingStartedData(
      this.applicationDataService.getApplication()?.form
    );
    const formValue = this.form.value;

    const isSsnChangedData = {
      leadId: this.applicantInfo?.partnerLead?.id,
      ssn: formValue.ssn
    };
    await lastValueFrom(
      this.partnerService.isSsnChanged(isSsnChangedData)
    ).then(async (response: IsSsnChangedModel) => {
      const isSsnChanged = formValue.ssn?.includes('55555')
        ? false
        : response.isChanged;
      if (
        !existingData ||
        this.applicantInfo.partnerLead.applicant.email !== formValue.email ||
        isSsnChanged
      ) {
        this.callStartOptions(formValue.ssn, isSsnChanged);
      } else if (
        this.applicantInfo.partnerLead.applicant.email === formValue.email &&
        !isSsnChanged
      ) {
        this.createApplication();
      }
    });
  }

  public openNoOptionsModal(): void {
    const noOptionModalRef = this.modalService.open(NoOptionModalComponent);
    noOptionModalRef.componentInstance.htmlContent = this.cmsPageContent.modals.noOptionModal;
    noOptionModalRef.result.then(() => {
      this.form.controls.email.markAsTouched();
      this.form.controls.email.markAsDirty();
      this.emailInput.input.nativeElement.focus();
      this.form.controls.email.updateValueAndValidity();
    });
  }

  private openVerificationModal(): void {
    this.loadingService.close();
    this.partnerModalRef = this.modalService.open(
      PartnerVerificationModalComponent,
      {
        backdrop: 'static',
        windowClass: 'verification-modal'
      }
    );
    this.partnerModalRef.componentInstance.data = {
      ...this.partnerIds,
      phoneNumberLast4: this.phoneNumberLast4Response.body.phoneNumberLast4,
      isSsnVerification: this.verificationResponse.body.ssnVerification,
      isOtpVerification: this.verificationResponse.body.otpVerification
    };
    this.partnerModalRef.componentInstance.htmlContent = this.cmsPageContent.modals.partnerVerificationModal;
    this.partnerModalRef.result.then(data =>
      this.handleVerificationModalResponse(data)
    );
  }

  private initiateCmsPageContent(): void {
    this.route.data.pipe(takeUntil(this.onDestroyed)).subscribe((data: any) => {
      this.cmsPartnerPageContent = data.cmsContent.prequalificationPartner;

      this.cmsPageContent = data.cmsContent.prequalificationPartner
        .gettingStartedCommon as CmsGettingStarted;

      this.form.build(this.cmsPageContent.validationMessages);

      const dateRangeMessage = this.cmsPageContent.validationMessages
        .dateOfBirth.otherErrorMessage;
      this.formConfig.dobConfig.errorDateRange = dateRangeMessage
        ? dateRangeMessage
        : this.formConfig.dobConfig.errorDateRange;

      this.pageSubTitle = null;
      this.cmsPageContentService.updatePageTitle(
        this.cmsPageContent.welcomeTitle
      );

      this.disclosurePageContent = this.cmsPageContent.disclosuresInfo;
      this.pageContent = this.sanitizer.sanitize(
        SecurityContext.HTML,
        this.cmsPageContent.content
      );

      this.pageSubTitleContent =
        !this.isPrePop && this.cmsPartnerPageContent.subHeaderContent
          ? this.sanitizer.sanitize(
              SecurityContext.HTML,
              this.cmsPartnerPageContent.subHeaderContent
            )
          : this.sanitizer.sanitize(
              SecurityContext.HTML,
              this.cmsPartnerPageContent.condSubHeader
            );

      this.pageConsentSectionTitle = this.cmsPartnerPageContent.consentSectionTitle;

      this.pageConsentSectionContent = this.cmsPartnerPageContent
        .consentSectionContent
        ? this.sanitizer.sanitize(
            SecurityContext.HTML,
            this.cmsPartnerPageContent.consentSectionContent
          )
        : null;

      this.consentsSection = ConsentHelper.converToArray(
        this.cmsPartnerPageContent.consentsSection
      );

      if (this.cmsPageContent.disclosures.consentToCommunication != null) {
        this.consentToCommunication = [
          {
            consent: this.cmsPageContent.disclosures.consentToCommunication
          }
        ];
      } else {
        this.consentToCommunication = null;
      }
    });
  }

  private updateCmsContentWithApplicantInfo(): void {
    const title = this.cmsPageContent.welcomeNameTitle.replace(
      '{name}',
      this.applicantInfo.partnerLead.partnerName
    );
    this.cmsPageContentService.updatePageTitle(title);

    const loandAmountStr = this.applicantInfo.offer?.loanAmount?.toLocaleString(
      'en-US',
      {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
      }
    );
    this.pageSubTitle = !this.isPrePop
      ? this.cmsPartnerPageContent.subHeader?.replace(
          '{prequalAmount}',
          loandAmountStr
        )
      : null;
  }

  private handleVerificationModalResponse(
    response: VerifiedResponseModel
  ): void {
    if (response) {
      this.sessionStorageService.setObject(
        SessionStorageObjects.partnerOfferInfo,
        response
      );
      this.applicantInfo = response;
      this.updateCmsContentWithApplicantInfo();
      this.fillPartnerForm(this.applicantInfo.partnerLead.applicant);
      this.neuroIdService.setVariable(
        'partnerId',
        this.applicantInfo.partnerLead.gcid
      );
      this.neuroIdService.setVariable('partnerchannel', 'PQ');

      switch (this.applicantInfo.startOptions) {
        case StartOption.NoOptions:
          this.loadingService.close();
          this.form.duplicateEmail = this.applicantInfo.partnerLead.applicant.email;
          this.openNoOptionsModal();
          return;
        case StartOption.RedirectToBau:
          this.loadingService.close();
          this.openGoToSignInModal({
            startOptions: this.applicantInfo.startOptions
          });
          return;
        case StartOption.RedirectFormerRefinanceSignIn:
          this.loadingService.close();
          this.openGoToSignInModal(
            {
              startOptions: this.applicantInfo.startOptions
            },
            !this.isPrePop,
            this.cmsPartnerPageContent.formerSigninModal
          );
          return;
        case StartOption.StartNewOnly:
          return;
        case StartOption.ResumeOrStartNew:
        case StartOption.ResumeOnly: {
          this.loadingService.close();
          const resumeModalRef = this.modalService.open(ResumeModalComponent, {
            backdrop: 'static',
            windowClass: 'resume-modal'
          });
          resumeModalRef.componentInstance.htmlContent = this.cmsPageContent.modals.resumeModal;
          resumeModalRef.componentInstance.data = {
            phoneNumberLast4: this.phoneNumberLast4Response.body
              .phoneNumberLast4,
            applicationId: this.applicantInfo.applicationId,
            startOptions: this.applicantInfo.startOptions,
            gcid: this.gcid,
            showCloseBtn:
              this.applicantInfo.startOptions === StartOption.ResumeOnly
                ? false
                : true,
            firstName: this.applicantInfo.partnerLead.applicant.firstName,
            fromPartnerModalVerification: true
          };
          resumeModalRef.result.then(resumeData => {
            if (resumeData && !resumeData.resumeApplication) {
              return;
            }
          });
          return;
        }
      }
    }
  }

  private async callStartOptions(
    ssn: string,
    isSsnChanged: boolean,
    existingData?: GettingStarted
  ): Promise<void> {
    let startOptionResponse: StartOptionsResponse;

    const startOptionsRequest: StartOptionsRequest = {
      brand: this.environment.brand,
      applicationFlow: this.selectedApplicationFlow,
      email: this.form.get('email').value,
      stateCode: this.form.get('state').value,
      dateOfBirth: this.form.get('dob').value,
      leadId: this.applicantInfo.partnerLead.id
    };

    if (isSsnChanged) {
      startOptionsRequest.socialSecurityNumber = ssn;
    }

    if (
      this.abTestingFromRedirect != null &&
      this.applicantInfo.partnerLead.applicant.address.stateCode ==
        this.form.get('state').value
    ) {
      startOptionsRequest.abTesting = this.abTestingFromRedirect;
    }

    startOptionResponse = await lastValueFrom(
      this.applicationApi.startOptions(startOptionsRequest)
    );

    switch (startOptionResponse.startOptions) {
      case StartOption.RedirectToLegacy:
        const redirectContent = this.route.snapshot.data.cmsContent
          .prequalificationGettingStarted.redirectToLegacy;
        if (redirectContent) {
          this.handleRedirectToLegacyStartOption(
            existingData,
            startOptionResponse,
            redirectContent
          );
        }
        return;
      case StartOption.NoOptions:
        this.loadingService.close();
        const noOptionModalRef = this.modalService.open(NoOptionModalComponent);
        noOptionModalRef.componentInstance.htmlContent = this.cmsPageContent.modals.noOptionModal;
        return;
      case StartOption.RedirectToBau:
        this.loadingService.close();
        this.openGoToSignInModal(startOptionResponse);
        return;
      case StartOption.RedirectFormerRefinanceSignIn:
        this.loadingService.close();
        this.openGoToSignInModal(
          startOptionResponse,
          !this.isPrePop,
          this.cmsPartnerPageContent.formerSigninModal
        );
        return;
      case StartOption.StartNewOnly:
        this.createApplication();
        return;
      case StartOption.ResumeOrStartNew:
      case StartOption.ResumeOnly: {
        this.loadingService.close();
        const resumeModalRef = this.openResumeModal(startOptionResponse);
        resumeModalRef.result.then(data => {
          if (data && !data.resumeApplication) {
            this.createApplication();
          }
        });
        return;
      }
    }
  }

  private appendDisclosuresToFormData(formData: ApplicationForm): void {
    if (formData.disclosures == null) {
      formData.disclosures = [];
    }
    let allDisclosures = [
      ...formData.disclosures,
      ...(this.consentsComponent?.disclosures || []),
      ...(this.consentToCommunicationComponent?.disclosures || []),
      ...ConsentHelper.getDefaultDisclosures(
        Boolean(this.form.value.consentToCommunication)
      )
    ];

    // Filter out duplicates by key
    const uniqueDisclosures = allDisclosures.filter(
      (disclosure, index, self) =>
        index === self.findIndex(d => d.key === disclosure.key)
    );
    formData.disclosures = uniqueDisclosures;

    this.defaultSetConsentToElectronicAgreement(formData);
  }

  private async defaultSetConsentToElectronicAgreement(
    formData: ApplicationForm
  ) {

    if (formData.disclosures == null) {
      formData.disclosures = [];
    }
    if (
      formData.disclosures.find(
        d => d.key === 'electronicCommunicationAgreement'
      ) == null
    ) {
      var electronicDisclosure: Disclosure = {
        key: 'electronicCommunicationAgreement',
        consentGiven: true,
        isIndependentDocument: false
      };
      formData.disclosures.push(electronicDisclosure);
    }
    formData.applicant.electronicCommunicationAgreement = true;
  }
  private async createApplication(): Promise<void> {
    if (
      this.sessionStorageService.getItem(
        SessionStorageKeys.authorizationToken
      ) !== null
    ) {
      this.appendApplicationData();
      return;
    }

    const formData = this.createApplicationRequest(
      this.form.value,
      this.nextPage
    );

    const ssnToPopulate = formData.applicant.identity.socialSecurityNumber?.includes(
      '55555'
    )
      ? null
      : formData.applicant.identity.socialSecurityNumber;

    if (ssnToPopulate) {
      formData.applicant.identity.socialSecurityNumber = ssnToPopulate;
    } else {
      delete formData.applicant.identity.socialSecurityNumber;
    }

    if (this.consentsComponent?.disclosures.length > 0) {
      this.appendDisclosuresToFormData(formData);
    } else {
      this.defaultSetConsentToElectronicAgreement(formData);
    }
    if (this.applicantInfo.partnerLead.requestedAmount) {
      formData.requestedAmount = this.applicantInfo.partnerLead.requestedAmount;
    }

    const createApplicationRequest: CreateApplicationRequest = {
      brand: this.environment.brand,
      applicationFlow: this.selectedApplicationFlow,
      partner: {
        leadId: this.applicantInfo.partnerLead.id,
        partnerName: this.applicantInfo.partnerLead.partnerName
      },
      form: formData,
      abTesting: this.abTestingFromRedirect
    };

    if (this.offerId) {
      createApplicationRequest.partner.offerId = this.applicantInfo.offer.id;
    }
    if (this.applicantInfo.customerId) {
      createApplicationRequest.customerId = this.applicantInfo.customerId;
    }

    if (this.applicantInfo.customerIdSignature) {
      createApplicationRequest.customerSignature = this.applicantInfo.customerIdSignature;
    }

    await lastValueFrom(
      this.applicationApi.create(createApplicationRequest)
    ).then(
      async httpResponse => {
        this.sessionStorageService.setItem(
          SessionStorageKeys.authorizationToken,
          httpResponse.token
        );
        this.updategoogleAnalyticsVariables(this.selectedApplicationFlow);
        this.applicationDataService.replaceApplicationForm({
          form: formData
        });

        if (this.resumeCookie) {
          this.cookieService.remove(CookieEnums.ResumeByAppId);
        }

        this.userAppStarted.setUserApplicationStatus(true);
        this.neuroIdService.setVariable('funnel', this.selectedApplicationFlow);

        await this.appendDeviceAndStartPrequalification();
        this.router.navigate([this.nextPage]);
      },
      httpError => {
        if (
          httpError.status === 422 &&
          httpError?.error['message'] === 'EnrollmentCode'
        ) {
          this.form
            .get('code')
            .setErrors(
              new ValidationMessagesError(
                'enrollmentCode',
                null,
                this.cmsPageContent.validationMessages.enrollmentCode.otherErrorMessage
              )
            );
          this.loadingService.close();
        } else if (httpError.error.message.includes('is not Serviced')) {
          this.loadingService.close();
          this.isStateEligible = false;
          this.isStateNotEligible();
        }
      }
    );
  }

  private async appendApplicationData(): Promise<void> {
    const formData = this.createApplicationRequest(
      this.form.value,
      this.nextPage
    );
    if (this.applicantInfo?.partnerLead.requestedAmount) {
      formData.requestedAmount = this.applicantInfo.partnerLead.requestedAmount;
    }
    const ssnToPopulate = formData.applicant.identity.socialSecurityNumber?.includes(
      '55555'
    )
      ? null
      : formData.applicant.identity.socialSecurityNumber;

    if (ssnToPopulate) {
      formData.applicant.identity.socialSecurityNumber = ssnToPopulate;
    } else {
      delete formData.applicant.identity.socialSecurityNumber;
    }

    this.applicationDataService.replaceApplicationForm({ form: formData });

    await lastValueFrom(this.applicationApi.append(formData)).then(
      () => {
        this.router.navigate([this.nextPage]);
        this.loadingService.close();
      },
      (error: any) => {
        if (error.error.message.includes('is not Serviced')) {
          this.loadingService.close();
          this.isStateEligible = false;
          this.isStateNotEligible();
        }
      }
    );
  }

  public debugHudSubmit(): void {
    setTimeout(() => {
      this.consentsComponent?.debugHudSubmitAllConsents();
      this.consentToCommunicationComponent?.debugHudSubmitAllConsents();
    });
  }

  private async appendDeviceAndStartPrequalification(): Promise<void> {
    if (this.nextPage !== 'verify-info') {
      await this.appendApplicationDevice();
      if (!this.offerId) {
        const application = this.applicationDataService.getApplication();
        this.underwriting.submitApplicationPrequalUnderwriting(application.id);
      }
    }
  }

  private async appendApplicationDevice(): Promise<void> {
    const blackBoxResponse = await lastValueFrom(
      this.iovationService.getIovationSignature()
    );
    const application = this.applicationDataService.getApplication();
    if (!application.iovationSigAppended) {
      const appendDeviceRequest = {
        iovationRequestType: this.environment.iovation.requestType,
        iovationSignature: blackBoxResponse.blackbox,
        neuroID: this.neuroIdService.neuroIdSessionId
      } as AppendDeviceRequest;

      let gcid = this.sessionStorageService.getItem(
        SessionStorageKeys.applicationGCID
      );

      if (gcid !== 'undefined') {
        appendDeviceRequest.GCID = gcid;
      }
      try {
        await lastValueFrom(
          this.applicationApi.appendDevice(appendDeviceRequest)
        );
      } catch {
        this.router.navigate(['/error']);
      }
    }
  }
}
