<div class="row" [formGroup]="form">
  <ecl-text-input
    class="col-md-8"
    formControlName="primaryEmployer"
    [formControlConfig]="primaryEmployerConfig"
    googleAnalyticsFocusOut="primaryEmployer"
  >
    {{ CMSEmployer.label }}
  </ecl-text-input>
</div>
