import { Component, Input, OnInit } from '@angular/core';
import { BaseModalConfig } from '@elevate/base-modal';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleAnalytics } from '@core/google-analytics/googleanalytics.service';

export interface UsePreviousAccountModalCmsContent {
  content: string;
  buttonContinue: string;
}

@Component({
  selector: 'app-use-previous-account-modal',
  templateUrl: './use-previous-account-modal.component.html',
  styleUrls: ['./use-previous-account-modal.component.scss']
})
export class UsePreviousAccountModalComponent implements OnInit {
  @Input() public modalCmsData: UsePreviousAccountModalCmsContent;
  @Input() public modalData: BaseModalConfig = {
    heading: null,
    content: null,
    action: {
      submitButton: null,
      allowClose: false,
      onSubmit: () => this.activeModal.close(true)
    }
  } as BaseModalConfig;

  constructor(
    private activeModal: NgbActiveModal,
    public googleAnalytics: GoogleAnalytics
  ) {}

  ngOnInit(): void {
    const defaultModalData = {
      content: `<p>Please use the checking account you previously provided on your application.
      By clicking CONTINUE you acknowledge that if a different account is used for
      this verification step it will change the account we use for funding and
      payment transactions.</p>`,
      buttonContinue: 'CONTINUE'
    } as UsePreviousAccountModalCmsContent;

    this.modalCmsData = this.modalCmsData || defaultModalData;

    this.modalData.content = this.modalCmsData.content;
    this.modalData.action.submitButton = this.modalCmsData.buttonContinue;
  }
}
