import { Injectable, OnDestroy } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { PageFormGroup } from '@core/page-form-group/page-form-group';
import { DatePicker } from '@elevate/date-picker';
import {
  dateRangeValidator,
  numericRangeValidator,
  requiredValidator
} from '@elevate/forms';
import moment from 'moment';
import { Subscription } from 'rxjs';

import {
  PaymentFrequencyCMSValidationMessages,
  PrimaryPaymentFrequencyForm,
  PrimaryPaymentFrequencyValue
} from './payment-frequency';

@Injectable()
export class PaymentFrequencyFormGroup extends PageFormGroup
  implements OnDestroy {

  private subscriptions: Subscription[] = [];
  public paymentFrequencyValue: PrimaryPaymentFrequencyValue;
  public minDate: string;
  public maxDate: string;

  public primaryNextPayDateConfig: DatePicker = {
    id: 'primaryNextPayDateText',
    required: 'true',
    scheduledDate: 'false',
    validationOnBlur: true,
    attributes: {
      'data-nid-target': 'weeklyBiWeeklyFrequency'
    }
  };
  constructor(
    messages: PaymentFrequencyCMSValidationMessages,
    payDateSelectEnabled: boolean
  ) {
    super({
      primaryPaymentFrequency: new FormControl(null, [
        requiredValidator(messages.howOften)
      ]),
      primaryNetAmount: new FormControl(null, [
        requiredValidator(messages.takeAmount),
        numericRangeValidator(1, 99999, messages.amountRange)
      ])
    });
    if (payDateSelectEnabled) {
      this.addControl(
        'primaryNextPayDate',
        new FormControl(
          requiredValidator(messages.nextPayDate),
          dateRangeValidator( this.minDate,  this.maxDate)
          )
      );
      this.subscriptions.push(this.subscribePaymentFrequencyType(messages));
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  private get primaryPaymentFrequency(): AbstractControl {
    return this.get('primaryPaymentFrequency');
  }

  private subscribePaymentFrequencyType(
    messages: PaymentFrequencyCMSValidationMessages
  ): Subscription {
    return this.primaryPaymentFrequency.valueChanges.subscribe(
      (type: PrimaryPaymentFrequencyValue) => {
        this.setMinAndMaxForWeeklyBiWeekly(type);

        this.primaryNextPayDateConfig.minDate = this.minDate;
        this.primaryNextPayDateConfig.maxDate = this.maxDate;
        this.updateNextPayDateValidators(type, messages);
      }
    );
  }
  public updateNextPayDateValidators(
    paymentFrequencyType: PrimaryPaymentFrequencyValue,
    messages: PaymentFrequencyCMSValidationMessages
  ): void {
    const nextPayDateControl = this.controls['primaryNextPayDate'];

    if (
      this.paymentFrequencyValue !== paymentFrequencyType ||
      nextPayDateControl.value === null ||
      nextPayDateControl.value?.includes('undefined')
    ) {
      this.paymentFrequencyValue = paymentFrequencyType;
      nextPayDateControl.setValue(null);
      nextPayDateControl.markAsPristine();
      nextPayDateControl.markAsUntouched();
    }
    if (
      paymentFrequencyType === PrimaryPaymentFrequencyValue.BiWeekly ||
      paymentFrequencyType === PrimaryPaymentFrequencyValue.Weekly
    ) {
      nextPayDateControl.setValidators([
        requiredValidator(messages.nextPayDate),
        dateRangeValidator( this.minDate,  this.maxDate)
      ]);
    } else if (paymentFrequencyType === PrimaryPaymentFrequencyValue.Monthly) {
      nextPayDateControl.setValidators([requiredValidator(messages.whichDay)]);
    } else {
      nextPayDateControl.setValidators([
        requiredValidator(messages.paidMonthly)
      ]);
    }
  }
  public setMinAndMaxForWeeklyBiWeekly(
    type: PrimaryPaymentFrequencyValue
  ): void {
    this.minDate = moment().add(1, 'days').format(
      'MM/DD/YYYY'
    );

    if (type === PrimaryPaymentFrequencyValue.Weekly) {
      this.maxDate = moment().add(1, 'weeks').format(
        'MM/DD/YYYY'
      );
    }

    if (type === PrimaryPaymentFrequencyValue.BiWeekly) {
      this.maxDate = moment().add(2, 'weeks').format(
        'MM/DD/YYYY'
      );
  
    }
  }
}
