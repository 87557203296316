import { Injectable } from '@angular/core';
import { Environment } from '@environment/environment';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { v4 as uuidv4 } from 'uuid';
import {
  SessionStorageObjects,
  SessionStorageService
} from '../session-storage/session-storage.service';
import { Subscription } from 'rxjs';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ApplicationDataService } from '@application/application.service';

/* istanbul ignore next */

export interface GoogleTagAppInsightsTelemetry {
  appInsightsSesssionId?: string;
  device?: string;
}
@Injectable({ providedIn: 'root' })
export class AppInsightsService {
  private readonly telemetryOrigin = "BlueprintWeb";

  private appInsights: ApplicationInsights;
  public routerSubscription: Subscription;

  constructor(
    private environment: Environment,
    private sessionStorageService: SessionStorageService,
    private router: Router,
    private applicationDataService: ApplicationDataService
  ) {}

  public init(): void {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: this.environment.appInsights.instrumentationKey,
        accountId: this.resolveAppInsightsId()
      }
    });

    this.appInsights.loadAppInsights();

    this.routerSubscription = this.router.events
      .pipe(filter((event: any) => event instanceof ResolveEnd))
      .subscribe((event: ResolveEnd) => {
        const activatedComponent = this.getActivatedComponent(event.state.root);
        if (activatedComponent) {
          this.logPageView(event.urlAfterRedirects, event.urlAfterRedirects);
        }
      });
  }

  public getAppInsightsAccountId(): string {
    return this.appInsights.config.accountId;
  }

  public getGoogleTagAppInsightsTelemetry(): GoogleTagAppInsightsTelemetry {
    var appInsightsTelemetry: GoogleTagAppInsightsTelemetry = {
      appInsightsSesssionId: this.appInsights.context.getSessionId(),
      device: this.appInsights.context.device.deviceClass
    };
    return appInsightsTelemetry;
  }

  public logPageView(name?: string, uri?: string): void {
    const application = this.applicationDataService.getApplication();

    this.appInsights.trackPageView({
      name,
      uri,
      properties: {
        telemetryOrigin: this.telemetryOrigin,
        sequenceId: application?.sequenceApplicationId,
        applicationId: application?.id,
        email: application?.form?.applicant?.emails[0]?.address
      }
    });
  }

  public trackEvent(name: string, properties?: any): void {
    const application = this.applicationDataService.getApplication();

    this.appInsights.trackEvent({
      name,
      properties: {
        ...properties,
        telemetryOrigin: this.telemetryOrigin,
        sequenceId: application?.sequenceApplicationId,
        applicationId: application?.id,
        email: application?.form?.applicant?.emails[0]?.address
      }
    });
  }

  public trackException(error: Error, customProperties: {} = {}): void {
    const application = this.applicationDataService.getApplication();
    const email = (application?.form?.applicant?.emails)? application.form.applicant.emails[0]?.address : ""
    console.error(error);
    this.appInsights.trackException(
      { error: error },
      {
        ...customProperties,
        ...error,
        telemetryOrigin: this.telemetryOrigin,
        url: this.router.url,
        sequenceId: application?.sequenceApplicationId,
        applicationId: application?.id,
        email: email
      }
    );
  }

  public trackTrace(trace: { message: any }, customProperties: {} = {}): void {
    const application = this.applicationDataService.getApplication();

    this.appInsights.trackTrace(trace, {
     ...customProperties,
     telemetryOrigin: this.telemetryOrigin,
     sequenceId: application?.sequenceApplicationId,
     applicationId: application?.id,
     email: application?.form?.applicant?.emails[0]?.address
    });
  }

  private resolveAppInsightsId(): string {
    const googleTagManagerVariables = this.sessionStorageService.getObject(
      SessionStorageObjects.googleTagManagerVariables
    );

    if (googleTagManagerVariables?.visitorid) {
      return googleTagManagerVariables.visitorid;
    }

    const guid = uuidv4();
    return guid;
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }
}
