import { Injectable } from '@angular/core';

export interface ParsedDisclosureAgreementData {
  Type: string;
  Label?: string;
  Id: string;
  IsRequired?: string;
  hrefTarget?: string;
  target?: string;
  hyperlinks?: any;
}

@Injectable({ providedIn: 'root' })
export class DynamicComponentsService {
  public cmsDynamicElementsJsonDataCollection: ParsedDisclosureAgreementData[] = [];

  constructor() {}

  public findAndReplaceJsonData(htmlContent: string): string {
    return htmlContent.replace(/{.+?}/gm, this.replaceJson.bind(this));
  }

  private replaceJson(inputJson: string): string {
    let outputTag = '';
    let jsonObject: ParsedDisclosureAgreementData;

    try {
      jsonObject = JSON.parse(inputJson);
      this.cmsDynamicElementsJsonDataCollection.push(jsonObject);
    } catch (error) {
      throw new Error(`json tag in disclosure is not readable: ${inputJson}`);
    }

    switch (jsonObject.Type) {
      case 'Checkbox':
        outputTag = `<app-dynamic-checkbox fieldname="${jsonObject.Id}"></app-dynamic-checkbox>`;
        break;
      case 'Last4SSN':
        outputTag = `<app-dynamic-last4ssn fieldname="${jsonObject.Id}"></app-dynamic-last4ssn>`;
        break;
    }
    return outputTag;
  }
}
