import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { requiredValidator } from '@elevate/forms';
import { TextInput } from '@elevate/ui-components';
import { IESiganture } from '../interfaces/e-siganture.interface';
import {
  ConsentModalAgreement,
  SignatureModel,
  SignatureType
} from '../signature.content';
import { agreementPhraseValidator } from './agreement-phrase.validator';

@Component({
  selector: 'app-agreement-phrase',
  templateUrl: './agreement-phrase.component.html',
  styleUrls: ['./agreement-phrase.component.scss']
})
export class AgreementPhraseComponent
  implements OnInit, OnDestroy, IESiganture {
  @Input() public agreement: ConsentModalAgreement;
  @Input() public formGroup: FormGroup;
  @Input() public controlName = 'agreementPhraseSignature';
  @Input() public initValue: string = null;

  constructor() {}

  public agreementPhraseConfig: TextInput = {
    id: 'agreementPhraseInput',
    placeholder: '',
    minlength: 2,
    maxlength: 100
  };

  public get signatureModel(): SignatureModel {
    return {
      isConsentGiven: this.isValid,
      signatureType: SignatureType.AgreementPhrase,
      controlName: this.controlName,
      signatureValue: this.formGroup.controls[this.controlName]?.value
    };
  }

  public get label(): string {
    return this.agreement.label;
  }
  public get instruction(): string {
    return this.agreement.instruction;
  }
  public get isValid(): boolean {
    return this.formGroup.controls[this.controlName]?.valid;
  }

  ngOnInit(): void {
    this.formGroup.addControl(
      this.controlName,
      new FormControl(this.initValue, [
        requiredValidator(this.agreement.validationRequired),
        agreementPhraseValidator(
          this.agreement.checkPhrase,
          this.agreement.validationNotMatch,
          this.agreement.ignoreCase,
          this.agreement.ignoreSpaces
        )
      ])
    );
  }

  public ngOnDestroy(): void {
    this.formGroup.removeControl(this.controlName);
  }
}
