import { Injectable } from '@angular/core';
import { ApplicationDataService } from '@application/application.service';
import { lastValueFrom, Observable, Subject } from 'rxjs';
import { FinicityApiService } from './finicity-api.service';
import {
  FinicityEventMetaData,
  FinicityEventRequest,
  FinicitySuccessObject
} from './finicity.types';
import { ApplicationData } from '@application/application';
import {
  FinicityConnect,
  ConnectEventHandlers,
  ConnectOptions,
  ConnectDoneEvent,
  ConnectRouteEvent,
  ConnectCancelEvent,
  ConnectErrorEvent
} from '@finicity/connect-web-sdk';
import { AppInsightsService } from '@core/app-insights/app-insights.service';
import { LoadingModalService } from '@application/loading-modal/loading-modal.service';
import { BankConnectionApi } from '@application/connect-bank/connect-bank-api.service';
import {
  GoogleAnalytics,
  GoogleTagData
} from '@core/google-analytics/googleanalytics.service';
import { Environment } from '@environment/environment';

@Injectable({
  providedIn: 'root'
})
export class FinicityService {
  private finicityConnectUrl: string;
  private successSubject = new Subject<FinicitySuccessObject>();
  private application: ApplicationData;
  public failedIntializeCall = false;

  private connectOptions: ConnectOptions = {
    overlay: 'rgba(144, 144, 144, 0.85)',
    popup: false
  };

  private connectEventHandlers: ConnectEventHandlers = {
    onDone: (event: ConnectDoneEvent) => {
      this.successSubject.next({ metaData: event.reportData[0] });
    },
    onRoute: (event: ConnectRouteEvent) => {
      this.onFinicityEvent(event.screen, event.params);
    },
    onCancel: (event: ConnectCancelEvent) => {
      this.onFinicityEvent('cancel', event);
    },
    onError: (event: ConnectErrorEvent) => {
      this.onFinicityEvent('error', event);
    },
    onUser: (event: any) => {
      this.onFinicityEvent(event?.action, event);
    },
    onLoad: () => {
      this.setFinicityIframeAttributes();
    }
  };

  constructor(
    private finicityApi: FinicityApiService,
    private applicationDataService: ApplicationDataService,
    private appInsightsService: AppInsightsService,
    private bankConnectionApi: BankConnectionApi,
    private loadingModal: LoadingModalService,
    private googleAnalytics: GoogleAnalytics,
    private environment: Environment
  ) {}

  public async open(): Promise<void> {
    this.loadingModal.open();
    this.failedIntializeCall = false;
    this.application = this.applicationDataService.getApplication();

    const lenderCode = this.application.product.lenderCode;
    const clientName = this.application.brand;
    const provider = this.application.product.bankDataProvider.toUpperCase();
    //track lender code to GA
    this.googleAnalytics.setGoogleTagManagerVariables({
      lenderCode: lenderCode
    });
    try {
      this.finicityConnectUrl = await lastValueFrom(
        this.finicityApi.getLinkURL(
          provider,
          lenderCode,
          clientName,
          this.environment.finicity.linkCustomizationName,
          'https://experian.com'
        )
      );
      this.loadingModal.close();
      FinicityConnect.launch(
        this.finicityConnectUrl,
        this.connectEventHandlers,
        this.connectOptions
      );
    } catch {
      this.failedIntializeCall = true;
      this.loadingModal.close();
      setTimeout(() => window.scrollTo(0, 0));
    }
  }

  public successSubscription(): Observable<FinicitySuccessObject> {
    return this.successSubject.asObservable();
  }

  public destroy(): void {
    if (FinicityConnect) {
      FinicityConnect.destroy();
      this.successSubject = new Subject<FinicitySuccessObject>();
    }
  }

  public setFinicityIframeAttributes() {
    let iframe = document.getElementById('finicityConnectIframe');
    const titleAttribute = document.createAttribute('title');
    titleAttribute.value = 'finicityIframe';
    iframe.setAttributeNode(titleAttribute);
    const privateAttribute = document.createAttribute('data-private');
    iframe.setAttributeNode(privateAttribute);
  }

  private onFinicityEvent(eventName: string, metaData: any): void {
    if (eventName) {
      metaData.applicationId = this.application.id;
      metaData.appSequenceId = this.application.sequenceApplicationId;

      this.appInsightsService.trackEvent(eventName, metaData);

      if (eventName !== 'SearchInstitutions' && metaData?.action) {
        // logging form_submit event at beginning of submit
        var data: GoogleTagData = {
          form_submit_text: null,
          finicity_value: `finicity_${eventName}`,
          form_type: 'finicity'
        };

        this.googleAnalytics.formInteractionEvent(data);
      }

      this.bankConnectionApi
        .postFinicityEvent(
          this.createFinincityEventRequest(eventName, metaData)
        )
        .subscribe();
    }
  }

  private createFinincityEventRequest(
    eventName: string,
    metaData: FinicityEventMetaData
  ): FinicityEventRequest {
    const finicityEvent: FinicityEventRequest = new FinicityEventRequest();
    finicityEvent.provider = 'Finicity';
    finicityEvent.eventType = eventName;
    finicityEvent.timeStamp = new Date().toISOString();
    finicityEvent.applicationId = this.application.id;
    finicityEvent.data = metaData;

    return finicityEvent;
  }
  public closeFinicity(): void {
    if (FinicityConnect) {
      FinicityConnect.destroy();
    }
  }
}
