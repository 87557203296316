import { Injectable } from '@angular/core';

export interface StateMessage {
  state: string;
  message: string;
}

@Injectable({ providedIn: 'root' })
export class StateMessageService {
  public getStateMessage(
    state: string,
    stateMessages: StateMessage[]
  ): StateMessage {
    if (!Array.isArray(stateMessages)) {
      stateMessages = [stateMessages];
    }
    const message = stateMessages.find((messageArray: StateMessage) => {
      return messageArray.state === state;
    });
    return message ? message : null;
  }
}
