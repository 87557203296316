import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  ActiveLoanData,
  PaymentDefaultOptionsRequest,
  PaymentOptionsResponse,
  SelectedPaymentOption
} from '@application/underwriting-decisions/choose-your-terms/choose-your-terms.model';
import { Environment } from '@environment/environment';
import { ApplicationForm } from '@application/application';


@Injectable({
  providedIn: 'root'
})
export class LoanPaymentApiService {
  constructor(
    private environment: Environment,
    private http: HttpClient,
    private dialog: MatDialog,
    private router: Router
  ) {}

  public getPaymentOptions(
    loanAmount: number,
    paymentOptions: 'Default' | 'All',
    originationFee: number = null
  ): Observable<PaymentOptionsResponse> {
    const request: PaymentDefaultOptionsRequest = {
      loanAmount: loanAmount,
      originationFee: originationFee,
      paymentOptions: paymentOptions
    };
    return this.http
      .post<PaymentOptionsResponse>(
        `${this.environment.brandApi.url}/api/v1/application/payment-schedule`,
        request,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
        }
      )
      .pipe(
        catchError((error: any) => {
          this.navigateToErrorPage();
          return throwError(() => new Error(error));
        })
      );
  }

  public getLoanAgreement(selectedPaymentOption: SelectedPaymentOption): any {
    return this.http
      .post(
        `${this.environment.brandApi.url}/api/v1/application/get-loan-agreement`,
        selectedPaymentOption,
        {
          responseType: 'text'
        }
      )
      .pipe(
        catchError((error: any) => {
          this.navigateToErrorPage();
          return throwError(() => new Error(error));
        })
      );
  }

  public getActiveLoanData(): any {
    return this.http
      .get<ActiveLoanData>(
        `${this.environment.brandApi.url}/api/v1/application/activeloandata`
      )
      .pipe(
        catchError((error: any) => {
          this.navigateToErrorPage();
          return throwError(() => new Error(error));
        })
      );
  }

  public signLoanAgreement(
    request: ApplicationForm
  ): Observable<any> {
    return this.http
      .patch<HttpResponse<any>>(
        `${this.environment.brandApi.url}/api/v1/application/sign-agreement`,
        request,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          }),
          observe: 'response'
        }
      )
      .pipe(
        catchError((error: any) => {
          this.navigateToErrorPage();
          return throwError(() => new Error(error));
        })
      );
  }

  private navigateToErrorPage(): void {
    this.dialog.closeAll();
    this.router.navigate(['/error']);
  }
}
