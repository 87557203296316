import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Environment } from './environment';
import { EnvironmentService } from './environment.service';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    Environment,
    {
      multi: true,
      provide: APP_INITIALIZER,
      deps: [EnvironmentService, Environment],
      useFactory: (service: EnvironmentService, environment: Environment) => {
        return async () => {
          return await lastValueFrom(
            await service.getEnvironment().then(
              tap((data: Environment) => {
                if(data.brand === 'Rise'){
                data.defaultLayout = false;
                }else{
                  data.defaultLayout = true;
                }
                Object.assign(environment, data);
              })
            )
          );
        };
      }
    }
  ]
})
export class EnvironmentModule {}
