import { AbstractControl, FormGroup } from '@angular/forms';
import { Rule, ValidationConfiguration } from '@elevate/forms';

export abstract class PageFormGroup extends FormGroup {
  public showValidationErrors(): void {
    this.showValidationError(this);
  }

  private showValidationError(control: AbstractControl): void {
    if (control.disabled) {
      return;
    }

    const configuration = new ValidationConfiguration();

    if (configuration.defaultRules.includes(Rule.Dirty)) {
      control.markAsDirty();
    }
    if (configuration.defaultRules.includes(Rule.Touched)) {
      control.markAsTouched();
    }

    if (control instanceof FormGroup) {
      Object.values(control.controls).forEach(
        (nestedControl: AbstractControl) => {
          nestedControl.updateValueAndValidity();
          this.showValidationError(nestedControl);
        }
      );
    }
  }
}
