import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import {
  GoogleAnalytics,
  GoogleTagData
} from '../googleanalytics.service';

@Directive({
  selector: '[googleAnalyticsFocusOut]'
})
export class GoogleAnalyticsFormControlFocusOutDirective {
  @Input('googleAnalyticsFocusOut') public googleAnalyticsCategory: string;
  private hostElement: ElementRef;

  constructor(el: ElementRef, private googleAnalytics: GoogleAnalytics) {
    this.hostElement = el;
  }

  @HostListener('focusout', ['$event.target.value'])
  public onFocusout(): void {
    const googleAnalyticsData: GoogleTagData = {
      field_id:
        this.googleAnalyticsCategory == ''
          ? this.hostElement.nativeElement.id
          : this.googleAnalyticsCategory,

      step_name: window.location.pathname
    };
    this.googleAnalytics.formInteractionEvent(googleAnalyticsData);
  }
}
