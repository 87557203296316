import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RestrictNavigationModalComponent } from './restrict-navigation-modal/restrict-navigation-modal.component';
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { SessionStorageService } from '@core/session-storage/session-storage.service';
import { ApplicationDataService } from '@application/application.service';
import { UnderwritingApiService } from '@application/underwriting/underwriting-api.service';
import { Environment } from '@environment/environment';
import { AppInsightsService } from '@core/app-insights/app-insights.service';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({ providedIn: 'root' })
export class RestrictNavigationGuard {
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private titleService: Title,
    private location: Location,
    private sessionStorageService: SessionStorageService,
    private appDataService: ApplicationDataService,
    private underwritingService: UnderwritingApiService,
    private environment: Environment,
    private appInsights: AppInsightsService
  ) {}

  canDeactivate(
    component: CanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    const isPopState =
      this.router.getCurrentNavigation().trigger === 'popstate';

    if (!isPopState) {
      return true;
    }

    this.titleService.setTitle('');
    this.location.go(currentState.url);
    this.titleService.setTitle(currentRoute.data.title);
    this.openRestrictModal();
    return false;
  }

  public openRestrictModal(): void {
    if (this.modalService.hasOpenModals()) {
      return;
    }

    const restrictModal = this.modalService.open(
      RestrictNavigationModalComponent,
      {
        backdrop: 'static',
        keyboard: false,
        centered: true,
        windowClass: 'restrict-navigation-modal'
      }
    );

    restrictModal.result.then((modalResult: boolean) => {
      if (modalResult) {
        this.appInsights.trackEvent('Browser-Back-Button-Continue');

        this.sessionStorageService.clearAll();
        this.appDataService.resetApplication();
        this.underwritingService.resetDecision();
        this.redirectToUrl(this.environment.sessionExpirationRedirectUrl);
      }
    });
  }

  public redirectToUrl(url: string): void {
    window.location.assign(url);
  }
}
