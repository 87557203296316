import { ModuleWithProviders, NgModule } from '@angular/core';
import { UserInactivityStatusService } from './user-inactivity.service';
import { InactivityModalComponent } from './inactivity-modal/inactivity-modal.component';
import { EclBaseModalModule } from '@elevate/base-modal';

@NgModule({
  declarations: [InactivityModalComponent],
  imports: [EclBaseModalModule],
  exports: [InactivityModalComponent]
})
export class UserInactivityModule {
  public static forRoot(
    redirectUrl: string
  ): ModuleWithProviders<UserInactivityModule> {
    return {
      ngModule: UserInactivityModule,
      providers: [
        UserInactivityStatusService,
        {
          provide: 'RedirectRoute',
          useValue: redirectUrl
        }
      ]
    };
  }
}
