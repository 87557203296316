import { Injectable } from '@angular/core';
import { FundMethodType } from '@application/application';
import {
  ContentstackConfiguration,
  ContentstackSettings,
  CmsQuery,
  BuildCmsQueryAnd,
  BuildCmsQuerySpecifiedOrEmpty
} from '@elevate/cms';
import { CmsConfiguration } from '@elevate/cms/lib/models/ContentstackConfiguration';

export enum SubmitApplicationFlowOptions {
  IncomeVerification = 'IncomeVerification',
  SignAndSubmit = 'SignAndSubmit'
}

export interface PlaidLenders {
  lenderCode?: string;
  clientName?: string;
  environment?: string;
  linkCustomizationName?: string;
}

/* istanbul ignore next */
@Injectable()
export class Environment {
  public readonly brand?: string;

  public readonly brandId?: string;

  public readonly productName?: string;

  public readonly production?: boolean;

  public readonly enableQualtrics?: boolean;

  public defaultLayout?: boolean;

  public readonly googleAnalytics?: {
    environment: string;
  };

  public readonly bau?: {
    url: string;
    secureUrl: string;
  };

  public readonly smartyStreets?: {
    siteKey: string;
    url: {
      lookup: string;
      suggest: string;
    };
  };

  public readonly brandApi?: {
    url: string;
    underwriteRetry: {
      retryCount: number;
      retryTimeout: number;
    };
  };

  public readonly configurationService?: {
    url: string;
    apiVersion: string;
  };

  public readonly sessionExpirationRedirectUrl?: string;

  public readonly navigation?: {
    publicBaseUrl: string;
    accountBaseUrl: string;
    login: string;
  };

  public readonly httpRetry?: {
    readonly retryCount: number;
    readonly retryTimeout: number;
  };

  public readonly reCaptcha?: {
    readonly siteKey: string;
  };

  public readonly iovation?: {
    readonly subkey: string;
    readonly requestType: string;
    readonly timeout: number;
  };

  public readonly plaid?: {
    readonly products: string[];
    readonly lenders: PlaidLenders[];
  };

  public readonly finicity?: {
    readonly linkCustomizationName: string;
  };

  public readonly inactivity?: {
    readonly idle: number;
    readonly timeout: number;
  };

  public readonly logRocket?: {
    readonly applicationId: string;
    readonly assetsBaseUrl: string;
    readonly rootDomain: string;
  };

  public readonly neuroId?: {
    readonly enabled: boolean;
    readonly scriptUrl: string;
  };

  public readonly appInsights?: {
    readonly instrumentationKey: string;
  };

  public readonly contentStack?: {
    readonly brandClient: ContentstackSettings;
    readonly sharedClient: ContentstackSettings;
  };

  public readonly contentStackDev?: {
    readonly environment: string;
    readonly accessToken: string;
  };

  public readonly tempLockThreshold?: {
    readonly attempts: number;
    readonly timerInMinutes: number;
  };

  public get CmsConfiguration(): CmsConfiguration {
    const contentstack = this.contentstackConfiguration;
    const brandName = this.brand;
    const defaultProductId = this.brandId.toString(); // we don't know the productId until it gets assigned depending on selected state, thus we use brandId as default.
    const configuration: CmsConfiguration = {
      contentstack: contentstack,
      brandName: brandName,
      productId: defaultProductId
    };

    return configuration;
  }

  public get contentstackConfiguration(): ContentstackConfiguration {
    if (window.location.origin.includes('localhost')) {
      Object.assign(this.contentStack.brandClient.apiSettings, {
        environment: this.contentStackDev.environment,
        accessToken: this.contentStackDev.accessToken
      });
    }

    const settings = [
      this.contentStack.brandClient,
      this.contentStack.sharedClient
    ] as ContentstackSettings[];

    const brandName = this.brand;
    const setDefaultProductId = () => this.brandId; // we don't know the productId until it gets assigned depending on selected state, thus we use brandId as default.

    const buildCacheKey = (uid: string): string => {
      const prodId = setDefaultProductId();
      const id = prodId ? prodId : 'DEFAULT';
      return `${id}-${uid}`;
    };

    const filterQueryBuilder = (project: string): CmsQuery<any> => {
      const query: CmsQuery<any>[] = [
        BuildCmsQuerySpecifiedOrEmpty('configuration.brand', brandName),
        BuildCmsQuerySpecifiedOrEmpty(
          'configuration.product',
          setDefaultProductId()
        )
      ];

      if (project) {
        query.push(
          BuildCmsQuerySpecifiedOrEmpty('configuration.project', project)
        );
      }

      return BuildCmsQueryAnd<any>(...query);
    };

    for (const setting of settings) {
      setting.filterQueryBuilder = () =>
        filterQueryBuilder(setting.projectName);
    }

    const configuration: ContentstackConfiguration = {
      settings,
      cacheKeyBuilder: buildCacheKey
    };

    return configuration;
  }

  public readonly duConfig?: {
    uploadUrl: string;
    baseUrl: string;
    acceptedFileTypes: string[];
    minFileCount: number;
    maxFileCount: number;
    maxFileSizeMb: number;
    shouldSaveDocument: boolean;
    shouldDisplayErrorMessage: boolean;
    uploadType: string;
    headers: any;
  };

  public readonly coBrowseConfiguration?: {
    orgGuid: string;
    chatDeploymentKey: string;
    chatScriptUrl: string;
    chatRegion: string;
    webchatAppUrl: string;
    webchatServiceUrl: string;
    promptForSecurityKey: boolean;
    cobrowseScriptUrl: string;
    cobrowseDeploymentKey: string;
    logLevel: string;
    linkText: string;
    linkBackground: string;
    stylesUrl: string;
    appBaseUrl: string;
    stylesSelector: string;
  };

  public readonly developerMode?: boolean;

  public readonly showCookieNotification?: boolean;
  public readonly disableCoBrowse?: boolean;

  public readonly mockOtpPhoneNumber?: string;

  public readonly gpc: {
    readonly apiVersion: string;
    readonly environment: string;
    readonly apiManagerSubscriptionKey: string;
    readonly postUrl: string;
  };

  public readonly cookie: {
    gpcSessionId: string;
    gcid: string;
  };

  public readonly setCookieConfig: {
    gcid: {
      domain: string;
      expiryInDays: number;
    };
  };

  public readonly applicationFlowSettings?: {
    isFundingPaymentSelectionEnabled: boolean;
    isPaymentMethodSelectionEnabled: boolean;
    defaultPaymentMethod: string;
    defaultFundingMethod: string;
    applicationSubmitStep: SubmitApplicationFlowOptions;
  };

  public get defaultFundingType(): FundMethodType {
    return this.applicationFlowSettings.defaultFundingMethod ==
      FundMethodType.Electronic
      ? FundMethodType.Electronic
      : FundMethodType.Paper;
  }

  public get defaultPaymentType(): FundMethodType {
    return this.applicationFlowSettings.defaultPaymentMethod ==
      FundMethodType.Electronic
      ? FundMethodType.Electronic
      : FundMethodType.Paper;
  }
  constructor() {}
}
