import {
  Component,
  Inject,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';

import { Environment } from '@environment/environment';
import { AppInsightsService } from '@core/app-insights/app-insights.service';
import { DefaultModal } from '../getting-started.content';
import { BaseModalConfig } from '@elevate/base-modal';
import {
  GoogleAnalytics,
  ModalClickAction
} from '@core/google-analytics/googleanalytics.service';

@Component({
  selector: 'app-goto-signin-modal',
  templateUrl: './goto-signin-modal.component.html',
  styleUrls: ['./goto-signin-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GoToSigninModalComponent implements OnInit {
  @Input() public userEmail: string;
  @Input() public redirectionReason: string;
  @Input() public htmlContent: DefaultModal;
  @Input() public modalData: BaseModalConfig = {
    action: {
      allowClose: false
    }
  } as BaseModalConfig;

  constructor(
    private readonly environment: Environment,
    @Inject('window') private window: Window,

    private appInsightService: AppInsightsService,
    public googleAnalytics: GoogleAnalytics
  ) {}

  public ngOnInit(): void {
    this.modalData.heading = this.htmlContent.header;
    this.modalData.content = this.htmlContent.content;
    this.modalData.action.submitButton = this.htmlContent.button;
    this.modalData.action.onSubmit = () => this.goToLogin();
  }

  public goToLogin(): void {
    this.appInsightService.trackEvent(this.redirectionReason, {
      user: this.userEmail
    });
    this.window.location.href = `${this.environment.navigation.publicBaseUrl}/${this.environment.navigation.login}`;
  }
}
