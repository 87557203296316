import { Injectable } from '@angular/core';
import { PageFormGroup } from '@core/page-form-group/page-form-group';
import { FormControl } from '@angular/forms';
import { requiredValidator } from '@elevate/forms';

export interface AccountTypeFormValidationMessages {
  selectOption: string;
}

@Injectable()
export class AccountTypeModalForm extends PageFormGroup {
  constructor(messages: AccountTypeFormValidationMessages) {
    super({
      draftFromKey: new FormControl(null, [
        requiredValidator(messages.selectOption)
      ])
    });
  }
}
