import { AfterViewInit } from '@angular/core';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DocumentApi } from '@core/document/document.api';
import { DocumentService } from '@core/document/document.service';
import { lastValueFrom } from 'rxjs';
import { CmsPageContentService } from '@core/cms/services/cms-page-content.service';

export interface CmsNoia {
  header: string;
  subHeader: string;
  content: string;
}

@Component({
  selector: 'app-noia',
  templateUrl: './notice-of-incomplete-action.component.html',
  styleUrls: ['./notice-of-incomplete-action.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NoiaComponent implements OnInit, AfterViewInit {
  public pageContent: CmsNoia;
  constructor(
    private activatedRoute: ActivatedRoute,
    private documentApi: DocumentApi,
    private documentService: DocumentService,
    private pageHeaderService: CmsPageContentService
  ) {}

  public ngOnInit(): void {
    this.pageContent = this.activatedRoute.snapshot.data.cmsContent
      .freeze as CmsNoia;
    this.pageHeaderService.updatePageTitle(this.pageContent.header);
  }

  public ngAfterViewInit(): void {
    const element = document.getElementById('downloadNoiaLink') as HTMLElement;
    element.addEventListener('click', () => {
      this.downloadNoia();
    });
  }

  public async downloadNoia(): Promise<void> {
    const documentName = `noia_${Date.now()}.pdf`;
    const blob: Blob = await lastValueFrom(this.documentApi.get('noaa'));
    this.documentService.download(blob, documentName);
  }
}
