import { Injectable } from '@angular/core';
import { PageFormGroup } from '@core/page-form-group/page-form-group';
import { FormControl } from '@angular/forms';
import { requiredValidator } from '@elevate/forms';

@Injectable()
export class CheckingAccountModalForm extends PageFormGroup {
  constructor() {
    super({
      draftFromKey: new FormControl(null, [
        requiredValidator('You need to select one option to continue.')
      ])
    });
  }
}
