import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationDataService } from '@application/application.service';
import { LoadingModalService } from '@application/loading-modal/loading-modal.service';
import { IdentityApiService } from '@core/identity-service/identity-api.service';
import { CmsPageContentService } from '@core/cms/services/cms-page-content.service';
import { TextInput } from '@elevate/ui-components';
import { AccountSetupFormGroup } from './account-setup.form';
import { AccountSetupDebugHudService } from './debug-hud/account-setup-debug-hud.service';
import { PasswordFormContent, PasswordControls } from '@elevate/password';
import { FormControl } from '@angular/forms';
import { AccountSetupContent } from './account-setup-content';
import { ValidationMessagesError } from '@elevate/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ApplicationFlowService } from '@core/application-flow/application-flow.service';
import { lastValueFrom, Subscription } from 'rxjs';
import { UsernameControls, UsernameFormContent } from '@elevate/username';

@Component({
  selector: 'app-account-setup',
  templateUrl: './account-setup.component.html',
  styleUrls: ['./account-setup.component.scss'],
  providers: [AccountSetupFormGroup, AccountSetupDebugHudService]
})
export class AccountSetupComponent implements OnInit, AfterViewInit, OnDestroy {
  public email: string;
  public userNameEnabled: boolean;

  public alphanumericOnlyRule: boolean | true;
  public minCharacterUserNameRule: boolean | true;
  public emailConfig: TextInput = {
    id: 'emailTextInput',
    required: 'true',
    type: 'email',
    attributes: {
      'data-nid-target': 'accountSetUpEmail'
    }
  };

  public userNameConfig: TextInput = {
    id: 'userNameTextInput',
    required: 'true',
    type: 'text',
    minlength: 8,
    maxlength: 25,
    attributes: {
      'data-nid-target': 'accountSetUpUserName'
    }
  };

  passFormContent: PasswordFormContent;
  userFormContent: UsernameFormContent;

  minCharacterRule = true;
  public get passwordControls(): PasswordControls {
    return {
      newPassword: this.form.get('newPassword') as FormControl,
      confirmPassword: this.form.get('confirmPassword') as FormControl,
      currentEmail: this.email
    };
  }
  public get usernameControls(): UsernameControls {
    return {
      username: this.form.get('username') as FormControl
    };
  }
  public content: AccountSetupContent;
  public formChangesSubscription: Subscription;

  constructor(
    public form: AccountSetupFormGroup,
    public changeDetector: ChangeDetectorRef,
    public debugHudService: AccountSetupDebugHudService,
    private applicationDataService: ApplicationDataService,
    private cmsPageContentService: CmsPageContentService,
    private loadingService: LoadingModalService,
    private identityApi: IdentityApiService,
    private router: Router,
    private route: ActivatedRoute,
    private applicationFlowService: ApplicationFlowService
  ) {}

  public ngOnInit(): void {
    const application = this.applicationDataService.getApplication();
    this.content = this.route.snapshot.data.cmsContent.accountSetup;
    this.setPasswordFormContent();
    this.setUsernameFormContent();
    this.cmsPageContentService.updatePageTitle(this.content.header);
    this.email = application.form.applicant.emails[0].address;
    this.form.patchValue({ email: this.email });
    this.form.controls['email'].disable();
    this.userNameEnabled = this.content.labels.userName.length > 0;
  }

  // this method was added because all unit tests in account-setup-component.spec.ts failed with such error:
  // Expression has changed after it was checked. Previous value for 'ng-valid': 'true'. Current value: 'false'
  ngAfterViewInit(): void {
    this.changeDetector.detectChanges();
  }

  public async submit(): Promise<void> {
    this.form.showValidationErrors();

    if (!this.form.valid) {
      return;
    }

    this.loadingService.open();

    try {
      if (this.userNameEnabled) {
        await lastValueFrom(
          this.identityApi.createIdentity(
            this.form.get('newPassword').value,
            this.form.get('username').value
          )
        );
      } else {
        await lastValueFrom(
          this.identityApi.createIdentity(this.form.get('newPassword').value)
        );
      }
      const nextPage = this.applicationFlowService.getContinuePath();
      this.router.navigate([nextPage]);
    } catch (error) {
      const httpError = error as HttpErrorResponse;
      if (httpError.status === 403) {
        this.addDuplicateUserNameError();
      } else if (httpError.status === 400) {
        this.addPasswordFailureError(httpError.error.error.errorCode);
      } else {
        this.router.navigate(['/error']);
      }
      this.loadingService.close();
    }
  }

  private setPasswordFormContent(): void {
    this.passFormContent = {
      formPassRuleHeader: this.content.password.labels.passwordMust,
      inputNotMatchError: this.content.password.validationMessages
        .confirmNotMatch,
      requireText: this.content.password.validationMessages.required,
      inputLabel: {
        newPassword: this.content.password.labels.createPassword,
        confirmPassword: this.content.password.labels.confirmPassword
      },
      minlength: this.content.password.passwordLength.minLength,
      maxlength: this.content.password.passwordLength.maxLength,
      passIndicatorRule: {
        lowerCase: this.content.password.validationRules.includeLowerCase,
        upperCase: this.content.password.validationRules.includeUpperCase,
        minCharacter: this.content.password.validationRules.minimumCharacters,
        number: this.content.password.validationRules.includeNumber,
        specialChar: this.content.password.validationRules
          .includeSpecialCharacter,
        notContainEmail: this.content.password.validationRules.notContainEmail
      }
    };
  }
  private setUsernameFormContent(): void {
    this.userFormContent = {
      formUsernameRuleHeader: this.content.validationRules.validationLabel,
      requireText: this.content.validationMessages.userName.required,
      minLengthText: this.content.validationMessages.userName.minLength,
      validCharsText: this.content.validationMessages.userName.alphanumericOnly,
      maxLengthText: this.content.validationMessages.userName.maxLength,
      minlength: this.content.usernameLength.minLength,
      maxlength: this.content.usernameLength.maxLength,
      inputLabel: {
        username: this.content.labels.userName
      },
      usernameIndicatiorRule: {
        minCharacter: this.content.validationMessages.userName.minLength,
        validCharacters: this.content.validationMessages.userName
          .alphanumericOnly
      }
    };
  }

  private addDuplicateUserNameError(): void {
    this.form
      .get('username')
      .setErrors(
        new ValidationMessagesError(
          'duplicateUserNameError',
          null,
          this.content.validationMessages.userName.existingMessage
        )
      );
  }
  public async ngOnDestroy(): Promise<void> {
    if (this.formChangesSubscription != null) {
      this.formChangesSubscription.unsubscribe();
    }
  }

  private addPasswordFailureError(error: string): void {
    this.form
      .get('newPassword')
      .setErrors(
        new ValidationMessagesError(
          error,
          null,
          this.content.validationMessages.password[error] ||
            this.content.validationMessages.password.defaultMessage
        )
      );
  }
}
