import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ControlValidationMessage } from '@application/getting-started/getting-started.content';
import { PageFormGroup } from '@core/page-form-group/page-form-group';
import {
  requiredValidator,
  fixedLengthValidator,
  patternValidator
} from '@elevate/forms';

export interface PartnerVerificationInfo {
  verificationCode: string;
  sendCodeMethod: string;
}
export interface PartnerVerificationSSNInfo {
  last4SSN: string;
}

@Injectable()
export class PartnerVerificationCodeFormGroup extends PageFormGroup {

  constructor() {
    super({});
  }

  public build(validationMessage: ControlValidationMessage): void {
    this.addControl(
      'verificationCode',
      new FormControl(null, {
        validators: [requiredValidator(validationMessage.requiredMessage)]
      })
    );
  }
}
@Injectable()
export class PartnerCommunicationTypeFormGroup extends PageFormGroup {

  constructor() {
    super({
      sendCodeMethod: new FormControl(null, {
        validators: [requiredValidator('Required')]
      })
    });
  }
}
@Injectable()
export class PartnerVerificationSSNFormGroup extends PageFormGroup {

  constructor() {
    super({});
  }

  public build(validationMessage: ControlValidationMessage): void {
    this.addControl(
      'last4SSN',
      new FormControl(null, {
        validators: [
          requiredValidator(validationMessage.requiredMessage),
          fixedLengthValidator(4, validationMessage.lengthMessage),
          patternValidator(/^\d+$/, validationMessage.patternMessage)
        ]
      })
    );
  }
}
