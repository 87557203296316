<div id="debugHudContainer" class="debug-hud-container" *ngIf="!production">
  <div [hidden]="!expanded">
    <div class="debug-hud-image">
      <img src="assets/images/marquee-header.png" />
    </div>

    <div id="debugHudContent" class="debug-hud-border-outer">
      <div class="debug-hud-border">
        <div class="debug-hud-content">
          <h5 id="debugHudHeading">DEBUG HUD</h5>

          <div class="mt-3">
            <div id="debugHudVisitorId">AppInsights ID:</div>
            <p class="app-insights-id">{{ appInsightsId }}</p>
          </div>

          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>

  <div id="debugHudTab" class="debug-hud-tab" (click)="toggle()">
    <i
      class="text-white fa"
      [ngClass]="!expanded ? 'fa-caret-right' : 'fa-caret-left'"
    ></i>
  </div>
</div>
