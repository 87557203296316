export enum PrimaryPaymentFrequencyValue {
  Weekly = 'Weekly',
  BiWeekly = 'BiWeekly',
  SemiMonthly = 'SemiMonthly',
  Monthly = 'Monthly'
}

export enum PrimaryPaymentFrequencyDisplayName {
  Weekly = 'Weekly',
  BiWeekly = 'Bi-weekly',
  SemiMonthly = 'Semi-monthly',
  Monthly = 'Monthly'
}

export interface PrimaryPaymentFrequency {
  primaryPaymentFrequency?: PrimaryPaymentFrequencyValue;
  primaryNextPayDate?: string;
  primaryPaymentDate?: string;
  primaryDayOfMonth1?: string;
  primaryDayOfMonth2?: string;
  primaryNetAmount?: string;
}

export interface PrimaryPaymentFrequencyForm {
  primaryPaymentFrequency?: PrimaryPaymentFrequencyValue;
  primaryNextPayDate?: string;
  primarySemiMonthlyDaysOfMonth?: string;
  primaryMonthlyDayOfMonth?: string;
  primaryNetAmount?: string;
  primaryPaymentDate?: string;
}

export interface PaymentFrequencyCMSValidationMessages {
  howOften: string;
  nextPayDate: string;
  paidMonthly: string;
  whichDay: string;
  takeAmount: string;
  amountRange: string;
}
