<div
  *ngIf="!pendingDecision"
  class="pending-page-container"
  id="pendingPageContents"
>
  <h1 class="pending-header" id="pendingPageDisplayMessage">
    {{ displayMessage }}
  </h1>
  <div class="spinner-container">
    <ecl-spinner size="lg"></ecl-spinner>
  </div>
</div>
<div
  *ngIf="pendingDecision"
  class="pending-page-container"
  id="pendingDecisionContents"
>
  <h1 class="pending-header" id="pendingDecisionHeader">
    {{ waitingContent.decisionHeader }}
  </h1>
  <div [innerHTML]="waitingContent.info" class="page-content"></div>
  <div class="btn-container">
    <button
      id="checkDecisionContinueButton"
      (click)="checkDecision()"
      class="ecl-button-primary"
    >
      {{ waitingContent.buttons.checkDecision }}
    </button>
  </div>
</div>
