export const resumeFormConfig = {
  verificationCodeConfig: {
    id: 'verificationCode',
    required: 'true',
    mask: '000000',
    maxlength: 6,
    attributes: {
      'data-private': 'redact',
      'data-nid-target': 'resumeVerificationCode'
    },
    addGenesysCobrowseMaskingClass: 'true'
  },
  contactTypeConfig: {
    name: 'sendCodeMethod',
    direction: 'column',
    required: 'true',
    value: 'Sms',
    buttons: [
      {
        id: 'callInputRadioBtn',
        label: 'Call me at XXX-XXX-YYYY',
        value: 'Call',
        attributes: {
          'data-nid-target': 'resumeCall'
        }
      },
      {
        id: 'smsInputRadioBtn',
        label: 'Text me at XXX-XXX-YYYY',
        value: 'Sms',
        attributes: {
          'data-nid-target': 'resumeSMS'
        }
      }
    ]
  }
};
