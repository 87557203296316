<form [formGroup]="form" (submit)="submit()">
  <h2 id="incomePageSubTitle" class="page-subtitle ecl-font-header-medium">
    {{ pageSubTitle }}
  </h2>

  <div id="alimonyInfoText" class="page-message">
    <span class="ecl-info-icon"></span>
    <div>{{ alimonyInfo }}</div>
  </div>

  <div class="row">
    <ecl-select
      class="col-md-8"
      [formControlConfig]="formConfig.primarySource"
      formControlName="primarySource"
      googleAnalyticsFocusOut="primarySource"
    >
      {{ income.sources.mainIncome.label }}
    </ecl-select>
  </div>

  <div
    id="primaryIncomeSourceInfoText"
    class="page-message"
    *ngIf="form.value.primarySource"
  >
    {{ incomeSourceInfo }}
  </div>

  <ng-container [ngSwitch]="form.value.primarySource">
    <app-job-income
      *ngSwitchCase="'JobIncome'"
      [formGroup]="form"
      [CMSJobIncome]="income.jobIncome"
      [CMSEmployer]="income.employer"
      [CMSPaymentFrequency]="income.pay"
    ></app-job-income>

    <app-self-employed
      *ngSwitchCase="'SelfEmployed'"
      [formGroup]="form"
      [CMSSSelfEmployed]="income.selfEmployed"
      [CMSEmployer]="income.employer"
      [CMSPaymentFrequency]="income.pay"
    ></app-self-employed>

    <app-social-security
      *ngSwitchCase="'SocialSecurityDisability'"
      [formGroup]="form"
      [CMSPaymentFrequency]="income.pay"
    ></app-social-security>

    <app-retirement
      *ngSwitchCase="'RetirementPension'"
      [formGroup]="form"
      [CMSPaymentFrequency]="income.pay"
    ></app-retirement>

    <app-other
      *ngSwitchCase="'Other'"
      [formGroup]="form"
      [CMSOther]="income.other"
      [CMSPaymentFrequency]="income.pay"
    ></app-other>

    <app-unemployment
      *ngSwitchCase="'Unemployment'"
      [formGroup]="form"
      [CMSPaymentFrequency]="income.pay"
    ></app-unemployment>
  </ng-container>

  <div class="row">
    <ecl-select
      class="col-md-8"
      [formControlConfig]="formConfig.secondarySource"
      formControlName="secondarySource"
      googleAnalyticsFocusOut="secondarySource"
    >
      {{ income.sources.secondaryIncome.label }}
    </ecl-select>
  </div>

  <div
    id="secondaryIncomeSourceInfoText"
    class="page-message"
    *ngIf="form.value.secondarySource"
  >
    {{ incomeSourceInfo }}
  </div>

  <div class="row">
    <ecl-input-currency
      *ngIf="form.value.secondarySource"
      class="col-md-8"
      formControlName="secondaryNetAmount"
      [formControlConfig]="formConfig.secondaryNetAmountConfig"
      googleAnalyticsFocusOut="secondaryNetAmountText"
    >
      {{ income.sources.secondaryIncome.netAmountLabel }}
    </ecl-input-currency>
    <ecl-input-currency
      class="col-md-8"
      [formControlConfig]="formConfig.monthlyRentMortgage"
      formControlName="monthlyRentMortgage"
      googleAnalyticsFocusOut="monthlyRentMortgage"
    >
      {{ income.mortgageRentLabel }}
    </ecl-input-currency>
  </div>

  <app-consents
    #consentsComponent
    [formGroup]="form"
    [consentSectionItems]="consentsSection"
  ></app-consents>
  <div class="continue-button-container">
    <button
      focusOnError
      type="submit"
      id="incomeSubmitBtn"
      googleAnalyticsClick="IncomeInformation-Continue"
      class="ecl-button-primary"
    >
      {{ income.buttons.continue }}
    </button>
  </div>
</form>

<app-debug-hud>
  <button (click)="simulateData('JobIncome')">JobIncome</button>
  <button (click)="simulateData('SelfEmployed')">SelfEmployed</button>
  <button (click)="simulateData('SocialSecurityDisability')">
    SocialSecurity
  </button>
  <button (click)="simulateData('RetirementPension')">Retirement</button>
  <button (click)="simulateData('Other')">Other</button>
  <!-- <button (click)="simulateData('Unemployment')">Unemployment</button> -->
</app-debug-hud>
