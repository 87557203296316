import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IncomeSourceComponent } from '@application/income/income';
import { CurrencyInput } from '@elevate/ui-components';

import { EmployerComponent } from '../components/employer/employer.component';
import { SelfEmployed } from './self-employed';
import { SelfEmployedFormGroup } from './self-employed.form';
import { PaymentFrequencyComponent } from '../components/payment-frequency/payment-frequency.component';

@Component({
  selector: 'app-self-employed',
  templateUrl: './self-employed.component.html',
  styleUrls: ['../../income.component.scss'],
  providers: [SelfEmployedFormGroup]
})
export class SelfEmployedComponent
  implements IncomeSourceComponent, OnInit, OnDestroy {
  @Input() public formGroup: FormGroup;
  @Input() public CMSSSelfEmployed: any;
  @Input() public CMSEmployer: any;
  @Input() public CMSPaymentFrequency: any;

  @ViewChild(EmployerComponent)
  private employer: EmployerComponent;

  @ViewChild(PaymentFrequencyComponent)
  private paymentFrequency: PaymentFrequencyComponent;

  public form: SelfEmployedFormGroup;

  public primaryNetAmountConfig: CurrencyInput = {
    id: 'primaryNetAmount',
    maxlength: 5,
    notDecimalAllow: true,
    digitSeparator: ',',
    leftAlign: true,
    attributes: {
      'data-nid-target': 'primaryNetAmount'
    }
  };
  constructor() {}
  public ngOnInit(): void {
    this.form = new SelfEmployedFormGroup();
    this.formGroup.addControl('selfEmployed', this.form);
  }

  public ngOnDestroy(): void {}
  public get value(): SelfEmployed {
    return {
      ...this.paymentFrequency.value,
      ...this.employer?.value
    };
  }

  public patchValue(selfEmployed: SelfEmployed): void {
    this.paymentFrequency.patchValue(selfEmployed);
    this.employer?.patchValue(selfEmployed);
  }

  public showValidationErrors(): void {
    this.employer?.showValidationErrors();
    this.paymentFrequency?.showValidationErrors();
  }
}
