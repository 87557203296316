import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Environment } from '@environment/environment';
import { Observable } from 'rxjs';

export interface CheckIdentityResponse {
  userExists: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class IdentityApiService {
  constructor(private environment: Environment, private http: HttpClient) {}

  public createIdentity(
    password: string,
    userName?: string
  ): Observable<HttpResponse<any>> {
    return this.http.put<HttpResponse<any>>(
      `${this.environment.brandApi.url}/api/v1/create-identity`,
      { password, userName },
      {
        observe: 'response'
      }
    );
  }

  public checkIdentity(): Observable<CheckIdentityResponse> {
    return this.http.get<CheckIdentityResponse>(
      `${this.environment.brandApi.url}/api/v1/check-identity`
    );
  }

  public updateIdentity(): Observable<HttpResponse<any>> {
    return this.http.put<HttpResponse<any>>(
      `${this.environment.brandApi.url}/api/v1/update-identity`,
      {},
      {
        observe: 'response'
      }
    );
  }
}
