import { Component, OnInit } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { CmsPageContentService } from '@core/cms/services/cms-page-content.service';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {
  public pageHeader: SafeHtml;
  constructor(
    private cmsPageContentService: CmsPageContentService,
    private pageHeaderService: CmsPageContentService
  ) {}

  ngOnInit(): void {
    this.cmsPageContentService.pageTitleObservable.subscribe(
      (title: string) => {
        this.pageHeader = title;
      }
    );

    this.pageHeaderService.cmsPageTitle.subscribe((title: string) => {
      this.pageHeader = title;
    });
  }
}
