<div class="verify-info-page-container">
  <h2
    *ngIf="pageSubTitle"
    id="pageSubTitleLabel"
    class="page-subtitle ecl-font-header-medium"
  >
    {{ pageSubTitle }}
  </h2>

  <div class="title-container">
    <h2
      class="verify-section-header ecl-font-section-header-color-xsmall display-heading-xs"
      id="personalInfoTitle"
    >
      {{ content.labels.personalInformation }}
    </h2>
    <span>
      <a
        class="ecl-font-link-light-bg"
        (click)="onEdit()"
        id="personalInformationEdit"
        >Edit
      </a>
    </span>
  </div>

  <div class="page-row row">
    <div class="col ecl-font-body-bold" id="nameLabel">
      {{ content.labels.name }}
    </div>
    <div class="col text-right" id="nameTextContainer">
      {{ applicant.identity.firstName }} {{ applicant.identity.lastName }}
      {{ applicant.identity.suffix }}
    </div>
  </div>

  <div class="page-row row">
    <div class="col ecl-font-body-bold" id="dobLabel">
      {{ content.labels.dateOfBirth }}
    </div>
    <div
      class="col text-right cobrowse-masked"
      id="dobTextContainer"
      data-private
    >
      {{ applicant.identity.dateOfBirth | date: 'MM/dd/yyyy' }}
    </div>
  </div>

  <div class="page-row row">
    <div class="col ecl-font-body-bold" id="ssnLabel">
      {{ content.labels.socialSecurityNumber }}
    </div>
    <div
      class="col text-right cobrowse-masked"
      id="ssnTextContainer"
      data-private
    >
      {{ maskedSSN }}
    </div>
  </div>
  <hr class="section-separater" />

  <div class="title-container">
    <h3
      class="verify-section-header ecl-font-section-header-color-xsmall"
      id="contactInfoTitle"
    >
      {{ content.labels.contactInformation }}
    </h3>
    <span>
      <a
        class="ecl-font-link-light-bg"
        (click)="onEdit()"
        id="contactInformationEdit"
        >Edit
      </a>
    </span>
  </div>

  <div class="page-row row">
    <div class="col ecl-font-body-bold" id="addresLabel">
      {{ content.labels.address }}
    </div>
    <div class="col text-right" id="addressTextContainer">
      {{ applicant.residences[0].address.line1 }}
      <span *ngIf="applicant.residences[0].address.line2?.trim()">
        <br />{{ applicant.residences[0].address.line2 }}</span
      >
      <br />{{ applicant.residences[0].address.city }},
      {{ applicant.residences[0].address.stateCode }}
      {{ applicant.residences[0].address.zipCode }}
    </div>
  </div>

  <div *ngIf="isMailingSectionEnabled && !!mailingAddress" class="page-row row">
    <div class="col ecl-font-body-bold" id="mailingAddresLabel">
      {{ cmsMailingAddressContent.labels.mailingAddress }}:
    </div>
    <div class="col text-right" id="mailingAddressTextContainer">
      {{ mailingAddress.line1 }}
      <span *ngIf="applicant.residences[1].address.line2?.trim()">
        <br />{{ mailingAddress.line2 }}</span
      >
      <br />{{ mailingAddress.city }},
      {{ mailingAddress.stateCode }}
      {{ mailingAddress.zipCode }}
    </div>
  </div>

  <div class="page-row row">
    <div class="col ecl-font-body-bold" id="emailLabel">
      {{ content.labels.emailAddress }}
    </div>
    <div class="col text-right" id="emailTextContainer">
      {{ maskedEmail }}
    </div>
  </div>

  <div class="page-row row">
    <div class="col ecl-font-body-bold" id="phoneLabel">
      {{ content.labels.mobilePhone }}
    </div>
    <div class="col text-right" id="mobilePhoneNumberText">
      {{ applicant.phones[0].number | mask: '(000) 000-0000' }}
    </div>
  </div>

  <div
    *ngIf="alternatePhoneEnabled && !!applicant.phones[1]"
    class="page-row row"
  >
    <div class="col ecl-font-body-bold" id="alternatePhoneLabel">
      {{ cmsMailingAddressContent.labels.alternatePhoneLabels.alternatePhone }}:
    </div>
    <div class="col text-right" id="alternatePhoneText">
      {{ applicant.phones[1].number | mask: '(000) 000-0000' }}
    </div>
  </div>
  <form [formGroup]="form" (submit)="onSubmit()">
    <div *ngIf="showRequestedAmount">
      <hr class="section-separater" />
      <h3
        id="verifyInfoRequestedAmount"
        class="form-section-title ecl-font-section-header-color-xsmall"
      >
        <span> {{ requestedAmountContent.subheader }}</span>
      </h3>
      <p>
        {{ minMaxSubtext }}<br /><small
          *ngIf="isloanOriginationFeeApplicable"
          >{{ orignationFeeMsg }}</small
        >
      </p>
      <div class="ecl-vgroup-m" *ngIf="loanMinMax">
        <div class="row" id="requestedAmountInformation">
          <ecl-input-currency
            #requestedAmount
            class="col-sm-6 ecl-bottom-xs"
            [formControlConfig]="formConfig.requestedAmount"
            formControlName="requestedAmount"
            googleAnalyticsFocusOut="requestedAmount"
          >
            <span>{{ requestedAmountContent.loanAmountInput.label }}</span>
          </ecl-input-currency>
        </div>
      </div>
    </div>
    <hr class="section-separater" />

    <div class="title-container ">
      <h3
        class="verify-section-header ecl-font-section-header-color-xsmall"
        id="directCodeTitle"
      >
        {{ content.labels.enrollmentCodeInformation }}
      </h3>
      <span>
        <a
          class="ecl-font-link-light-bg"
          (click)="onEdit()"
          id="enrollmentCodeInformationEdit"
          >Edit
        </a>
      </span>
    </div>

    <div class="page-row row">
      <div class="col ecl-font-body-bold" id="codeLabel">
        {{ content.labels.enrollmentCodeOptional }}:
      </div>
      <div class="col text-right">
        <div
          *ngIf="application.form.enrollmentCode?.trim()"
          id="enrollmentCodeTextContainer"
        >
          {{ application.form?.enrollmentCode }}
        </div>
        <div
          *ngIf="!application.form?.enrollmentCode?.trim()"
          id="enrollmentCodeEmptyContainer"
        >
          --
        </div>
      </div>
    </div>
    <app-consents
      #consentsComponent
      [formGroup]="form"
      [consentSectionItems]="content.consentsSection"
    ></app-consents>
    <div *ngIf="stateLicenses">
      <hr class="section-separater" />
      <p class="ecl-font-body-bold">{{ licenseSubtext }}</p>
      <div class="state-license-wrapper-div">
        <a
          class="state-license-link"
          rel="noopener"
          *ngFor="let license of stateLicenses.file"
          href="{{ license.url }}"
          target="_blank"
        >
          <img src="{{ license.url }}" alt="{{ license.title }}"
        /></a>
      </div>
    </div>

    <div *ngIf="policySection" class="policy-section">
      <div *ngFor="let policy of policySection">
        <div [innerHTML]="policy.defaultContent"></div>
      </div>
    </div>

    <div class="ecl-button-hgroup ecl-wrap-reverse btn-container">
      <button
        focusOnError
        id="verifyContinueButton"
        type="submit"
        class="ecl-button-primary"
        googleAnalyticsClick="VerifyInformation-Continue"
      >
        {{ content.buttons.continue }}
      </button>
      <button
        id="verifyEditButton"
        type="button"
        (click)="onEdit()"
        class="ecl-button-secondary"
        googleAnalyticsClick="VerifyInformation-Edit"
      >
        {{ content.buttons.edit }}
      </button>
    </div>
  </form>
</div>

<app-debug-hud>
  <button (click)="debugHudFillSubmit()">fill and submit</button>
</app-debug-hud>
