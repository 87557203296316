import { Injectable } from '@angular/core';
import { CookieEnums, CookieService } from '@core/cookie/cookie-service';
import {
  SessionStorageKeys,
  SessionStorageService
} from '@core/session-storage/session-storage.service';
import { Environment } from '@environment/environment';
import moment from 'moment';

@Injectable({ providedIn: 'root' })
export class TemporaLockService {
  constructor(
    private environment: Environment,
    private sessionStorageService: SessionStorageService,
    private cookieService: CookieService
  ) {}

  public checkLockStatus(): boolean {
    const temporalLockCookie =
      this.cookieService.get(CookieEnums.TemporaryLockCookie) === 'true';

    if (temporalLockCookie) {
      return true;
    }

    const lockAttemptsCount = this.sessionStorageService.getItem(
      SessionStorageKeys.temporaryLock
    );

    let lockAttemptsCountToInt = +lockAttemptsCount || 0;

    if (lockAttemptsCountToInt >= this.environment.tempLockThreshold.attempts) {
      //reset session variable
      this.sessionStorageService.setItem(SessionStorageKeys.temporaryLock, 0);

      //set cookie
      this.cookieService.set(CookieEnums.TemporaryLockCookie, 'true', {
        expires: moment()
          .add(this.environment.tempLockThreshold.timerInMinutes, 'minutes')
          .toDate()
      });

      return true;
    }

    if (lockAttemptsCountToInt <= this.environment.tempLockThreshold.attempts) {
      //increment session variable value
      this.sessionStorageService.setItem(
        SessionStorageKeys.temporaryLock,
        lockAttemptsCountToInt + 1
      );
    }

    return false;
  }
}
