import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationFlowService } from '@core/application-flow/application-flow.service';
import { CmsService } from '@core/cms';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PaymentGuardService {
  constructor(
    private router: Router,
    private cmsService: CmsService,
    private applicationFlowService: ApplicationFlowService
  ) {}

  public canActivate(): Observable<boolean> {
    return this.cmsService
      .getByReferences('booking', ['payment_method_selection'])
      .pipe(
        switchMap(data => {
          this.cmsService.clearCache();
          if (!data[0].paymentMethodSelection.skippage) {
            return of(true);
          }
          const nextPage = this.applicationFlowService.getContinuePath(
            'payment-method-selection'
          );
          this.router.navigate([nextPage]);
          return of(false);
        }),
        catchError(
          (): Observable<boolean> => {
            this.cmsService.clearCache();
            const nextPage = this.applicationFlowService.getContinuePath(
              'payment-method-selection'
            );
            this.router.navigate([nextPage]);
            return of(false);
          }
        )
      );
  }
}
