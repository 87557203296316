import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  ECCustomDateAdapter,
  ECCustomDateParserFormatter
} from '@core/datepicker/EDCustomDatepickerAdapter.service';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';

import {
  PrimaryPaymentFrequency,
  PrimaryPaymentFrequencyForm,
  PrimaryPaymentFrequencyValue
} from './payment-frequency';
import { PaymentFrequencyFormGroup } from './payment-frequency.form';
import { PaymentFrequencyFormConfig } from './payment-frequency.form.config';

export interface DatePickerDateType {
  year?: number;
  month?: number;
  day?: number;
}

@Component({
  selector: 'app-payment-frequency',
  templateUrl: './payment-frequency.component.html',
  styleUrls: ['../../../income.component.scss'],
  providers: [
    PaymentFrequencyFormGroup,
    PaymentFrequencyFormConfig,
    {
      provide: NgbDateAdapter,
      useClass: ECCustomDateAdapter
    },
    { provide: NgbDateParserFormatter, useClass: ECCustomDateParserFormatter }
  ]
})
export class PaymentFrequencyComponent implements OnInit, OnDestroy {
  @Input() public formGroup: FormGroup;
  @Input() public CMSPaymentFrequency: any;

  public model: NgbDateStruct;
  public date: DatePickerDateType;
  public minDateTomorrow: DatePickerDateType;
  public maximumDate: DatePickerDateType;
  public nextWeek: DatePickerDateType;
  public nextTwoWeeks: DatePickerDateType;
  public minDate: string;
  public maxDate: string;
  public form: PaymentFrequencyFormGroup;
  public formConfig: PaymentFrequencyFormConfig;

  constructor() {}

  public ngOnInit(): void {
    this.form = new PaymentFrequencyFormGroup(
      this.CMSPaymentFrequency.validation,
      this.CMSPaymentFrequency.ispaydateenabled
    );

    this.formConfig = new PaymentFrequencyFormConfig(
      this.form,
      this.CMSPaymentFrequency.paymentFrequencyOptions
    );

    if (this.CMSPaymentFrequency.ispaydateenabled) {
      const oneWeekLimit = moment().add(1, 'week');
      const twoWeeksLimit = moment().add(2, 'week');
      const tomorrow = moment().add(1, 'day');

      this.minDateTomorrow = {
        year: parseInt(tomorrow.format('YYYY'), 10),
        month: parseInt(tomorrow.format('MM'), 10),
        day: parseInt(tomorrow.format('DD'), 10)
      };

      this.nextWeek = {
        year: parseInt(oneWeekLimit.format('YYYY'), 10),
        month: parseInt(oneWeekLimit.format('MM'), 10),
        day: parseInt(oneWeekLimit.format('DD'), 10)
      };

      this.nextTwoWeeks = {
        year: parseInt(twoWeeksLimit.format('YYYY'), 10),
        month: parseInt(twoWeeksLimit.format('MM'), 10),
        day: parseInt(twoWeeksLimit.format('DD'), 10)
      };
    }
    this.formGroup.addControl('paymentFrequency', this.form);
  }

  public get maxDateLimit(): DatePickerDateType {
    const primarPaymentFrequency = this.form.get('primaryPaymentFrequency')
      .value;

    if (primarPaymentFrequency === PrimaryPaymentFrequencyValue.BiWeekly) {
      return this.nextTwoWeeks;
    }

    return this.nextWeek;
  }

  public ngOnDestroy(): void {
    this.formGroup.removeControl('paymentFrequency');
  }

  public get value(): PrimaryPaymentFrequency {
    return this.getPrimaryPaymentFrequency(this.form.getRawValue());
  }

  public patchValue(value: PrimaryPaymentFrequency): void {
    let parsedPaymentFrequencyValueKeyName = 'primaryNextPayDate';

    const formValue = this.getPrimaryPaymentFrequencyForm(value);
    this.form.patchValue(formValue);
    if (this.CMSPaymentFrequency.ispaydateenabled) {
      switch (formValue.primaryPaymentFrequency) {
        case PrimaryPaymentFrequencyValue.Monthly:
          parsedPaymentFrequencyValueKeyName = 'primaryMonthlyDayOfMonth';
          break;

        case PrimaryPaymentFrequencyValue.SemiMonthly:
          parsedPaymentFrequencyValueKeyName = 'primarySemiMonthlyDaysOfMonth';
          break;

        default:
          parsedPaymentFrequencyValueKeyName = 'primaryNextPayDate';
          break;
      }
      setTimeout(() => {
        this.form
          .get('primaryNextPayDate')
          .setValue(formValue[parsedPaymentFrequencyValueKeyName]);
      });
    }
  }

  public showValidationErrors(): void {
    this.form?.showValidationErrors();
  }

  private getPrimaryPaymentFrequency(
    formValue: PrimaryPaymentFrequencyForm
  ): PrimaryPaymentFrequency {
    let primaryDayOfMonth1: string;
    let primaryDayOfMonth2: string;

    if (
      formValue.primaryPaymentFrequency ===
        PrimaryPaymentFrequencyValue.SemiMonthly &&
      formValue.primaryNextPayDate
    ) {
      const daysOfMonth = formValue.primaryNextPayDate.split(',');

      primaryDayOfMonth1 = daysOfMonth[0];
      primaryDayOfMonth2 = daysOfMonth[1];
    }

    if (
      formValue.primaryPaymentFrequency ===
        PrimaryPaymentFrequencyValue.Monthly &&
      formValue.primaryNextPayDate
    ) {
      primaryDayOfMonth1 = formValue.primaryNextPayDate;
    }

    return {
      primaryPaymentFrequency: formValue.primaryPaymentFrequency,
      primaryNetAmount: formValue.primaryNetAmount,
      primaryNextPayDate: formValue.primaryNextPayDate,
      primaryDayOfMonth1,
      primaryDayOfMonth2
    };
  }

  private getPrimaryPaymentFrequencyForm(
    obj: PrimaryPaymentFrequency
  ): PrimaryPaymentFrequencyForm {
    return {
      primaryPaymentFrequency: obj.primaryPaymentFrequency,
      primaryNetAmount: obj.primaryNetAmount,
      primaryNextPayDate: obj?.primaryNextPayDate
        ? moment(obj.primaryNextPayDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
        : null,
      primarySemiMonthlyDaysOfMonth: obj?.primaryDayOfMonth1
        ? `${obj.primaryDayOfMonth1},${obj.primaryDayOfMonth2}`
        : null,
      primaryMonthlyDayOfMonth: obj.primaryDayOfMonth1
    };
  }
}
