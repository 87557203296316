import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidatorFn } from '@angular/forms';
import { patternValidator, ValidationMessagesError } from '@elevate/forms';
import { Environment } from '@environment/environment';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { GettingStartedValidatorOptions } from './getting-started';
import { StateEligibilityService } from './state-eligibility/state-eligibility.service';
import { StateResourceModel } from './state-eligibility/stateResourceModel';

@Injectable()
export class GettingStartedValidator {
  public dob = {
    minAgeValidator: (
      options?: GettingStartedValidatorOptions
    ): ValidatorFn => (control: AbstractControl) =>
      this.dobMinAgeValidator(control, options)
  };

  public ssn = {
    patternValidator: (options?: GettingStartedValidatorOptions): ValidatorFn =>
      this.ssnPatternValidator(options)
  };

  public state = {
    eligibilityValidator: (
      options?: GettingStartedValidatorOptions
    ): AsyncValidatorFn => (control: AbstractControl) =>
      this.eligibilityValidator(control, options)
  };

  constructor(
    private environment: Environment,
    private stateEligibilityService: StateEligibilityService
  ) {}

  private dobMinAgeValidator(
    control: AbstractControl,
    options: GettingStartedValidatorOptions = {}
  ): ValidationMessagesError {
    try {
      const dateString = control.value as string;
      if (!dateString.match(/(0|1)?\d\/(0|1|2|3)?\d\/\d{4}/gm)) {
        return;
      }

      const date = moment(control.value, 'MM/DD/YYYY');

      const minAge = this.stateEligibilityService.getStateResourceAge();
      if (moment().diff(date, 'y') < minAge) {
        return new ValidationMessagesError(
          options.name ?? 'minAge',
          [],
          options.message ?? 'Insufficient age.'
        );
      } else {
        return null;
      }
    } catch {
      return null;
    }
  }

  private ssnPatternValidator(
    options?: GettingStartedValidatorOptions
  ): ValidatorFn {
    let regex: RegExp;

    if (this.environment.production) {
      regex = /^(?!219099999|078051120)(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/;
    } else {
      regex = /^(?!219099999|078051120)(?!000)\d{3}(?!00)\d{2}(?!0{4})\d{4}$/;
    }

    return patternValidator(regex, options.message);
  }

  private eligibilityValidator(
    control: AbstractControl,
    options: GettingStartedValidatorOptions = {}
  ): Observable<ValidationMessagesError> {
    return control.value
      ? this.stateEligibilityService.getStateResource(control.value).pipe(
          map((stateRes: StateResourceModel) => {
            if (!stateRes || !stateRes.isLoanServiced) {
              return new ValidationMessagesError(
                options.name ?? 'stateNotServiced',
                [],
                options.message ??
                  'We are unable to process your application. The state you are currently reside in is not serviced by this product.'
              );
            }
          })
        )
      : of(null);
  }
}
