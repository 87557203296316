import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  AfterViewChecked
} from '@angular/core';
import { ApplicationDataService } from '@application/application.service';
import { PlaidService } from '@core/plaid/plaid.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UsePreviousAccountModalComponent } from './use-previous-account-modal/use-previous-account-modal.component';
import { HandleBankConnectionResponseService } from '../../../core/connect-bank/handle-bank-connection-response.service';
import { DocumentApi } from '@core/document/document.api';
import { lastValueFrom, Subscription } from 'rxjs';
import { Accordion, AccordionGroup } from '@elevate/ui-components';
import { ActivatedRoute, Router } from '@angular/router';
import { CmsPageContentService } from '@core/cms/services/cms-page-content.service';
import { AccountTypeModalCmsContents } from '@application/connect-bank/account-type-modal/account-type-modal.component';
import { AccessibilityService } from '@core/accessibility/accessibility.service';
import { FinicityService } from '@core/finicity/finicity.service';
import { BankConnectSuccessObject } from '@core/connect-bank/bank-connect.types';
import { BankDataProviders } from '@application/application';
import { CheckingAccountModalCmsContents } from '@application/connect-bank/checking-account-modal/checking-account-modal.component';
import { GoogleAnalytics } from '@core/google-analytics/googleanalytics.service';
import { LocationProxy } from '@core/location-proxy/location-proxy.service';
import { DocumentService } from '@core/document/document.service';
import { LoadingModalService } from '@application/loading-modal/loading-modal.service';

export interface AdditionalInfoBankCmsContent {
  header: string;
  subHeader: string;
  content: string;
  messages: {
    plaidError: string;
    checkingAccountError: string;
    finicityError: string;
  };
  buttons: {
    continue: string;
  };
  disclosures: {
    title: string;
    disclosures: Accordion[];
  };
  dynamicDisclosures: {
    title: string;
    bankDataProvider: {
      finicity: {
        title: string;
        disclosures: Accordion[];
      };
      plaid: {
        title: string;
        disclosures: Accordion[];
      };
    };
  };
  accountTypeModal: AccountTypeModalCmsContents;
  checkingAccountModal: CheckingAccountModalCmsContents;
  usePreviousAccountModal: {
    content: string;
    buttonContinue: string;
  };
}

@Component({
  selector: 'app-additional-info-bank',
  templateUrl: './additional-info-bank.component.html',
  styleUrls: ['./additional-info-bank.component.scss'],
  providers: [HandleBankConnectionResponseService]
})
export class AdditionalInfoBankComponent
  implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  public whyDoWeNeedThisInformationDisclosure: Accordion;
  public whatHappensWhenIConnectDisclosure: Accordion;
  public whatHappensIfICannotLogInDisclosure: Accordion;
  public plaidSuccessSubscription: Subscription;
  public finicitySuccessSubscription: Subscription;
  public groupAccordionConfig: AccordionGroup;
  public cmsContent: AdditionalInfoBankCmsContent;
  public disclosureContent: Accordion[];
  public isFinicity: boolean;

  private documentName = `rfai_${Date.now()}.pdf`;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private pageHeaderService: CmsPageContentService,
    private applicationDataService: ApplicationDataService,
    private plaidService: PlaidService,
    private modalService: NgbModal,
    private documentService: DocumentService,
    public handleBankConnectionResponseService: HandleBankConnectionResponseService,
    private documentApi: DocumentApi,
    public finicityService: FinicityService,
    private adaAccessibility: AccessibilityService,
    public googleAnalytics: GoogleAnalytics,
    private locationProxy: LocationProxy,
    private loadingModalService: LoadingModalService
  ) {}

  ngOnInit(): void {
    const applicationData = this.applicationDataService.getApplication();

    this.cmsContent = this.activatedRoute.snapshot.data.cmsContent
      .additionalInfoBank as AdditionalInfoBankCmsContent;

    this.isFinicity =
      applicationData.product.bankDataProvider.toUpperCase() ===
      BankDataProviders.Finicity
        ? true
        : false;

    const bankDataProvider = this.activatedRoute.snapshot.data.cmsContent
      .additionalInfoBank.dynamicDisclosures.bankDataProvider;

    this.isFinicity
      ? (this.disclosureContent = bankDataProvider.finicity.disclosures)
      : (this.disclosureContent = bankDataProvider.plaid.disclosures);

    const firstName = applicationData.form.applicant.identity.firstName;

    this.cmsContent.header = this.cmsContent.header.replace(
      '${firstName}',
      firstName
    );

    this.pageHeaderService.updatePageTitle(this.cmsContent.header);
    this.groupAccordionConfig = this.getGroupAccordionConfig();

    if (this.isFinicity) {
      this.finicitySuccessSubscription = this.finicityService
        .successSubscription()
        .subscribe((successObject: BankConnectSuccessObject) => {
          this.handleBankConnectionResponseService.callBankConnection(
            successObject,
            '/pending',
            undefined,
            null,
            this.cmsContent.checkingAccountModal
          );  
        });
    } else {
      this.plaidService.initializePlaid();

      this.plaidSuccessSubscription = this.plaidService
        .successSubscription()
        .subscribe(async (successObject: BankConnectSuccessObject) => {
          await this.handleBankConnectionResponseService.callBankConnection(
            successObject,
            '/pending',
            undefined,
            this.cmsContent.accountTypeModal,
            null
          );
        });
    }
    this.adaAccessibility.removeUnAllowedAriaAttributes();
  }

  public ngAfterViewChecked(): void {
    this.adaAccessibility.removeUnAllowedAriaAttributes();
  }

  public ngAfterViewInit(): void {
    const element = document.getElementById(
      'rfaiDocumentDownloadLink'
    ) as HTMLElement;
    element.addEventListener('click', () => {
      this.getDocument();
    });
  }

  public ngOnDestroy(): void {
    if (this.isFinicity) {
      this.finicitySuccessSubscription.unsubscribe();
      this.finicityService.destroy();
    } else {
      this.plaidSuccessSubscription.unsubscribe();
      this.plaidService.destroy();
    }
  }

  public continue(): void {
    const usePreviousAccountModalConsent: NgbModalRef = this.modalService.open(
      UsePreviousAccountModalComponent,
      {
        backdrop: 'static',
        keyboard: false,
        centered: true,
        windowClass: 'use-previous-account-modal'
      }
    );
    usePreviousAccountModalConsent.componentInstance.modalCmsData = this.cmsContent.usePreviousAccountModal;

    usePreviousAccountModalConsent.result.then(result => {
      if (result) {
        if (!this.isFinicity) {
          this.plaidService.open();
        } else {
          this.finicityService.open();
        }
      }
    });
  }

  public async getDocument(): Promise<void> {
    this.loadingModalService.open();
    const documentName = `rfai_${Date.now()}.pdf`;
    const blob: Blob = await lastValueFrom(this.documentApi.get('rfai'));
    this.loadingModalService.close();
    this.documentService.download(blob, documentName);
  }

  private getGroupAccordionConfig(): AccordionGroup {
    const whyDoWeNeedThisInformationDisclosure = {
      id: this.disclosureContent[0].id,
      head: this.disclosureContent[0].head,
      body: this.disclosureContent[0].body,
      expanded: false
    };

    const whatHappensWhenIConnectDisclosure = {
      id: this.disclosureContent[1].id,
      head: this.disclosureContent[1].head,
      body: this.disclosureContent[1].body,
      expanded: false
    };

    const whatHappensIfICannotLogInDisclosure = {
      id: this.disclosureContent[2].id,
      head: this.disclosureContent[2].head,
      body: this.disclosureContent[2].body,
      expanded: false
    };

    return {
      id: 'AdditionalInfoBankAccordionGroup',
      autoclose: true,
      spacing: 10,
      items: [
        { ...whyDoWeNeedThisInformationDisclosure },
        { ...whatHappensWhenIConnectDisclosure },
        { ...whatHappensIfICannotLogInDisclosure }
      ]
    };
  }

  public expand($event: number): void {
    this.googleAnalytics.expandAccordionEvent({
      link_id: this.groupAccordionConfig.items[$event].id,
      link_text: this.groupAccordionConfig.items[$event].head,

      link_classes: 'ecl-accordion-head',
      link_type: 'accordion',
      link_domain: this.locationProxy.origin,
      link_url: this.locationProxy.href
    });

    setTimeout(() => {
      const accordionClickAnchorTags = document.querySelectorAll(
        "[data-ga4='accordion-anchor-click']"
      );

      const anchorElements: Element[] = Array.from(accordionClickAnchorTags);

      for (let item of anchorElements) {
        item.addEventListener('click', () => {
          this.googleAnalytics.clickAccordionEvent({
            link_id: item.id,
            link_text: item.innerHTML,
            link_classes: item.className,
            link_type: 'accordion',
            link_domain: item.baseURI,
            link_url: item.attributes['href']?.value
          });
        });
      }
    }, 1000);
  }

  public collapse($event: number): void {
    this.googleAnalytics.collapseAccordionEvent({
      link_id: this.groupAccordionConfig.items[$event].id,
      link_text: this.groupAccordionConfig.items[$event].head,

      link_classes: 'ecl-accordion-head',
      link_type: 'accordion',
      link_domain: this.locationProxy.origin,
      link_url: this.locationProxy.href
    });
  }
}