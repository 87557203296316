<div class="processing-docs-container">
  <h2 class="page-subtitle">{{ cmsContent.subHeader }}</h2>

  <div
    class="processing-docs-text-section"
    #processingDocsTextSection
    [innerHTML]="contentText | safe: 'html'"
  ></div>

  <ecl-document-upload
    #documentUploadComponent
    [config]="documentUploadConfig"
    [cmsContent]="cmsContent.documentUpload"
    [disableSubmitButton]="disableSubmit"
    [privacyPolicyUrl]="currentUrl"
    (documentTypeChange)="documentTypeChanged($event)"
    (onCancel)="cancelUploadDocuments()"
    (submitDocuments)="submit()"
    (delete)="deleteDocument($event)"
  >
  </ecl-document-upload>
</div>
