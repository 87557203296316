import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CobrowseConfig, CobrowseContent } from '@elevate/cobrowse';
import { Environment } from '@environment/environment';
import { CmsService } from '@core/cms';
import { lastValueFrom } from 'rxjs';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit {
  public sideBarContents: SafeHtml;
  public sideBarContentsAdditional: SafeHtml;
  public assistanceLogo: any;
  public committedSecurityLogo: any;
  public cobrowseHtmlContent: CobrowseContent;
  public cobrowseConfig: CobrowseConfig;

  @Input() public isCobrowseEnabled: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private environment: Environment,
    private cmsService: CmsService
  ) {
    this.cobrowseConfig = this.environment.coBrowseConfiguration;
    this.sideBarContents = this.route.snapshot.data.sharedCmsContent.sideBar.content as SafeHtml;
    this.assistanceLogo = this.route.snapshot.data.sharedCmsContent.sideBar?.assistanceImage;
    this.committedSecurityLogo = this.route.snapshot.data.sharedCmsContent.sideBar?.commitmentSecurityImage;
    this.sideBarContentsAdditional = this.route.snapshot.data.sharedCmsContent.sideBar.additionalContent as SafeHtml;
  }

  public async ngOnInit(): Promise<void> {
    await lastValueFrom(this.cmsService.getEntries('layout')).then(data => {
      this.cobrowseHtmlContent = data.coBrowse as CobrowseContent;
    });
  }
}
