import { Component, Input, OnInit } from '@angular/core';
import { BaseModalConfig } from '@elevate/base-modal';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DefaultModal } from '../getting-started.content';
import { GoogleAnalytics } from '@core/google-analytics/googleanalytics.service';

@Component({
  selector: 'app-state-not-serviced-modal',
  templateUrl: './state-not-serviced-modal.component.html'
})
export class StateNotServicedModalComponent implements OnInit {
  @Input() public htmlContent: DefaultModal;
  @Input() public modalData: BaseModalConfig = {
    action: {
      allowClose: true
    }
  } as BaseModalConfig;

  constructor(
    public activeModal: NgbActiveModal,
    public googleAnalytics: GoogleAnalytics
  ) {}

  ngOnInit(): void {
    this.modalData.heading = this.htmlContent.header;
    this.modalData.content = this.htmlContent.content;
    this.modalData.action.submitButton = this.htmlContent.button;
    this.modalData.action.onSubmit = () => this.closeModal('continue');
    this.modalData.action.onClose = () => this.closeModal('close');
  }

  public closeModal(buttonType: string): void {
    this.activeModal.close();
  }
}
