import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageFormGroup } from '@core/page-form-group/page-form-group';
import { ActivatedRoute } from '@angular/router';
import {
  AccountSetupContent,
  PasswordValidationMessages
} from '@application/account-setup/account-setup-content';

@Injectable()
export class AccountSetupFormGroup extends PageFormGroup {
  get validationMessages(): PasswordValidationMessages {
    const pageContent = this.route.snapshot.data.cmsContent
      .accountSetup as AccountSetupContent;
    return pageContent.password.validationMessages;
  }

  constructor(private route: ActivatedRoute) {
    super({});
    this.addControl('email', new FormControl({ value: null, disabled: true }));
    this.addControl('newPassword', new FormControl(null, []));
    this.addControl('confirmPassword', new FormControl(null, []));
    this.addControl('username', new FormControl(null, []));
  }
}
