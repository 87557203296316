import { Injectable } from '@angular/core';
import { Environment } from '@environment/environment';
import {
  Observable,
  ReplaySubject,
  take
} from 'rxjs';
import { ScriptLoaderService } from '../script-loader/script-loader.service';
import { ConfigurationService } from '@core/configuration/configuration.service';

export interface BlackboxResponse {
  blackbox: string;
  finished: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class IovationService {
  private iovationResponse: ReplaySubject<BlackboxResponse>;
  private blackBoxCheckInterval: any;
  private iovationWaitEnabled: boolean;
  constructor(
    private environment: Environment,
    private scriptLoaderService: ScriptLoaderService,
    private configurationService: ConfigurationService
  ) {
    window['io_global_object_name'] = 'IGLOO';
    window['IGLOO'] = window['IGLOO'] || {
      loader: {
        version: 'general5',
        subkey: this.environment.iovation.subkey
      }
    };

    this.scriptLoaderService.loadScript('assets/scripts/iovation_loader.js');
    this.configurationService
      .IsIovationWaitEnabled()
      .pipe(take(1))
      .subscribe(value => {
        this.iovationWaitEnabled = value;
      });
  }

  public getIovationSignature(): Observable<BlackboxResponse> {
    this.iovationResponse = new ReplaySubject<BlackboxResponse>();
    if (this.iovationWaitEnabled) {
      this.startBlackBoxRetrieval();
      return this.iovationResponse;
    } else {
      try {
        const bbData = window['IGLOO'].getBlackbox() as BlackboxResponse;
        this.iovationResponse.next(bbData);
        this.iovationResponse.complete();
        return this.iovationResponse;
      } catch {
        this.iovationResponse.next({
          blackbox: '',
          finished: false
        });
        this.iovationResponse.complete();
        return this.iovationResponse;
      }
    }
  }

  private startBlackBoxRetrieval(): void {
    this.blackBoxCheckInterval = setInterval(
      this.checkBlackBoxRetrieval.bind(this),
      100
    );
  }
  
  private checkBlackBoxRetrieval(): void {
    try {
      const bbData = window['IGLOO'].getBlackbox() as BlackboxResponse;
      if (bbData.finished) {
        clearInterval(this.blackBoxCheckInterval);
        this.iovationResponse.next(bbData);
        this.iovationResponse.complete();
      }
    } catch {}
  }
}
