import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApplicationDataService } from '@application/application.service';
import { UnderwritingApiService } from '@application/underwriting/underwriting-api.service';
import { UserInactivityStatusService } from '@core/inactivity-module/user-inactivity.service';
import { SessionStorageService } from '@core/session-storage/session-storage.service';
import { CmsPageContentService } from '@core/cms/services/cms-page-content.service';

export interface ErrorPageCmsContent {
  header: string;
  content: string;
}

export const DefaultPageContent = {
  header: `We're Sorry!`,
  content: `<p>
  We apologize for the inconvenience but we have encountered a system error.
  Please contact us at 888-225-0080 if you need immediate assistance, or
  please try again.
</p>
<p>Thank you for your understanding as we work to resolve this issue.</p>`
} as ErrorPageCmsContent;

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ErrorComponent implements OnInit {
  public cmsContent: ErrorPageCmsContent = {} as ErrorPageCmsContent;

  constructor(
    private pageHeaderService: CmsPageContentService,
    private sessionStorageService: SessionStorageService,
    private appDataService: ApplicationDataService,
    private underwritingService: UnderwritingApiService,
    private userAppStarted: UserInactivityStatusService,
    private route: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this.cmsContent =
      this.route.snapshot.data?.sharedCmsContent?.errorPage ||
      DefaultPageContent;
    this.pageHeaderService.updatePageTitle(this.cmsContent.header);
    this.userAppStarted.setUserApplicationStatus(false);
    this.sessionStorageService.clearAll();
    this.underwritingService.resetDecision();
    this.appDataService.resetApplication();
  }
}
