import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { GoogleAnalytics, GtagLinkData } from '../googleanalytics.service';

@Directive({
  selector: '[googleAnalyticsClick]'
})
export class GoogleAnalyticsClickDirective {
  @Input('googleAnalyticsClick') public googleAnalyticsCategory: string;

  private hostElement: ElementRef;

  constructor(el: ElementRef, private googleAnalytics: GoogleAnalytics) {
    this.hostElement = el;
  }

  @HostListener('click', ['$event.target.value'])
  public onClick(): void {
    const clickData: GtagLinkData = {
      link_id: this.hostElement.nativeElement.id,
      link_text: this.hostElement.nativeElement.innerHTML,
      link_classes: this.hostElement.nativeElement.className,
      link_type: 'button',
      link_domain: this.hostElement.nativeElement.baseURI,
      link_url: this.hostElement.nativeElement.formAction
    };
    this.googleAnalytics.ctaClickEvent(clickData);
  }
}
