import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { CmsService } from '../services';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { CmsPageContentService } from '@core/cms/services/cms-page-content.service';
import { Environment } from '@environment/environment';

export interface BrowserWindow {
  cms: any;
  getApplication: any;
}

@Injectable({ providedIn: 'root' })
export class CmsPageContentResolver {
  constructor(
    private cmsService: CmsService,
    private cmsPageContentService: CmsPageContentService,
    private router: Router,
    private env: Environment,
    @Inject('window') private window: BrowserWindow
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    const routeData = route.data;

    const entries =
      routeData.cmsContentReferences?.length > 0
        ? this.cmsService.getByReferences(
            routeData.cmsContentTypeUid,
            routeData.cmsContentReferences
          )
        : this.cmsService.getEntries(routeData.cmsContentTypeUid);

    return entries.pipe(
      map(data => (Array.isArray(data) ? data[0] : data)),
      tap(cmsData => {
        if (!this.env.production) {
          if (!this.window.cms) {
            this.window.cms = {};
          }

          this.window.cms[routeData.cmsContentTypeUid] = cmsData;
        }
      }),
      shareReplay(1),
      catchError(() => {
        // navigate to error page if content wasn't loaded
        return this.router.navigate(['/error']);
      })
    );
  }
}
