export enum UnderwritingSteps {
  Prequal = 'Prequal',
  ApplicationSubmit = 'ApplicationSubmit'
}

export enum UnderwritingStepStatus {
  NotStarted = 'NotStarted',
  PendingDecision = 'PendingDecision',
  Incomplete = 'Incomplete',
  Complete = 'Complete'
}

export enum UnderwritingStatus {
  InProgress = 'InProgress',
  Approved = 'Approved',
  Declined = 'Declined'
}

export enum ManualReviewType {
  OFACTA = 'O',
  FACTA = 'F',
  MLA = 'M',
  BANKVerification = 'B',
  CVS = 'C'
}

export enum UnderwritingDecisionEnum {
  Decline = 'Decline',
  Offer = 'Offer',
  MoreInfo = 'MoreInformation',
  CounterOffer = 'CounterOffer',
  NoOffer = 'NoOffer',
  Continue = 'Continue',
  LenderChange = 'LenderChange'
}

export interface UnderwritingModel {
  steps: UnderwritingStepModel[];
  decisions: UnderwritingDecisionModel[];
}

export interface UnderwritingStepModel {
  name: UnderwritingSteps;
  status?: string;
  disposition?: UnderwritingDispositionModel;
}

export interface UnderwritingDecisionModel {
  name: UnderwritingSteps;
  requestedAt?: string;
  status?: string;
  disposition?: UnderwritingDispositionModel;
}

export interface UnderwritingDispositionModel {
  type?: string;
  reason?: string;
  offer?: UnderwritingOfferModel;
  noaa?: {code? : string};
}

export interface TrackedRequests {
  name?: string;
  requester?: string;
  status?: string;
  type?: string;
  result?: string;
}

export interface UnderwritingOfferModel {
  line?: number;
  apr?: number;
  expireAt?: string;
  isPrescreen?: boolean;
}
