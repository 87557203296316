import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { BaseModalConfig } from '@elevate/base-modal';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DefaultModal } from '../getting-started.content';
import { GoogleAnalytics } from '@core/google-analytics/googleanalytics.service';

@Component({
  templateUrl: './no-option-modal.component.html',
  encapsulation: ViewEncapsulation.None
})
export class NoOptionModalComponent {
  @Input() public htmlContent: DefaultModal;
  @Input() public modalData: BaseModalConfig = {
    useWrapperOnly: true,
    useWrapperEmpty: true,
    action: {
      allowClose: true
    }
  } as BaseModalConfig;

  constructor(
    private activeModal: NgbActiveModal,
    public googleAnalytics: GoogleAnalytics
  ) {}

  public close(): void {
    this.activeModal.close();
  }
}
