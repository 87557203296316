import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  UnderwritingApiService,
  UnderwritingResponseModel
} from '@application/underwriting/underwriting-api.service';
import { ApplicationDataService } from '@application/application.service';
import {
  UnderwritingSteps,
  UnderwritingStatus,
  UnderwritingStepStatus,
  TrackedRequests
} from '@application/underwriting/underwriting.model';
import { ApplicationData, ApplicationStates } from '@application/application';
import { ApplicationApi } from '@application/application.api';
import { lastValueFrom, Subject, Subscription, timer } from 'rxjs';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';
import { CmsPageContentService } from '@core/cms/services/cms-page-content.service';
import {
  ConfigurationService,
  DecisionFlowResponse,
  Decision,
  PlatformConfigFeatures
} from '@core/configuration/configuration.service';
import { AppInsightsService } from '@core/app-insights/app-insights.service';

export interface CmsPending {
  decisionHeader: string;
  info: string;
  applicationIsReviewing: WaitingMessage;
  messages: WaitingMessage[];
  buttons: {
    checkDecision: string;
  };
}

export interface WaitingMessage {
  message: string;
  secondsDelay: number;
}
@Component({
  selector: 'app-pending',
  templateUrl: './pending.component.html',
  styleUrls: ['./pending.component.scss']
})
export class PendingComponent implements OnInit, OnDestroy {
  private currentMessage = 0;
  private timeoutCycle;
  private applicationData: ApplicationData;
  private trackedRequest: TrackedRequests;
  public waitingContent: CmsPending;
  public displayMessage: string;
  public pendingDecision = false;
  public decisionUpdated: Subject<boolean> = new Subject<boolean>();
  public poller: Subscription;
  private decisionFlowResponse: DecisionFlowResponse;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private underwritingService: UnderwritingApiService,
    private applicationDataService: ApplicationDataService,
    private cmsPageContentService: CmsPageContentService,
    private applicationApi: ApplicationApi,
    private configurationService: ConfigurationService,
    private appInsightsService: AppInsightsService
  ) {
    this.cmsPageContentService.updatePageTitle(``);
    this.waitingContent = this.activatedRoute.snapshot.data.cmsContent
      .pending as CmsPending;
    this.messageCycle();
  }

  public async ngOnInit(): Promise<void> {
    const application = await lastValueFrom(this.applicationApi.get());

    if (
      application.state === ApplicationStates.Accepted ||
      application.state === ApplicationStates.Booked
    ) {
      this.router.navigate(['booking']);
    } else {
      this.underwritingService.resetDecision();
      this.applicationData = application;

      this.decisionFlowResponse = await lastValueFrom(
        this.configurationService.getConfigFeature<DecisionFlowResponse>(
          PlatformConfigFeatures.DecisionFlow
        )
      );
      setTimeout(() => {
        document.getElementsByTagName('body')[0].setAttribute('style','overflow: scroll;');
      }, 1000);
      const appSubmitStatus = application.trackedRequests.find(
        (request: TrackedRequests) => this.isBankRequired(request.name) === true
      );

      if (
        appSubmitStatus &&
        appSubmitStatus.status === 'Incomplete' &&
        'connectionResourceUrl' in application.form.applicant.bank
      ) {
        this.pollApplicationData();
      } else {
        this.underwriting();
      }
    }
  }

  private async pollApplicationData(): Promise<void> {
    this.waitingContent.messages.push(
      this.waitingContent.applicationIsReviewing
    );
    const timer$ = timer(300000); // 5 Minute timeout
    this.poller = timer(0, 5000)
      .pipe(
        switchMap(() => this.applicationApi.get()),
        takeUntil(this.decisionUpdated),
        takeUntil(timer$),
        finalize(() => {
          if (this.trackedRequest) {
            this.underwriting();
          } else {
            this.router.navigate(['/error']);
          }
        })
      )
      .subscribe((response: ApplicationData) => {
        this.trackedRequest = response.trackedRequests.find(
          (entry: any) => this.isBankRequired(entry.name) === true
        );

        if (this.trackedRequest && this.trackedRequest.status === 'Complete') {
          this.decisionUpdated.next(true);
        }
      });
  }

  private underwriting(): void {
    if (
      !this.underwritingService.isUnderwritingProcessing &&
      !this.underwritingService.hasUnderwritingCompleted
    ) {
      this.underwritingService.submitApplicationUnderwriting();
    }

    this.underwritingService.underwritingDecisionSubject.subscribe(
      async (response: UnderwritingResponseModel) => {
        clearTimeout(this.timeoutCycle);
        const routePath = this.getNavigationUrl(response);
        if (routePath === 'pending') {
          this.pendingDecision = true;
          
          this.appInsightsService.trackEvent("DMS-FullSubmit-Timeout");
        } else {
          const applicationOffer = this.buildUnderwriting(response);

          const fullSubmitIndex = this.applicationData.underwriting.steps.findIndex(
            (decision: any) => decision.name === 'ApplicationSubmit'
          );

          if (fullSubmitIndex !== -1) {
            this.applicationData.underwriting.steps[fullSubmitIndex] =
              applicationOffer.underwriting.steps[0];
          } else {
            this.applicationData.underwriting.steps.push(
              applicationOffer.underwriting.steps[0]
            );
          }

          this.applicationDataService.addTrackedRequests([
            {
              requester: response.underwrite.name,
              status: response.underwrite.status,
              type: 'Rfai',
              name:
                response.requiredBeforeNextUnderwrite?.additionalInformation[0]
            }
          ]);

          this.applicationDataService.updateUnderwriting(
            this.applicationData.underwriting
          );
          this.router.navigate([routePath]);
        }
      }
    );
  }

  public getNavigationUrl(response: UnderwritingResponseModel): string {
    let target: string;

    if (response.status === UnderwritingStatus.Approved) {
      target = 'approval';
    } else if (response.status === UnderwritingStatus.Declined) {
      target = this.getDeclineDecisionPage(
        'decline',
        response.underwrite.disposition.noaa?.code
      );
    } else if (response.status === UnderwritingStepStatus.PendingDecision) {
      target = 'pending';
    } else if ('agentReviewType' in response.requiredBeforeNextUnderwrite) {
      target = 'in-review';
    } else if (
      response.requiredBeforeNextUnderwrite.additionalInformation.length > 0
    ) {
      const rfaiCode =
        response.requiredBeforeNextUnderwrite?.additionalInformation[0];

      if (this.isBankRequired(rfaiCode)) {
        target = 'additional-info-bank';
      } else {
        target = response.containsUploadedDocuments
          ? 'processing-docs'
          : 'additional-info';
      }
    } else {
      target = '';
    }
    return target;
  }

  public ngOnDestroy(): void {
    if (this.poller) {
      this.poller.unsubscribe();
    }
  }

  public buildUnderwriting(response: UnderwritingResponseModel): any {
    return {
      underwriting: {
        steps: [
          {
            name: UnderwritingSteps.ApplicationSubmit,
            status: response.underwrite.status,
            disposition: response.underwrite.disposition
          }
        ],
        decisions: []
      }
    };
  }

  public checkDecision(): void {
    this.appInsightsService.trackEvent("Check-Decision-Retry");

    this.currentMessage = 0;
    this.underwritingService.resetDecision();
    this.messageCycle();
    this.underwriting();
  }

  private messageCycle(): void {
    this.pendingDecision = false;
    this.showMessage(this.currentMessage);
    if (this.currentMessage < this.waitingContent.messages.length - 1) {
      this.timeoutCycle = setTimeout(
        this.messageCycle.bind(this),
        this.waitingContent.messages[this.currentMessage].secondsDelay * 1000
      );
      this.currentMessage++;
    }
  }

  private showMessage(num: number): void {
    this.displayMessage = this.waitingContent.messages[num].message;
  }

  public isBankRequired(code?: string): boolean {
    if (this.decisionFlowResponse && code) {
      let decision = this.decisionFlowResponse.decision.find(
        x => x.code === code
      );
      if (decision != undefined && decision.bankRequired) {
        return true;
      }
    }

    return false;
  }

  public getDeclineDecisionPage(
    defaultDeclineDecisionPage: string,
    code?: string
  ): string {
    if (this.decisionFlowResponse && code) {
      let decision: Decision = this.decisionFlowResponse.decision.find(
        x => x.code === code
      );
      if (decision != undefined && decision.declineDecisionPage != null) {
        return decision.declineDecisionPage;
      }
    }

    return defaultDeclineDecisionPage;
  }
}
