import { Component, OnInit, Input } from '@angular/core';
import { BaseModalConfig } from '@elevate/base-modal';
import { DefaultModal } from '../getting-started.content';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleAnalytics } from '@core/google-analytics/googleanalytics.service';

@Component({
  selector: 'app-state-message-modal',
  templateUrl: './state-message-modal.component.html',
  styleUrls: ['./state-message-modal.component.scss']
})
export class StateMessageModalComponent {
  @Input() public htmlContent: DefaultModal;
  @Input() public modalData: BaseModalConfig = {
    useWrapperOnly: true,
    useWrapperEmpty: true,
    action: {
      allowClose: true
    }
  } as BaseModalConfig;

  constructor(
    private activeModal: NgbActiveModal,
    public googleAnalytics: GoogleAnalytics
  ) {}

  public close(): void {
    this.activeModal.close();
  }
}
