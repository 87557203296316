import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationApi } from '@application/application.api';
import { ApplicationDataService } from '@application/application.service';
import { LoadingModalService } from '@application/loading-modal/loading-modal.service';
import { ApplicationForm, BankDataProviders } from '@application/application';
import { PrequalApprovalDebugService } from './debug-hud/debug-hud.service';
import { GoogleAnalytics } from '@core/google-analytics/googleanalytics.service';
import { CmsPageContentService } from '@core/cms/services/cms-page-content.service';
import { ConsentSectionItem } from '@application/consents/consents.content';
import { PrequalApprovalFormGroup } from './prequal-approval.component.form';
import { ConsentsComponent } from '@application/consents/consents.component';
import { ConsentHelper } from '@application/consents/consent.helper';
import { lastValueFrom } from 'rxjs';
import { UnderwritingDecisionEnum } from '@application/underwriting/underwriting.model';
import { CurrencyPipe } from '@angular/common';
import { DocumentApi } from '@core/document/document.api';
import { DocumentService } from '@core/document/document.service';

export interface PrequalApprovalCmsContents {
  title: string;
  header: string;
  disclaimer: string;
  message: string;
  nonDmOffer: Offercontent;
  dmOffer: Offercontent;
  counterOffer?: CounterOffer;
  buttons: {
    continue: string;
  };
  consentsSection: ConsentSectionItem[];
}

interface CounterOffer {
  header: string;
  noaaLinkText: string;
  subHeader: string;
  subText: string;
}

export interface Offercontent {
  subHeader: string;
  content: string;
}

@Component({
  selector: 'app-prequal-approval',
  templateUrl: './prequal-approval.component.html',
  styleUrls: ['./prequal-approval.component.scss'],
  providers: [PrequalApprovalFormGroup, PrequalApprovalDebugService]
})
export class PrequalApprovalComponent implements OnInit {
  @ViewChild('consentsComponent') private consentsComponent: ConsentsComponent;
  public subHeader: string;
  public offerContent: string;
  public content: PrequalApprovalCmsContents;
  public approvalAmount: number;
  public isDMuser: boolean;
  public isRise: boolean;
  public isCounterOffer: boolean;
  public prequalOfferAmount: number;
  public consentsSection: ConsentSectionItem[];
  public counterOfferSubText: string;
  public formattedApprovalAmount: string;

  constructor(
    private route: ActivatedRoute,
    public debugHud: PrequalApprovalDebugService,
    public form: PrequalApprovalFormGroup,
    private applicationDataService: ApplicationDataService,
    private router: Router,
    private applicationApi: ApplicationApi,
    private cmsPageContentService: CmsPageContentService,
    private loadingService: LoadingModalService,
    private googleAnalytics: GoogleAnalytics,
    private currencyPipe: CurrencyPipe,
    private documentApi: DocumentApi,
    private documentService: DocumentService
  ) {}

  ngOnInit(): void {
    const application = this.applicationDataService.getApplication();
    this.isRise = application?.brand === 'Rise';
    this.content = this.route.snapshot.data.cmsContent
      .prequalificationApproval as PrequalApprovalCmsContents;
    this.consentsSection =
      this.route.snapshot.data.cmsContent.prequalificationApproval
        .consentsSection || [];
    const firstName = application?.form.applicant.identity.firstName;
    this.approvalAmount = application?.offer.line;
    const applicationSubmitOffer = application?.underwriting?.steps.find(
      (decision: any) =>
        decision.name === 'Prequal' && decision.status === 'Complete'
    );

    this.googleAnalytics.setGoogleTagManagerVariables({
      pre_qual_amount: this.approvalAmount
    });
    this.isDMuser = application?.offer?.isPrescreen || false;

    this.cmsPageContentService.updatePageTitle(this.getPageTitle(firstName));

    if (this.isDMuser) {
      this.subHeader = this.content.dmOffer.subHeader;
      this.offerContent = this.content.dmOffer.content;
    } else {
      this.subHeader = this.content.nonDmOffer.subHeader;
      this.offerContent = this.content.nonDmOffer.content;
    }

    if (
      applicationSubmitOffer?.disposition.type ===
      UnderwritingDecisionEnum.CounterOffer
    ) {
      this.isCounterOffer = true;
      this.prequalOfferAmount = application.form?.requestedAmount;
      this.cmsPageContentService.updatePageTitle(
        `${this.content?.counterOffer?.header}`.replace('{firstName}', firstName)
      );
      this.getCounterOfferHeaders();
    }
  }

  public ngAfterViewInit(): void {
    if (this.isCounterOffer) {
      document
        .getElementById('noaaLink')
        .addEventListener('click', this.downloadNoaa);
    }
  }

  public downloadNoaa = async (): Promise<void> => {
    this.loadingService.open();
    const documentName = `noaa_${Date.now()}.pdf`;
    const blob: Blob = await lastValueFrom(this.documentApi.get('noaa'));
    this.loadingService.close();
    this.documentService.download(blob, documentName);
  };

  private getCounterOfferHeaders(): void {
    const formattedPrequalAmount = this.currencyPipe.transform(
      this.prequalOfferAmount,
      '$',
      false,
      '1.0-0'
    );
    const formattedApprovalAmount = this.currencyPipe.transform(
      this.approvalAmount,
      '$',
      false,
      '1.0-0'
    );
    this.formattedApprovalAmount = formattedApprovalAmount;

    this.subHeader = `${this.content?.counterOffer?.subHeader}`
      .replace('{prequalOfferAmount}', formattedPrequalAmount)
      .replace(/{approvalAmount}/g, formattedApprovalAmount);

    this.counterOfferSubText = `${this.content?.counterOffer?.subText}`
      .replace('{prequalOfferAmount}', formattedPrequalAmount)
      .replace(/{approvalAmount}/g, formattedApprovalAmount);
  }

  public get formContainsConsents(): boolean {
    return this.content.consentsSection?.length > 0;
  }

  public async onSubmit(): Promise<void> {
    this.form.showValidationErrors();
    if (!this.form.valid) {
      return;
    }

    const nextPath =
      this.applicationDataService.getApplication()?.product.bankDataProvider ===
      BankDataProviders.None
        ? 'bank-information'
        : 'connect-bank';
    this.loadingService.open();

    const applicationForm: ApplicationForm = {
      continuePath: nextPath,
      disclosures: this.consentsComponent?.disclosures
    };

    await lastValueFrom(this.applicationApi.append(applicationForm)).then(
      () => {
        this.router.navigate([nextPath]);
      }
    );
  }

  public update(): void {
    this.ngOnInit();
  }

  public debugHudSignAndSubmit(): void {
    this.consentsComponent.debugHudSubmitAllConsents();
    this.onSubmit();
  }

  private getPageTitle(firstName: string): string {
    return this.content.header.replace('{firstName}', firstName);
  }
}
