import { Injectable } from '@angular/core';
import { UserInactivityStatusService } from '@core/inactivity-module/user-inactivity.service';

/* istanbul ignore next */
@Injectable()
export class ConnectBankDebugService {
  constructor(private userInactivityStatus: UserInactivityStatusService) {}

  public setInactivityTime(): void {
    this.userInactivityStatus.setUserApplicationStatus(false);
    this.userInactivityStatus.setIdleTimeConfigurations(15, 30);
    this.userInactivityStatus.setUserApplicationStatus(true);
  }

  public resetInactivityTime(): void {
    this.userInactivityStatus.setUserApplicationStatus(false);
    this.userInactivityStatus.setIdleTimeConfigurations();
    this.userInactivityStatus.setUserApplicationStatus(true);
  }
}
