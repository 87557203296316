import { HttpResponse } from '@angular/common/http';
import {
  Component,
  Inject,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { PartnerVerificationModal } from '@application/getting-started/getting-started.content';
import { LoadingModalService } from '@application/loading-modal/loading-modal.service';
import {
  SendCodeRequestModel,
  VerifyCodeRequestModel,
  VerifiedResponseModel,
  VerifySSNPrepopRequestModel
} from '@core/partner/partner-verification.models';
import {
  IPartnerVerificationService,
  PARTNER_VERIFICATION_SERVICE
} from '@core/partner/partner-verification.interface';
import { ValidationMessagesError } from '@elevate/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import {
  PartnerCommunicationTypeFormGroup,
  PartnerVerificationCodeFormGroup,
  PartnerVerificationSSNFormGroup
} from './form/partner-verification-modal.form';
import { partnerVerificationFormConfig } from './form/partner-verification-modal.form.config';
import { BaseModalConfig } from '@elevate/base-modal';
import { GoogleAnalytics } from '@core/google-analytics/googleanalytics.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-partner-verification-modal',
  templateUrl: './partner-verification-modal.component.html',
  styleUrls: ['./partner-verification-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PartnerVerificationModalComponent implements OnInit {
  @Input() public data;
  @Input() public htmlContent: PartnerVerificationModal;
  @Input() public modalData: BaseModalConfig = {
    useWrapperOnly: true,
    useWrapperEmpty: true,
    action: {
      allowClose: false
    }
  } as BaseModalConfig;

  public page: string;
  public phoneNumberLast4: string;
  public socialSecurity: string;
  public contactTypeForm: PartnerCommunicationTypeFormGroup;
  public verificationForm: PartnerVerificationCodeFormGroup;
  public ssnVerificationForm: PartnerVerificationSSNFormGroup;
  public formConfig = partnerVerificationFormConfig;
  public isSsnVerification = true;
  public isOtpVerification = true;
  public content: PartnerVerificationModal;

  constructor(
    @Inject(PARTNER_VERIFICATION_SERVICE)
    private partnerVerificationService: IPartnerVerificationService,
    private loadingService: LoadingModalService,
    private dialogRef: NgbActiveModal,
    public googleAnalytics: GoogleAnalytics
  ) {
    this.contactTypeForm = new PartnerCommunicationTypeFormGroup();
    this.verificationForm = new PartnerVerificationCodeFormGroup();
    this.ssnVerificationForm = new PartnerVerificationSSNFormGroup();
  }

  public ngOnInit(): void {
    this.phoneNumberLast4 = this.data.phoneNumberLast4;
    this.isSsnVerification = this.data.isSsnVerification;
    this.isOtpVerification = this.data.isOtpVerification;
    this.page = 'SendCode';

    this.formConfig.contactTypeConfig = {
      ...this.formConfig.contactTypeConfig,
      buttons: this.formConfig.contactTypeConfig.buttons.map((prop: any) => ({
        ...prop,
        label: prop.label.replace('YYYY', this.phoneNumberLast4)
      }))
    };

    this.content = this.htmlContent;
    this.verificationForm.build(
      this.content.validationMessages.verificationCode
    );
    this.ssnVerificationForm.build(
      this.content.validationMessages.verificationSsn
    );
  }

  public sendVerificationCode(): void {
    this.contactTypeForm.showValidationErrors();
    if (!this.contactTypeForm.valid) {
      return;
    }

    this.formConfig.contactTypeConfig.value = this.contactTypeForm.value.sendCodeMethod;

    this.loadingService.open();

    const partnerSendCodeInfo: SendCodeRequestModel = {
      leadId: this.data.leadId,
      channel: this.contactTypeForm.value.sendCodeMethod
    };

    lastValueFrom(
      this.partnerVerificationService.sendCode(partnerSendCodeInfo)
    ).then(
      () => {
        this.moveToVerifyScreen();
      },
      () => {
        this.moveToVerifyScreen();
      }
    );
  }

  public verificationSubmit(): void {
    this.verificationForm.showValidationErrors();

    if (!this.verificationForm.valid) {
      return;
    }
    this.loadingService.open();

    const partnerVerifyCodeData: VerifyCodeRequestModel = {
      leadId: this.data.leadId,
      offerId: this.data.offerId,
      channel: this.contactTypeForm.value.sendCodeMethod,
      otp: this.verificationForm.value.verificationCode
    };

    lastValueFrom(
      this.partnerVerificationService.verifyCode(partnerVerifyCodeData)
    ).then(
      (response: HttpResponse<VerifiedResponseModel>) => {
        if (response.status === 200) {
          this.loadingService.close();
          this.dialogRef.close(response.body);
        }
      },
      (error: HttpResponse<any>) => {
        this.verificationForm
          .get('verificationCode')
          .setErrors(
            new ValidationMessagesError(
              'verificationCode',
              null,
              this.content.errorMessages.verificationCode
            )
          );

        this.loadingService.close();
      }
    );
  }
  public ssnVerificationSubmit(): void {
    this.ssnVerificationForm.showValidationErrors();

    if (!this.ssnVerificationForm.valid) {
      return;
    }
    this.loadingService.open();

    const verifyCall = this.data.offerId
      ? this.partnerVerificationService.verifyLast4SSN({
          leadId: this.data.leadId,
          last4Ssn: this.ssnVerificationForm.value.last4SSN,
          offerId: this.data.offerId
        })
      : this.partnerVerificationService.verifyLast4SSNPrepop({
          leadId: this.data.leadId,
          last4Ssn: this.ssnVerificationForm.value.last4SSN
        });

    lastValueFrom(verifyCall).then(
      (response: HttpResponse<VerifiedResponseModel>) => {
        if (response.status === 200) {
          this.loadingService.close();
          this.dialogRef.close(response.body);
        }
      },
      (error: HttpResponse<any>) => {
        this.ssnVerificationForm
          .get('last4SSN')
          .setErrors(
            new ValidationMessagesError(
              'last4SSN',
              null,
              this.content.errorMessages.ssnMatch
            )
          );

        this.loadingService.close();
      }
    );
  }
  public useContactVerification(): void {
    this.page = 'SendCode';
    this.ssnVerificationForm.reset();
  }

  public useSSNVerification(): void {
    this.page = 'VerifySSN';
    this.contactTypeForm.reset();
  }

  public goBack(): void {
    this.page = 'SendCode';
    this.verificationForm.reset();
  }

  private moveToVerifyScreen(): void {
    this.page = 'VerifyCode';
    this.loadingService.close();
  }
}
