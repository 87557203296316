<ecl-base-modal
  class="modal-body"
  id="accountTypeModalWindow"
  [modalConfig]="modalData"
>
  <i
    class="fal fa-times fa-2x close-icon"
    (click)="close()"
    id="accountTypeModalCloseButton"
    (closeModal)="
      googleAnalytics.clickModalEvent({
        action: 'Close',
        modal_name: this.accountTypeModalData.cmsData.header
      })
    "
    (openModal)="
      googleAnalytics.viewModalEvent({
        modal_name: this.accountTypeModalData.cmsData.header
      })
    "
  ></i>
  <h1 ngbAutofocus class="account-type-modal-header" id="accountTypeModalTitle">
    {{ pageTitle }}
  </h1>

  <div class="account-type-modal-error-message ">
    <ecl-notification-box
      type="error"
      id="accountTypeModalCheckingAccountErrorMessage"
    >
      {{ warningMessage }}
    </ecl-notification-box>
  </div>

  <div *ngIf="accountsSelectForm" class="account-type-modal-result-section">
    <p id="userHasAccountMessage">{{ userHasAccountMessage }}</p>

    <form [formGroup]="form" class="account-type-modal-form-container">
      <ecl-radio
        [formControlConfig]="accountTypeRadioFormConfig"
        formControlName="draftFromKey"
      ></ecl-radio>
    </form>
    <div class="account-type-modal-submit-button-container">
      <button
        type="submit"
        (click)="submit()"
        id="accountTypeModalContinueButton"
        class="ecl-button-primary"
      >
        {{ continueButton }}
      </button>
    </div>
  </div>

  <div class="account-type-modal-submit-button-container">
    <button
      type="submit"
      (click)="close()"
      id="accountTypeModalReturnButton"
      class="ecl-button-primary"
    >
      {{ returnButton }}
    </button>
  </div>
</ecl-base-modal>
