<div class="connect-bank-more-info-page-container">
  <ecl-notification-box
    type="error"
    id="bankConnectionErrorMessage"
    *ngIf="
      handleBankConnectionResponseService.bankConnectionError && !isFinicity
    "
  >
    {{ content.messages.plaidError }}
  </ecl-notification-box>
  <ecl-notification-box
    type="error"
    id="bankConnectionErrorMessageFincity"
    *ngIf="
      handleBankConnectionResponseService.bankConnectionError && isFinicity
    "
  >
    {{ content.messages.finicityError }}
  </ecl-notification-box>

  <ecl-notification-box
    type="error"
    id="accountNotPermittedErrorMessage"
    *ngIf="handleBankConnectionResponseService.accountNotPermittedError"
  >
    {{ content.messages.checkingAccountError }}
  </ecl-notification-box>

  <h2 class="page-subtitle">
    {{ content.subHeader }}
  </h2>

  <div>
    {{ content.content }}
  </div>

  <hr class="connect-bank-hr" />

  <ecl-accordion-group [config]="groupAccordionConfig"></ecl-accordion-group>

  <div class="continue-button-container">
    <button
      id="needMoreInfoContinueButton"
      type="button"
      class="ecl-button-primary"
      googleAnalyticsClick="PrequalNMI-Continue"
      (click)="continue()"
    >
      {{ content.buttons.continue }}
    </button>
  </div>
</div>
