<ecl-base-modal
  id="noOptionModalWindow"
  [modalConfig]="modalData"
  (closeModal)="
    googleAnalytics.clickModalEvent({
      action: 'Close',
      modal_name: this.htmlContent.header
    })
  "
  (openModal)="
    googleAnalytics.viewModalEvent({
      modal_name: this.htmlContent.header
    })
  "
>
  <div class="modal-container">
    <h1 id="noOptionModalHeader" class="modal-container-header">
      {{ htmlContent.header }}
    </h1>
    <div id="noOptionModalContent" class="modal-container-content">
      <p [innerHTML]="htmlContent.content | safe: 'html'"></p>
    </div>
    <div class="button-group">
      <button
        id="noOptionModalButton"
        class="ecl-button-primary"
        (click)="close()"
      >
        {{ this.htmlContent.button }}
      </button>
    </div>
  </div>
</ecl-base-modal>
