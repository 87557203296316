<ecl-base-modal
  id="affectCreditScoreModal"
  [modalConfig]="modalData"
  (closeModal)="
    googleAnalytics.clickModalEvent({
      action: 'Close',
      modal_name: this.htmlContent.header
    })
  "
  (openModal)="
    googleAnalytics.viewModalEvent({
      modal_name: this.htmlContent.header
    })
  "
>
  <div [innerHTML]="modalData.content"></div>
</ecl-base-modal>
