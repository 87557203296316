import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Environment } from '@environment/environment';
import { AppInsightsService } from '../../core/app-insights/app-insights.service';

/* istanbul ignore next */
@Component({
  selector: 'app-debug-hud',
  templateUrl: './debug-hud.component.html',
  styleUrls: ['./debug-hud.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DebugHudComponent implements OnInit {
  public production: boolean;
  public expanded: boolean;
  public appInsightsId: string;

  constructor(
    environment: Environment,
    private appinsigthsService: AppInsightsService
  ) {
    this.production = environment.production;
  }

  public toggle(): void {
    this.expanded = !this.expanded;
  }

  public ngOnInit(): void {
    this.appInsightsId = this.appinsigthsService.getAppInsightsAccountId();
  }
}
