import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageFormGroup } from '@core/page-form-group/page-form-group';
import {
  emailValidator,
  minLengthValidator,
  patternValidator,
  requiredValidator
} from '@elevate/forms';
import { MaritalFormValidationMessages } from '../underwriting-approval-content';

export const marriedFormGroupName = 'married';

export interface Married {
  spouseFirstName?: string;
  spouseLastName?: string;
  spouseEmailAddress?: string;
  spouseEmailIsTheSame?: boolean;
}

@Injectable()
export class MarriedFormGroup extends PageFormGroup {

  constructor() {
    super({});
  }

  public buildForm(validationMessages: MaritalFormValidationMessages): void {
    this.addControl(
      'spouseFirstName',
      new FormControl(null, [
        requiredValidator(validationMessages.spouseFirstNameRequired),
        minLengthValidator(2, validationMessages.spouseFirstNameMinLength),
        patternValidator(
          /^[a-zA-Z"'\- ]+$/,
          validationMessages.spouseFirstNamePattern
        )
      ])
    );
    this.addControl(
      'spouseLastName',
      new FormControl(null, [
        requiredValidator(validationMessages.spouseLastNameRequired),
        minLengthValidator(2, validationMessages.spouseLastNameMinLength),
        patternValidator(
          /^[a-zA-Z."'\- ]+$/,
          validationMessages.spouseLastNamePattern
        )
      ])
    );

    this.addControl(
      'spouseEmailAddress',
      new FormControl(null, [
        requiredValidator(validationMessages.spouseEmailRequired),
        emailValidator(validationMessages.spouseEmailInvalid),
        patternValidator(
          /^[^\x80-\xFF]*$/,
          validationMessages.spouseEmailPattern
        )
      ])
    );
    this.addControl('spouseEmailIsTheSame', new FormControl(false));
  }
}
