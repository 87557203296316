import { Injectable } from '@angular/core';
import { ApplicationData, FundMethodType } from '@application/application';

import { Income, PrimarySourceValue, SecondarySourceValue } from './income';
import { PrimaryPaymentFrequencyValue } from './sources/components/payment-frequency/payment-frequency';
import moment from 'moment';

@Injectable()
export class IncomeService {
  public convertToApplicationData(
    income: Income,
    isPaydateEnabled: boolean,
    fundingPaymentSelection?: FundMethodType,
    paymentSelection?: FundMethodType,
  ): ApplicationData {
    const primaryPaymentFrequency = income.primaryPaymentFrequency;

    const model: ApplicationData = {
      form: {
        continuePath: '/verify-income',
        applicant: {
          income: {
            sources: [
              {
                key: 'Primary',
                type: income.primarySource,
                frequency: primaryPaymentFrequency,
                netAmount: +income.primaryNetAmount
              }
            ]
          },
          employments: [
            {
              key: '1'
            }
          ]
        }
      }
    };

    if (fundingPaymentSelection) {
      model.form.fundMethod = fundingPaymentSelection;
    }
    if (paymentSelection) {
      model.form.paymentMethod = paymentSelection;
    }


    if (
      (primaryPaymentFrequency === PrimaryPaymentFrequencyValue.SemiMonthly ||
        primaryPaymentFrequency === PrimaryPaymentFrequencyValue.Monthly) &&
      isPaydateEnabled
    ) {
      if (income.primaryDayOfMonth1) {
        model.form.applicant.income.sources[0].daysOfMonth = {
          first: +income.primaryDayOfMonth1
        };

        if (income.primaryDayOfMonth2) {
          model.form.applicant.income.sources[0].daysOfMonth.second = +income.primaryDayOfMonth2;
        }
      }
    }

    if (
      (primaryPaymentFrequency === PrimaryPaymentFrequencyValue.Weekly ||
        primaryPaymentFrequency === PrimaryPaymentFrequencyValue.BiWeekly) &&
      income?.primaryNextPayDate
    ) {
      model.form.applicant.income.sources[0].nextPayDate = moment(
        income.primaryNextPayDate,
        'MM/DD/YYYY'
      ).format('YYYY-MM-DD');
    }

    if (income.primaryEmployer || income.primaryPhoneNumber) {
      model.form.applicant.employments = [
        {
          key: '1'
        }
      ];
      model.form.applicant.employments[0].incomeSourceKey = 'Primary';
    }

    if (income.primaryEmployer) {
      model.form.applicant.employments[0].employer = income.primaryEmployer;
    }

    if (income.primaryPhoneNumber) {
      model.form.applicant.employments[0].phone = {
        number: income.primaryPhoneNumber
      };

      if (income.primaryPhoneNumberExtension) {
        model.form.applicant.employments[0].phone.extension =
          income.primaryPhoneNumberExtension;
      }
    }

    if (income.secondarySource) {
      model.form.applicant.income.sources.push({
        key: 'Secondary',
        type: income.secondarySource,
        netAmount: +income.secondaryNetAmount,
        frequency: PrimaryPaymentFrequencyValue.Monthly
      });
    }
    model.form.applicant.residences = [
      {
        key: '1',
        monthlyAmount: +income.monthlyRentMortgage
      }
    ];

    return model;
  }

  public convertToIncome(application: ApplicationData): Income {
    const primary = application.form.applicant.income.sources.find(
      s => s.key === 'Primary'
    );

    const primaryEmployer = application.form.applicant.employments?.find(
      e => e.incomeSourceKey === 'Primary'
    );

    const secondary = application.form.applicant.income.sources.find(
      s => s.key === 'Secondary'
    );

    const residence = application.form.applicant.residences.find(
      r => r.key === '1'
    );

    const income: Income = {
      primarySource: primary?.type ? PrimarySourceValue[primary.type] : null,
      primaryPaymentFrequency: primary?.frequency
        ? PrimaryPaymentFrequencyValue[primary.frequency]
        : null,
      primaryNetAmount: primary?.netAmount ? `${primary.netAmount}` : null,
      primaryNextPayDate: primary?.nextPayDate,
      primaryDayOfMonth1: primary?.daysOfMonth?.first
        ? `${primary.daysOfMonth.first}`
        : null,
      primaryDayOfMonth2: primary?.daysOfMonth?.second
        ? `${primary.daysOfMonth.second}`
        : null,
      primaryEmployer: primaryEmployer?.employer,
      primaryPhoneNumber: primaryEmployer?.phone?.number,
      primaryPhoneNumberExtension: primaryEmployer?.phone?.extension,
      secondarySource: secondary?.type
        ? SecondarySourceValue[secondary.type]
        : null,
      secondaryNetAmount: secondary?.netAmount
        ? `${secondary.netAmount}`
        : null,
      monthlyRentMortgage: `${residence.monthlyAmount}`
    };

    return income;
  }
}
