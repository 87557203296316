import { AfterViewInit } from '@angular/core';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DocumentApi } from '@core/document/document.api';
import { lastValueFrom } from 'rxjs';
import { CmsPageContentService } from '@core/cms/services/cms-page-content.service';
import { DocumentService } from '@core/document/document.service';
import { LoadingModalService } from '@application/loading-modal/loading-modal.service';

export interface CmsDecline {
  header: string;
  subHeader: string;
  content: string;
}

@Component({
  selector: 'app-decline',
  templateUrl: './decline.component.html',
  styleUrls: ['./decline.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeclineComponent implements OnInit, AfterViewInit {
  public pageContent: CmsDecline;
  constructor(
    private activatedRoute: ActivatedRoute,
    private documentApi: DocumentApi,
    private pageHeaderService: CmsPageContentService,
    private documentService: DocumentService,
    private loadingModalService: LoadingModalService
  ) {}

  public ngOnInit(): void {
    this.pageContent = this.activatedRoute.snapshot.data.cmsContent
      .decline as CmsDecline;
    this.pageHeaderService.updatePageTitle(this.pageContent.header);
  }

  public ngAfterViewInit(): void {
    const element = document.getElementById('downloadNoaaLink') as HTMLElement;
    element.addEventListener('click', () => {
      this.openNoaaDocument();
    });
  }

  public async openNoaaDocument(): Promise<void> {
    this.loadingModalService.open();
    const documentName = `noaa_${Date.now()}.pdf`;
    const blob: Blob = await lastValueFrom(this.documentApi.get('noaa'));
    this.loadingModalService.close();
    this.documentService.download(blob, documentName);
  }
}
