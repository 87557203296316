import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageFormGroup } from '@core/page-form-group/page-form-group';

@Injectable()
export class IncomeVerificationForm extends PageFormGroup {
  constructor() {
    super({
      incomeVirificationOption: new FormControl(null, [])
    });
  }
}
