import { Injectable } from '@angular/core';

import { Income, PrimarySourceValue, SecondarySourceValue } from '../income';
import { PrimaryPaymentFrequencyValue } from '../sources/components/payment-frequency/payment-frequency';

/* istanbul ignore next */
@Injectable()
export class IncomeDebugService {
  public simulateData(source: PrimarySourceValue): Income {
    let income: Income;

    switch (source) {
      case PrimarySourceValue.JobIncome:
        income = this.simulateJobIncome();
        break;
      case PrimarySourceValue.SelfEmployed:
        income = this.simulateSelfEmployer();
        break;
      case PrimarySourceValue.SocialSecurityDisability:
        income = this.simulateSocialSecurityDisability();
        break;
      case PrimarySourceValue.RetirementPension:
        income = this.simulateRetirementPension();
        break;
      case PrimarySourceValue.Other:
        income = this.simulateOther();
        break;
      // case PrimarySourceValue.Unemployment:
      //   income = this.simulateUnemployment();
      //   break;
    }

    income.primarySource = source;

    return income;
  }

  private simulateJobIncome(): Income {
    return {
      primaryPaymentFrequency: PrimaryPaymentFrequencyValue.Monthly,
      secondarySource: SecondarySourceValue.Other,
      primaryNetAmount: '3000',
      secondaryNetAmount: '2000',
      monthlyRentMortgage: '1000',
      primaryEmployer: 'Employer Test',
      primaryDayOfMonth1: "1"
    } as Income;
  }

  private simulateSelfEmployer(): Income {
    return {
      primaryNetAmount: '2000',
      monthlyRentMortgage: '3000'
    } as Income;
  }

  private simulateSocialSecurityDisability(): Income {
    return {
      primaryPaymentFrequency: PrimaryPaymentFrequencyValue.Monthly,
      primaryNetAmount: '2000',
      monthlyRentMortgage: '3000'
    } as Income;
  }

  private simulateRetirementPension(): Income {
    return {
      primaryPaymentFrequency: PrimaryPaymentFrequencyValue.Weekly,
      // format will be parsed in payment-frequency.component patch function to MM/DD/YYYY
      primaryNetAmount: '2000',
      monthlyRentMortgage: '3000'
    } as Income;
  }

  private simulateOther(): Income {
    return {
      primaryPaymentFrequency: PrimaryPaymentFrequencyValue.BiWeekly,
      // format will be parsed in payment-frequency.component patch function to MM/DD/YYYY
      primaryNetAmount: '2000',
      monthlyRentMortgage: '3000'
    } as Income;
  }

  // private simulateUnemployment(): Income {
  //   return {
  //     primaryPaymentFrequency: PrimaryPaymentFrequencyValue.SemiMonthly,
  //     primaryDayOfMonth1: '1',
  //     primaryDayOfMonth2: '16',
  //     primaryNetAmount: '2000',
  //     monthlyRentMortgage: '3000'
  //   } as Income;
  // }
}
