import {
  Component,
  OnDestroy,
  OnInit,
  SecurityContext,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ApplicantAddressModel,
  ApplicationData,
  ApplicationFlow,
  ApplicationForm,
  StateLineModel
} from '@application/application';
import {
  AppendDeviceRequest,
  ApplicationApi
} from '@application/application.api';
import { ApplicationDataService } from '@application/application.service';
import { IovationService } from '@core/iovation/iovation.service';
import { Environment } from '@environment/environment';
import { forkJoin, lastValueFrom, ReplaySubject, Subscription } from 'rxjs';

import { VerifyInfoFormGroup } from './verify-info.form';
import { LoadingModalService } from '@application/loading-modal/loading-modal.service';
import { GoogleAnalytics } from '@core/google-analytics/googleanalytics.service';
import { CmsPageContentService } from '@core/cms/services/cms-page-content.service';
import { ConsentsComponent } from '@application/consents/consents.component';
import { ApplicationFlowService } from '@core/application-flow/application-flow.service';
import { CmsMailingAddress } from '@application/getting-started/getting-started.content';
import { UnderwritingApiService } from '@application/underwriting/underwriting-api.service';
import {
  SessionStorageKeys,
  SessionStorageService
} from '@core/session-storage/session-storage.service';
import { CurrencyPipe } from '@angular/common';
import * as GettingStartedFormConfig from '../getting-started/getting-started.form.config';
import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import {
  ValidationMessagesError,
  numericMaxValidator,
  numericMinValidator,
  requiredValidator
} from '@elevate/forms';
import { StateEligibilityService } from '@application/getting-started/state-eligibility/state-eligibility.service';
import { CmsService } from '@core/cms';
import {
  CmsRequestedAmount,
  CmsVerifyInfo,
  PolicySection,
  StateLicenses
} from './verify-info';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NeuroIdService } from '@core/neuro-id/neuro-id.service';
import { StateResourceModel } from '@application/getting-started/state-eligibility/stateResourceModel';

@Component({
  selector: 'app-verify-info',
  templateUrl: './verify-info.component.html',
  styleUrls: ['./verify-info.component.scss'],
  providers: [VerifyInfoFormGroup],
  encapsulation: ViewEncapsulation.None
})
export class VerifyInfoComponent implements OnInit, OnDestroy {
  @ViewChild('consentsComponent') private consentsComponent: ConsentsComponent;
  public formConfig = GettingStartedFormConfig;
  public pageContent: SafeHtml;
  public pageSubTitle: string;
  public maskedSSN: string;
  public maskedEmail: string;
  public applicant: any;
  public application: ApplicationData;
  public routeSubscription: Subscription;
  public content: CmsVerifyInfo;
  public cmsMailingAddressContent: CmsMailingAddress;
  public mailingAddress: ApplicantAddressModel;
  public alternatePhoneEnabled: boolean;
  public isMailingSectionEnabled: boolean;
  private appendDeviceApiSubject = new ReplaySubject<boolean>(1);
  public formChangesSubscription: Subscription;
  public requestedAmountContent: CmsRequestedAmount;
  public appendDeviceRequest: AppendDeviceRequest;
  public loanMinMax: StateLineModel;
  public minMaxSubtext: string;
  public stateLicenses: StateLicenses;
  public licenseSubtext: string;
  public policySection: PolicySection[];
  public lenderCode: string;
  public currentState: string;
  public originationFeeCapDollarAmount: number;
  public isloanOriginationFeeApplicable = false;
  public originationFeePercentage: number;
  public orignationFeeMsg: string;
  public showRequestedAmount = false;

  constructor(
    public form: VerifyInfoFormGroup,
    public modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private environment: Environment,
    private applicationDataService: ApplicationDataService,
    private iovationService: IovationService,
    private applicationApi: ApplicationApi,
    private router: Router,
    private loadingService: LoadingModalService,
    private pageHeaderService: CmsPageContentService,
    private applicationFlowService: ApplicationFlowService,
    private underwriting: UnderwritingApiService,
    public googleAnalytics: GoogleAnalytics,
    private sessionStorageService: SessionStorageService,
    public currencyPipe: CurrencyPipe,
    public stateEligibilityService: StateEligibilityService,
    private cmsService: CmsService,
    private neuroIdService: NeuroIdService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.application = this.applicationDataService.getApplication();
    this.content = this.route.snapshot.data.cmsContent.verifyInfo;
    this.requestedAmountContent = this.route.snapshot.data.cmsContent?.requestedAmount;
    this.pageHeaderService.updatePageTitle(this.content.header);
    this.applicant = this.application.form.applicant;
    this.lenderCode = this.application?.product?.lenderCode;
    this.currentState = this.applicant?.residences?.find(
      residence => residence.type === 'Current'
    )?.address?.stateCode;

    if (this.content.policySection != null) {
      this.getStateLenderBasedPolicy(this.content.policySection);
    }
    this.maskedSSN = this.showLast4(
      this.applicant.identity.socialSecurityNumber
    );
    this.maskedEmail = this.emailShortFormat(this.applicant.emails[0].address);
    this.getStateLicenses();
    this.pageSubTitle = this.content.subHeader;

    this.cmsMailingAddressContent = this.route.snapshot.data.cmsContent.prequalificationMailingAddress;
    if (
      this.requestedAmountContent &&
      this.application.applicationFlow !== ApplicationFlow.PartnerPrequal
    ) {
      this.showRequestedAmount = true;
      this.loanMinMax = this.application.product.stateLine;
      const incrementalAmount = Number(
        this.requestedAmountContent.loanAmountInput.validations.incrementAmount
      );
      this.form.addControl(
        'requestedAmount',
        new FormControl('', [
          requiredValidator(),
          numericMinValidator(
            this.loanMinMax.minAmount,
            this.requestedAmountContent.loanAmountInput.validations
              .minimumAmount
          ),
          numericMaxValidator(
            () => this.loanMinMax.maxAmount,
            this.requestedAmountContent.loanAmountInput.validations
              .maximumAmount
          ),
          this.checkIncrementValue(incrementalAmount)
        ])
      );
      if(this.application.form?.requestedAmount){
        this.form.get('requestedAmount').setValue(this.application.form?.requestedAmount);
      }
      this.formConfig.requestedAmount.placeholder = this.requestedAmountContent.loanAmountInput.placeholder;
      this.minMaxSubtext = this.requestedAmountContent.subtext
        .replace('{minimum}', this.formatAmount(this.loanMinMax.minAmount))
        .replace('{maximum}', this.formatAmount(this.loanMinMax.maxAmount))
        .replace('{incrementAmount}', this.formatAmount(incrementalAmount));
      if (!this.stateEligibilityService.getProductConfig()) {
        await lastValueFrom(
          this.stateEligibilityService.getStateResource(this.currentState)
        ).then((res: StateResourceModel) => {
          this.setOriginationValues(res);
        });
      } else {
        this.setOriginationValues(
          this.stateEligibilityService.getProductConfig()
        );
      }
    }
    if (this.cmsMailingAddressContent) {
      this.isMailingSectionEnabled = true;
      this.alternatePhoneEnabled = this.cmsMailingAddressContent.isenabled;
      this.mailingAddress = this.applicant.residences.filter(
        r => r.type === 'Mailing'
      )[0]?.address;
    }

    this.pageContent = this.sanitizer.sanitize(
      SecurityContext.HTML,
      this.content.disclosuresInfo
    );

    this.googleAnalytics.setGoogleTagManagerVariables({
      sequence_id: this.application.sequenceApplicationId,
      application_id: this.application.id
    });
  }

  getStateLenderBasedPolicy(policies: PolicySection[]) {
    this.policySection = policies.filter(
      x =>
        x?.lender === this.lenderCode || x?.states?.includes(this.currentState)
    );
  }

  public showLast4(ssn: string): string {
    const last4ssn = ssn.slice(ssn.length - 4);
    return `***-**-${last4ssn}`;
  }

  public async getStateLicenses() {
    await lastValueFrom(this.cmsService.getEntries('state_license')).then(
      data => {
        let licenses = Array.isArray(data) ? data[0] : data;
        if (licenses) {
          const application = this.application;
          const lender = licenses.group?.filter(
            group => group.lender == application.product.lenderCode
          )[0];
          this.licenseSubtext = lender?.subtext;
          this.stateLicenses = lender?.stateLicenses?.filter(
            stateCode =>
              stateCode.state ===
              application.form.applicant.residences[0].address.stateCode
          )[0];
          if (this.stateLicenses) {
            this.stateLicenses.file = Array.isArray(this.stateLicenses.file)
              ? this.stateLicenses.file
              : [this.stateLicenses.file];
          }
        }
      }
    );
  }

  public emailShortFormat(email: string): string {
    if (email.length > 25) {
      const emailId = email.split('@');
      const firstThree = emailId[0].substring(0, 3);
      const lastThree = emailId[0].substring(emailId[0].length - 3);

      return `${firstThree}****${lastThree}@${emailId[1]}`;
    }

    return email;
  }

  public onEdit(): void {
    const { applicationFlow } = this.applicationDataService.getApplication();

    switch (applicationFlow) {
      case ApplicationFlow.Reapply:
        this.router.navigate(['reapply']);
        break;
      case ApplicationFlow.PartnerPrepop:
      case ApplicationFlow.PartnerPrequal:
        this.router.navigate(['partner']);
        break;

      default:
        this.router.navigate(['getting-started']);
        break;
    }
  }

  public async onSubmit(): Promise<void> {
    this.form.showValidationErrors();

    if (!this.form.valid) {
      return;
    }

    this.googleAnalytics.setGoogleTagManagerVariables({
      loan_requested_amount: this.form.value.requestedAmount
    });
    this.loadingService.open();
    const blackBoxResponse = await lastValueFrom(
      this.iovationService.getIovationSignature()
    );

    if (!this.application.iovationSigAppended) {
      this.appendDeviceRequest = {
        iovationRequestType: this.environment.iovation.requestType,
        iovationSignature: blackBoxResponse.blackbox,
        neuroID: this.neuroIdService.neuroIdSessionId
      };
      let gcid = this.sessionStorageService.getItem(
        SessionStorageKeys.applicationGCID
      );

      if (gcid !== 'undefined') {
        this.appendDeviceRequest.GCID = gcid;
      }
      try {
        await lastValueFrom(
          this.applicationApi.appendDevice(this.appendDeviceRequest)
        );
      } catch {
        this.router.navigate(['/error']);
      }

      this.appendDeviceApiSubject.next(true);
      this.appendDeviceApiSubject.complete();
    } else {
      this.appendDeviceApiSubject.next(true);
      this.appendDeviceApiSubject.complete();
    }

    let appendPayload: ApplicationForm = {
      continuePath: this.applicationFlowService.getContinuePath(),
      disclosures: [
        {
          key: 'PrivacyPolicy',
          consentGiven: true
        },

        {
          key: 'MailOptIn',
          consentGiven: true
        },
        {
          key: 'TextOptIn',
          consentGiven: Boolean(this.form.value.consentToCommunication)
        },
        {
          key: 'PhoneOptIn',
          consentGiven: Boolean(this.form.value.consentToCommunication)
        },
        {
          key: 'ThirdPartyOptIn',
          consentGiven: true
        },
        {
          key: 'ArbitrationOptIn',
          consentGiven: true
        },
        {
          key: 'LegalDiscourseOptIn',
          consentGiven: true
        }
      ]
    };

    if (this.showRequestedAmount) {
      appendPayload.requestedAmount = Number(this.form.value?.requestedAmount);
    }

    Array.prototype.push.apply(
      appendPayload.disclosures,
      this.consentsComponent?.disclosures
    );

    const appendRequest = this.applicationApi.append(appendPayload);
    forkJoin([this.appendDeviceApiSubject, appendRequest]).subscribe({
      complete: () => {
        this.applicationDataService.mergeArray({
          form: {
            ...appendPayload
          }
        });

        if (
          this.application.applicationFlow != ApplicationFlow.PartnerPrequal
        ) {
          this.underwriting.submitApplicationPrequalUnderwriting(
            this.application.id
          );
        }

        this.router.navigate([appendPayload.continuePath]);
      },
      error: () => {
        this.loadingService.close();
        this.router.navigate(['/error']);
      }
    });
  }

  public debugHudFillSubmit(): void {
    if (this.showRequestedAmount) {
      this.form.get('requestedAmount').patchValue(2500);
    }
    this.consentsComponent.debugHudSubmitAllConsents();
    this.onSubmit();
  }

  public async ngOnDestroy(): Promise<void> {
    if (this.formChangesSubscription != null) {
      this.formChangesSubscription.unsubscribe();
    }
  }

  private formatAmount(amount: number): string {
    let formattedAmount = this.currencyPipe.transform(
      amount,
      '$',
      'symbol',
      '1.0-0'
    );
    return formattedAmount;
  }

  private setOriginationValues(productConfig: StateResourceModel) {
    if (productConfig?.originationFeePercentage != 0) {
      this.isloanOriginationFeeApplicable = true;
      this.originationFeeCapDollarAmount =
        productConfig.originationFeeCapDollarAmount;
      this.originationFeePercentage = productConfig.originationFeePercentage;
      this.orignationFeeMsg = this.requestedAmountContent.originationFeeMsg
        .replaceAll(
          '{originationFeePercentage }',
          this.originationFeePercentage.toString()
        )
        .replaceAll(
          '{originationFeeCapDollarAmount}',
          this.originationFeeCapDollarAmount.toString()
        );
    }
  }

  private checkIncrementValue(amount: number): ValidatorFn {
    return function(control: AbstractControl): ValidationErrors {
      if (control.value % amount) {
        return new ValidationMessagesError(
          'incrementError',
          null,
          `Amount must be an increment of $${amount}`
        );
      }
      return null;
    };
  }
}
export { CmsVerifyInfo };
