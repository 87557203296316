import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  IsSsnChangedData,
  IsSsnChangedModel,
  PartnerLeadIdModel,
  SendCodeRequestModel,
  VerifiedResponseModel,
  VerifyCodeRequestModel,
  VerifySSNPrepopRequestModel,
  VerifySSNRequestModel
} from './partner-verification.models';

import { IPartnerVerificationService } from './partner-verification.interface';

@Injectable({
  providedIn: 'root'
})
export class PartnerVerificationServiceMock
  implements IPartnerVerificationService {
  constructor() {}

  public getVerificationOptions(leadId: string): Observable<HttpResponse<any>> {
    return of(
      new HttpResponse({
        body: {
          ssnVerification: true,
          otpVerification: true
        }
      })
    );
  }

  public getLast4PhoneNo(
    getPhoneNoData: PartnerLeadIdModel
  ): Observable<HttpResponse<any>> {
    return of(
      new HttpResponse({
        body: {
          phoneNumberLast4: '6716'
        }
      })
    );
  }

  public getLeadIdLendingTree(
    gcid: string,
    trackingId: string
  ): Observable<HttpResponse<any>> {
    return of(
      new HttpResponse({
        body: {
          leadId: 'lid'
        }
      })
    );
  }

  public getLast4PhoneNoPrepop(leadId: string): Observable<HttpResponse<any>> {
    return of(
      new HttpResponse({
        body: {
          phoneNumberLast4: '6716'
        }
      })
    );
  }

  public sendCode(sendCodeData: SendCodeRequestModel): Observable<any> {
    return of(
      new HttpResponse<any>({
        status: 204
      })
    );
  }

  public verifyCode(
    verificationCodeData: VerifyCodeRequestModel
  ): Observable<HttpResponse<VerifiedResponseModel>> {
    const verifyCodeResponse = {
      token: 'ABCDEFG'
    };
    const verifyCodePassResponse: HttpResponse<any> = new HttpResponse<any>({
      status: 200,
      body: verifyCodeResponse
    });
    return of(verifyCodePassResponse);
  }

  public verifyLast4SSN(
    ssnVerificationData: VerifySSNRequestModel
  ): Observable<HttpResponse<VerifiedResponseModel>> {
    return of(
      new HttpResponse<any>({
        status: 200,
        body: {}
      })
    );
  }

  public verifyLast4SSNPrepop(
    ssnVerificationData: VerifySSNPrepopRequestModel
  ): Observable<HttpResponse<VerifiedResponseModel>> {
    return of(
      new HttpResponse<any>({
        status: 200,
        body: {}
      })
    );
  }

  public isSsnChanged(
    ssnChangedData: IsSsnChangedData
  ): Observable<IsSsnChangedModel> {
    return of({ isChanged: false });
  }
}
