import { Optional } from '@angular/core';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationDataService } from '@application/application.service';
import { UnderwritingApiService } from '@application/underwriting/underwriting-api.service';
import { SessionStorageService } from '@core/session-storage/session-storage.service';
import { Environment } from '@environment/environment';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { InactivityModalComponent } from './inactivity-modal/inactivity-modal.component';
import { AppInsightsService } from '@core/app-insights/app-insights.service';

@Injectable({ providedIn: 'root' })
export class UserInactivityStatusService {
  public dialogRef: NgbModalRef;

  public idleTimeOutStatusObservable: Observable<any>;

  private timeoutInSeconds: number;
  private idleInSeconds: number;

  private idleTimeOutStatus = new Subject<void>();

  private runIdle = new BehaviorSubject<boolean>(true);
  private runIdleObservable = this.runIdle.asObservable();

  constructor(
    private idle: Idle,
    private router: Router,
    private modalService: NgbModal,
    private sessionStorageService: SessionStorageService,
    private enviroment: Environment,
    private underwritingService: UnderwritingApiService,
    private appDataService: ApplicationDataService,
    private appInsightsService: AppInsightsService,
    @Optional() @Inject('RedirectRoute') private redirectRoute: string
  ) {
    this.timeoutInSeconds = this.enviroment.inactivity.timeout;
    this.idleInSeconds = this.enviroment.inactivity.idle;
    this.idleTimeOutStatusObservable = this.idleTimeOutStatus.asObservable();
    this.redirectRoute = this.redirectRoute ?? 'getting-started';

    this._init();
  }

  public _init(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };

    this.setIdleTimeConfigurations();

    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleStart.subscribe(() => {
      if (this.dialogRef?.componentInstance === undefined) {
        this.idleTimeOutStatus.next();
        this.dialogRef = this.modalService.open(InactivityModalComponent, {
          windowClass: 'ng-inactivity-modal',
          backdrop: 'static'
        });
      }
    });

    this.idle.onTimeout.subscribe(() => {
      this.appInsightsService.trackEvent("Idle-Session-Ended");

      this.modalService.dismissAll();
      this.sessionStorageService.clearAll();
      this.underwritingService.resetDecision();
      this.appDataService.resetApplication();
      this.router.navigated = false;
      this.router.navigate([this.redirectRoute]);
    });

    this.runIdleObservable.subscribe((userStartedApp: boolean) => {
      userStartedApp ? this.idle.watch() : this.idle.stop();
    });
  }

  public setIdleTimeConfigurations(
    idle: number = this.idleInSeconds,
    timeout: number = this.timeoutInSeconds
  ): void {
    this.idle.setIdle(idle);
    this.idle.setTimeout(timeout);
  }

  public pingInterruptionToIdle(): void {
    this.idle.interrupt();
  }

  public setUserApplicationStatus(userStartedApp: boolean): void {
    this.runIdle.next(userStartedApp);
  }
}
