import { Component, Input } from '@angular/core';
import { FiveDayRiskFreeGuarantee } from './five-day-bar';

@Component({
  selector: 'rise-five-day-bar',
  templateUrl: './five-day-bar.component.html',
  styleUrls: ['./five-day-bar.component.scss'],
})
export class FiveDayBarComponent {
  @Input() cmsPageContent: FiveDayRiskFreeGuarantee;
}
