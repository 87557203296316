export interface PlaidObj {
  institutions?: PlaidInstitution[];
  open(): void;
  exit(opts?: { force: boolean }): void;
  destroy(): void;
}

export interface PlaidInstitution {
  name: string;
  institution_id: string;
}

export interface PlaidAccount {
  id: string;
  name: string;
  mask?: string;
  type?: string;
  subtype?: string;
  verification_status?: PlaidVerificationStatus;
}

export interface PlaidSuccessMetaData {
  institution?: PlaidInstitution;
  account?: PlaidAccount;
  account_id?: string;
  link_session_id?: string;
  accounts?: PlaidAccount[];
}

export interface PlaidOptions {
  env: string;
  token: string;
  onSuccess: (publicToken: string, metaData: PlaidSuccessMetaData) => void;
  onExit?: (error: PlaidError | null, metaData: PlaidExitMetaData) => void;
  onEvent?: (eventName: PlaidEventName, metaData: PlaidEventMetaData) => void;
  onLoad?: () => void;
  userLegalName?: string;
  userEmailAddress?: string;
  isWebView?: boolean;
  oauthNonce?: string;
  oauthRedirectUri?: string;
  oauthStateId?: string;
}

export interface PlaidStatic {
  create(opts: PlaidOptions): PlaidObj;
}

export interface PlaidSuccessObject {
  publicToken: string;
  metaData: PlaidSuccessMetaData;
}

export interface PlaidEventMetaData {
  error_code: PlaidErrorCodes;
  error_message: string;
  timestamp: string;
  error_type: string;
  exit_status?: PlaidExitStatus;
  institution_id?: string;
  institution_name?: string;
  institution_search_query?: string;
  link_session_id?: string;
  mfa_type?: string;
  request_id?: string;
  view_name?: PlaidViewName;
  amsAppId: string;
  appSequenceId: number;
}

export interface PlaidError {
  display_message?: string;
  error_code: string;
  error_message?: string;
  error_type?: string;
}

export interface PlaidExitMetaData {
  link_session_id?: string;
  request_id?: string;
  institution?: PlaidInstitution;
  status?: PlaidExitStatus;
}

export class PlaidEventRequest {
  public provider: string;
  public eventType: string;
  public timeStamp: string;
  public data?: any;
}

export enum PlaidErrorCodes {
  INSUFFICIENT_CREDENTIALS = 'INSUFFICIENT_CREDENTIALS',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  INVALID_MFA = 'INVALID_MFA',
  INVALID_SEND_METHOD = 'INVALID_SEND_METHOD',
  INVALID_UPDATED_USERNAME = 'INVALID_UPDATED_USERNAME',
  ITEM_LOCKED = 'ITEM_LOCKED',
  ITEM_LOGIN_REQUIRED = 'ITEM_LOGIN_REQUIRED',
  ITEM_NO_ERROR = 'ITEM_NO_ERROR',
  ITEM_NOT_SUPPORTED = 'ITEM_NOT_SUPPORTED',
  INCORRECT_DEPOSIT_AMOUNTS = 'INCORRECT_DEPOSIT_AMOUNTS',
  USER_SETUP_REQUIRED = 'USER_SETUP_REQUIRED',
  MFA_NOT_SUPPORTED = 'MFA_NOT_SUPPORTED',
  NO_ACCOUNTS = 'NO_ACCOUNTS',
  NO_AUTH_ACCOUNTS = 'NO_AUTH_ACCOUNTS',
  NO_INVESTMENT_ACCOUNTS = 'NO_INVESTMENT_ACCOUNTS',
  NO_LIABILITY_ACCOUNTS = 'NO_LIABILITY_ACCOUNTS',
  PRODUCT_NOT_READY = 'PRODUCT_NOT_READY',
  PRODUCTS_NOT_SUPPORTED = 'PRODUCTS_NOT_SUPPORTED',
  INSTANT_MATCH_FAILED = 'INSTANT_MATCH_FAILED',
  INVALID_LINK_TOKEN = 'INVALID_LINK_TOKEN'
}

export enum PlaidEventName {
  ERROR = 'ERROR',
  EXIT = 'EXIT',
  HANDOFF = 'HANDOFF',
  OPEN = 'OPEN',
  OPEN_MY_PLAID = 'OPEN_MY_PLAID',
  SEARCH_INSTITUTION = 'SEARCH_INSTITUTION',
  SELECT_INSTITUTION = 'SELECT_INSTITUTION',
  SUBMIT_CREDENTIALS = 'SUBMIT_CREDENTIALS',
  SUBMIT_MFA = 'SUBMIT_MFA',
  TRANSITION_VIEW = 'TRANSITION_VIEW'
}

export type PlaidVerificationStatus =
  | 'pending_automatic_verification'
  | 'pending_manual_verification'
  | 'manually_verified';
export type PlaidExitStatus =
  | 'requires_questions'
  | 'requires_selections'
  | 'requires_code'
  | 'choose_device'
  | 'requires_credentials'
  | 'institution_not_found';

export type PlaidViewName =
  | 'CONNECTED'
  | 'CREDENTIAL'
  | 'ERROR'
  | 'EXIT'
  | 'LOADING'
  | 'MFA'
  | 'NUMBERS'
  | 'RECAPTCHA'
  | 'SELECT_ACCOUNT'
  | 'SELECT_INSTITUTION';
