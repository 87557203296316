<div class="ecl-sidebar-wrappper">
  <div
    [ngClass]="
      committedSecurityLogo?.desktopUrl.url
        ? 'sidebar-content-block-with-image'
        : 'sidebar-content-block'
    "
  >
    <img
      *ngIf="assistanceLogo?.desktopUrl.url"
      src="{{ assistanceLogo?.desktopUrl.url }}"
      class="logo sidebar-logo"
      alt="{{ assistanceLogo?.altText }}"
    />
    <div [innerHTML]="sideBarContents | safe: 'html'"></div>
    <div class="cobrowse-container">
      <ecl-cobrowse
        [config]="cobrowseConfig"
        *ngIf="isCobrowseEnabled"
        [content]="cobrowseHtmlContent"
        [displayIcon]="false"
      >
      </ecl-cobrowse>
    </div>
    <div><hr /></div>
    <img
      *ngIf="committedSecurityLogo?.desktopUrl.url"
      src="{{ committedSecurityLogo?.desktopUrl.url }}"
      class="logo sidebar-logo"
      alt="{{ committedSecurityLogo?.altText }}"
    />
    <div [innerHTML]="sideBarContentsAdditional | safe: 'html'"></div>
  </div>
</div>
