import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationFlowService } from '@core/application-flow/application-flow.service';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import {
  CheckIdentityResponse,
  IdentityApiService
} from './identity-api.service';

@Injectable({
  providedIn: 'root'
})
export class IdentityGuardService {
  constructor(
    private identityApi: IdentityApiService,
    private router: Router,
    private applicationFlowService: ApplicationFlowService
  ) {}

  public canActivate(): Observable<boolean> {
    return this.identityApi.checkIdentity().pipe(
      switchMap((result: CheckIdentityResponse) => {
        if (!result.userExists) {
          return of(true);
        }
        const nextPage = this.applicationFlowService.getContinuePath(
          'account-setup'
        );
        this.router.navigate([nextPage]);
        return of(false);
      }),
      catchError(
        (): Observable<boolean> => {
          this.router.navigate(['/error']);
          return of(true);
        }
      )
    );
  }
}
