import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CmsPageContentService } from '@core/cms/services/cms-page-content.service';
export interface CmsInReview {
  header: string;
  content: string;
}

@Component({
  selector: 'app-in-review',
  templateUrl: './in-review.component.html',
  styleUrls: ['./in-review.component.scss']
})
export class InReviewComponent implements OnInit {
  public cmsContent: CmsInReview;

  constructor(
    private activatedRoute: ActivatedRoute,
    private pageHeaderService: CmsPageContentService
  ) {}

  public ngOnInit(): void {
    this.cmsContent = this.activatedRoute.snapshot.data.cmsContent
      .inReview as CmsInReview;

    this.pageHeaderService.updatePageTitle(this.cmsContent.header);
  }
}
