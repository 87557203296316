import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EclCobrowseModule } from '@elevate/cobrowse';
import { EnvironmentService } from '@environment/environment.service';
import { EclLayoutModule } from '@elevate/layout';
import { LayoutComponent } from './layout.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { EclBaseModalModule } from '@elevate/base-modal';
import { FeedbackComponent } from './feedback/feedback.component';
import { FiveDayBarComponent } from './five-day-bar/five-day-bar.component';
import { ThirdPartyDisclaimerModalComponent } from './thirdparty-disclaimer-modal/thirdparty-disclaimer-modal.component';

@NgModule({
  imports: [CommonModule, RouterModule, EclLayoutModule, EclBaseModalModule, EclCobrowseModule],
  declarations: [
    LayoutComponent,
    PageHeaderComponent,
    SidebarComponent,
    FiveDayBarComponent,
    FeedbackComponent,
    ThirdPartyDisclaimerModalComponent
  ],
  providers: []
})
export class LayoutModule {
  constructor(
    private environmentService: EnvironmentService
  ) {
  
  }

  
}
