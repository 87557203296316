import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageFormGroup } from '@core/page-form-group/page-form-group';
import { patternValidator, requiredValidator } from '@elevate/forms';

import { EmployerCMSValidationMessages, PrimaryEmployer } from './employer';

@Injectable()
export class EmployerFormGroup extends PageFormGroup {

  constructor(messages: EmployerCMSValidationMessages) {
    super({
      primaryEmployer: new FormControl(null, [
        requiredValidator(messages.nameMsg),
        patternValidator(/^[a-zA-Z0-9._ '\- ]+$/, messages.companyRules)
      ])
    });
  }
}
