import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageFormGroup } from '@core/page-form-group/page-form-group';
import { fixedLengthValidator, requiredValidator } from '@elevate/forms';

import { JobIncome, JobIncomeCMSValidationMessages } from './job-income';

@Injectable()
export class JobIncomeFormGroup extends PageFormGroup {

  constructor(messages: JobIncomeCMSValidationMessages, phoneEnabled: boolean) {
    super({});
    if (phoneEnabled) {
      this.addControl(
        'primaryPhoneNumber',
        new FormControl(null, [
          requiredValidator(messages.phoneMsg),
          fixedLengthValidator(10, messages.lengthMsg)
        ])
      );
      this.addControl('primaryPhoneNumberExtension', new FormControl(null, []));
    }
  }
}
