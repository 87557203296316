import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[focusOnError]'
})
export class FocusOnErrorDirective {
  @HostListener('click', ['$event'])
  public check(): void {
    setTimeout(() => {
      const invalidElements = document.querySelectorAll(
        'input.ecl-ng-invalid, ecl-checkbox.ng-invalid, ecl-radio-group.ng-invalid, .radio-group.ng-invalid, ecl-select.ng-invalid'
      );

      if (invalidElements.length > 0) {
        const elm = invalidElements[0] as HTMLElement;
        elm.scrollIntoView({
          block: 'center'
        });
        elm.focus();
      }
    }, 100);
  }
}
