import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StartOption } from '@application/application.api';
import {
  OtpEmailVerifyRequest,
  SendCodeRequestModel,
  VerifyCodeRequestModel,
  VerifyCodeResponseModel
} from '@application/getting-started/resume-modal/resume-modal';
import { Environment } from '@environment/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OtpService {
  constructor(private environment: Environment, private http: HttpClient) {}

  public sendCode(sendCodeInfo: SendCodeRequestModel): Observable<any> {
    return this.http.post<HttpResponse<any>>(
      `${this.environment.brandApi.url}/api/v1/otp/send`,
      sendCodeInfo,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        observe: 'response'
      }
    );
  }

  public verifyCode(
    verificationCode: VerifyCodeRequestModel
  ): Observable<HttpResponse<VerifyCodeResponseModel>> {
    return this.http.post<VerifyCodeResponseModel>(
      `${this.environment.brandApi.url}/api/v1/application/resume`,
      verificationCode,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        observe: 'response'
      }
    );
  }

  public verifyEmailCode(
    oneTimePassword: OtpEmailVerifyRequest
  ): Observable<HttpResponse<any>> {
    return this.http.patch<any>(
      `${this.environment.brandApi.url}/api/v1/application/email-confirmation`,
      oneTimePassword,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        observe: 'response'
      }
    );
  }
}
