import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Environment } from '@environment/environment';
import { Observable } from 'rxjs';
import {
  SessionStorageKeys,
  SessionStorageService
} from '../session-storage/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthTokenInterceptor implements HttpInterceptor {
  constructor(
    private sessionStorageService: SessionStorageService,
    private environment: Environment
  ) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authToken = this.sessionStorageService.getItem(
      SessionStorageKeys.authorizationToken
    );
    if (
      this.environment.brandApi &&
      req.url.startsWith(this.environment.brandApi.url)
    ) {
      const headers = req.headers
        .set('Api-Version', 'v2')
        .set('Authorization', `Bearer ${authToken}`);
      const dupRequest = req.clone({
        headers
      });
      return next.handle(dupRequest);
    }
    return next.handle(req);
  }
}
