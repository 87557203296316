import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageFormGroup } from '@core/page-form-group/page-form-group';
import { numericRangeValidator, requiredValidator } from '@elevate/forms';

import { Income, IncomeCMSValidationMessages } from './income';

@Injectable()
export class IncomeFormGroup extends PageFormGroup {

  constructor(messages: IncomeCMSValidationMessages) {
    super({
      primarySource: new FormControl(null, [
        requiredValidator(messages.sourceMsg)
      ]),
      secondarySource: new FormControl(),
      secondaryNetAmount: new FormControl({ value: null, disabled: true }, [
        requiredValidator(messages.incomeAmountMsg),
        numericRangeValidator(1, 99999, messages.rangeMsg)
      ]),
      monthlyRentMortgage: new FormControl(null, [
        requiredValidator(messages.homeMsg),
        numericRangeValidator(0, 99999, messages.homeRangeMsg)
      ])
    });
  }
}
