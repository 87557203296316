import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationApi } from '@application/application.api';
import { ApplicationDataService } from '@application/application.service';
import { UnderwritingSteps } from '@application/underwriting/underwriting.model';
import { CookieEnums, CookieService } from '@core/cookie/cookie-service';
import { IovationService } from '@core/iovation/iovation.service';
import { Environment } from '@environment/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, ReplaySubject, lastValueFrom } from 'rxjs';
import { retryWhen, delay, take, tap, timeout } from 'rxjs/operators';

export interface UnderwritingResponseModel {
  offer?: {
    line?: number;
    apr?: number;
    expireAt?: string;
  };
  status?: string;
  requiredBeforeNextUnderwrite?: {
    agentReviewType?: string;
    additionalInformation?: string[];
  };
  underwrite?: {
    name?: string;
    status?: string;
    disposition?: {
      type?: string;
      reason?: string;
      offer?: {
        line?: number;
        apr?: number;
        expireAt?: string;
      };
      noaa?: {
        code?: string;
      };
    };
  };
  containsUploadedDocuments?: boolean;
}

/* istanbul ignore next */
@Injectable({
  providedIn: 'root'
})
export class UnderwritingApiService {
  public underwritingDecisionSubject = new ReplaySubject<
    UnderwritingResponseModel
  >(1);
  public appendDeviceRetried = false;
  public prequalUnderwritingDecisionSubject = new ReplaySubject<
    UnderwritingResponseModel
  >(1);

  public isUnderwritingProcessing = false;
  public hasUnderwritingCompleted = false;
  public isPrequalUnderwritingProcessing = false;
  public hasPrequalUnderwritingCompleted = false;

  constructor(
    private httpClient: HttpClient,
    private environment: Environment,
    private router: Router,
    private modalService: NgbModal,
    private cookieService: CookieService,
    private applicationDataService: ApplicationDataService,
    private iovationService: IovationService,
    private applicationApi: ApplicationApi
  ) {}

  public resetDecision(): void {
    this.underwritingDecisionSubject = new ReplaySubject<
      UnderwritingResponseModel
    >(1);
    this.prequalUnderwritingDecisionSubject = new ReplaySubject<
      UnderwritingResponseModel
    >(1);
    this.hasUnderwritingCompleted = false;
    this.hasPrequalUnderwritingCompleted = false;
  }

  public submitApplicationUnderwriting(): void {
    this.isUnderwritingProcessing = true;
    this.initiateFinalUnderwrite();
  }

  public submitApplicationPrequalUnderwriting(applicationId: string): void {
    this.isPrequalUnderwritingProcessing = true;
    this.initiatePrequalUnderwrite(applicationId);
  }

  public callUnderwriting(
    step: UnderwritingSteps
  ): Observable<UnderwritingResponseModel> {
    return this.httpClient
      .patch(
        `${this.environment.brandApi.url}/api/v1/application/underwrite/${step}`,
        {}
      )
      .pipe(
        retryWhen((error: any) => {
          return error.pipe(
            delay(this.environment.brandApi.underwriteRetry.retryTimeout),
            take(this.environment.brandApi.underwriteRetry.retryCount),
            tap(
              (resp: any) => {
                const httpError = resp as HttpErrorResponse;
                if (httpError.status > 0) {
                  throw new Error();
                }
              },
              () => {
                throw new Error();
              },
              () => {
                throw new Error();
              }
            )
          );
        })
      );
  }

  private initiateFinalUnderwrite(): void {
    this.callUnderwriting(UnderwritingSteps.ApplicationSubmit).subscribe(
      (response: UnderwritingResponseModel) => {
        this.isUnderwritingProcessing = false;
        this.hasUnderwritingCompleted = true;
        this.underwritingDecisionSubject.next(response);
        this.underwritingDecisionSubject.complete();
      },
      () => {
        this.navigateToErrorPage();
      }
    );
  }

  private initiatePrequalUnderwrite(applicationId: string): void {
    this.callUnderwriting(UnderwritingSteps.Prequal).subscribe(
      (response: UnderwritingResponseModel) => {
        this.isPrequalUnderwritingProcessing = false;
        this.hasPrequalUnderwritingCompleted = true;
        this.cookieService.set(CookieEnums.ResumeByAppId, applicationId, {
          expires: 30
        });
        this.prequalUnderwritingDecisionSubject.next(response);
        this.prequalUnderwritingDecisionSubject.complete();
      },
      async () => {
        const application = this.applicationDataService.getApplication();
        if (!this.appendDeviceRetried && !application.iovationSigAppended) {
          try {
            this.appendDeviceRetried = true;
            const blackBoxResponse = await lastValueFrom(
              this.iovationService
                .getIovationSignature()
                .pipe(timeout(this.environment.iovation.timeout))
            );
            await lastValueFrom(
              this.applicationApi.appendDevice({
                iovationRequestType: this.environment.iovation.requestType,
                iovationSignature: blackBoxResponse.blackbox
              })
            );
            this.initiatePrequalUnderwrite(application.id);
          } catch {
            this.router.navigate(['/error']);
          }
        } else {
          this.navigateToErrorPage();
        }
      }
    );
  }

  private navigateToErrorPage(): void {
    this.modalService.dismissAll();
    this.router.navigate(['/error']);
  }
}
