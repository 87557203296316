import { Injectable } from '@angular/core';
import {
  NgbDate,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class ECCustomDateAdapter extends NgbDateAdapter<string> {
  readonly DELIMITER = '/';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[1], 10),
        month: parseInt(date[0], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date
      ? ('0' + date.month).slice(-2) +
          this.DELIMITER +
          ('0' + date.day).slice(-2) +
          this.DELIMITER +
          date.year
      : '';
  }
}

@Injectable()
export class ECCustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[1], 10),
        month: parseInt(date[0], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? ('0' + date.month).slice(-2) +
          this.DELIMITER +
          ('0' + date.day).slice(-2) +
          this.DELIMITER +
          date.year
      : '';
  }

  public isDisabled = (date: NgbDate) => {
    // const day = moment(`${date.year}-${date.month}-${date.day}`)
    // const cutoffDay = moment(`${date.year - 18}-${date.month}-${date.day}`);
    return date.day === 3 || date.day === 4;
  };
}
