import { Component, Inject } from '@angular/core';
import { LogRocketService } from './core/logrocket/logrocket.service';
import { AppInsightsService } from './core/app-insights/app-insights.service';
import { DOCUMENT } from '@angular/common';
import { lastValueFrom } from 'rxjs';
import { CmsService } from '@core/cms';
import { GpcService } from '@core/gpc/gpc.service';
import { NeuroIdService } from '@core/neuro-id/neuro-id.service';
import { Router, NavigationEnd } from '@angular/router';
import { ThirdPartyDisclaimerModalComponent } from '@layout/thirdparty-disclaimer-modal/thirdparty-disclaimer-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  GoogleAnalytics,
  GtagModalData,
  ModalClickAction
} from '@core/google-analytics/googleanalytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'web-ams';
  public brand: string;
  public gtmData;

  constructor(
    private neuroIdService: NeuroIdService,
    @Inject(DOCUMENT) private document: Document,
    private logRocketService: LogRocketService,
    private appInsightsService: AppInsightsService,
    private cmsService: CmsService,
    private gpcService: GpcService,
    private router: Router,
    private modalService: NgbModal,
    private googleanalytics: GoogleAnalytics
  ) {
    this.appInsightsService.init();
    this.neuroIdService.init();
    this.logRocketService.init();
    this.loadGTAscript();

    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.gpcService.PostGpc();
      }
    });

    window.onerror = function(err) {
      if (err === 'ResizeObserver loop') {
        console.warn('Ignored: ResizeObserver loop');
        return false;
      }
    };
    document.addEventListener('click', (event: any) => {
      if (
        event.target?.attributes['cookie-banner']?.value ===
        'Consent CCPA Banner'
      ) {
        this.consentBannerEvents(event);
      } else if (
        event.target.attributes['third-party-disclaimer'] ||
        event.target?.href === 'https://www.annualcreditreport.com/'
      ) {
        event.preventDefault();
        this.displaySpeedBump(event.target.href);
      }
    });
  }

  consentBannerEvents(event: any) {
    const consentPromptBannerEventData: GtagModalData = {
      action: ModalClickAction.Cta_Click,
      modal_name: event.target?.attributes['cookie-banner']?.value
    };
    consentPromptBannerEventData.link_text = event.target.innerText;
    this.googleanalytics.clickModalEvent(consentPromptBannerEventData);
  }

  private async loadGTAscript(): Promise<void> {
    await lastValueFrom(
      this.cmsService.getEntries('gtm_and_tealium_tags')
    ).then(data => {
      this.gtmData = Array.isArray(data) ? data[0] : data;
    });

    const gmaScript = this.document.createElement('script');
    const noScript = this.document.createElement('noscript');
    noScript.innerHTML = `<iframe
    id="GTMtag"
    src="${this.gtmData.gtmBodyScript}"
    height="0"
    width="0"
    style="display:none;visibility:hidden"
></iframe
>`;
    gmaScript.innerHTML = this.gtmData.gtmHeadScript;
    this.document.head.appendChild(gmaScript);
    this.document.body.appendChild(noScript);
  }

  displaySpeedBump(url: string) {
    const thirdPartyDisclaimerOpenModal = this.modalService.open(
      ThirdPartyDisclaimerModalComponent,
      {
        modalDialogClass: 'third-party-disclosure-modal',
        centered: true
      }
    );
    const disclosureModal = thirdPartyDisclaimerOpenModal.componentInstance as ThirdPartyDisclaimerModalComponent;
    disclosureModal.thirdPartyUrl = url;
  }
}
