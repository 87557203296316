import { Inject, ViewRef } from '@angular/core';
import {
  ComponentFactoryResolver,
  Injectable,
  ViewContainerRef
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { NotificationBoxComponent } from '@elevate/ui-components';

export enum NotificationType {
  INFORMATIVE = 'informative',
  SUCCESS = 'success',
  ALERT = 'alert',
  ERROR = 'error'
}

@Injectable({
  providedIn: 'root'
})
export class NotificationBoxService {
  private factoryResolver: ComponentFactoryResolver;
  private rootViewContainer: ViewContainerRef;
  private hostView: ViewRef;

  constructor(
    @Inject(ComponentFactoryResolver) factoryResolver,
    private router: Router
  ) {
    this.factoryResolver = factoryResolver;
    this.subscribeToRouterEvents();
  }

  /**
   * This public function allows the application configure which loading parent template to load the notification box
   * template into.
   * @param viewContainerRef The parent container where the notification box template selector is to be inserted.
   */
  public setRootViewContainerRef(viewContainerRef: ViewContainerRef): void {
    this.rootViewContainer = viewContainerRef;
  }

  /**
   * This public dymnamically creates an instance of the NotificationBoxComponent and insert instance into the parent template
   * set in the setRootViewContainerRef function.  The function will then populate the inside contents within the notification component
   * template with the notificationMessage parameter, set the type of the notification bsed on the second parameter, and align the
   * component template's margin and width based on the application content inside the div container with the app-content-container class.
   * @param notificationMessage The text message to be displayed inside the notification box to inform the user.
   * @param notificationType An enum representing the allowable notification types i.e. Informative, Success, Error, and Alert.
   */
  public addNotificationBox(
    notificationMessage: string,
    notificationType: NotificationType
  ): void {
    this.rootViewContainer.element.nativeElement.hidden = true;
    this.removeNotificationBox();
    if (
      !(this.hostView && this.rootViewContainer?.indexOf(this.hostView) > -1)
    ) {
      this.createAndInsertNotificationComponent(notificationType);
    }
    const index = this.rootViewContainer?.indexOf(this.hostView);
    const notificationBoxElement: Element = (this.rootViewContainer?.get(
      index
    ) as any)?.rootNodes[0];

    this.populateNotificationBoxMessage(
      notificationBoxElement,
      notificationMessage
    );
    this.alignNotificationBox(notificationBoxElement);
  }

  /**
   * Helper method to remove the notification box from the DOM and the corresponding parent container.
   */
  public removeNotificationBox(): void {
    const index: number = this.rootViewContainer?.indexOf(this.hostView);

    if (index > -1) {
      this.rootViewContainer.remove(
        this.rootViewContainer.indexOf(this.hostView)
      );
      document.querySelector('ecl-notification-box')?.remove();
    }
  }

  /**
   * Helper method to subscribe to the Angular route navigation to listen to navigation start in order to
   * remove any existing notification when the user navigates to a new page.
   */
  private subscribeToRouterEvents(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.removeNotificationBox();
        this.hostView?.destroy();
      }
    });
  }

  /**
   * Helper method to instantiate the NotificationBoxComponent and insert the template to the the parent template.
   * @param notificationType An enum representing the allowable notification types i.e. Informative, Success, Error, and Alert
   */
  private createAndInsertNotificationComponent(
    notificationType: NotificationType
  ): void {
    const component = this.factoryResolver
      .resolveComponentFactory(NotificationBoxComponent)
      .create(this.rootViewContainer.injector);
    component.instance.type = notificationType;
    this.hostView = this.rootViewContainer.insert(component.hostView, 0);
  }

  /**
   * Helper method to set the notification message within the notification box.
   * @param notificationBoxElement The DOM element representing the template generated when the NotificationBoxComponent is instantiated and
   * inserted inside the parent template.
   * @param noticationMessage An enum representing the allowable notification types i.e. Informative, Success, Error, and Alert.
   */
  private populateNotificationBoxMessage(
    notificationBoxElement: Element,
    noticationMessage: string
  ): void {
    const messageContainer: Element = notificationBoxElement.querySelector(
      'div.ecl-message'
    );

    if (messageContainer) {
      messageContainer.innerHTML = noticationMessage;
    }
  }

  /**
   * Helper method to align the left margin and width of the notification box to match the content of the content div container set below
   * the notification box on the current page.
   * @param notificationBoxElement The DOM element representing the template generated when the NotificationBoxComponent is instantiated and
   * inserted inside the parent template.
   */
  private alignNotificationBox(notificationBoxElement: Element): void {
    let marginLeft = 0;

    const childDivContainer: Element = document
      .querySelector('ecl-notification-box')
      .parentElement.querySelector(
        ':scope > :not(router-outlet):not(ecl-notification-box) > div'
      );
    const grandChildDivContainer: Element = document
      .querySelector('ecl-notification-box')
      .parentElement.querySelector(
        ':scope > :not(router-outlet):not(ecl-notification-box) > div > div'
      );
    const notificationBoxContainer: HTMLElement = notificationBoxElement.querySelector(
      'div.ecl-notification-box'
    );

    if (childDivContainer) {
      marginLeft =
        marginLeft +
        parseInt(getComputedStyle(childDivContainer).paddingLeft, 10);
    }

    if (grandChildDivContainer) {
      marginLeft =
        marginLeft +
        parseInt(getComputedStyle(grandChildDivContainer).paddingLeft, 10);
    }

    if (notificationBoxContainer) {
      notificationBoxContainer.style.marginTop = '20px';

      if (marginLeft) {
        notificationBoxContainer.style.marginLeft = `${marginLeft}px`;
      }

      // since the default for the notication box is 806px...the max-width needs to be modified so that it
      // does not run over the viewport
      if (this.isMobile) {
        notificationBoxContainer.style.setProperty(
          'max-width',
          `calc(100% - ${marginLeft * 2}px)`
        );
      }
    }

    window.scrollTo(0, 0);
  }

  /**
   * Private helper function to determine whether the application is currently running in an environment with
   * the mobile size view port based on the assumption that the width is less than 768px.
   */
  private get isMobile(): boolean {
    const mediaQuery = window.matchMedia('(min-width: 768px)');
    return !mediaQuery.matches;
  }
}
