import { Injectable } from '@angular/core';
import { ApplicationForm, FundMethodType } from '@application/application';
import moment from 'moment';
import { GettingStarted } from './getting-started';

@Injectable({ providedIn: 'root' })
export class GettingStartedService {
  constructor() { }

  public buildReapplyCreateRequest(formData: GettingStarted): ApplicationForm {
    const dob = moment(formData.dob, 'MM/DD/YYYY');

    const application: ApplicationForm = {
      continuePath: '/verify-info',
      applicant: {
        identity: {
          firstName: formData.firstname,
          lastName: formData.lastname,
          dateOfBirth: dob.format('YYYY-MM-DD')
        },
        phones: [
          {
            key: '1',
            number: formData.mobile,
            type: 'Mobile'
          }
        ],
        residences: [
          {
            key: '1',
            type: 'Current',
            address: {
              zipCode: formData.postalCode,
              stateCode: formData.state,
              line1: formData.addressLine1,
              city: formData.city
            }
          }
        ]
      }
    };

    if (formData.addressLine2) {
      application.applicant.residences[0].address.line2 = formData.addressLine2;
    }
    if (formData.suffix) {
      application.applicant.identity.suffix = formData.suffix;
    }

    if (formData.code) {
      application.enrollmentCode = formData.code;
    }

    return application;
  }
}
