import deepMerge from 'deepmerge';

export function mergeObjectInArrayByKey(key: string): any {
  return (obj1: any, obj2: any) => {
    if (Array.isArray(obj1) && Array.isArray(obj2)) {
      var propName = (key === "abTesting") ? "testName" : "key";
      obj1.forEach((o1: any, i: number) => {
        const sameKeyObject = obj2.find((o2: any) => o2[propName] === o1[propName]);

        if (sameKeyObject) {
          obj1[i] = deepMerge(o1, sameKeyObject, {
            customMerge: mergeObjectInArrayByKey
          });
        }
      });
      return obj1;
    }
    return deepMerge(obj1, obj2, {
      customMerge: mergeObjectInArrayByKey
    });
  };
}
