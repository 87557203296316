<ecl-base-modal id="temporaryLockModalWindow" [modalConfig]="modalData">
  <div class="modal-container">
    <h1 id="temporaryLockModalHeader" class="modal-container-header">
      {{ htmlContent.header }}
    </h1>
    <div id="temporaryLockModalContent" class="modal-container-content">
      <p [innerHTML]="htmlContent.content | safe: 'html'"></p>
    </div>
  </div>
</ecl-base-modal>
