import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IncomeSourceComponent } from '@application/income/income';
import { NumberInput, TextInput } from '@elevate/ui-components';

import { EmployerComponent } from '../components/employer/employer.component';
import { PaymentFrequencyComponent } from '../components/payment-frequency/payment-frequency.component';
import { JobIncome } from './job-income';
import { JobIncomeFormGroup } from './job-income.form';

@Component({
  selector: 'app-job-income',
  templateUrl: './job-income.component.html',
  styleUrls: ['../../income.component.scss'],
  providers: [JobIncomeFormGroup]
})
export class JobIncomeComponent
  implements IncomeSourceComponent, OnInit, OnDestroy {
  @Input() public formGroup: FormGroup;
  @Input() public CMSJobIncome: any;
  @Input() public CMSEmployer: any;
  @Input() public CMSPaymentFrequency: any;

  @ViewChild(EmployerComponent)
  private employer: EmployerComponent;

  @ViewChild(PaymentFrequencyComponent)
  private paymentFrequency: PaymentFrequencyComponent;

  public form: JobIncomeFormGroup;

  public primaryJobIncomePhoneNumberConfig: TextInput = {
    id: 'primaryJobIncomePhoneNumberText',
    placeholder: '',
    mask: '(000) 000-0000',
    attributes: {
      'data-nid-target': 'primaryJobIncomePhoneNumber'
    }
  };

  public primaryJobIncomePhoneNumberExtensionConfig: NumberInput = {
    id: 'primaryJobIncomePhoneNumberExtensionText',
    placeholder: '',
    mask: 'XXXXX',
    maxlength: 5,
    attributes: {
      'data-nid-target': 'primaryJobIncomePhoneNumberExtension'
    },
    disableKeyboardArrows: true
  };

  constructor() {}

  public ngOnInit(): void {
    this.form = new JobIncomeFormGroup(
      this.CMSJobIncome.validation,
      this.CMSJobIncome.isphoneenabled
    );
    this.formGroup.addControl('jobIncome', this.form);
  }

  public ngOnDestroy(): void {
    this.formGroup.removeControl('jobIncome');
  }

  public get value(): JobIncome {
    return {
      ...this.form.value,
      ...this.employer?.value,
      ...this.paymentFrequency.value
    };
  }

  public patchValue(jobIncome: JobIncome): void {
    this.form.patchValue(jobIncome);
    this.employer?.patchValue(jobIncome);
    this.paymentFrequency.patchValue(jobIncome);
  }

  public showValidationErrors(): void {
    this.form.showValidationErrors();
    this.employer?.showValidationErrors();
    this.paymentFrequency?.showValidationErrors();
  }
}
