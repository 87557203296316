import { Component, OnInit, Input } from '@angular/core';
import { BaseModalConfig } from '@elevate/base-modal';
import { DefaultModal } from '../getting-started.content';
import { GoogleAnalytics } from '@core/google-analytics/googleanalytics.service';

@Component({
  selector: 'app-affect-credit-score-modal',
  templateUrl: './affect-credit-score-modal.component.html'
})
export class AffectCreditScoreModalComponent implements OnInit {
  @Input() public htmlContent: DefaultModal;
  @Input() public modalData: BaseModalConfig = {
    action: {
      allowClose: true
    }
  } as BaseModalConfig;

  constructor(public googleAnalytics: GoogleAnalytics) {}

  public ngOnInit(): void {
    this.modalData.heading = this.htmlContent.header;
    this.modalData.content = this.htmlContent.content;
    this.modalData.action.submitButton = this.htmlContent.button;
  }
}
