import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ApplicationDataService } from '@application/application.service';
import {
  fixedLengthValidator,
  patternValidator,
  requiredValidator
} from '@elevate/forms';
import { NumberInput } from '@elevate/ui-components';
import { IESiganture } from '../interfaces/e-siganture.interface';
import {
  ConsentModalAgreement,
  SignatureModel,
  SignatureType
} from '../signature.content';

@Component({
  selector: 'app-last-four-ssn',
  templateUrl: './last-4-ssn.component.html',
  styleUrls: ['./last-4-ssn.component.scss']
})
export class Last4SSNComponent implements OnInit, OnDestroy, IESiganture {
  @Input() public agreement: ConsentModalAgreement;
  @Input() public formGroup: FormGroup;
  @Input() public controlName = 'last4SSNSignature';
  @Input() public initValue: string = null;

  constructor(private appDataService: ApplicationDataService) {}

  public formConfig: NumberInput = {
    id: 'last4SSNInput',
    minlength: 4,
    maxlength: 4,
    addGenesysCobrowseMaskingClass: 'true',
    attributes: {
      'data-private': 'redact',
      'data-nid-target': 'termsAndConditionsLast4ssnConsentSignature'
    }
  };

  public get signatureModel(): SignatureModel {
    return {
      isConsentGiven: this.isValid,
      signatureType: SignatureType.Last4Ssn,
      controlName: this.controlName,
      signatureValue: this.formGroup.controls[this.controlName]?.value
    };
  }

  public get label(): string {
    return this.agreement.label;
  }

  public get instruction(): string {
    return this.agreement.instruction;
  }

  public get isValid(): boolean {
    return this.formGroup.controls[this.controlName]?.valid;
  }

  ngOnInit(): void {
    this.formGroup.addControl(
      this.controlName,
      new FormControl(this.initValue, [
        requiredValidator(this.agreement.validationRequired),
        fixedLengthValidator(4, this.agreement.validationNotMatch),
        patternValidator(
          new RegExp(
            this.appDataService
              .getApplication()
              .form.applicant.identity.socialSecurityNumber.slice(-4)
          ),
          this.agreement.validationNotMatch
        )
      ])
    );
  }

  public ngOnDestroy(): void {
    this.formGroup.removeControl(this.controlName);
  }
}
