import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  OnDestroy
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationForm } from '@application/application';
import { ApplicationApi } from '@application/application.api';
import { ApplicationDataService } from '@application/application.service';
import { ConsentsComponent } from '@application/consents/consents.component';
import { ConsentSectionItem } from '@application/consents/consents.content';
import { LoadingModalService } from '@application/loading-modal/loading-modal.service';
import { ValidationMessagesError } from '@elevate/forms';
import { TextInput } from '@elevate/ui-components';
import { CmsPageContentService } from '@core/cms/services/cms-page-content.service';
import {
  NotificationBoxService,
  NotificationType
} from '@core/notification-service/notification.service';

import {
  BankInformation,
  BankInformationFormGroup,
  BankInformationValidationMessages
} from './bank-information.form';
import { ApplicationFlowService } from '@core/application-flow/application-flow.service';
import { lastValueFrom, Subscription } from 'rxjs';
import { Environment } from '@environment/environment';

export interface CmsBankInformation {
  header: string;
  subHeader: string;
  invalidBankInformationErrorMessage: string;
  checkRoutingImage: {
    url: string;
  };
  checkAccountImage: {
    url: string;
  };
  labels: {
    bankRoutingNumber: string;
    checkingAccountNumber: string;
    confirmCheckingAccountNumber: string;
  };
  buttons: {
    continue: string;
  };
  validationMessages: BankInformationValidationMessages;
  consentsSection: ConsentSectionItem[];
  giactCodes: {
    messages: GiactErrorMessage[];
  };
}
export interface GiactErrorMessage {
  errorCode: string;
  text: string;
}

@Component({
  selector: 'app-bank-information',
  templateUrl: './bank-information.component.html',
  styleUrls: ['./bank-information.component.scss']
})
export class BankInformationComponent implements OnInit, OnDestroy {
  @ViewChild('consentsComponent') public consentsComponent: ConsentsComponent;
  @ViewChild('giactErrorNotificationsBox', { read: ViewContainerRef })
  notificationsBox: ViewContainerRef;

  public pageContent: CmsBankInformation;
  public consentsSection: ConsentSectionItem[];
  public checkRoutingImgURL: string;
  public checkAccountImgURL: string;
  public form: BankInformationFormGroup;
  public showBankRoutingNumberImage: boolean;
  public showBankAccountNumberImage: boolean;
  public formChangesSubscription: Subscription;

  public routeNoConfig: TextInput = {
    id: 'bankRoutingNumberTextInput',
    required: 'true',
    mask: '000000000',
    maxlength: 9,
    type: 'number',
    attributes: {
      'data-private': 'redact',
      'data-nid-target': 'routingNumber',
      mask: '000000000'
    },
    addGenesysCobrowseMaskingClass: 'true'
  };

  public bankAcctConfig: TextInput = {
    id: 'bankAccountNumberTextInput',
    required: 'true',
    mask: 'XXXXXXXXXXXXXXXXX',
    type: 'number',
    maxlength: 17,
    hidden: 'onblur',
    attributes: {
      mask: 'XXXXXXXXXXXXXXXXX',
      'data-private': 'redact',
      'data-nid-target': 'accountNumber'
    },
    addGenesysCobrowseMaskingClass: 'true'
  };

  public confirmBankAcctConfig: TextInput = {
    id: 'confirmBankAccountNumberTextInput',
    required: 'true',
    mask: 'XXXXXXXXXXXXXXXXX',
    maxlength: 17,
    type: 'number',
    hidden: 'onblur',
    attributes: {
      'data-private': 'redact',
      'data-nid-target': 'confirmAccountNumber'
    },
    addGenesysCobrowseMaskingClass: 'true'
  };

  constructor(
    private router: Router,
    private applicationApi: ApplicationApi,
    private applicationDataService: ApplicationDataService,
    private loadingService: LoadingModalService,
    private activatedRoute: ActivatedRoute,
    private pageHeaderService: CmsPageContentService,
    public notificationService: NotificationBoxService,
    private applicationFlowService: ApplicationFlowService,
    private environment: Environment
  ) {}

  public ngOnInit(): void {
    this.pageContent = this.activatedRoute.snapshot.data.cmsContent
      .bankInformation as CmsBankInformation;
    this.checkRoutingImgURL = this.pageContent.checkRoutingImage?.url;
    this.checkAccountImgURL = this.pageContent.checkAccountImage?.url;
    this.consentsSection = this.pageContent.consentsSection;

    this.pageHeaderService.updatePageTitle(this.pageContent.header);
    this.form = new BankInformationFormGroup(
      this.pageContent.validationMessages
    );
  }

  public async submit(): Promise<void> {
    this.form.showValidationErrors();

    if (!this.form.valid) {
      return;
    }

    const bankApplicationData = this.convertToApplicationData(this.form.value);

    this.notificationService.removeNotificationBox();

    this.loadingService.open();

    try {
      await lastValueFrom(this.applicationApi.append(bankApplicationData));
      this.applicationDataService.merge({ form: bankApplicationData });
      this.router.navigate([bankApplicationData.continuePath]);
    } catch (error) {
      const httpError = error as HttpErrorResponse;
      if (
        httpError.status === 400 &&
        httpError.error['message'] ===
          this.pageContent.invalidBankInformationErrorMessage
      ) {
        this.addDuplicateAccountError();
      } else if (httpError.status === 406) {
        this.addGIACTErrorMessage(httpError.error['message']);
      }
      this.loadingService.close();
    }
  }

  public handleBankAccountInput(event: UIEvent): void {
    if (event.type === 'focus') {
      this.showBankAccountNumberImage = true;
    }

    if (event.type === 'blur') {
      this.showBankAccountNumberImage = false;
    }
  }

  private addDuplicateAccountError(): void {
    this.form
      .get('bankAccountNumber')
      .setErrors(
        new ValidationMessagesError(
          'duplicateAccountError',
          null,
          this.pageContent.validationMessages.duplicateAccountError
        )
      );
  }

  private addGIACTErrorMessage(code: string): void {
    let message = this.pageContent.giactCodes.messages.find(
      m => m.errorCode === code
    )?.text;
    message = message
      ? message
      : this.pageContent.giactCodes.messages.find(m => !m.errorCode)?.text;
    this.showNotification(message);
  }

  private convertToApplicationData(form: BankInformation): ApplicationForm {
    return {
      continuePath: this.applicationFlowService.getContinuePath(),
      applicant: {
        bank: {
          draftFromKey: '1',
          accounts: [
            {
              key: '1',
              type: 'Checking',
              accountNumber: form.bankAccountNumber.toString(),
              routingNumber: form.bankRoutingNumber.toString(),
              accountNumberLast4: form.bankAccountNumber
                .toString()
                .substring(form.bankAccountNumber.toString().length - 4),
              source: 'Applicant'
            }
          ]
        }
      },
      disclosures: this.consentsComponent?.disclosures
    };
  }

  private showNotification(message: string): void {
    this.notificationService.setRootViewContainerRef(this.notificationsBox);

    this.notificationService.addNotificationBox(
      message,
      NotificationType.ERROR
    );
  }

  public debugHudFillSubmit(): void {
    const acctNumber = Math.floor(
      1000000000 + Math.random() * 9000000000
    ).toString();

    this.form.patchValue({
      bankRoutingNumber: (this.environment.brandId == "2200") ? "061000010" : "111000614",
      bankAccountNumber: acctNumber,
      confirmBankAccountNumber: acctNumber
    });
    this.consentsComponent.debugHudSubmitAllConsents();
    this.submit();
  }

  public async ngOnDestroy(): Promise<void> {
    if (this.formChangesSubscription != null) {
      this.formChangesSubscription.unsubscribe();
    }
  }
}
