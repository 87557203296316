<div class="getting-started-page-container">
  <h2
    *ngIf="pageSubTitle"
    id="gettingStartedPageSubTitle"
    class="page-subtitle ecl-font-header-medium"
  >
    {{ pageSubTitle }}
  </h2>

  <div
    id="gettingStartedPageSubTitleContent"
    *ngIf="pageSubTitleContent"
    [innerHtml]="pageSubTitleContent"
  ></div>

  <form [formGroup]="form" (submit)="submit()">
    <h2
      id="gettingStartedPersonalInfo"
      class="form-section-title ecl-font-section-header-color-xsmall display-heading-xs"
    >
      {{ cmsPageContent.labels.personalInfo }}
    </h2>

    <div class="ecl-vgroup-m row">
      <ecl-text-input
        googleAnalyticsInputFocus="input-focus-category"
        class="col-sm-4"
        [formControlConfig]="formConfig.firstnameConfig"
        formControlName="firstname"
        googleAnalyticsFocusOut="firstName"
      >
        {{ cmsPageContent.labels.firstName }}
      </ecl-text-input>

      <ecl-text-input
        class="col-sm-4"
        [formControlConfig]="formConfig.lastnameConfig"
        formControlName="lastname"
        googleAnalyticsFocusOut="lastName"
      >
        {{ cmsPageContent.labels.lastName }}
      </ecl-text-input>
      <ecl-select
        class="col-sm-4"
        [formControlConfig]="formConfig.suffix"
        formControlName="suffix"
        googleAnalyticsFocusOut="suffix"
      >
        {{ cmsPageContent.labels.suffix }}
      </ecl-select>

      <div class="col-sm-6 cobrowse-masked">
        <ecl-date-picker
          [formControlConfig]="formConfig.dobConfig"
          formControlName="dob"
          googleAnalyticsFocusOut="dob"
        >
          {{ cmsPageContent.labels.dateOfBirth }}
        </ecl-date-picker>
      </div>

      <ecl-ssn
        #ssn
        class="col-sm-6"
        [formControlConfig]="formConfig.ssnConfig"
        (setkeepHidden)="updateKeepHidden($event)"
        formControlName="ssn"
        googleAnalyticsFocusOut="ssn"
      >
        <span>{{ cmsPageContent.labels.socialSecurityNumber }}</span>
        <a
          class="ecl-info-icon"
          href="javascript:void(0);"
          aria-label="Read more about ssn field"
          [ngbTooltip]="cmsPageContent.messages.ssnTooltip"
          [placement]="['right', 'left', 'top']"
          tooltipClass="elastic-tooltip"
          triggers="manual"
          #ssnToolTip="ngbTooltip"
          (click)="ssnToolTip.open()"
          (mouseover)="ssnToolTip.open()"
          (mouseout)="ssnToolTip.close()"
        ></a>
      </ecl-ssn>
    </div>

    <h3
      id="gettingStartedContactInfo"
      *ngIf="!isMailingSectionEnabled"
      class="form-section-title ecl-font-section-header-color-xsmall"
    >
      {{ cmsPageContent.labels.contactInfo }}
    </h3>
    <h3
      id="gettingStartedResidentialAddress"
      *ngIf="isMailingSectionEnabled"
      class="form-section-title ecl-font-section-header-color-xsmall"
    >
      {{ cmsMailingAddressContent.labels.residentialAddress }}
    </h3>
    <div class="ecl-vgroup-m row">
      <ecl-address
        class="col-12"
        [addressControls]="addressControls"
        [addressLabels]="addressLabels"
        [envConfig]="addressConfig"
        (stateSelect)="onStateUpdate($event)"
        (focusout)="onFocusOut($event)"
        (onAddressError)="onAddressSuggestionError($event)"
      >
      </ecl-address>
    </div>
    <h3
      id="gettingStartedMailingAddress"
      *ngIf="isMailingSectionEnabled"
      class="form-section-title ecl-font-section-header-color-xsmall"
    >
      {{ cmsMailingAddressContent.labels.mailingAddress }}
    </h3>
    <div class="ecl-vgroup-m" *ngIf="isMailingSectionEnabled">
      <ecl-checkbox
        id="mailingSameCheckBox"
        class="communications-checkbox"
        [formControlConfig]="formConfig.mailingAddressCheckBox"
        formControlName="mailingAddressCheckBox"
        (change)="onMailingAddressSameChange($event)"
        >{{ cmsMailingAddressContent.labels.sameAsResidentialAddressCheckbox }}
      </ecl-checkbox>

      <div class="row">
        <app-mailing-address
          class="col-12"
          *ngIf="!isMailingAddressSameChecked"
          [formGroup]="form"
        >
        </app-mailing-address>
      </div>
    </div>

    <h3
      id="gettingStartedContactInfoMailingEnabled"
      *ngIf="isMailingSectionEnabled"
      class="form-section-title ecl-font-section-header-color-xsmall"
    >
      {{ cmsPageContent.labels.contactInfo }}
    </h3>
    <div class="ecl-vgroup-m">
      <div class="row" id="contactInformation">
        <ecl-email
          #email
          class="col-sm ecl-bottom-xs"
          [formControlConfig]="formConfig.emailConfig"
          formControlName="email"
          googleAnalyticsFocusOut="email"
        >
          <span>{{ cmsPageContent.labels.emailAddress }}</span>
          <a
            class="ecl-info-icon"
            href="javascript:void(0);"
            aria-label="Read more about email field"
            [ngbTooltip]="cmsPageContent.messages.emailTooltip"
            [placement]="['right', 'left', 'top']"
            tooltipClass="elastic-tooltip"
            triggers="manual"
            #mailToolTip="ngbTooltip"
            (click)="mailToolTip.open()"
            (mouseover)="mailToolTip.open()"
            (mouseout)="mailToolTip.close()"
          ></a>
        </ecl-email>
        <ecl-phone-number
          class="col-sm"
          [formControlConfig]="formConfig.mobileConfig"
          formControlName="mobile"
          googleAnalyticsFocusOut="mobile"
        >
          {{ cmsPageContent.labels.mobilePhone }}
        </ecl-phone-number>
      </div>
      <div *ngIf="isMailingSectionEnabled && alternatePhoneEnabled" class="row">
        <ecl-phone-number
          class="col-sm ecl-bottom-xs"
          [formControlConfig]="formConfig.alternatePhoneConfig"
          formControlName="alternatePhoneNumber"
          googleAnalyticsFocusOut="alternatePhoneNumber"
        >
          {{
            cmsMailingAddressContent.labels.alternatePhoneLabels.alternatePhone
          }}
        </ecl-phone-number>
        <div class="col-sm">
          <label class="ecl-input-label" for="alternatePhone">
            <span>
              {{
                cmsMailingAddressContent.labels.alternatePhoneLabels
                  .alternatePhoneType
              }}</span
            >
          </label>
          <div class="mailingaddress-radiobutton">
            <ecl-radio-group
              [formControlConfig]="formConfig.alternatePhoneRadioGroupConfig"
              formControlName="alternatePhoneType"
              googleAnalyticsFocusOut="alternatePhoneType"
            >
              <div
                *ngFor="
                  let alternatePhoneRadioItem of formConfig
                    .alternatePhoneRadioGroupConfig.buttons
                "
              >
                <ecl-radio-button
                  [formControlConfig]="alternatePhoneRadioItem"
                ></ecl-radio-button>
              </div>
            </ecl-radio-group>
          </div>
        </div>
      </div>
    </div>
    <div class="disclosure-container">
      <app-consents
        #consentToCommunicationComponent
        [formGroup]="form"
        [consentSectionItems]="consentToCommunication"
        [selectedState]="lastSelectedState"
        [hideDividingLine]="true"
        [containerName]="consentToCommunicationContainerName"
      ></app-consents>
    </div>

    <h3
      id="gettingStartedEnrollmentCodeInfo"
      class="form-section-title ecl-font-section-header-color-xsmall"
    >
      {{ cmsPageContent.labels.enrollmentCodeInfo }}
    </h3>

    <div class="ecl-vgroup-m row">
      <ecl-text-input
        class="col-sm-7"
        [formControlConfig]="formConfig.codeConfig"
        formControlName="code"
        googleAnalyticsFocusOut="enrollmentCode"
      >
        <span class="enrollment-code-label">
          {{ cmsPageContent.labels.enrollmentCode }}
        </span>
        <a
          class="ecl-info-icon enrollment-code-tooltip-icon"
          href="javascript:void(0);"
          aria-label="Read more about enrollment field"
          [ngbTooltip]="enrollmentCodeTooltip"
          [placement]="['right', 'left', 'top']"
          tooltipClass="elastic-tooltip"
          triggers="manual"
          #codeToolTip="ngbTooltip"
          (click)="codeToolTip.open()"
          (mouseover)="codeToolTip.open()"
          (mouseout)="codeToolTip.close()"
        ></a>
      </ecl-text-input>
    </div>

    <hr class="page-standard-hr" />

    <h3 *ngIf="pageConsentSectionTitle">{{ pageConsentSectionTitle }}</h3>

    <div
      *ngIf="pageConsentSectionContent"
      [innerHtml]="pageConsentSectionContent"
    ></div>

    <app-consents
      #consentsComponent
      [formGroup]="form"
      [consentSectionItems]="consentsSection"
      [selectedState]="lastSelectedState"
      [hideDividingLine]="true"
    ></app-consents>

    <div class="score-message-container" *ngIf="affectedCreditSectionEnabled">
      <i class="fas fa-exclamation-triangle ecl-notification-icon"></i>
      <div id="affectingCreditScoreLabel">
        <span id="disclosuresAffectingCreditScoreLabel">
          {{ cmsPageContent.disclosures.affectingCreditScore }}
          <a
            id="gettingStartedLearnMore"
            class="ecl-font-link-light-bg"
            href="javascript:void(0);"
            (click)="openAffectCreditScore()"
            >{{ cmsPageContent.labels.learnMore }}</a
          >
        </span>
      </div>
    </div>

    <div id="gettingStartedPageContent" [innerHtml]="pageContent"></div>

    <div
      class="additional-message-to-applicant small"
      [innerHtml]="
        cmsPageContent.modals.affectCreditScoreModal
          ?.additionalMessageToApplicant
      "
    ></div>

    <div class="continue-button-container">
      <button
        focusOnError
        googleAnalyticsClick="GetStarted-Continue"
        type="submit"
        id="gettingSartedSubmitBtn"
        class="ecl-button-primary"
      >
        {{ cmsPageContent.buttons.continue }}
      </button>
    </div>
  </form>
</div>
<app-recaptcha [hidden]="recaptchaIsHidden"></app-recaptcha>

<app-debug-hud *ngIf="showDebugHud === 'gettingStarted'">
  <button (click)="debugHudSubmit()">Simulate Data</button>

  <button (click)="debugHud.toggleReCaptcha()">
    {{ debugHud.disableReCaptcha ? 'Enable' : 'Disable' }} ReCaptcha
  </button>
  <button (click)="debugHud.navigateReapplyPage()">Navigate to Reapply</button>
  <label>Start Options</label>
  <select
    (change)="debugHud.selectMockStartOptionResponse($event.target.value)"
  >
    <option>Call Api</option>
    <option>StartNewOnly</option>
    <option>ResumeOnly</option>
    <option>ResumeOrStartNew</option>
    <option>NoOptions</option>
    <option>RedirectToBau</option>
  </select>
</app-debug-hud>

<app-debug-hud *ngIf="showDebugHud === 'partner'">
  <button (click)="debugHudSubmit()">Sign Disclosures</button>
</app-debug-hud>
