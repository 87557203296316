<div class="row" [formGroup]="form">
  <ecl-select
    class="col-md-8"
    formControlName="primaryPaymentFrequency"
    [formControlConfig]="formConfig.primaryPaymentFrequencyConfig"
    googleAnalyticsFocusOut="primaryPaymentFrequency"
  >
    {{ CMSPaymentFrequency.paymentFrequencyLabel }}
  </ecl-select>
  <ng-container
    *ngIf="
      value.primaryPaymentFrequency === 'Weekly' &&
      CMSPaymentFrequency.ispaydateenabled
    "
  >
    <div class="col-md-8 payment-frequency-datepicker-container">
      <ecl-date-picker
        [formControlConfig]="form.primaryNextPayDateConfig"
        formControlName="primaryNextPayDate"
        googleAnalyticsFocusOut="primaryNextPayDate"
      >
        {{ CMSPaymentFrequency.nextPayDateLabel }}
      </ecl-date-picker>
    </div>
  </ng-container>
  <ng-container
    *ngIf="
      value.primaryPaymentFrequency === 'BiWeekly' &&
      CMSPaymentFrequency.ispaydateenabled
    "
  >
    <div class="col-md-8 payment-frequency-datepicker-container">
      <ecl-date-picker
        [formControlConfig]="form.primaryNextPayDateConfig"
        formControlName="primaryNextPayDate"
        googleAnalyticsFocusOut="primaryNextPayDate"
      >
        {{ CMSPaymentFrequency.nextPayDateLabel }}
      </ecl-date-picker>
    </div>
  </ng-container>
  <ng-container
    *ngIf="
      value.primaryPaymentFrequency === 'SemiMonthly' &&
      CMSPaymentFrequency.ispaydateenabled
    "
  >
    <ecl-select
      class="col-md-8"
      formControlName="primaryNextPayDate"
      [formControlConfig]="formConfig.primarySemiMonthlyDaysOfMonthConfig"
      googleAnalyticsFocusOut="primaryNextPayDate"
    >
      {{ CMSPaymentFrequency.semiMonthLabel }}
    </ecl-select>
  </ng-container>

  <ng-container
    *ngIf="
      value.primaryPaymentFrequency === 'Monthly' &&
      CMSPaymentFrequency.ispaydateenabled
    "
  >
    <ecl-select
      class="col-md-8"
      formControlName="primaryNextPayDate"
      [formControlConfig]="formConfig.primaryMonthlyDayOfMonthConfig"
      googleAnalyticsFocusOut="primaryNextPayDate"
    >
      {{ CMSPaymentFrequency.monthlyLabel }}
    </ecl-select>
  </ng-container>
  <ecl-input-currency
    class="col-md-8"
    formControlName="primaryNetAmount"
    [formControlConfig]="formConfig.primaryNetAmountConfig"
    googleAnalyticsFocusOut="primaryNetAmount"
  >
    {{ CMSPaymentFrequency.takeHomeAmountLabel }}

    <a
      id="primaryNetAmountTooltip"
      aria-label="Read more about amount"
      class="ecl-info-icon"
      href="javascript:void(0);"
      ngbTooltip="{{ CMSPaymentFrequency.takeHomeInfo }}"
      [placement]="['right', 'left', 'top']"
      tooltipClass="elastic-tooltip"
      triggers="manual"
      #primaryNetAmountTooltip="ngbTooltip"
      (click)="primaryNetAmountTooltip.open()"
      (mouseover)="primaryNetAmountTooltip.open()"
      (mouseout)="primaryNetAmountTooltip.close()"
    ></a>
  </ecl-input-currency>
</div>

<ng-template
  #customDay
  let-date="date"
  let-currentMonth="currentMonth"
  let-selected="selected"
  let-disabled="disabled"
  let-focused="focused"
  let-today="today"
>
  <span
    class="custom-day"
    [class.hidden]="date.month !== currentMonth"
    [class.selected]="selected"
    [class.today]="today"
  >
    {{ date.day }}
  </span>
</ng-template>

<ng-template #footerTemplate>
  <div class="datepicker-footer">
    <div>
      <span class="square today"></span>
      <span>Today</span>
    </div>
    <div>
      <span class="square selected"></span>
      <span>Selected</span>
    </div>
  </div>
</ng-template>
