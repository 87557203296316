import { Component, OnInit, OnDestroy } from '@angular/core';
import { CmsGettingStarted } from '../getting-started.content';
import { GettingStartedFormGroup } from '../getting-started.form';

import {
  AddressConfiguration,
  EditAddressControls,
  EditAddressLabels,
  getDefaultAddressControls
} from '@elevate/address';
import { Environment } from '@environment/environment';
import { ApplicationDataService } from '@application/application.service';
import { ActivatedRoute } from '@angular/router';
import { GoogleAnalytics } from '@core/google-analytics/googleanalytics.service';
import { AppInsightsService } from '@core/app-insights/app-insights.service';

@Component({
  selector: 'app-mailing-address',
  templateUrl: './mailing-address.component.html',
  styleUrls: ['./mailing-address.component.scss']
})
export class MailingAddressComponent implements OnInit, OnDestroy {
  public cmsPageContent: CmsGettingStarted;

  public addressConfig: AddressConfiguration;

  public mailingAddressControls: EditAddressControls;

  public addressLabels: EditAddressLabels;

  constructor(
    public form: GettingStartedFormGroup,
    private environment: Environment,
    private applicationDataService: ApplicationDataService,
    public route: ActivatedRoute,
    public googleAnalytics: GoogleAnalytics,
    public appInsightsService: AppInsightsService
  ) {
    this.mailingAddressControls = getDefaultAddressControls(null, true, true);
    const cmsContent = this.route.snapshot.data.cmsContent
      .prequalificationMailingAddress;

    this.addressLabels = {
      addressLine1: cmsContent.labels.addressLine1,
      addressLine2: cmsContent.labels.addressLine2,
      city: cmsContent.labels.city,
      state: cmsContent.labels.state,
      postalCode: cmsContent.labels.postalCode
    };
    this.addressConfig = {
      siteKey: this.environment.smartyStreets.siteKey,

      url: {
        lookup: this.environment.smartyStreets.url.suggest,
        lookupCheck: this.environment.smartyStreets.url.lookup
      },
      stateBlackListed: [],
      threeColumnDisplay: true
    };
  }

  public ngOnInit(): void {
    Object.keys(this.mailingAddressControls).forEach((key: string) => {
      this.form.addControl(
        `mailingAddress_${key}`,
        this.mailingAddressControls[key]
      );
    });
    this.populateData();
  }

  public async onFocusOut(event: any): Promise<void> {
    this.googleAnalytics.formInteractionEvent({
      step_name: event.target.id
    });
  }

  public onAddressSuggestionError(event: any){
    this.appInsightsService.trackException(event);
  }

  public ngOnDestroy(): void {
    Object.keys(this.form.controls).forEach(key => {
      if (key.includes('mailingAddress_')) {
        this.form.removeControl(key);
      }
    });
  }

  private populateData(): void {
    const form = this.applicationDataService.getApplication()?.form;
    const address = form?.applicant?.residences[0]?.address;
    const mailingAddress = form?.applicant?.residences?.find(
      residence => residence.type === 'Mailing'
    )?.address;
    if (mailingAddress) {
      let isMailingAddresSame = true;
      Object.keys(address).forEach(k => {
        if (address[k] !== mailingAddress[k]) {
          isMailingAddresSame = false;
        }
      });
      if (!isMailingAddresSame) {
        const data = {
          mailingAddress_addressLine1: mailingAddress.line1,
          mailingAddress_addressLine2: mailingAddress.line2,
          mailingAddress_postalCode: mailingAddress.zipCode,
          mailingAddress_city: mailingAddress.city,
          mailingAddress_state: mailingAddress.stateCode,
          mailingAddressCheckBox: false
        };
        setTimeout(() => {
          this.form.patchValue(data);
        });
      }
    }
  }
}
