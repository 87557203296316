import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CheckingAccounts } from '@application/connect-bank/connect-bank-api.service';
import { Radio } from '@elevate/ui-components';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountTypeModalForm } from './account-type-modal.form';
import { ApplicationForm } from '@application/application';
import { BaseModalConfig } from '@elevate/base-modal';

import { GoogleAnalytics } from '@core/google-analytics/googleanalytics.service';

export enum AccountTypeModalResult {
  Success = 'sucess',
  Error = 'error',
  Close = 'close'
}

export interface AccountTypeModalCmsContents {
  header: string;
  messages: {
    selectedAccountIsNotChecking: string;
    chooseCheckingAccount: string;
    noCheckingAccounts: string;
  };
  buttons: {
    continue: string;
    return: string;
  };
  validationMessages: {
    selectOption: string;
  };
  labels: {
    checkingOption: string;
  };
}

@Component({
  selector: 'app-account-type-modal',
  templateUrl: './account-type-modal.component.html',
  styleUrls: ['./account-type-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [AccountTypeModalForm]
})
export class AccountTypeModalComponent implements OnInit {
  @Input() public accountTypeModalData: {
    availableAccounts: CheckingAccounts[];
    cmsData: AccountTypeModalCmsContents;
  };
  @Input() public modalData: BaseModalConfig = {
    useWrapperOnly: true,
    useWrapperEmpty: true
  } as BaseModalConfig;

  private form: AccountTypeModalForm;
  public notExistingAccounts = false;
  public accountsSelectForm = false;
  public pageTitle: string;
  public warningMessage: string;
  public userHasAccountMessage: string;
  public userDoesNotHaveAccountsMessage: string;
  public continueButton: string;
  public returnButton: string;

  public accountTypeRadioFormConfig: Radio = {
    name: 'accountTypeRadio',
    direction: 'column',
    value: '',
    buttons: []
  };

  constructor(
    private accountTypeModal: NgbActiveModal,
    public googleAnalytics: GoogleAnalytics
  ) {}

  public ngOnInit(): void {
    const accountTypeModalContent = {
      header: 'Account type not permitted',
      messages: {
        selectedAccountIsNotChecking:
          'In a previous step you selected an account that is not a checking account. You need to have a checking account in good standing to borrow from Elastic.',
        chooseCheckingAccount:
          'Please choose a checking account from your list of accounts below:',
        noCheckingAccounts:
          'There are no checking accounts associated with this login. Click Return to select a different bank.'
      },
      buttons: {
        continue: 'CONTINUE',
        return: 'RETURN'
      },
      validationMessages: {
        selectOption: 'Please select an option to continue.'
      },
      labels: {
        checkingOption: 'Checking Account (${accountNumberLast4})'
      }
    };
    const availableAccounts = this.accountTypeModalData.availableAccounts;
    const cmsData =
      this.accountTypeModalData?.cmsData || accountTypeModalContent;
    this.pageTitle = cmsData.header;
    this.warningMessage = cmsData.messages.selectedAccountIsNotChecking;
    this.userHasAccountMessage = cmsData.messages.chooseCheckingAccount;
    this.userDoesNotHaveAccountsMessage = cmsData.messages.noCheckingAccounts;
    this.continueButton = cmsData.buttons.continue;
    this.returnButton = cmsData.buttons.return;
    this.form = new AccountTypeModalForm(cmsData.validationMessages);

    if (availableAccounts.length !== 0) {
      availableAccounts.forEach((account: CheckingAccounts, index: number) => {
        this.accountTypeRadioFormConfig.buttons.push({
          id: `checkingAccountOption${index}`,
          label: cmsData.labels.checkingOption.replace(
            '${accountNumberLast4}',
            account.accountNumberLast4
          ),
          value: account.key
        });
      });

      this.accountsSelectForm = true;
    } else {
      this.notExistingAccounts = true;
    }
  }

  public close(): void {
    this.accountTypeModal.close({ result: AccountTypeModalResult.Close });
  }

  public async submit(): Promise<void> {
    this.form.showValidationErrors();

    if (!this.form.valid) {
      return;
    }

    this.accountTypeModal.close({
      result: AccountTypeModalResult.Success,
      selectedAccountPayload: this.createBankDataPayload()
    });
  }

  private createBankDataPayload(): ApplicationForm {
    return {
      applicant: {
        bank: {
          draftFromKey: this.form.get('draftFromKey').value
        }
      }
    };
  }
}
