import { Injectable } from '@angular/core';
import { ConsentHelper } from '@application/consents/consent.helper';
import { PrequalApprovalFormGroup } from '../prequal-approval.component.form';
/* istanbul ignore next */
@Injectable()
export class PrequalApprovalDebugService {
  public isDMuser: boolean;

  constructor(private form: PrequalApprovalFormGroup) {}

  public toggleDmUser(): void {
    this.isDMuser = !this.isDMuser;
  }

  public signDisclousures(): void {
    ConsentHelper.confirmAllConsents(this.form);
  }
}
