export interface Banner {
  bannerName: string;
  bannerContent: string;
  bannerType: BannerType;
  showCloseButton: boolean;
}

export enum BannerType {
  Informative = 'Informative',
  Alert = 'Alert',
  Success = 'Success',
  Error = 'Error',
  Critical = 'Critical'
}

export interface BannersContainer {
  banners: Banner[];
}

export interface BannersResponse {
  banners: DisplayBanner[];
}

export interface DisplayBanner {
  bannerCmsName: string;
}