<div class="layout-container">
  <ng-container *ngIf="environment.defaultLayout; else otherLayout">
    <ecl-header
      *ngIf="rawHeaderCms$ | async"
      class="bau-header-link"
      [rawHeaderCms]="rawHeaderCms$ | async"
      [publicBaseUrl]="publicBaseUrl"
      [banners]="banners$ | async"
      (closeBanner)="closeBanner($event)"
      (redirectHome)="logoClicked($event)"
      (signIn)="signIn($event)"
      unreadMessages="0"
    ></ecl-header>

    <div class="main-page-container">
      <header class="row">
        <div class="offset-lg-1 col-lg-10">
          <app-page-header></app-page-header>
        </div>
      </header>

      <main class="row">
        <div class="offset-lg-1 col-lg-10 col-xl-6">
          <router-outlet></router-outlet>
        </div>

        <div class="offset-lg-1 d-none d-xl-block col-xl-3  sidebar-top-margin">
          <app-sidebar [isCobrowseEnabled]="isCobrowseEnabled"></app-sidebar>
        </div>
      </main>
    </div>
    <footer>
      <ecl-footer
        class="default-layout-footer"
        [footerContent]="footerContent$ | async"
        [publicBaseUrl]="publicBaseUrl"
        [accountBaseUrl]="accountBaseUrl"
        [cobrowse]="cobrowse"
        (click)="onNavClick($event)"
      ></ecl-footer>
      <div
        class="app-cookie-notification-container"
        role="alert"
        *ngIf="showCookieNotification"
      >
        <ecl-cookie-notification
          (isNotificationShowing)="getNotificationStatus($event)"
          [notification]="cookieNotificationContent$ | async"
        ></ecl-cookie-notification>
      </div>
    </footer>
  </ng-container>
  <ng-template #otherLayout>
    <ecl-header
      *ngIf="rawHeaderCms$ | async"
      class="bau-header-link"
      [rawHeaderCms]="rawHeaderCms$ | async"
      [publicBaseUrl]="publicBaseUrl"
      [banners]="banners$ | async"
      (closeBanner)="closeBanner($event)"
      (redirectHome)="logoClicked($event)"
      (signIn)="signIn($event)"
      unreadMessages="0"
    ></ecl-header>
    <header>
      <rise-five-day-bar
        class="risk-free-msg d-none d-xl-block d-md-block"
        id="riseFiveDayRiskFreeMsg"
        [cmsPageContent]="fiveDayRiskFreeGuarantee$ | async"
      ></rise-five-day-bar>
    </header>
    <main>
      <div class="row main-page-container other-layout-main mx-auto">
        <div class="col container-xl">
          <app-page-header></app-page-header>
          <router-outlet></router-outlet>
        </div>
        <div class="col-md-3 offset-md-1 d-none d-xl-block d-md-block">
          <div class="row">
            <app-sidebar
              class="page-header-container"
              [isCobrowseEnabled]="isCobrowseEnabled"
            ></app-sidebar>
          </div>
        </div>
      </div>
    </main>
    <footer>
      <ecl-footer
        class="other-layout-footer"
        [footerContent]="footerContent$ | async"
        [publicBaseUrl]="publicBaseUrl"
        [accountBaseUrl]="accountBaseUrl"
        [cobrowse]="cobrowse"
        (click)="onNavClick($event)"
      ></ecl-footer>
      <div
        class="app-cookie-notification-container"
        role="alert"
        *ngIf="showCookieNotification"
      >
        <ecl-cookie-notification
          (isNotificationShowing)="getNotificationStatus($event)"
          [notification]="cookieNotificationContent$ | async"
        ></ecl-cookie-notification>
      </div>
    </footer>
  </ng-template>
  <div>
    <app-feedback></app-feedback>
  </div>
</div>
