/* istanbul ignore file */
import { Injectable } from '@angular/core';
import { Environment } from '@environment/environment';
import * as LogRocket from 'logrocket';
import { ApplicationData } from '@application/application';

export interface AMSAppSessionCorrelator {
  appInsightsSessionId: string;
  ga4SessionId: string;
}

@Injectable({ providedIn: 'root' })
export class LogRocketService {
  constructor(private environment: Environment) {}

  public init(): void {
    const logRocketInitConfig = {
      rootHostname: this.environment.logRocket.rootDomain,
      network: {
        requestSanitizer: (request: any) => {
          request.body = null;
          return request;
        },
        responseSanitizer: (response: any) => {
          response.body = null;
          return response;
        }
      }
    };

    // pointing assets to prod for lle environments
    if (!this.environment.production) {
      logRocketInitConfig['dom'] = {
        baseHref: this.environment.logRocket.assetsBaseUrl
      };
    }

    LogRocket.init(
      this.environment.logRocket.applicationId,
      logRocketInitConfig
    );
  }

  public async trackAppLogRocketCustomEvent(data: AMSAppSessionCorrelator) {
    LogRocket.track('AMSAppSessionCorrelator', {
      appInsightsSessionId: data.appInsightsSessionId,
      ga4SessionId: data.ga4SessionId
    });
  }

  public async addSearchableFields(
    applicationData: ApplicationData
  ): Promise<void> {
    LogRocket.identify(applicationData.id, {
      name: `${applicationData.form.applicant.identity.firstName} ${applicationData.form.applicant.identity.lastName}`,
      firstName: applicationData.form.applicant.identity.firstName,
      lastName: applicationData.form.applicant.identity.lastName,
      mobilePhone: applicationData.form.applicant.phones[0].number,
      email: applicationData.form.applicant.emails[0].address,
      sequenceApplicationId: applicationData.sequenceApplicationId
    });
  }

  public async addAbTestingData(testName: string, segmentName: string)
  {
    LogRocket.track(testName, {
      segmentName: segmentName
    });
  }
}
