import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PartnerLeadIdModel } from '@core/partner/partner-verification.models';
import { Environment } from '@environment/environment';

@Injectable({
  providedIn: 'root'
})
export class PartnerRegisterActionsService {
  constructor(private environment: Environment, private http: HttpClient) {}

  public registerPartnerAction(
    leadInfo: PartnerLeadIdModel,
    action: 'click' | 'redeem'
  ): void {
    const path = leadInfo.offerId
      ? `${this.environment.brandApi.url}/api/v1/partner/lead/${leadInfo.leadId}/offer/${leadInfo.offerId}/${action}`
      : `${this.environment.brandApi.url}/api/v1/partner/lead/${leadInfo.leadId}/${action}`;
    this.http.patch<any>(path, {}).subscribe(() => {});
  }
}
