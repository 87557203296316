import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { requiredCheckboxValidator } from '@elevate/forms';
import { Checkbox } from '@elevate/ui-components';
import { IESiganture } from '../interfaces/e-siganture.interface';
import {
  ConsentModalAgreement,
  SignatureModel,
  SignatureType
} from '../signature.content';
import {
  GoogleAnalytics,
  ModalClickAction
} from '@core/google-analytics/googleanalytics.service';

@Component({
  selector: 'app-signature-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit, OnDestroy, IESiganture {
  @Input() public agreement: ConsentModalAgreement;
  @Input() public formGroup: FormGroup;
  @Input() public controlName = 'checkboxSignature';
  @Input() public modalName: string;
  @Input() public initValue: boolean = null;

  constructor(public googleAnalytics: GoogleAnalytics) {}

  public checkboxConfig: Checkbox = {
    id: 'checkboxInput',
    required: 'true'
  };

  public get signatureModel(): SignatureModel {
    return {
      isConsentGiven: this.isValid,
      signatureType: SignatureType.CheckBox,
      controlName: this.controlName,
      signatureValue: this.formGroup.controls[this.controlName]?.value
    };
  }

  public get isValid(): boolean {
    return this.formGroup.controls[this.controlName]?.valid;
  }
  public get label(): string {
    return this.agreement.label;
  }
  public get instruction(): string {
    return this.agreement.instruction;
  }

  ngOnInit(): void {
    this.initValue = String(this.initValue).toLocaleLowerCase() === 'true'; // it's need when initValue is a string "true"
    this.formGroup.addControl(
      this.controlName,
      new FormControl(this.initValue, [
        requiredCheckboxValidator(this.agreement.validationRequired)
      ])
    );
    if (this.agreement.name !== '') {
      this.checkboxConfig.id = this.agreement.name;
    }
  }

  public ngOnDestroy(): void {
    this.formGroup.removeControl(this.controlName);
  }

  public checkboxChange(): void {
    this.googleAnalytics.clickModalEvent({
      link_text: this.agreement.label
        .replace('<span>', '')
        .replace('</span>', ''),
      action:
        this.formGroup.controls[this.controlName].value == true
          ? ModalClickAction.Check.toString()
          : ModalClickAction.UnCheck.toString(),
      modal_name: this.modalName == '' ? this.agreement.label : this.modalName,
      modal_section: this.agreement.name
    });
  }
}
