import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageFormGroup } from '@core/page-form-group/page-form-group';
import { requiredValidator } from '@elevate/forms';

export interface ConnectBankFormValidationMessages {
  selectOneOption: string;
}

@Injectable()
export class ConnectBankForm extends PageFormGroup {
  constructor(messages: ConnectBankFormValidationMessages) {
    super({
      connectBankOption: new FormControl(null, [
        requiredValidator(messages.selectOneOption)
      ])
    });
  }
}
