import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AccessibilityService {
  constructor(private modalService: NgbModal) {}

  /**
   * Initialize the service to list for NgbModal dialogs open event to add the necessary attributes for the application to pass
   * accessibility audits.
   */
  public initialize(): void {
    this.configureNgbModalDialogForAccessibility();
  }

  /**
   * Add pertitent attributes such as role and title to contents within an NgbModal dialog to pass accessibility audits.
   */
  private configureNgbModalDialogForAccessibility(): void {
    this.modalService.activeInstances
      .pipe(
        mergeMap(modals => from(modals).pipe(mergeMap(modal => modal.shown)))
      )
      .subscribe(() => {
        this.setTitleAttributeToModalWindow();
      });
  }

  /**
   * Function that retrives a list of ngb-modal-window elements and shifts the value from the attribute
   * aria-describedby to the title attribute to pass acccessibility tests.
   */
  private setTitleAttributeToModalWindow(): void {
    const modalWindows = document.querySelectorAll('ngb-modal-window');

    if (modalWindows && modalWindows.length > 0) {
      modalWindows.forEach(modalWindow => {
        const ariaDescribedByAttr = modalWindow.getAttribute(
          'aria-describedby'
        );

        if (!modalWindow.hasAttribute('title') && ariaDescribedByAttr) {
          modalWindow.setAttribute('title', ariaDescribedByAttr);
          modalWindow.removeAttribute('aria-describedby');
        }
      });
    }
  }
  /*
     Add Aria Label to each of drop down items
  */
  public setAriaLabelAttributeToAllDropDownListItems(): void {
    const dropDownItems = document.querySelectorAll('li.ng-star-inserted');
    if (dropDownItems && dropDownItems.length > 0) {
      dropDownItems.forEach(dropDownItem => {
        if (dropDownItem.textContent.trim() === '') {
          dropDownItem.setAttribute('aria-label', 'Select Default Selector');
        } else {
          dropDownItem.setAttribute(
            'aria-label',
            'Select ' + dropDownItem.textContent
          );
        }
      });
    }
  }
  /*
     Remove not allowed Aria-Attributes on an accordian
  */
  public removeUnAllowedAriaAttributes(): void {
    const accodianList = document.querySelectorAll('div.ecl-accordion');
    if (accodianList && accodianList.length > 0) {
      accodianList.forEach(accodianItem => {
        accodianItem.removeAttribute('aria-expanded');
      });
    }
  }

  /**
   * Function that retrieves a list of modal container inside an NgbModal and set the role to "main" in order to
   * pass  ++++++++++the landmark criteria for accessibility.
   */
  // private setRoleAttributeToModalContentContainer(): void {
  //   const contentContainers = document.querySelectorAll(
  //     'div.modal-container-content'
  //   );

  //   if (contentContainers && contentContainers.length > 0) {
  //     contentContainers.forEach(contentContainer => {
  //       if (!contentContainer.hasAttribute('role')) {
  //         contentContainer.setAttribute('role', 'main');
  //       }
  //     });
  //   }
  // }
}
