<ecl-base-modal
  class="use-previous-account-modal-container"
  id="usePreviousAccountDisclosureModalWindow"
  [modalConfig]="modalData"
  (closeModal)="
    googleAnalytics.clickModalEvent({
      action: 'Close',
      modal_name: 'UsePreviousAccountModal'
    })
  "
  (openModal)="
    googleAnalytics.viewModalEvent({
      modal_name: 'UsePreviousAccountModal'
    })
  "
>
  <div ngbAutofocus>
    <!-- {{modalCmsData.content}} -->
    <div
      id="usePreviousAccountDisclosureModalContent"
      [innerHTML]="modalData.content | safe: 'html'"
    ></div>
  </div>
</ecl-base-modal>
