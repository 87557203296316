import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoadingModalComponent } from './loading-modal.component';

@Injectable({ providedIn: 'root' })
export class LoadingModalService {
  public isOpen = false;

  private loadingModal: NgbModalRef;

  constructor(private modalService: NgbModal) {}

  public open(): void {
    if (this.isOpen) {
      return;
    }

    this.isOpen = true;

    this.loadingModal = this.modalService.open(LoadingModalComponent, {
      windowClass: 'ng-loading-modal',
      centered: true,
      backdrop: 'static',
      keyboard: false
    });
  }

  public close(): void {
    this.isOpen = false;
    this.loadingModal?.close();
  }
}
