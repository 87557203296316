import { Inject, Injectable } from '@angular/core';
import { ApplicationData, ShortAppOffer } from '@application/application';
import {
  ConsentModal,
  ConsentModalContent,
  ConsentMapping
} from '@application/consents/consents.content';
import { DisclosureModalComponent } from '@application/consents/disclosure-modal/disclosure-modal.component';
import { CmsService } from '@core/cms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationDataService } from '@application/application.service';
import { shareReplay } from 'rxjs/operators';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable, lastValueFrom } from 'rxjs';
import { ConsentHelper } from '@application/consents/consent.helper';

@Injectable({
  providedIn: 'root'
})
export class TodayCardHandleService {
  private application: ApplicationData;
  private buisnesTermsCmsContent$: Observable<any>;

  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private cmsService: CmsService,
    private applicationDataService: ApplicationDataService
  ) {
    this.application = this.applicationDataService.getApplication();
    this.buisnesTermsCmsContent$ = this.cmsService
      .getEntries('business_terms_glossary')
      .pipe(shareReplay(1));
  }

  public async modifyStateSpecificConsent(
    ConsentMapping: ConsentMapping,
    shortAppOffer: ShortAppOffer
  ): Promise<ConsentMapping> {
    const consent = await this.createModalContent(
      ConsentMapping,
      shortAppOffer?.annualFee,
      this.application?.offer?.apr ?? shortAppOffer?.purchaseAPR,
      false
    );
    const currentState = this.application?.form?.applicant?.residences?.find(
      residence => residence.type === 'Current'
    )?.address?.stateCode;
    return this.setStateConsent(ConsentMapping, consent, currentState);
  }

  public configFooter(ConsentMapping: ConsentMapping): void {
    this.route.queryParams.subscribe(async (params: Params) => {
      const shortApp = this.tryParseShortAppOffer(params);
      const annualFee =
        this.application?.form?.shortAppOffer?.annualFee ?? shortApp?.annualFee;
      const disclosureModals = await this.createModalContent(
        ConsentMapping,
        annualFee,
        this.application?.offer?.apr ?? shortApp?.purchaseAPR,
        true
      );

      this.setUpFooterLink(disclosureModals);
    });
  }

  public replaceMargin(disclosureText: string): Promise<string> {
    return lastValueFrom(this.buisnesTermsCmsContent$).then(
      buisnesTermsContent => {
        this.route.queryParams.subscribe(async (params: Params) => {
          const shortApp = this.tryParseShortAppOffer(params);
          const primeRate = parseFloat(buisnesTermsContent.primeRate);
          const apr = this.application?.offer?.apr ?? shortApp?.purchaseAPR;
          const margin = apr - primeRate;
          disclosureText = disclosureText.replace(
            '{margin}',
            margin?.toString()
          );
        });
        return disclosureText;
      }
    );
  }

  private createModalContent(
    consentMapping: ConsentMapping,
    annualFee: number,
    apr: number,
    removeAgreements: boolean
  ): Promise<ConsentModal> {
    const currentState = this.application?.form?.applicant?.residences?.find(
      residence => residence.type === 'Current'
    )?.address?.stateCode;
    const consent = this.getConsent(consentMapping, currentState);
    consent.content = ConsentHelper.converToArray(consent.content);
    if (removeAgreements) {
      this.removeAgreementsFromConsents(consent.content);
    }
    let disclosureText = consent.content[0].disclosureText;
    return lastValueFrom(this.buisnesTermsCmsContent$).then(
      buisnesTermsContent => {
        const primeRate = parseFloat(buisnesTermsContent.primeRate);
        const margin = apr - primeRate;
        const monthlyFeeAmount = (annualFee / 12).toFixed(2);
        let re = '{APR}';
        disclosureText = disclosureText.replace(re, apr?.toString());
        re = '{feeAmount}';
        disclosureText = disclosureText.replace(re, annualFee?.toString());
        re = '{monthlyFeeAmount}';
        disclosureText = disclosureText.replace(
          re,
          monthlyFeeAmount?.toString()
        );
        re = '{margin}';
        consent.content[0].disclosureText = disclosureText.replace(
          re,
          margin?.toString()
        );
        return consent;
      }
    );
  }

  private removeAgreementsFromConsents(
    consentContents: ConsentModalContent[]
  ): void {
    consentContents.forEach(content => {
      content.agreement = null;
    });
  }

  private getConsent(
    consentMapping: ConsentMapping,
    currentState: string
  ): ConsentModal {
    consentMapping.defaultConsent.disclosureModals = ConsentHelper.converToArray(
      consentMapping.defaultConsent.disclosureModals
    );

    if (!currentState) {
      return consentMapping.defaultConsent.disclosureModals[0];
    }

    consentMapping.statesConsents = ConsentHelper.converToArray(
      consentMapping.statesConsents
    );

    const stateConsent = consentMapping.statesConsents?.filter(
      consent => {
        return consent?.states && consent.states.includes(currentState);
      }
    );

    if (stateConsent?.length > 0) {
      stateConsent[0].consent.disclosureModals = ConsentHelper.converToArray(
        stateConsent[0].consent.disclosureModals
      );
      return stateConsent[0].consent.disclosureModals[0];
    }

    return consentMapping.defaultConsent.disclosureModals[0];
  }

  private setStateConsent(
    consentMapping: ConsentMapping,
    consent: ConsentModal,
    currentState: string
  ): ConsentMapping {
    consentMapping.defaultConsent.disclosureModals = ConsentHelper.converToArray(
      consentMapping.defaultConsent.disclosureModals
    );

    if (!currentState || !consentMapping.statesConsents) {
      consentMapping.defaultConsent.disclosureModals = [consent];
      return consentMapping;
    }

    consentMapping.statesConsents?.forEach(item => {
      if (item?.states && item.states.includes(currentState)) {
        item.consent.disclosureModals = [consent];
      }
    });
    return consentMapping;
  }

  private setUpFooterLink(content: ConsentModal): void {
    let element = document.getElementById('pricing-&-terms') as HTMLElement;
    const a = document.createElement('a');
    a.setAttribute('class', element.getAttribute('class'));
    a.setAttribute('id', element.getAttribute('id'));
    a.setAttribute('href', element.getAttribute('href'));
    a.innerText = element.innerText;
    element.replaceWith(a);
    element = document.getElementById('pricing-&-terms') as HTMLElement;

    element.addEventListener('click', event => {
      event.preventDefault();
      const modalRef = this.modalService.open(DisclosureModalComponent, {
        windowClass: 'ng-disclosure-modal'
      });
      const disclosureModal = modalRef.componentInstance as DisclosureModalComponent;
      disclosureModal.consentModal = content;
    });
  }

  private tryParseShortAppOffer(params: Params): ShortAppOffer {
    try {
      const offer = JSON.parse(
        atob(decodeURIComponent(params['offer']))
      ) as ShortAppOffer;
      return offer;
    } catch {}
  }
}
