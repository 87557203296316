import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

import { ReCaptchaService } from './recaptcha.service';

@Component({
  selector: 'app-recaptcha',
  template: '<div id="recaptcha" #recaptcha></div>'
})
export class ReCaptchaComponent implements AfterViewInit {
  @ViewChild('recaptcha') private recaptchaElement: ElementRef;

  constructor(private reCaptchaService: ReCaptchaService) {}

  public ngAfterViewInit(): void {
    this.reCaptchaService.initialize();
    this.reCaptchaService.render(this.recaptchaElement.nativeElement);
  }
}
