<app-employer
  *ngIf="CMSEmployer.isenabled"
  [formGroup]="form"
  [CMSEmployer]="CMSEmployer"
></app-employer>

<div *ngIf="CMSJobIncome.isphoneenabled" [formGroup]="form" class="row">
  <ecl-phone-number
    class="col-md-8 income-phone-number"
    formControlName="primaryPhoneNumber"
    [formControlConfig]="primaryJobIncomePhoneNumberConfig"
    googleAnalyticsFocusOut="primaryPhoneNumber"
  >
    {{ CMSJobIncome.workPhoneLabel }}
  </ecl-phone-number>

  <ecl-number
    class="col-md-8"
    formControlName="primaryPhoneNumberExtension"
    [formControlConfig]="primaryJobIncomePhoneNumberExtensionConfig"
    withStringOutput="true"
    googleAnalyticsFocusOut="primaryPhoneNumberExtension"
    (paste)="(false)"
  >
    {{ CMSJobIncome.workExtensionLabel }}
  </ecl-number>
</div>

<app-payment-frequency
  [formGroup]="formGroup"
  [CMSPaymentFrequency]="CMSPaymentFrequency"
></app-payment-frequency>
