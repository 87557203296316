import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ApplicationDataService } from '@application/application.service';
import { requiredValidator } from '@elevate/forms';
import { TextInput } from '@elevate/ui-components/lib/models';
import { IESiganture } from '../interfaces/e-siganture.interface';
import {
  ConsentModalAgreement,
  SignatureModel,
  SignatureType
} from '../signature.content';
import { typedNameValidator } from './typed-name.validator';

@Component({
  selector: 'app-typed-name',
  templateUrl: './typed-name.component.html',
  styleUrls: ['./typed-name.component.scss']
})
export class TypedNameComponent implements OnInit, OnDestroy, IESiganture {
  @Input() public agreement: ConsentModalAgreement;
  @Input() public formGroup: FormGroup;
  @Input() public controlName = 'typedNameSignature';
  @Input() public initValue: string = null;

  constructor(private appDataService: ApplicationDataService) {}

  public typedNameConfig: TextInput = {
    id: 'typedNameInput',
    placeholder: '',
    minlength: 2,
    maxlength: 100
  };

  public get signatureModel(): SignatureModel {
    return {
      isConsentGiven: this.isValid,
      signatureType: SignatureType.TypedName,
      controlName: this.controlName,
      signatureValue: this.formGroup.controls[this.controlName]?.value
    };
  }

  public get label(): string {
    return this.agreement.label;
  }
  public get instruction(): string {
    return this.agreement.instruction;
  }
  public get isValid(): boolean {
    return this.formGroup.controls[this.controlName]?.valid;
  }

  ngOnInit(): void {
    this.formGroup.addControl(
      this.controlName,
      new FormControl(this.initValue, [
        requiredValidator(this.agreement.validationRequired),
        typedNameValidator(
          this.appDataService
            .getApplication()
            .form.applicant.identity.firstName.trim(),
          this.appDataService
            .getApplication()
            .form.applicant.identity.lastName.trim(),
          this.agreement.validationNotMatch,
          this.agreement.ignoreCase,
          this.agreement.ignoreSpaces
        )
      ])
    );
  }

  public ngOnDestroy(): void {
    this.formGroup.removeControl(this.controlName);
  }
}
