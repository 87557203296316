import { HttpResponse } from '@angular/common/http';
import {
  Component,
  Input,
  NgZone,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import {
  AppendDeviceRequest,
  ApplicationApi,
  StartOption
} from '@application/application.api';
import { ApplicationDataService } from '@application/application.service';
import { LoadingModalService } from '@application/loading-modal/loading-modal.service';
import {
  ApplicationData,
  ApplicationStates,
  FundMethodType
} from '@application/application';
import { OtpService } from '@core/otp/otp.service';
import {
  SessionStorageKeys,
  SessionStorageObjects,
  SessionStorageService
} from '@core/session-storage/session-storage.service';
import { ValidationMessagesError } from '@elevate/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  SendCodeRequestModel,
  VerifyCodeRequestModel,
  VerifyCodeResponseModel
} from './resume-modal';
import {
  CommunicationTypeFormGroup,
  VerificationCodeFormGroup
} from './resume-modal.form';
import { resumeFormConfig } from './resume-modal.form.config';
import {
  GoogleAnalytics,
  GoogleTagManagerVariables
} from '@core/google-analytics/googleanalytics.service';
import { UserInactivityStatusService } from '@core/inactivity-module/user-inactivity.service';
import { ResumeModal } from '../getting-started.content';
import { BaseModalConfig } from '@elevate/base-modal';
import { NeuroIdService } from '@core/neuro-id/neuro-id.service';
import { lastValueFrom } from 'rxjs';
import { AppInsightsService } from '@core/app-insights/app-insights.service';
import { ApplicationFlowService } from '@core/application-flow/application-flow.service';
import { CookieService, CookieEnums } from '@core/cookie/cookie-service';
import { Environment } from '@environment/environment';
import { CookieAttributes } from 'js-cookie';
@Component({
  selector: 'app-resume-modal',
  templateUrl: './resume-modal.component.html',
  styleUrls: ['./resume-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResumeModalComponent implements OnInit {
  @Input() public data;
  @Input() public htmlContent: ResumeModal;
  @Input() public modalData: BaseModalConfig = {
    useWrapperOnly: true,
    useWrapperEmpty: true,
    action: { allowClose: true, onClose: this.closeModal }
  } as BaseModalConfig;

  public page: string;
  public phoneNumberLast4: string;
  public firstName: string;
  public contactTypeForm: CommunicationTypeFormGroup;
  public verificationForm: VerificationCodeFormGroup;
  public formConfig = resumeFormConfig;

  public modalTitle: string;
  public modalContent: string;
  public showCloseBtn: boolean;
  public isPartnerVerified: boolean;
  public verificationResponse: VerifyCodeResponseModel;

  public content: ResumeModal;
  public thanksHeader: string;
  public gcidCookieValue: string;

  constructor(
    public ngZone: NgZone,
    private otpService: OtpService,
    private activeModal: NgbActiveModal,
    private sessionStorageService: SessionStorageService,
    private applicationApi: ApplicationApi,
    private applicationDataService: ApplicationDataService,
    private router: Router,
    private loadingService: LoadingModalService,
    public googleAnalytics: GoogleAnalytics,
    private userAppStarted: UserInactivityStatusService,
    private neuroIdService: NeuroIdService,
    private modalService: NgbModal,
    private appInsightsService: AppInsightsService,
    public applicationFlowService: ApplicationFlowService,
    public cookieService: CookieService,
    public environment: Environment
  ) {
    this.contactTypeForm = new CommunicationTypeFormGroup();
    this.verificationForm = new VerificationCodeFormGroup();
  }

  public async ngOnInit(): Promise<void> {
    this.content = this.htmlContent;

    this.page =
      this.data.startOptions === StartOption.ResumeOnly
        ? 'WelcomeWithoutOptions'
        : 'WelcomeWithOptions';
    this.modalData.action.allowClose =
      this.data.showCloseBtn !== undefined ? this.data.showCloseBtn : true;
    this.phoneNumberLast4 = this.data.phoneNumberLast4;
    this.firstName = this.data.firstName;
    this.formConfig.contactTypeConfig.buttons[0].label = this.htmlContent.callContactLabel;
    this.formConfig.contactTypeConfig.buttons[1].label = this.htmlContent.textContactLabel;
    this.formConfig.contactTypeConfig = {
      ...this.formConfig.contactTypeConfig,
      buttons: this.formConfig.contactTypeConfig.buttons.map((prop: any) => ({
        ...prop,
        label: prop.label.replace('YYYY', this.phoneNumberLast4)
      }))
    };

    if(this.data.fromPartnerModalVerification){
      var partnerVerficationObj = this.sessionStorageService.getObject(SessionStorageObjects.partnerOfferInfo);
      if(partnerVerficationObj.token){
        this.verificationResponse = partnerVerficationObj;
        this.modalData.action.allowClose = false;
        this.isPartnerVerified = true;
        this.userAppStarted.setUserApplicationStatus(true);
      }
      return;
    }

    this.verificationForm.build(this.content.verificationCodeValidationMessage);
    this.thanksHeader =
      this.firstName !== ''
        ? this.content.thanksHeaderName.replace('{firstName}', this.firstName)
        : this.content.thanksHeader;

    await this.applicationFlowService.setApplicationFlows();
  }

  public sendVerificationCode(): void {
    this.contactTypeForm.showValidationErrors();
    if (!this.contactTypeForm.valid) {
      return;
    }

    this.loadingService.open();
    this.formConfig.contactTypeConfig.value = this.contactTypeForm.value.sendCodeMethod;
    const sendCodeInfo: SendCodeRequestModel = {
      applicationId: this.data.applicationId,
      channel: this.contactTypeForm.value.sendCodeMethod,
      brand: this.applicationDataService.getApplication().brand
    };

    this.otpService.sendCode(sendCodeInfo).subscribe(
      () => {
        this.moveToVerifyScreen();
      },
      () => {
        this.moveToVerifyScreen();
      }
    );
  }

  public verificationSubmit(): void {
    this.verificationForm.showValidationErrors();

    if (!this.verificationForm.valid) {
      return;
    }

    this.loadingService.open();

    const verifyCodeData: VerifyCodeRequestModel = {
      applicationId: this.data.applicationId,
      otp: this.verificationForm.value.verificationCode,
      brand: this.applicationDataService.getApplication().brand
    };

    this.otpService.verifyCode(verifyCodeData).subscribe(
      (response: HttpResponse<VerifyCodeResponseModel>) => {
        if (response.status === 200) {
          this.loadingService.close();
          this.verificationResponse = response.body;
          this.modalData.action.allowClose = false;
          this.page = 'VerificationSucess';
          this.userAppStarted.setUserApplicationStatus(true);
        }
      },
      (error: HttpResponse<any>) => {
        this.verificationForm
          .get('verificationCode')
          .setErrors(
            new ValidationMessagesError(
              'incorrectVerificationCode',
              null,
              this.content.verificationCodeValidationMessage.otherErrorMessage
            )
          );
        this.loadingService.close();
      }
    );
  }

  public goBack(): void {
    this.page = 'SendCode';
    this.verificationForm.reset();
  }

  public resumeApplication(): void {
    this.loadingService.open();

    this.sessionStorageService.setItem(
      SessionStorageKeys.authorizationToken,
      this.verificationResponse.token
    );

    this.applicationApi
      .get()
      .subscribe(async (applicationData: ApplicationData) => {
        const appStateAllowsToAppend =
          applicationData.state !== ApplicationStates.Booked &&
          applicationData.state !== ApplicationStates.Accepted;

        // use what was stored in the session on resume
        this.data.gcid = this.sessionStorageService.getItem(
          SessionStorageKeys.applicationGCID
        );

        const googleAnalyticsVars: GoogleTagManagerVariables = {
          state: applicationData.form.applicant.residences[0].address.stateCode,
          application_start_date: applicationData.createdAt,
          sequence_id: applicationData.sequenceApplicationId,
          application_id: applicationData.id,
          resumed_app: true,
          neuroId: this.neuroIdService.neuroIdSessionId,
          gcid: this.data?.gcid
        };
        this.googleAnalytics.setGoogleTagManagerVariables(googleAnalyticsVars);

        if (appStateAllowsToAppend) {
          const appendDeviceReq: AppendDeviceRequest = {
            neuroID: this.neuroIdService.neuroIdSessionId
          };

          if (this.data.gcid) {
            appendDeviceReq.GCID = this.data.gcid;
          }

          try {
            await lastValueFrom(
              this.applicationApi.appendDevice(appendDeviceReq)
            );
          } catch {
            this.modalService.dismissAll();
            this.router.navigate(['/error']);
          }
        }

        this.neuroIdService.setVariable(
          'funnel',
          applicationData.applicationFlow
        );

        this.neuroIdService.sendUserId(applicationData.id);

        this.ngZone.run(() => {
          this.appInsightsService.trackEvent('Application-Resume');
          const path = this.applicationFlowService.getContinuePath(
            null,
            applicationData.applicationFlow
          );
          applicationData.form.continuePath = applicationData.form?.continuePath || path;
          this.router.navigate([applicationData.form.continuePath]);
          this.activeModal.close({ resumeApplication: true });
          this.loadingService.close();
        });
      });
  }

  private moveToVerifyScreen(): void {
    this.page = 'VerifyCode';
    this.loadingService.close();
  }

  public closeModal(): void {
    this.contactTypeForm?.reset();
    this.verificationForm?.reset();
  }

  public startNew(): void {
    this.activeModal.close({ resumeApplication: false });
  }
}
