import { Injectable } from '@angular/core';
import { ApplicationDataService } from '@application/application.service';
import { SignAndSubmitFormGroup } from '../sign-and-submit.form';
import { Disclosure } from '../../application';

/* istanbul ignore next */
@Injectable()
export class SignAndSubmitDebugHudService {
  constructor(
    private applicationDataService: ApplicationDataService,
    private form: SignAndSubmitFormGroup
  ) {}

  public sign(): void {
    const applicant = this.applicationDataService.getApplication().form
      .applicant.identity;

    this.form.patchValue({
      typedNameSignature: `${applicant.firstName} ${applicant.lastName}`
    });
  }

  public whatYouNeedToKnowSignature(): Disclosure[] {
    // key has to be same as provided in cms json id
    return [{ key: 'whatYouNeedToKnowSignCheckBox', consentGiven: true }];
  }

  public termsConditions(): Disclosure[] {
    const applicant = this.applicationDataService.getApplication().form
      .applicant.identity;

    // key has to be same as provided in cms json id
    return [
      {
        key: 'last4SSNSignatureInput',
        consentGiven: true,
        signature: applicant.socialSecurityNumber.slice(-4)
      }
    ];
  }
}
