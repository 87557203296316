import { Injectable } from '@angular/core';
import {
  SessionStorageObjects,
  SessionStorageService
} from '@core/session-storage/session-storage.service';
import { Environment } from '@environment/environment';
import { EnvironmentService } from '@environment/environment.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationFlowService {
  private applicationFlow: object;
  private continuePath: string;

  constructor(private sessionStorageService: SessionStorageService,
    private environmentService: EnvironmentService, private environment: Environment) {}

  public getContinuePath(path?: string, userType?: string): string {
    const continuePath = path || this.continuePath;
    if (userType) {
      this.applicationFlow = this.getApplicationFlows()?.[userType];
    }
    return this.applicationFlow[continuePath];
  }

  public saveContinuePath(continuePath: string): void {
    this.continuePath = continuePath;
  }

  public async setApplicationFlows(){
    await this.environmentService.loadRouting(this.environment.brand.toLowerCase());
  }

  public getApplicationFlows() : any {
    return this.sessionStorageService.getObject(SessionStorageObjects.applicationFlows);
  }
}
