import { Injectable, OnDestroy } from '@angular/core';
import { CurrencyInput, Select, SelectOption } from '@elevate/ui-components';
import { Subscription } from 'rxjs';
import { PaymentFrequencyFormGroup } from './payment-frequency.form';

@Injectable()
export class PaymentFrequencyFormConfig implements OnDestroy {
  public primaryPaymentFrequencyConfig: Select = {
    id: 'primaryPaymentFrequencySelect',
    placeholder: 'Select paycheck frequency',
    value: '',
    visibleOptions: 4,
    options: [
      {
        label: 'None',
        value: ''
      }
    ],
    attributes: {
      'data-nid-target': 'paymentFrequencyType'
    }
  };

  public primarySemiMonthlyDaysOfMonthConfig: Select = {
    id: 'primarySemiMonthlyDaysOfMonthSelect',
    placeholder: 'Select payment dates',
    value: '',
    visibleOptions: 2,
    options: [
      {
        value: '1,16',
        label: '1st, 16th'
      },
      {
        value: '15,31',
        label: '15th, Last Day'
      }
    ],
    attributes: {
      'data-nid-target': 'semiMonthlyFrequency'
    }
  };

  public primaryMonthlyDayOfMonthConfig: Select = {
    id: 'primaryMonthlyDayOfMonthSelect',
    placeholder: 'Select payment date',
    value: '',
    options: [
      {
        value: '31',
        label: 'Last day of month'
      }
    ],
    attributes: {
      'data-nid-target': 'monthlyFrequency'
    }
  };

  public primaryNetAmountConfig: CurrencyInput = {
    id: 'primaryNetAmountText',
    maxlength: 5,
    notDecimalAllow: true,
    digitSeparator: ',',
    leftAlign: true,
    attributes: {
      'data-nid-target': 'primaryNetAmount'
    }
  };

  private subscriptions: Subscription[] = [];

  constructor(form: PaymentFrequencyFormGroup, dropDown: SelectOption[]) {
    for (let i = 1; i < 29; i++) {
      this.primaryMonthlyDayOfMonthConfig.options.push({
        value: `${i}`,
        label: `${i}`
      });
    }

    const sub = form
      .get('primaryPaymentFrequency')
      .statusChanges.subscribe((c: string) => {
        this.primaryPaymentFrequencyConfig.disabled = `${c === 'DISABLED'}`;
      });

    this.primaryPaymentFrequencyConfig.options.push(...dropDown);
    this.primaryPaymentFrequencyConfig.visibleOptions = this.primaryPaymentFrequencyConfig.options.length;
    this.primaryPaymentFrequencyConfig.options.splice(
      this.primaryPaymentFrequencyConfig.options.findIndex(
        o => o.label === 'None'
      ),
      1
    );

    this.subscriptions.push(sub);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }
}
