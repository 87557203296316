import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const typedNameValidatorName = 'typedNameSignature';

export function typedNameValidator(
  firstName: string,
  lastName: string,
  text: string = 'Signature does not match',
  ignoreCase: boolean,
  ignoreSpaces: boolean
): ValidatorFn {
  const signatureRegex = new RegExp(
    ignoreSpaces
      ? `^[\\s]*${firstName}[\\s]*${lastName}[\\s]*$`
      : `^${firstName}[\\s]${lastName}$`,
    ignoreCase ? 'i' : ''
  );

  return (control: AbstractControl): ValidationErrors => {
    const errorModel = {
      [typedNameValidatorName]: {
        name: typedNameValidatorName,
        text
      }
    };

    return !signatureRegex.test(control.value) ? errorModel : null;
  };
}
