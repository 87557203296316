import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseModalConfig } from '@elevate/base-modal';
import { CmsService } from '@core/cms';

@Component({
  selector: 'app-inactivity-modal',
  templateUrl: './inactivity-modal.component.html',
  styleUrls: ['./inactivity-modal.component.scss']
})
export class InactivityModalComponent {
  @Input() public modalData: BaseModalConfig = {
    heading: null,
    content: null,
    action: {
      submitButton: null,
      onSubmit: () => this.continueSession(),
      allowClose: false
    }
  } as BaseModalConfig;

  public message: string;
  constructor(
    private activeModal: NgbActiveModal,
    private cmsService: CmsService
  ) {
    this.cmsService.getBulkEntries(['shared']).subscribe(data => {
      const modalContent = data[0].inactivityModal;
      this.modalData.content = modalContent.text;
      this.modalData.action.submitButton = modalContent.buttons.continue;
    });
  }

  public continueSession(): void {
    this.activeModal.close();
  }
}
