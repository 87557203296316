import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import {
  GoogleAnalytics,
  GoogleTagData,
  GoogleTagEvent
} from '../googleanalytics.service';

@Directive({
  selector: '[googleAnalyticsInputFocus]'
})
export class GoogleAnalyticsInputFocusDirective {
  @Input('googleAnalyticsInputFocus') public googleAnalyticsCategory: string;

  private hostElement: ElementRef;

  constructor(el: ElementRef, private googleAnalytics: GoogleAnalytics) {
    this.hostElement = el;
  }

  @HostListener('focus', ['$event.target.value'])
  public onFocus(): void {
    const googleAnalyticsData: GoogleTagData = {
      event_category: this.googleAnalyticsCategory,
      event_action: GoogleTagEvent.Click,
      event_name: this.hostElement.nativeElement.id
    };
  }
}
