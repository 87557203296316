<div class="checkbox" [formGroup]="formGroup">
  <div class="checkbox-signature">
    <ecl-checkbox
      class="checkbox-input"
      [formControlConfig]="checkboxConfig"
      [formControlName]="controlName"
      (change)="checkboxChange()"
    >
      <span [innerHtml]="label | safe: 'html'"></span>
    </ecl-checkbox>
  </div>
  <div
    *ngIf="instruction"
    class="checkbox-instruction"
    [innerHtml]="instruction | safe: 'html'"
  ></div>
</div>
