import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Environment } from '@environment/environment';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

/* istanbul ignore next */
@Injectable({ providedIn: 'root' })
export class FinicityApiService {
  constructor(private http: HttpClient, private environment: Environment) {}

  public getLinkURL(
    provider: string,
    lenderCode: string,
    clientName: string,
    linkCustomizationName: string,
    redirectUri: string
  ): Observable<string> {
    const request = {
      provider,
      lenderCode,
      clientName,
      linkCustomizationName,
      redirectUri
    };

    return this.http
      .post<string>(
        `${this.environment.brandApi.url}/api/v2/initialize`,
        request
      )
      .pipe(
        map((response: any) => response.linkUrl),
        take(1)
      );
  }
}
