<div class="underwrite-approval-page-container">
  <h2
    id="offerTextSection"
    [innerHtml]="subHeader | safeHtml"
    [ngClass]="{ 'counteroffer-subheader': isCounterOffer }"
  ></h2>

  <div
    id="counterOfferApprovedAmount"
    *ngIf="isCounterOffer"
    class="ecl-font-hero counteroffer-approved-amount ecl-font-color-text ecl-rise-color-text"
  >
    {{ formattedApprovalAmount }}
  </div>
  <div
    *ngIf="isCounterOffer"
    id="counterOfferSubTextId"
    [innerHtml]="counterOfferSubText"
  ></div>

  <section *ngIf="content.approvalSteps" class="approval-steps">
    <span class="ecl-font-body-medium approval-steps-header">
      {{ content.approvalSteps.header }}</span
    >
    <p [innerHTML]="content.approvalSteps.stepsText | safe: 'html'"></p>
  </section>
  <form *ngIf="!emailVerified" [formGroup]="form" (submit)="onSubmit()">
    <div class="married-content" *ngIf="isWisconsin">
      <p id="maritalHeader" class="ecl-font-body-bold">
        {{ content.maritalStatus.header | uppercase }}
      </p>
      <p id="maritalContent">
        {{ content.maritalStatus.content }}
      </p>
      <div class="email-input">
        <ecl-select
          [formControlConfig]="maritalStatusConfig"
          formControlName="maritalStatus"
          (change)="showAdditionalQuestions($event)"
        >
          {{ content.maritalStatus.maritalStatusSelect.label }}
        </ecl-select>
      </div>
      <app-married *ngIf="hideAdditionalQuestions" [formGroup]="form">
      </app-married>
    </div>
    <div [ngClass]="{ 'rise-class': brand === 'Rise' }">
      <div
        [ngClass]="
          isCounterOffer ? 'ecl-font-black-300' : 'ecl-font-color-text'
        "
        id="emailHeader"
      >
        {{ content.content.header }}
      </div>
      <p class="message-txt">
        {{ messageText }}
      </p>
    </div>
    <div class="email-input">
      <ecl-text-input
        id="confirmationCodeInput"
        [formControlConfig]="emailCodeConfig"
        formControlName="confirmationCode"
        googleAnalyticsFocusOut="approvalConfirmationCode"
      >
        {{ content.labels.confirmationCode }}
      </ecl-text-input>
    </div>
    <div class="ecl-button-hgroup ecl-wrap-reverse continue-button-container">
      <button
        focusOnError
        type="submit"
        id="approvalContinueButton"
        googleAnalyticsClick="Acceptance-Continue"
        class="ecl-button-primary"
      >
        {{ content.buttons.continue | uppercase }}
      </button>
      <button
        (click)="resendConfirmationCode()"
        type="button"
        id="approvalResendButton"
        class="ecl-button-secondary"
        googleAnalyticsClick="ResendConfirmationCode"
      >
        {{ content.buttons.resendCode | uppercase }}
      </button>
    </div>
  </form>
  <div *ngIf="emailVerified" class="continue-button-container">
    <button
      (click)="skipEmailVerification()"
      id="skipConfirmationContinueButton"
      class="ecl-button-primary"
      googleAnalyticsClick="SkipConfirmationContinueButton"
    >
      {{ content.buttons.skipEmailVerification | uppercase }}
    </button>
  </div>
</div>
<app-debug-hud>
  <button (click)="populateConfirmationCode()">
    Populate Confirmation Code
  </button>
  <button (click)="skipEmailVerification()">
    Skip Email Verification
  </button>
</app-debug-hud>
