import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Environment } from '@environment/environment';
import { catchError, Observable } from 'rxjs';
import { FinicityEventRequest } from '@core/finicity/finicity.types';
import { PlaidEventRequest } from '../../core/plaid/plaid.types';
export interface BankConnectionApiRequest {
  publicToken?: string;
  provider: string;
  selectedAccountId?: string;
  clientName?: string;
  applicationId: string;
  reportId?: string;
}
export interface SelectedAccountApiRequest {
  provider: string;
  clientName: string;
  selectedAccountId: string;
  reportId?: string;
  publicToken?: string;
}

export interface BankConnectionApiResponse {
  accounts: CheckingAccounts[];
  checkingAccountSelected: boolean;
  bankConnectionId: string;
  isErrored: boolean;
}

export interface CheckingAccounts {
  key: string;
  accountNumberLast4: string;
  accountId?: string;
}

export interface BankAccountsInfo {
  apiRequestOrigin: string;
  accounts: CheckingAccounts[];
}

export interface PlaidErrorEvent {
  code: string;
}

/* istanbul ignore next */
@Injectable({ providedIn: 'root' })
export class BankConnectionApi {
  constructor(private http: HttpClient, private environment: Environment) {}

  public postBankConnectResults(
    request: BankConnectionApiRequest
  ): Observable<HttpResponse<BankConnectionApiResponse>> {
    return this.http.post<BankConnectionApiResponse>(
      `${this.environment.brandApi.url}/api/v2/bank-connection`,
      request,
      {
        observe: 'response'
      }
    );
  }

  public selectedDraftBankAccount(
    request: SelectedAccountApiRequest,
    bankConnectionGuid: string
  ): Observable<HttpResponse<BankConnectionApiResponse>> {
    return this.http.put<BankConnectionApiResponse>(
      `${this.environment.brandApi.url}/api/v2/bank-connection/${bankConnectionGuid}/selected-account`,
      request,
      { observe: 'response' }
    );
  }

  public postPlaidErrorCode(event: PlaidErrorEvent): Observable<any> {
    return this.http.post<any>(
      `${this.environment.brandApi.url}/api/v2/bank-connection-failed`,
      event
    );
  }

  public postPlaidEvent(event: PlaidEventRequest): Observable<any> {
    return this.http.post<any>(
      `${this.environment.brandApi.url}/api/v2/bank-connection/ui/events`,
      event
    ).pipe(response => {
      return response;
    },
    catchError(error => {
      document.getElementsByTagName('body')[0].setAttribute('style','overflow: scroll;');
      throw error
    })
  );
  }
  public postFinicityEvent(event: FinicityEventRequest): Observable<any> {
    return this.http.post<any>(
      `${this.environment.brandApi.url}/api/v2/bank-connection/ui/events`,
      event
    );
  }
}
