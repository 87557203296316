import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TextInput } from '@elevate/ui-components';
import { PrimaryEmployer } from './employer';

import { EmployerFormGroup } from './employer.form';

@Component({
  selector: 'app-employer',
  templateUrl: './employer.component.html',
  styleUrls: ['../../../income.component.scss'],
  providers: [EmployerFormGroup]
})
export class EmployerComponent implements OnInit, OnDestroy {
  @Input() public formGroup: FormGroup;
  @Input() public CMSEmployer: any;

  public primaryEmployerConfig: TextInput = {
    id: 'primaryEmployer',
    placeholder: '',
    maxlength: 50,
    attributes: {
      'data-nid-target': 'primaryEmployer'
    }
  };
  public form: EmployerFormGroup;
  constructor() {}
  public get value(): PrimaryEmployer {
    return this.form.value;
  }

  public ngOnInit(): void {
    this.form = new EmployerFormGroup(this.CMSEmployer.validation);
    this.formGroup.addControl('employer', this.form);
  }

  public ngOnDestroy(): void {
    this.formGroup.removeControl('employer');
  }

  public patchValue(jobIncome: PrimaryEmployer): void {
    this.form?.patchValue(jobIncome);
  }

  public showValidationErrors(): void {
    this.form?.showValidationErrors();
  }
}
