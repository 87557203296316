import { OnDestroy, OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApplicationDataService } from '@application/application.service';
import { Checkbox, TextInput } from '@elevate/ui-components';

import { MarriedFormGroup, marriedFormGroupName } from './married.form';
import { ActivatedRoute } from '@angular/router';
import { UnderwritingApprovalPageContent } from '../underwriting-approval-content';

@Component({
  selector: 'app-married',
  templateUrl: './married.component.html',
  styleUrls: ['./married.component.scss'],
  providers: [MarriedFormGroup]
})
export class MarriedComponent implements OnInit, OnDestroy {
  @Input() public formGroup: FormGroup;
  public content: UnderwritingApprovalPageContent;
  public spousesFirstNameLabel: string;
  public spousesLastNameLabel: string;
  public spousesEmailLabel: string;
  public spousesEmailIsTheSameLabel: string;
  private applicantEmail: string;

  public spouseFirstnameConfig: TextInput = {
    id: 'firstNameTextInput',
    placeholder: '',
    minlength: 2,
    maxlength: 50,
    required: 'true',
    attributes: {
      'data-nid-target': 'spouseFirstName'
    }
  };

  public spouseLastnameConfig: TextInput = {
    id: 'lastNameTextInput',
    minlength: 2,
    maxlength: 50,
    required: 'true',
    attributes: {
      'data-nid-target': 'spouseLastName'
    }
  };

  public spouseEmailConfig: TextInput = {
    id: 'spouseEmailTextInput',
    type: 'email',
    maxlength: 50,
    required: 'true',
    attributes: {
      'data-nid-target': 'spouseEmail'
    }
  };

  public spouseEmailIsTheSameConfig: Checkbox = {
    id: 'spouseEmailIsTheSameCheckbox',
    disableLabel: true,
    attributes: {
      'data-nid-target': 'spouseSameEmailCheckbox'
    }
  };

  constructor(
    public form: MarriedFormGroup,
    private applicationDataService: ApplicationDataService,
    private route: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this.content = this.route.snapshot.data.cmsContent
      .approval as UnderwritingApprovalPageContent;
    this.spousesFirstNameLabel = this.content.maritalStatus.labels.spouseFirstName;
    this.spousesLastNameLabel = this.content.maritalStatus.labels.spouseLastName;
    this.spousesEmailLabel = this.content.maritalStatus.labels.spouseEmail;
    this.spousesEmailIsTheSameLabel = this.content.maritalStatus.labels.spouseEmailIsSame;
    this.form.buildForm(this.content.maritalStatus.validationMessages);
    const application = this.applicationDataService.getApplication();
    this.applicantEmail = application.form.applicant.emails[0].address;

    this.formGroup.addControl(marriedFormGroupName, this.form);
  }

  public ngOnDestroy(): void {
    this.formGroup.removeControl(marriedFormGroupName);
  }

  public checkboxChange(): void {
    if (this.form.value.spouseEmailIsTheSame) {
      this.form.patchValue({ spouseEmailAddress: this.applicantEmail });
      this.form.controls['spouseEmailAddress'].disable();
    } else {
      this.form.patchValue({ spouseEmailAddress: null });
      this.form.controls['spouseEmailAddress'].enable();
    }
  }
}
