<ecl-base-modal
  id="stateMessageModal"
  [modalConfig]="modalData"
  (closeModal)="
    googleAnalytics.clickModalEvent({
      action: 'Close',
      modal_name: this.htmlContent.header
    })
  "
  (openModal)="
    googleAnalytics.viewModalEvent({
      modal_name: this.htmlContent.header
    })
  "
>
  <div class="modal-container">
    <h1 id="stateMessageModalHeader" class="modal-container-header">
      {{ htmlContent.header }}
    </h1>
    <div id="stateMessageModalContent" class="modal-container-content">
      <p [innerHTML]="htmlContent.content"></p>
    </div>
    <div class="button-group">
      <button
        id="stateMessageModalButton"
        class="ecl-button-primary"
        (click)="close()"
      >
        {{ htmlContent.button }}
      </button>
    </div>
  </div>
</ecl-base-modal>
