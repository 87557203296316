<div id="finalPageCmsContent" class="success-text-container">
  <div id="contentText" [innerHTML]="content.subHeader"></div>

  <ng-container *ngIf="content?.postBookingLoanDetails">
    <article class="choose-your-terms-section-container row">
      <section
        class="loan-amount-section col-sm choose-your-terms-card-section"
      >
        <header
          id="loanAmountTitle"
          class="ecl-font-body-bold choose-your-terms-offer-section-title"
        >
          {{ content?.postBookingLoanDetails?.loanAmountTitle }}
        </header>
        <div class="loan-amount-input-container">
          <p class="ecl-font-body-bold apr-amount" id="loanAmount">
            {{
              acceptedTerms?.terms?.fundedAmount
                | currency: 'USD':'symbol':'1.0-0'
            }}
          </p>
          <p class="additional-info small" id="loanAmountInfo">
            {{
              content?.postBookingLoanDetails?.loanAmountAdditionalInformation
            }}
            <br />
            <span *ngIf="isOriginationFeeApplicable">{{
              content?.postBookingLoanDetails.originationFeeSubtext
            }}</span>
          </p>
        </div>
      </section>
      <section class="col-sm choose-your-terms-card-section">
        <header
          id="dateAvailableTitle"
          class="ecl-font-body-bold choose-your-terms-offer-section-title"
        >
          {{ content?.postBookingLoanDetails?.dateAvailable }}
        </header>
        <div>
          <p class="ecl-font-body-bold date-available" id="dateAvailable">
            {{ formatDate(acceptedTerms?.terms?.effectiveAt) }}
          </p>
          <p class="small additional-info" id="dateAvailableInfo">
            {{
              content?.postBookingLoanDetails
                ?.dateAvailableAdditionalInformation
            }}
          </p>
        </div>
      </section>
    </article>
    <div
      class="payment-info"
      [innerHTML]="content?.postBookingLoanDetails?.paymentInformation"
    ></div>
  </ng-container>

  <div class="final-page-continue-button-container">
    <button
      type="submit"
      (click)="goToLoginPage()"
      id="finalPageContinueButton"
      class="ecl-button-primary"
    >
      {{ content.buttons.continue | uppercase }}
    </button>
  </div>
</div>
