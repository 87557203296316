import { OnDestroy, OnInit } from '@angular/core';
import { Component, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IncomeSourceComponent } from '@application/income/income';
import { NumberInput, TextInput } from '@elevate/ui-components';

import { PaymentFrequencyComponent } from '../components/payment-frequency/payment-frequency.component';
import { Other } from './other';
import { OtherFormGroup } from './other.form';

@Component({
  selector: 'app-other',
  templateUrl: './other.component.html',
  styleUrls: ['../../income.component.scss'],
  providers: [OtherFormGroup]
})
export class OtherComponent
  implements IncomeSourceComponent, OnInit, OnDestroy {
  @Input() public formGroup: FormGroup;
  @Input() public CMSOther: any;
  @Input() public CMSPaymentFrequency: any;

  @ViewChild(PaymentFrequencyComponent)
  private paymentFrequency: PaymentFrequencyComponent;

  public form: OtherFormGroup;

  public primaryOtherIncomePhoneNumberConfig: TextInput = {
    id: 'primaryOtherIncomePhoneNumberText',
    placeholder: '',
    mask: '(000) 000-0000',
    attributes: {
      'data-nid-target': 'primaryOtherIncomePhoneNumber'
    }
  };

  public primaryOtherIncomePhoneNumberExtensionConfig: NumberInput = {
    id: 'primaryOtherIncomePhoneNumberExtensionText',
    placeholder: '',
    mask: 'XXXXX', 
    maxlength: 5,
    attributes: {
      'data-nid-target': 'primaryOtherIncomePhoneNumberExtension'
    },
    disableKeyboardArrows: true
  };

  constructor() {}

  public ngOnInit(): void {
    this.form = new OtherFormGroup(this.CMSOther);
    this.formGroup.addControl('otherContact', this.form);
  }

  public ngOnDestroy(): void {
    this.formGroup.removeControl('otherContact');
  }
  public get value(): Other {
    return {
      ...this.form?.value,
      ...this.paymentFrequency.value
    };
  }

  public patchValue(other: Other): void {
    this.form?.patchValue(other);
    this.paymentFrequency.patchValue(other);
  }

  public showValidationErrors(): void {
    this.form?.showValidationErrors();
    this.paymentFrequency.showValidationErrors();
  }
}
