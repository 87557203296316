import { Component, Input, ViewEncapsulation } from '@angular/core';
import { BaseModalConfig } from '@elevate/base-modal';
import { DefaultModal } from '../getting-started.content';

@Component({
  selector: 'app-temporary-lock-modal',
  templateUrl: './temporary-lock-modal.component.html',
  styleUrl: './temporary-lock-modal.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class TemporaryLockModalComponent {
  @Input() public htmlContent: DefaultModal;
  @Input() public modalData: BaseModalConfig = {
    useWrapperOnly: true,
    useWrapperEmpty: true,
    action: {
      allowClose: false
    }
  } as BaseModalConfig;
}
