import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const agreementPhraseValidatorName = 'typedNameSignature';

export function agreementPhraseValidator(
  phrase: string,
  text: string = 'Signature does not match',
  ignoreCase: boolean,
  ignoreSpaces: boolean
): ValidatorFn {
  const signatureRegex = new RegExp(
    ignoreSpaces
      ? `^[\\s]*${phrase.replace(/\s+/g, '[\\s]*')}[\\s]*$`
      : `^${phrase}$`,
    ignoreCase ? 'i' : ''
  );

  return (control: AbstractControl): ValidationErrors => {
    const errorModel = {
      [agreementPhraseValidatorName]: {
        name: agreementPhraseValidatorName,
        text
      }
    };

    return !signatureRegex.test(control.value) ? errorModel : null;
  };
}
