import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApplicationModule } from '@application/application.module';
import { GoogleAnalyticsModule } from '@core/google-analytics/googleanalytics.module';
import { EnvironmentModule } from '@environment/environment.module';
import { LayoutModule } from '@layout/layout.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { EclLayoutModule } from '@elevate/layout';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EclCobrowseModule } from '@elevate/cobrowse';
@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    EnvironmentModule,
    LayoutModule,
    ApplicationModule,
    NgbModule,
    EclLayoutModule,
    GoogleAnalyticsModule,
    EclCobrowseModule,
    NgIdleKeepaliveModule.forRoot()
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
