import { NumberInput } from '@angular/cdk/coercion';
import { DatePicker } from '@elevate/date-picker';
import {
  Autocomplete,
  CurrencyInput,
  RadioGroup,
  Select,
  Ssn,
  TextInput
} from '@elevate/ui-components';
import moment from 'moment';

export const firstnameConfig: TextInput = {
  id: 'firstName',
  placeholder: '',
  minlength: 2,
  maxlength: 50,
  required: 'true',
  attributes: {
    'data-nid-target': 'firstName'
  }
};

export const lastnameConfig: TextInput = {
  id: 'lastName',
  minlength: 2,
  maxlength: 50,
  required: 'true',
  attributes: {
    'data-nid-target': 'lastName'
  }
};

export const dobConfig: DatePicker = {
  id: 'dob',
  required: 'true',
  scheduledDate: 'false',
  minDate: moment()
    .subtract(100, 'y')
    .format('MM/DD/YYYY'),
  maxDate: moment()
    .subtract(18, 'y')
    .format('MM/DD/YYYY'),
  validationOnBlur: true,
  attributes: {
    'data-nid-target': 'dateOfBirth',
    'data-private': 'redact'
  },
  errorDateRange: 'Invalid Date'
};

export const ssnConfig: Ssn = {
  id: 'ssn',
  hiddenInput: 'true',
  showHide: 'false',
  required: 'true',
  validationOnBlur: true,
  attributes: {
    'data-private': 'redact',
    'data-nid-target': 'ssn'
  },
  addGenesysCobrowseMaskingClass: 'true'
};

export const addressConfig: Autocomplete = {
  id: 'address',
  type: 'text',
  required: 'true',
  maxlength: 50,
  attributes: {
    'data-nid-target': 'street'
  },
  options: [
    {
      id: '1',
      value: '123 Main St',
      label: '123 Main St, Dallas, TX'
    },
    {
      id: '2',
      value: '123 Elm St',
      label: '123 Elm St, Dallas, TX'
    },
    {
      id: '3',
      value: '123 Elm St',
      label: '123 Elm St, Houston, TX'
    },
    {
      id: '4',
      value: '1234 Commerce St',
      label: '1234 Commerce St, Dallas, TX'
    },
    {
      id: '5',
      value: '125 Oak St',
      label: '125 Oak St, Fort Worth, TX'
    },
    {
      id: '6',
      value: '1251 Maple',
      label: '1251 Maple, Houston, TX'
    },
    {
      id: '7',
      value: '1276 Lamar',
      label: '1276 Lamar, Austin, TX'
    },
    {
      id: '8',
      value: '1281 Lamar',
      label: '1281 Lamar, Austin, TX'
    }
  ]
};

export const suiteConfig: TextInput = {
  id: 'suite',
  maxlength: 30,
  attributes: {
    'data-nid-target': 'suiteApt'
  }
};


export const requestedAmount: CurrencyInput = {
  id: 'requestedAmount',
  maxlength: 3,
  notDecimalAllow: true,
  digitSeparator: ',',
  leftAlign: true,
  attributes: {
    'data-nid-target': 'requestedAmount'
  }
};

export const cityConfig: TextInput = {
  id: 'city',
  required: 'true',
  maxlength: 25,
  minlength: 2,
  attributes: {
    'data-nid-target': 'city'
  }
};

export const suffix: Select = {
  id: 'sufixInput',
  placeholder: 'Select Suffix',
  disabled: 'false',
  value: '',
  visibleOptions: 11,
  options: [
    { value: '', label: '' },
    { value: 'Jr.', label: 'Jr.' },
    { value: 'Sr.', label: 'Sr.' },
    { value: 'I', label: 'I' },
    { value: 'II', label: 'II' },
    { value: 'III', label: 'III' },
    { value: 'IV', label: 'IV' },
    { value: 'V', label: 'V' },
    { value: 'VI', label: 'VI' },
    { value: 'VII', label: 'VII' },
    { value: 'VIII', label: 'VIII' },
    { value: 'IX', label: 'IX' }
  ],
  attributes: {
    'data-nid-target': 'suffix'
  }
};

export const stateConfig: Select = {
  id: 'stateInput',
  placeholder: 'Select a State',
  disabled: 'false',
  value: '',
  visibleOptions: 8,
  options: [
    { value: 'AL', label: 'Alabama', disabled: false },
    { value: 'AK', label: 'Alaska', disabled: false },
    { value: 'AZ', label: 'Arizona', disabled: false },
    { value: 'AR', label: 'Arkansas', disabled: false },
    { value: 'CA', label: 'California', disabled: false },
    { value: 'CO', label: 'Colorado', disabled: false },
    { value: 'CT', label: 'Connecticut', disabled: false },
    { value: 'DC', label: 'District of Columbia', disabled: false },
    { value: 'DE', label: 'Delaware', disabled: false },
    { value: 'FL', label: 'Florida', disabled: false },
    { value: 'GA', label: 'Georgia', disabled: false },
    { value: 'HI', label: 'Hawaii', disabled: false },
    { value: 'ID', label: 'Idaho', disabled: false },
    { value: 'IL', label: 'Illinois', disabled: false },
    { value: 'IN', label: 'Indiana', disabled: false },
    { value: 'IA', label: 'Iowa', disabled: false },
    { value: 'KS', label: 'Kansas', disabled: false },
    { value: 'KY', label: 'Kentucky', disabled: false },
    { value: 'LA', label: 'Louisiana', disabled: false },
    { value: 'ME', label: 'Maine', disabled: false },
    { value: 'MD', label: 'Maryland', disabled: false },
    { value: 'MA', label: 'Massachusetts', disabled: false },
    { value: 'MI', label: 'Michigan', disabled: false },
    { value: 'MN', label: 'Minnesota', disabled: false },
    { value: 'MS', label: 'Mississippi', disabled: false },
    { value: 'MO', label: 'Missouri', disabled: false },
    { value: 'MT', label: 'Montana', disabled: false },
    { value: 'NE', label: 'Nebraska', disabled: false },
    { value: 'NV', label: 'Nevada', disabled: false },
    { value: 'NH', label: 'New Hampshire', disabled: false },
    { value: 'NJ', label: 'New Jersey', disabled: false },
    { value: 'NM', label: 'New Mexico', disabled: false },
    { value: 'NY', label: 'New York', disabled: false },
    { value: 'NC', label: 'North Carolina', disabled: false },
    { value: 'ND', label: 'North Dakota', disabled: false },
    { value: 'OH', label: 'Ohio', disabled: false },
    { value: 'OK', label: 'Oklahoma', disabled: false },
    { value: 'OR', label: 'Oregon', disabled: false },
    { value: 'PA', label: 'Pennsylvania', disabled: false },
    { value: 'RI', label: 'Rhode Island', disabled: false },
    { value: 'SC', label: 'South Carolina', disabled: false },
    { value: 'SD', label: 'South Dakota', disabled: false },
    { value: 'TN', label: 'Tennessee', disabled: false },
    { value: 'TX', label: 'Texas', disabled: false },
    { value: 'UT', label: 'Utah', disabled: false },
    { value: 'VT', label: 'Vermont', disabled: false },
    { value: 'VA', label: 'Virginia', disabled: false },
    { value: 'WA', label: 'Washington', disabled: false },
    { value: 'WV', label: 'West Virginia', disabled: false },
    { value: 'WI', label: 'Wisconsin', disabled: false },
    { value: 'WY', label: 'Wyoming', disabled: false }
  ],
  attributes: {
    'data-nid-target': 'state'
  }
};

export const zipConfig: TextInput = {
  id: 'zip',
  required: 'true',
  mask: '00000',
  attributes: {
    'data-nid-target': 'zipCode'
  }
};

export const mobileConfig: TextInput = {
  id: 'mobile',
  mask: '(000) 000-0000',
  required: 'true',
  attributes: {
    'data-nid-target': 'cellPhone'
  }
};

export const alternatePhoneConfig: TextInput = {
  id: 'alternatePhoneNumber',
  mask: '(000) 000-0000',
  required: 'true',
  attributes: {
    'data-nid-target': 'alternatePhoneNo'
  }
};

export const alternatePhoneRadioGroupConfig: RadioGroup = {
  direction: 'row',
  value: '',
  buttons: []
};

export const emailConfig: TextInput = {
  id: 'email',
  type: 'email',
  required: 'true',
  maxlength: 50,
  attributes: {
    'data-nid-target': 'email'
  }
};

export const mailingAddressCheckBox = {
  id: 'mailingAddressCheckbox',
  required: 'false',
  checked: 'true'
};

export const codeConfig: TextInput = {
  id: 'code',
  maxlength: 50,
  attributes: {
    'data-nid-target': 'invitationCode'
  }
};

export const cityMap = {
  1: { city: 'Dallas', state: 'TX' },
  2: { city: 'Dallas', state: 'TX' },
  3: { city: 'Houston', state: 'TX' },
  4: { city: 'Dallas', state: 'TX' },
  5: { city: 'Fort Worth', state: 'TX' },
  6: { city: 'Houston', state: 'TX' },
  7: { city: 'Austin', state: 'TX' },
  8: { city: 'Austin', state: 'TX' }
};
