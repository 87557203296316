import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';
import { PageFormGroup } from '@core/page-form-group/page-form-group';
import {
  dateRangeValidator,
  dateValidator,
  minLengthValidator,
  patternValidator,
  requiredCheckboxValidator,
  requiredValidator,
  ValidationMessagesError
} from '@elevate/forms';
import moment from 'moment-timezone/builds/moment-timezone-with-data-2012-2022';
import { Observable, of } from 'rxjs';

import { ValidationMessages } from './getting-started.content';
import { GettingStartedValidator } from './getting-started.validator';
import { Environment } from '@environment/environment';

@Injectable()
export class GettingStartedFormGroup extends PageFormGroup {
  public duplicateEmail: string;
  private validators: GettingStartedValidator;
  private environment: Environment;

  constructor(validators: GettingStartedValidator, environment: Environment) {
    super({});
    this.validators = validators;
    this.environment = environment;
  }

  public build(validationMessages: ValidationMessages): void {
    this.addControl(
      'firstname',
      new FormControl('', [
        requiredValidator(validationMessages.firstName.requiredMessage),
        minLengthValidator(2, validationMessages.firstName.lengthMessage),
        patternValidator(
          /^[a-zA-Z'\- ]+$/,
          validationMessages.firstName.patternMessage
        )
      ])
    );
    this.addControl(
      'lastname',
      new FormControl('', [
        requiredValidator(validationMessages.lastName.requiredMessage),
        minLengthValidator(2, validationMessages.lastName.lengthMessage),
        patternValidator(
          /^[a-zA-Z'\- ]+$/,
          validationMessages.lastName.patternMessage
        )
      ])
    );
    this.addControl('suffix', new FormControl('', []));
    this.addControl(
      'dob',
      new FormControl('', [
        requiredValidator(validationMessages.dateOfBirth.requiredMessage),
        dateValidator(validationMessages.dateOfBirth.patternMessage),
        dateRangeValidator(
          moment().subtract(100, 'y'),
          moment(),
          validationMessages.dateOfBirth.lengthMessage
        ),
        this.validators.dob.minAgeValidator()
      ])
    );
    this.addControl(
      'ssn',
      new FormControl('', [
        requiredValidator(validationMessages.ssn.requiredMessage),
        this.ssnPatternValidator(validationMessages.ssn.patternMessage)
      ])
    );
    this.addControl(
      'mobile',
      new FormControl('', [
        requiredValidator(validationMessages.mobilePhone.requiredMessage)
      ])
    );
    this.addControl(
      'email',
      new FormControl('', {
        asyncValidators: [
          (control: AbstractControl) =>
            this.invalidEmail(control, validationMessages.email.lengthMessage)
        ]
      })
    );

    this.addControl(
      'code',
      new FormControl('', [
        patternValidator(
          /^[0-9A-Za-z]+$/,
          validationMessages.enrollmentCode.patternMessage
        )
      ])
    );
  }

  public setEnrollmentCodeField(
    validationMessages: ValidationMessages,
    required: boolean
  ): void {
    this.get('code').clearValidators();

    if (required) {
      this.get('code').setValidators([
        requiredValidator(validationMessages.enrollmentCode.requiredMessage),
        patternValidator(
          /^[0-9A-Za-z]+$/,
          validationMessages.enrollmentCode.patternMessage
        )
      ]);
    } else {
      this.get('code').setValidators([
        patternValidator(
          /^[0-9A-Za-z]+$/,
          validationMessages.enrollmentCode.patternMessage
        )
      ]);
    }

    this.get('code').updateValueAndValidity();
  }

  private invalidEmail(
    control: AbstractControl,
    errorMessage: string
  ): Observable<ValidationMessagesError> {
    if (this.duplicateEmail && control.value === this.duplicateEmail) {
      return of(
        new ValidationMessagesError('duplicateEmail', null, errorMessage)
      );
    }
    return of(null);
  }

  public appendDisclosures(): void {
    this.addControl('consentToCommunications', new FormControl(false));
    this.addControl(
      'correctInformationConfirmation',
      new FormControl(null, [requiredCheckboxValidator('Required')])
    );
    this.addControl(
      'consentToElectronics',
      new FormControl(null, [requiredCheckboxValidator('Required')])
    );
    this.addControl(
      'creditReportDisclosure',
      new FormControl(null, [requiredCheckboxValidator('Required')])
    );
  }

  public getCalendarMaxDate(age?: number): string {
    return moment()
      .subtract(age ?? 18, 'y')
      .format('MM/DD/YYYY');
  }

  public getCalendarMinDate(): string {
    return moment()
      .subtract(100, 'y')
      .format('MM/DD/YYYY');
  }

  private ssnPatternValidator(message): ValidatorFn {
    let regex: RegExp;

    if (this.environment.production) {
      regex = /^(?!219099999|078051120)(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/;
    } else {
      regex = /^(?!219099999|078051120)(?!000)\d{3}(?!00)\d{2}(?!0{4})\d{4}$/;
    }

    return patternValidator(regex, message);
  }

  public addAlternatePhoneControls(): void {
    this.addControl('alternatePhoneNumber', new FormControl(''));
    this.addControl('alternatePhoneType', new FormControl(''));
  }
}
