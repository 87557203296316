import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DocumentService {
  constructor(
    @Inject('window') private window: Window,
    @Inject(DOCUMENT) private document: Document
  ) {}

  public download(fileBlob: Blob, filename: string): void {
    const fileUrl = URL.createObjectURL(fileBlob);
    const aTag = this.document.createElement('a');
    aTag.href = fileUrl;
    aTag.download = filename;
    this.document.body.appendChild(aTag);
    aTag.click();
    this.document.body.removeChild(aTag);
  }
}
