import { Component, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IncomeSourceComponent } from '@application/income/income';
import { PaymentFrequencyComponent } from '../components/payment-frequency/payment-frequency.component';
import { Unemployment } from './unemployment';

@Component({
  selector: 'app-unemployment',
  templateUrl: './unemployment.component.html',
  styleUrls: ['../../income.component.scss']
})
export class UnemploymentComponent implements IncomeSourceComponent {
  @Input() public formGroup: FormGroup;
  @Input() public CMSPaymentFrequency: any;

  @ViewChild(PaymentFrequencyComponent)
  private paymentFrequency: PaymentFrequencyComponent;

  public get value(): Unemployment {
    return {
      ...this.paymentFrequency.value
    };
  }

  public patchValue(unemployment: Unemployment): void {
    this.paymentFrequency.patchValue(unemployment);
  }

  public showValidationErrors(): void {
    this.paymentFrequency.showValidationErrors();
  }
}
