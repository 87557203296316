<form [formGroup]="form">
  <ecl-address
    [addressControls]="mailingAddressControls"
    [addressLabels]="addressLabels"
    [envConfig]="addressConfig"
    [idPrefix]="'mailingAddress-'"
    (focusout)="onFocusOut($event)"
    (onAddressError)="onAddressSuggestionError($event)"
  >
  </ecl-address>
</form>
