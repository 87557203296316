<div class="fund-container">
  <form class="radio-group" [formGroup]="form">
    <div
      *ngFor="let item of fundPaymentOptions"
      class="radio-block"
      (click)="selectValue(item.formControlConfig.value)"
    >
      <div class="radio-button-container">
        <ecl-radio-button
          [formControlConfig]="item.formControlConfig"
        ></ecl-radio-button>
      </div>
      <div class="radio-description" [innerHtml]="item.description"></div>
      <div
        class="radio-disclosure-container"
        *ngIf="form.value.paymentMethod === item.formControlConfig.value"
      >
        <hr class="funding-page-hr" />
        <div class="radio-disclosure" [innerHtml]="item.disclosure"></div>
      </div>
    </div>
    <ecl-validation-messages
      controlName="paymentMethod"
    ></ecl-validation-messages>
  </form>
  <app-consents
    *ngIf="consentsSection"
    [ngClass]="{ hidden: !displayConsentSection }"
    #consentsComponent
    [formGroup]="form"
    [consentSectionItems]="consentsSection"
  ></app-consents>
  <div class="ecl-button-hgroup ecl-wrap-reverse btn-container">
    <button
      focusOnError
      id="fundingPaymentContinueButton"
      type="submit"
      (click)="onSubmit()"
      class="ecl-button-primary"
      googleAnalyticsClick="ReceiveFunds-Continue"
    >
      {{ continueButton }}
    </button>
  </div>
</div>
<app-debug-hud *ngIf="formContainsConsents">
  <button (click)="debugHudSubmitConsents()">
    Submit Consents
  </button>
</app-debug-hud>
