import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { EventEmitter, Output } from '@angular/core';
import { CmsService } from '@core/cms';
import { ThirdPartyDisclaimer } from '@layout/models/third-party-disclaimer.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-thirdparty-disclaimer-modal',
  templateUrl: './thirdparty-disclaimer-modal.component.html',
  styleUrls: ['./thirdparty-disclaimer-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ThirdPartyDisclaimerModalComponent implements OnInit {
  @Input() public thirdPartyUrl: string;
  @Output() public continueButtonClicked = new EventEmitter<boolean>();
  public cmsContent: ThirdPartyDisclaimer;

  constructor(
    private activeModal: NgbActiveModal,
    private cmsService: CmsService,
  ) {}

  public async ngOnInit(): Promise<void> {
    const cmsData = await lastValueFrom(this.cmsService.getSharedCmsContent());
    this.cmsContent = cmsData.thirdPartDisclaimer;
  }

  public close(): void {
    this.activeModal.close();
  }

  public continue(): void {
    this.activeModal.close();
    if (this.thirdPartyUrl != null && this.thirdPartyUrl != '') {
      window.open(this.thirdPartyUrl, '_blank');
    }
    this.continueButtonClicked.emit(true);
  }
}
