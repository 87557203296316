import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
  BankConnectionApi,
  BankConnectionApiRequest,
  CheckingAccounts
} from '@application/connect-bank/connect-bank-api.service';
import { Radio } from '@elevate/ui-components';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CheckingAccountModalForm } from './checking-account-modal.form';
import { ApplicationForm } from '@application/application';
import { lastValueFrom } from 'rxjs';

export enum CheckingAccountModalResult {
  Success = 'sucess',
  Error = 'error',
  Close = 'close'
}
export interface CheckingAccountModalCmsContents {
  header: string;
  messages: {
    selectPrimaryAccount: string;
  };
}

@Component({
  selector: 'app-checking-account-modal',
  templateUrl: './checking-account-modal.component.html',
  styleUrls: ['./checking-account-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [CheckingAccountModalForm]
})
export class CheckingAccountModalComponent implements OnInit {
  @Input() public checkingAcctModalData: {
    bankConnectGuid?: string;
    bankconnectionReq?: BankConnectionApiRequest;
    availableAccounts: CheckingAccounts[];
    cmsData: CheckingAccountModalCmsContents;
  };

  public notExistingAccounts = false;
  public accountsSelectForm = false;
  public pageTitle: string;
  public warningMessage: string;
  public userHasAccountMessage: string;
  public userDoesNotHaveAccountsMessage: string;
  public bankConnectReq: BankConnectionApiRequest;
  public checkingAcctRadioFormConfig: Radio = {
    name: 'checkingAcctRadio',
    direction: 'column',
    value: '',
    buttons: []
  };
  public accountNos = {};

  constructor(
    private checkingAcctModal: NgbActiveModal,
    private form: CheckingAccountModalForm,
    private bankConnectionApi: BankConnectionApi
  ) {}

  public ngOnInit(): void {
    const availableAccounts = this.checkingAcctModalData.availableAccounts;
    const cmsData = this.checkingAcctModalData.cmsData;
    this.pageTitle = cmsData.header;
    this.userHasAccountMessage = cmsData.messages.selectPrimaryAccount;

    this.bankConnectReq = this.checkingAcctModalData.bankconnectionReq;
    availableAccounts.forEach((account: CheckingAccounts, index: number) => {
      this.checkingAcctRadioFormConfig.buttons.push({
        id: `checkingAccountOption${index}`,
        label: `Checking Account (•••••${account.accountNumberLast4})`,
        value: account.key
      });
      this.accountNos[account.key] = account.accountId;
    });

    this.accountsSelectForm = true;
  }

  public close(): void {
    this.checkingAcctModal.close({ result: CheckingAccountModalResult.Close });
  }

  public async submit(): Promise<void> {
    this.form.showValidationErrors();

    if (!this.form.valid) {
      return;
    }

    const selectedAcctRequest = {
      provider: this.bankConnectReq.provider,
      clientName: this.bankConnectReq.clientName,
      reportId: this.bankConnectReq.reportId || '',
      selectedAccountId: this.accountNos[this.form.get('draftFromKey').value],
      publicToken: this.bankConnectReq.publicToken || ''
    };
    try {
      await lastValueFrom(
        this.bankConnectionApi.selectedDraftBankAccount(
          selectedAcctRequest,
          this.checkingAcctModalData.bankConnectGuid
        )
      );

      this.checkingAcctModal.close({
        result: CheckingAccountModalResult.Success,
        selectedAccountPayload: this.createBankDataPayload()
      });
    } catch {
      this.checkingAcctModal.close({
        result: CheckingAccountModalResult.Error
      });
    }
  }

  private createBankDataPayload(): ApplicationForm {
    return {
      applicant: {
        bank: {
          draftFromKey: this.form.get('draftFromKey').value
        }
      }
    };
  }
}
