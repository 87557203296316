import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewChecked,
} from '@angular/core';
import { ApplicationDataService } from '@application/application.service';
import { PlaidService } from '@core/plaid/plaid.service';
import { HandleBankConnectionResponseService } from '../../core/connect-bank/handle-bank-connection-response.service';
import { Subscription } from 'rxjs';
import { Accordion, AccordionGroup } from '@elevate/ui-components';
import { CmsPageContentService } from '@core/cms/services/cms-page-content.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PrequalificationConnectBankMoreInformationContent } from './prequalification-connect-bank-more-information-content';
import { BankDataProviders } from '@application/application';
import { AccessibilityService } from '@core/accessibility/accessibility.service';
import { FinicityService } from '@core/finicity/finicity.service';
import { BankConnectSuccessObject } from '@core/connect-bank/bank-connect.types';
import { Environment } from '@environment/environment';

@Component({
  selector: 'app-connect-bank-more-information',
  templateUrl: './connect-bank-more-information.component.html',
  styleUrls: ['./connect-bank-more-information.component.scss'],
  providers: [HandleBankConnectionResponseService]
})
export class ConnectBankMoreInformationComponent
  implements OnInit, OnDestroy, AfterViewChecked {
  public plaidSuccessSubscription: Subscription;
  public finicitySuccessSubscription: Subscription;
  public groupAccordionConfig: AccordionGroup;
  public content: PrequalificationConnectBankMoreInformationContent;
  public disclosureContent: Accordion[];
  public isFinicity: boolean;

  constructor(
    private applicationDataService: ApplicationDataService,
    private cmsPageContentService: CmsPageContentService,
    private plaidService: PlaidService,
    public handleBankConnectionResponseService: HandleBankConnectionResponseService,
    private route: ActivatedRoute,
    private adaAccessibility: AccessibilityService,
    public finicityService: FinicityService,
    private environment: Environment
  ) {}

  public ngOnInit(): void {
    const application = this.applicationDataService.getApplication();

    this.content = this.route.snapshot.data.cmsContent.prequalificationConnectBankMoreInfo;

    this.isFinicity =
      application.product.bankDataProvider.toUpperCase() ===
      BankDataProviders.Finicity
        ? true
        : false;

    const bankDataProvider = this.route.snapshot.data.cmsContent
      .prequalificationConnectBankMoreInfo.dynamicDisclosures.bankDataProvider;

    this.isFinicity
      ? (this.disclosureContent = bankDataProvider.finicity.disclosures)
      : (this.disclosureContent = bankDataProvider.plaid.disclosures);

    const firstName = application.form.applicant.identity.firstName;

    this.cmsPageContentService.updatePageTitle(this.getPageTitle(firstName));

    this.groupAccordionConfig = this.getGroupAccordionConfig();

    if (this.isFinicity) {
      this.finicitySuccessSubscription = this.finicityService
        .successSubscription()
        .subscribe((successObject: BankConnectSuccessObject) => {
          this.handleBankConnectionResponseService.callBankConnection(
            successObject,
            this.environment.applicationFlowSettings.isFundingPaymentSelectionEnabled ?
            '/funding-payment-selection':
            '/income',            
            'PrequalNMI-Continue-Success',
            null,
            this.content.checkingAccountModal
          );
        });
    } else {
      this.plaidService.initializePlaid();

      this.plaidSuccessSubscription = this.plaidService
        .successSubscription()
        .subscribe(async (successObject: BankConnectSuccessObject) => {
          this.handleBankConnectionResponseService.callBankConnection(
            successObject,
            this.environment.applicationFlowSettings.isFundingPaymentSelectionEnabled ?
            '/funding-payment-selection':
            '/income',
            'PrequalNMI-Continue-Success',
            this.content.accountTypeModal,
            null
          );
        });
    }
    this.adaAccessibility.removeUnAllowedAriaAttributes();
  }

  public ngAfterViewChecked(): void {
    this.adaAccessibility.removeUnAllowedAriaAttributes();
  }

  public ngOnDestroy(): void {
    if (this.isFinicity) {
      this.finicitySuccessSubscription.unsubscribe();
      this.finicityService.destroy();
    } else {
      this.plaidSuccessSubscription.unsubscribe();
      this.plaidService.destroy();
    }
  }

  public continue(): void {
    if (this.isFinicity) {
      this.finicityService.open();
    } else {
      this.plaidService.open();
    }
  }

  private getPageTitle(firstName: string): string {
    return this.content.header.replace('{firstName}', firstName);
  }

  private getGroupAccordionConfig(): AccordionGroup {
    const whyDoWeNeedThisInformationDisclosure = {
      id: this.disclosureContent[0].id,
      head: this.disclosureContent[0].head,
      body: this.disclosureContent[0].body,
      expanded: false
    };

    const whatHappensWhenIConnectDisclosure = {
      id: this.disclosureContent[1].id,
      head: this.disclosureContent[1].head,
      body: this.disclosureContent[1].body,
      expanded: false
    };

    const whatHappensIfICannotLogInDisclosure = {
      id: this.disclosureContent[2].id,
      head: this.disclosureContent[2].head,
      body: this.disclosureContent[2].body,
      expanded: false
    };

    return {
      id: 'connectBankMoreInfoAccordionGroup',
      autoclose: true,
      spacing: 10,
      items: [
        { ...whyDoWeNeedThisInformationDisclosure },
        { ...whatHappensWhenIConnectDisclosure },
        { ...whatHappensIfICannotLogInDisclosure }
      ]
    };
  }
}
