import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  StartOption,
  StartOptionsResponse
} from '@application/application.api';

import { GettingStarted } from '../getting-started';
import { GettingStartedFormGroup } from '../getting-started.form';
import { Environment } from '@environment/environment';

/* istanbul ignore next */
@Injectable()
export class GettingStartedDebugService {
  public disableReCaptcha: boolean;
  public mockStartOption = 'Call Api';

  constructor(
    private form: GettingStartedFormGroup, 
    private router: Router,
    private environment: Environment) {}

  public simulateData(): void {
    const randomSocialSecurityNumber = this.getRandomSocialSecurityNumber();
    const randomCharacterString = this.getRandomLowerCaseString(5);
    
    let firstname = `TEST${randomCharacterString}`;
    let lastName = `AUTO${randomCharacterString}`;
    let edomain = "blueprintcash";

    if(this.environment?.brandId == "2200"){
      firstname = "Alberta";
      lastName = "Charleson";
      edomain = "risecash";
    }

    if(this.environment?.brandId == "1400"){
      firstname = "John";
      lastName = "Doe";
      edomain = "elasticcash"
    }

    let state = (this.environment?.brandId == "2200") ? "MS": "TX";
    let postalCode = (this.environment?.brandId == "2200") ? "39201": "75001";
    let city = (this.environment?.brandId == "2200") ? "Jackson": "Addison";;

    const value: GettingStarted = {
      firstname: firstname,
      lastname: lastName,
      suffix: '',
      dob: '01/01/1990',
      ssn: randomSocialSecurityNumber,
      addressLine1: '123 Main St.',
      mailingAddress_addressLine1: '124 Main St.',
      addressLine2: '',
      mailingAddress_addressLine2: '',
      city: city,
      mailingAddress_city: city,
      state: state,
      mailingAddress_state: state,
      postalCode: postalCode,
      mailingAddress_postalCode: postalCode,
      email: `test_${randomSocialSecurityNumber}@${edomain}.net`,
      mobile: this.environment.mockOtpPhoneNumber.toString(),
      code: 'CODESAMPLE',
      alternatePhoneNumber: '9728151395',
      alternatePhoneType: 'Business'
    };

    this.form.patchValue(value);
  }

  public toggleReCaptcha(): void {
    this.disableReCaptcha = !this.disableReCaptcha;
  }

  public selectMockStartOptionResponse(value: string): void {
    this.mockStartOption = value;
  }

  public getMockApiResponse(): StartOptionsResponse {
    return {
      applicationId: '',
      customerId: 0,
      customerIdSignature: '',
      phoneNumberLast4: this.environment.mockOtpPhoneNumber.substring(
        this.environment.mockOtpPhoneNumber.length - 4),
      startOptions: (this.mockStartOption as unknown) as StartOption
    };
  }

  public navigateReapplyPage(): void {
    this.router.navigate(['/reapply'], { state: { mock: true } });
  }

  private getRandomSocialSecurityNumber(): string {
    const prefix = Math.floor(900 + Math.random() * 70).toString();
    const subset = Math.floor(1000 + Math.random() * 9000).toString();
    return `${prefix}${subset}55`;
  }

  private getRandomLowerCaseString(length: number): string {
    const aCode = 'a'.charCodeAt(0);
    const zCode = 'z'.charCodeAt(0);
    let randomString = '';
    for (let i = 0; i < length; i++) {
      const randomCharacterCode =
        Math.floor(Math.random() * (zCode - aCode + 1)) + aCode;
      randomString += String.fromCharCode(randomCharacterCode);
    }
    return randomString;
  }
}
