<div class="additional-info-bank-container">
  <ecl-notification-box
    type="error"
    id="bankConnectionErrorMessage"
    *ngIf="
      handleBankConnectionResponseService.bankConnectionError && !isFinicity
    "
  >
    {{ cmsContent.messages.plaidError }}
  </ecl-notification-box>
  <ecl-notification-box
    type="error"
    id="bankConnectionErrorMessageFinicity"
    *ngIf="
      handleBankConnectionResponseService.bankConnectionError && isFinicity
    "
  >
    {{ cmsContent.messages.finicityError }}
  </ecl-notification-box>

  <ecl-notification-box
    type="error"
    id="accountNotPermittedErrorMessage"
    *ngIf="handleBankConnectionResponseService.accountNotPermittedError"
  >
    {{ cmsContent.messages.checkingAccountError }}
  </ecl-notification-box>

  <ecl-notification-box
    type="error"
    id="failedServerCallErrorMessage"
    *ngIf="
      handleBankConnectionResponseService.failedServerCallError ||
      finicityService.failedIntializeCall
    "
  >
    We apologize for the inconvenience but we have encountered a system error.
    Please contact us at 888-225-0080 if you need immediate assistance, or
    please try again.
  </ecl-notification-box>

  <div class="page-subtitle ecl-font-section-header-xsmall display-heading-xs">
    {{ cmsContent.subHeader }}
  </div>

  <div
    id="additionalInfoBankContent"
    [innerHTML]="cmsContent.content | safe: 'html'"
  ></div>

  <hr class="page-standard-hr" />

  <ecl-accordion-group
    [config]="groupAccordionConfig"
    (expandAccordion)="expand($event)"
    (collapseAccordion)="collapse($event)"
  ></ecl-accordion-group>

  <div class="continue-button-container">
    <button
      id="additionalInfoBankContinueButton"
      type="button"
      class="ecl-button-primary"
      (click)="continue()"
      googleAnalyticsClick="AdditionalInfoBankContinue"
    >
      {{ cmsContent.buttons.continue }}
    </button>
  </div>
</div>
