<div *ngIf="CMSOther.isphoneenabled" class="row" [formGroup]="form">
  <ecl-phone-number
    class="col-md-8 income-phone-number"
    formControlName="primaryPhoneNumber"
    [formControlConfig]="primaryOtherIncomePhoneNumberConfig"
    googleAnalyticsFocusOut="primaryOtherIncomePhoneNumber"
  >
    {{ CMSOther.contactPhoneLabel }}
  </ecl-phone-number>

  <ecl-number
    class="col-md-8"
    formControlName="primaryPhoneNumberExtension"
    [formControlConfig]="primaryOtherIncomePhoneNumberExtensionConfig"
    withStringOutput="true"
    (paste)="(false)"
    googleAnalyticsFocusOut="primaryOtherIncomePhoneNumberExtension"
  >
    {{ CMSOther.contactExtensionLabel }}
  </ecl-number>
</div>

<app-payment-frequency
  [formGroup]="form"
  [CMSPaymentFrequency]="CMSPaymentFrequency"
></app-payment-frequency>
