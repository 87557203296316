import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IncomeSourceComponent } from '@application/income/income';

import { PrimaryPaymentFrequencyValue } from '../components/payment-frequency/payment-frequency';
import { PaymentFrequencyComponent } from '../components/payment-frequency/payment-frequency.component';
import { SocialSecurity } from './social-security';

@Component({
  selector: 'app-social-security',
  templateUrl: './social-security.component.html',
  styleUrls: ['../../income.component.scss']
})
export class SocialSecurityComponent
  implements IncomeSourceComponent, AfterViewInit {
  @Input() public formGroup: FormGroup;
  @Input() public CMSPaymentFrequency: any;

  @ViewChild(PaymentFrequencyComponent)
  private paymentFrequency: PaymentFrequencyComponent;

  public ngAfterViewInit(): void {
    this.paymentFrequency.patchValue({
      primaryPaymentFrequency: PrimaryPaymentFrequencyValue.Monthly
    });

    this.paymentFrequency.form.get('primaryPaymentFrequency').disable();
  }

  public get value(): SocialSecurity {
    return {
      ...this.paymentFrequency.value
    };
  }

  public patchValue(socialSecurity: SocialSecurity): void {
    this.paymentFrequency.patchValue(socialSecurity);
  }

  public showValidationErrors(): void {
    this.paymentFrequency.showValidationErrors();
  }
}
