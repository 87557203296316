import { ConsentModalAgreement } from './e-signature/signature.content';
export interface ConsentModalContent {
  disclosureText?: string;
  agreement?: ConsentModalAgreement;
  fundingMethod?: string[];
}
export interface ConsentModalDocumentContent {
  documentType: string;
  documentId?: string;
  documentText: string;
}
export interface ConsentModal {
  header: string;
  key: string;
  labels: {
    buttonLabel: string;
    cancelButtonLabel?: string;
    savePrintLabel: string;
    scrollDownLabel: string;
    scrollDownLinkLabel: string;
  };
  isScrollDownMandatory: boolean;
  isScrollLinkVisible: boolean;
  checkboxChange: boolean;
  isPrintLinkVisible: boolean;
  documentContent: ConsentModalDocumentContent;
  content: ConsentModalContent[];
  iscontenthtml: boolean;
  date: {
    dateTitle: string;
    isDateVisible: boolean;
    outOfScroll?: boolean;
  };
}
export interface Consent {
  key: string;
  isIndependentDocument: boolean;
  title: string;
  text: string;
  isRequired: boolean;
  isMandatoryToOpen: boolean;
  footer: string;
  disclosureModals: ConsentModal[];
  nonlinktext?: string;
}
export interface DistinctConsent {
  states?: string;
  lender?: string;
  consent: Consent;
}
export interface ConsentMapping {
  title: string;
  defaultConsent: Consent;
  statesConsents: DistinctConsent[];
}
export interface ConsentSectionItem {
  consent: ConsentMapping;
}

export const Lender = {
  ELVT : 'RISE',
  CCB : 'CCB',
  FNWS : 'FinWise'
}
