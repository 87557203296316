<div class="typed-name" [formGroup]="formGroup">
  <div class="typed-name-signature">
    <ecl-text-input
      class="typed-name-input"
      [formControlConfig]="typedNameConfig"
      [formControlName]="controlName"
      googleAnalyticsFocusOut="typedNameSignature"
    >
      <span [innerHtml]="label | safe: 'html'"></span>
    </ecl-text-input>
    <div class="typed-name-check-mark">
      <i *ngIf="isValid" class="fa fa-check" aria-hidden="true"></i>
    </div>
  </div>
  <div
    *ngIf="instruction"
    class="typed-name-instruction"
    [innerHtml]="instruction | safe: 'html'"
  ></div>
</div>
