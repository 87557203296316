import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { ApplicationData, Disclosure } from '@application/application';
import { ApplicationDataService } from '@application/application.service';
import { requiredCheckboxValidator } from '@elevate/forms';
import { Checkbox } from '@elevate/ui-components';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConsentHelper } from './consent.helper';
import {
  Consent,
  ConsentModal,
  ConsentSectionItem,
  ConsentMapping,
  ConsentModalDocumentContent,
  ConsentModalContent,
  Lender as lender
} from './consents.content';
import { DisclosureModalComponent } from './disclosure-modal/disclosure-modal.component';
import {
  ConsentModalAgreement,
  DisclosureModalEmitDataOnSubmit
} from './e-signature/signature.content';
import { SignatureType } from './e-signature/signature.content';
import { SignatureHelper } from './e-signature/signature.content';
import { SignatureModel } from './e-signature/signature.content';
import {
  GoogleAnalytics,
  GoogleTagData
} from '@core/google-analytics/googleanalytics.service';
import {
  DisclosureTypes,
  DocumentApi,
  DocumentTypeGenerated
} from '@core/document/document.api';
import { Subscription, forkJoin } from 'rxjs';
import { Environment } from '@environment/environment';
import { LoadingModalService } from '@application/loading-modal/loading-modal.service';
import { Router } from '@angular/router';
import { AppInsightsService } from '@core/app-insights/app-insights.service';
import {
  ConfigurationService,
  PlatformConfigFeatures
} from '@core/configuration/configuration.service';
@Component({
  selector: 'app-consents',
  templateUrl: './consents.component.html',
  styleUrls: ['./consents.component.scss']
})
export class ConsentsComponent implements AfterViewInit, OnInit, OnDestroy {
  private consentsSignatures: Map<ConsentModal, SignatureModel[]> = new Map();
  private htmlDocumentCallData: DocumentTypeGenerated;
  @ViewChild('consentContainer') private consentContainer: ElementRef;
  @Input() public consentSectionItems: ConsentSectionItem[];
  @Input() public formGroup: FormGroup;
  @Input() public hideDividingLine = false;
  @Input() public containerName = null;
  @Input() set selectedState(value: string) {
    this.updateState(value);
  }
  @Output() public get disclosures(): Disclosure[] {
    return this.getConsentsResultModel();
  }
  public currentState: string;
  public consentGroup: Consent[];
  private inited = false;
  public application: ApplicationData;
  public ngbModalSubscription: Subscription;
  public lenderCode: String;

  constructor(
    private modalService: NgbModal,
    private loadingModalService: LoadingModalService,
    private appDataService: ApplicationDataService,
    private router: Router,
    private appinsightsService: AppInsightsService,
    public googleAnalytics: GoogleAnalytics,
    private documentApi: DocumentApi,
    public environment: Environment,
    public configurationService: ConfigurationService
  ) {
    this.application = this.appDataService.getApplication();
  }

  public ngOnInit(): void {
    this.consentGroup = [];
    this.initFormControls();
  }

  public ngAfterViewInit(): void {
    this.initConsentModals();
  }

  private initFormControls(): void {
    if (!this.formGroup) {
      this.formGroup = new FormGroup({});
    }
    if (!this.currentState) {
      this.currentState = this.application.form?.applicant?.residences?.find(
        residence => residence.type === 'Current'
      )?.address?.stateCode;
    }
    this.lenderCode = this.application?.product?.lenderCode;
    this.consentSectionItems = !this.consentSectionItems
      ? []
      : !Array.isArray(this.consentSectionItems)
      ? [this.consentSectionItems]
      : this.consentSectionItems;
    this.consentSectionItems.forEach(consent => {
      if (consent.consent) {
        const distinctConsent = this.getConsent(consent.consent);
        this.consentGroup.push(distinctConsent);
        if (distinctConsent) {
          this.formatConsentArrays(distinctConsent);
          const consentControlName = this.getConsentControlName(
            distinctConsent
          );
          this.addConsentControl(distinctConsent, consentControlName);
        }
      }
    });
    this.inited = true;
  }

  public get formContainsConsents(): boolean {
    return ConsentHelper.formContainsConsents(this.consentGroup);
  }

  public isConsentNeeded(consentSectionItem: ConsentSectionItem): boolean {
    return (
      !!consentSectionItem.consent &&
      !!this.getConsent(consentSectionItem.consent)
    );
  }

  public getConsentContainerId(): string {
    return this.containerName != null
      ? `consentsSection_${this.containerName}`
      : 'consentsSection';
  }

  public getConsentControlByName(consentControlName: string): AbstractControl {
    return this.formGroup?.get(consentControlName);
  }

  public getConsentControl(consent: Consent): AbstractControl {
    return this.getConsentControlByName(this.getConsentControlName(consent));
  }

  public getAgreementControl(
    consent: Consent,
    stateSpecificConsent: ConsentMapping
  ): AbstractControl {
    return this.getConsentControlByName(this.getConsentControlName(consent));
  }

  public getConsent(consentMapping: ConsentMapping): Consent {
    if (!consentMapping?.statesConsents) {
      return consentMapping.defaultConsent;
    }
    // If ContentStack Multiple Group has only one instance - it's loaded as Object but not Array
    if (!Array.isArray(consentMapping.statesConsents)) {
      consentMapping.statesConsents = [consentMapping.statesConsents];
    }
    const distinctConsent = consentMapping.statesConsents?.filter(consent => {
      if (consent?.states && consent?.lender) {
        return (
          consent.states.includes(this.currentState) &&
          this.lenderCode === consent.lender
        );
      } else if (consent?.states) {
        return consent.states.includes(this.currentState);
      } else {
        return consent?.lender === this.lenderCode;
      }
    });

    if (distinctConsent?.length > 0) {
      return distinctConsent[0].consent;
    }
    return consentMapping.defaultConsent;
  }

  public getConsentControlConfig(
    stateSpecificConsent: ConsentMapping
  ): Checkbox {
    const consent = this.getConsent(stateSpecificConsent);
    if (consent == null) {
      return null;
    }
    return {
      id: consent.key,
      required: String(consent.isRequired),
      attributes: {
        'data-nid-target': consent.key
      }
    } as Checkbox;
  }

  public getConsentControlName(stateSpecificConsent: Consent): string {
    return ConsentHelper.getConsentControlName(stateSpecificConsent);
  }

  public getConsentEclCheckboxId(consent: Consent): string {
    return this.getConsentControlName(consent) + 'EclCheckbox';
  }

  public isConsentLabelLink(stateSpecificConsent: ConsentMapping): boolean {
    const consent = this.getConsent(stateSpecificConsent);
    if (this.isConsentHasModal(consent) && this.isConsentHasOneModal(consent)) {
      return true;
    }
    return false;
  }

  public onCheckboxChange(stateSpecificConsent: ConsentMapping): void {
    const consent = this.getConsent(stateSpecificConsent);
    var data: GoogleTagData = {
      step_name: window.location.pathname,
      field_id: consent.key
    };
    this.googleAnalytics.formInteractionEvent(data);
    if (!consent?.disclosureModals) {
      return;
    }
    const checkboxControl = this.getConsentControl(consent);
    if (consent.isMandatoryToOpen && checkboxControl.value === true) {
      checkboxControl.setValue(false);
      this.openModal(consent, consent.key);
    }
  }

  public isConsentHasOneModal(consent: Consent): boolean {
    return consent?.disclosureModals.length === 1;
  }

  public isConsentHasModal(consent: Consent): boolean {
    return (
      consent !== undefined &&
      (Array.isArray(consent?.disclosureModals)
        ? consent.disclosureModals.length > 0
        : consent.disclosureModals != null)
    );
  }

  private initConsentModals(): void {
    const getDisclosuresCallArray = [];

    this.consentContainer.nativeElement
      .querySelectorAll('A')
      .forEach(element => {
        element.classList.add('ecl-font-link-light-bg');
        let documentName = element.href.slice(
          element.href.lastIndexOf('/') + 1
        );
        if (!element.href.includes('%7Benvironment%7D')) {
          element.href = documentName;
        } else {
          element.href = `${this.environment.bau.url}/${documentName}`;

          if (element.href.includes('{lender}')) {
            element.href = element.href.replaceAll(
              '{lender}',
              lender[`${this.lenderCode}`]
            );
          }
          if (element.href.includes('{statecode}')) {
            element.href = element.href.replaceAll(
              '{statecode}',
              this.currentState
            );
          }
        }
      });

    this.consentSectionItems
      .filter(
        item =>
          this.isConsentNeeded(item) &&
          this.isConsentHasModal(this.getConsent(item.consent))
      )
      .forEach(item => {
        const consent = this.getConsent(item.consent);
        consent.disclosureModals.forEach(async modal => {
          const selector = `[href="${modal.key}"]`;
          const element = this.consentContainer.nativeElement.querySelector(
            selector
          );

          if (!element) {
            console.error(
              `checkbox disclosure element was not found with selector ${selector} review cms to restore open functionality`
            );
          }

          if (modal.iscontenthtml) {
            getDisclosuresCallArray.push(modal);
          }

          if (element) {
            const openModal = function(): boolean {
              this.openModal(consent, modal.key);
              return false;
            };
            element.onclick = openModal.bind(this);
          }
        });
      });

    if (getDisclosuresCallArray.length > 0) {
      this.getAllHtmlDocuments(getDisclosuresCallArray);
    }
  }

  public openModal(
    consent: Consent,
    modalKey?: string
  ): DisclosureModalComponent {
    const consentModal = this.getConsentModal(consent, modalKey);

    this.googleAnalytics.viewModalEvent({
      modal_name:
        consentModal.header == '' ? consentModal.key : consentModal.header,
      link_text: consentModal.header
    });
    if (!consentModal) {
      return;
    }

    const modalRef = this.modalService.open(DisclosureModalComponent, {
      windowClass: 'ng-disclosure-modal',
      ariaLabelledBy: 'Disclosure Modal'
    });
    const inputSignatures = this.getDisclosureModalSignatures(
      consent,
      consentModal
    );
    const disclosureModal = modalRef.componentInstance as DisclosureModalComponent;
    this.ngbModalSubscription = modalRef.shown.subscribe(() => {
      document
        .getElementsByTagName('ngb-modal-window')[0]
        .setAttribute('aria-label', 'Disclosure Modal');
    });
    disclosureModal.consentModal = consentModal;
    disclosureModal.inputSignatures = inputSignatures;
    disclosureModal.submitClick.subscribe(
      (emitData: DisclosureModalEmitDataOnSubmit) => {
        if (emitData.signatureModel && emitData.signatureModel.length > 0) {
          if (consentModal.iscontenthtml) {
            emitData.signatureModel.map(sig =>
              Object.assign(sig, sig, {
                documentId: consentModal.documentContent?.documentId
              })
            );
          }
          this.consentsSignatures.set(consentModal, emitData.signatureModel);
        } else if (modalKey === 'KnowBeforeYouBorrow') {
        }
        const checkboxControl = this.getConsentControl(consent);
        if (consentModal.checkboxChange) {
          checkboxControl.setValue(true);
        }
      }
    );
    return disclosureModal;
  }

  private formatConsentArrays(consent: Consent): void {
    if (consent.disclosureModals === undefined) {
      return;
    }
    if (
      consent.disclosureModals != null &&
      !Array.isArray(consent.disclosureModals)
    ) {
      consent.disclosureModals = [consent.disclosureModals];
    }
    consent.disclosureModals.forEach(modal => {
      if (modal.content != null && !Array.isArray(modal.content)) {
        modal.content = [modal.content];
      }
    });
  }

  private addConsentControl(
    stateConsent: Consent,
    consentControlName: string
  ): void {
    const controlValidators = [];
    if (stateConsent.isRequired) {
      controlValidators.push(requiredCheckboxValidator('Required'));
    }
    const alreadySignedDisclosures = this.appDataService.getConsentDisclosures(
      stateConsent
    );
    const isConsentSigned =
      alreadySignedDisclosures?.length > 0 &&
      alreadySignedDisclosures.every(disc => disc.consentGiven);
    this.formGroup.addControl(
      consentControlName,
      new FormControl(isConsentSigned, controlValidators)
    );
  }

  private updateState(state: string): void {
    if (this.inited && this.currentState !== state) {
      this.currentState = state;
      this.consentSectionItems.forEach(item => {
        if (item.consent) {
          const consentByState = this.getConsent(item.consent);
          const controlName = this.getConsentControlName(consentByState);
          if (consentByState && !this.formGroup.get(controlName)) {
            this.formatConsentArrays(consentByState);
            this.addConsentControl(consentByState, controlName);
          } else if (!consentByState) {
            this.formGroup.removeControl(controlName);
          }
        }
      });
    } else {
      this.currentState = state;
    }
  }

  private async getAllHtmlDocuments(modalArray: ConsentModal[]): Promise<void> {
    const obserableArray = modalArray.map(modal =>
      this.documentApi.getHtmlDocument(modal.key)
    );
    const observableConfigFeature = this.configurationService.getConfigFeature(
      PlatformConfigFeatures.Lender
    );

    this.loadingModalService.open();

    forkJoin([...obserableArray, observableConfigFeature]).subscribe({
      next: (allDocumentResponse: any[]) => {
        const configFeatureResponse = allDocumentResponse.pop();
        const documentData: ConsentModalDocumentContent[] = [];

        //convert response to modal content data
        allDocumentResponse.forEach(
          (document: DocumentTypeGenerated, index: number) => {
            documentData.push({
              documentText: document.htmlContent,
              documentId: document.documentId,
              documentType: modalArray[index].key
            });
          }
        );

        //append content to modal object
        this.consentSectionItems.forEach(section => {
          section.consent.defaultConsent.disclosureModals.forEach(modal => {
            const documentHtml = documentData.find(
              data => data.documentType === modal.key
            );
            modal.documentContent = documentHtml;

            //inner modal text replacements
            if (modal.key === 'LoanAgreement') {
              modal?.content?.forEach(
                (content: ConsentModalContent, index: number) => {
                  if (content?.disclosureText) {
                    modal.content[
                      index
                    ].disclosureText = content?.disclosureText?.replace(
                      '{lenderName}',
                      configFeatureResponse.name
                    );
                  }
                }
              );
            }
          });
        });

        this.loadingModalService.close();
      },
      error: () => {
        this.appinsightsService.trackEvent('Get-Html-Documents-Failure');
        this.loadingModalService.close();
        this.router.navigate(['/error']);
      }
    });
  }

  private getConsentModal(consent: Consent, key?: string): ConsentModal {
    if (!this.isConsentHasOneModal(consent) && key) {
      const consentModal = consent.disclosureModals.find(x => x.key === key);
      return consentModal;
    }
    return consent.disclosureModals[0];
  }

  private getConsentsResultModel(): Disclosure[] {
    const disclosures = [];
    this.consentSectionItems.forEach(item => {
      const consentByState = this.getConsent(item.consent);
      if (!consentByState) {
        return;
      }
      let signatures = [];
      if (this.isConsentHasModal(consentByState)) {
        consentByState.disclosureModals
          .filter(modal => !!modal.content)
          .forEach(modal => {
            signatures = signatures.concat(
              this.consentsSignatures.get(modal) ?? []
            );
          });
      }
      if (signatures.length === 0) {
        const disclosure = {
          key: ConsentHelper.generateDisclosureKeyByConsent(consentByState),
          consentGiven: !!this.getConsentControl(consentByState)?.value,
          isIndependentDocument: consentByState.isIndependentDocument
        };
        disclosures.push(disclosure);
      } else {
        signatures.forEach(signature => {
          const disclosure = {
            key: ConsentHelper.generateDisclosureKeyBySignature(
              consentByState,
              signature
            ),
            consentGiven: signature.isConsentGiven,
            documentId: signature?.documentId,
            signature: signature.signatureValue.toString(),
            isIndependentDocument: consentByState.isIndependentDocument
          };
          if (this.htmlDocumentCallData) {
            disclosure['documentId'] = this.htmlDocumentCallData.documentId;
          }
          disclosures.push(disclosure);
        });
      }
    });
    const consentToCommunication = disclosures.filter(
      o =>
        o.key != null &&
        o.key.toUpperCase().trim() === 'CONSENTTOCOMMUNICATIONS'
    );
    const creditDisclosure = disclosures.filter(
      disclosures =>
        disclosures.key != null &&
        disclosures.key.trim() === DisclosureTypes.CreditScoreDisclosure
    );
    if (creditDisclosure.length > 0) {
      disclosures.push({
        key: DisclosureTypes.KnowBeforeYouBorrow,
        documentId: this.consentGroup
          .find(
            consent => consent.key === DisclosureTypes.CreditScoreDisclosure
          )
          .disclosureModals.find(
            x => x.key === DisclosureTypes.KnowBeforeYouBorrow
          ).documentContent?.documentId,

        consentGiven: creditDisclosure[0].consentGiven
      });
    }
    if (consentToCommunication.length > 0) {
      disclosures.push(
        {
          key: 'TextOptIn',
          consentGiven: consentToCommunication[0].consentGiven
        },
        {
          key: 'PhoneOptIn',
          consentGiven: consentToCommunication[0].consentGiven
        }
      );
    }
    return disclosures;
  }

  public debugHudSubmitAllConsents(): void {
    ConsentHelper.confirmAllConsents(this.formGroup, this.consentGroup);
    this.debugHudSubmitAllAgreements();
  }

  private debugHudSubmitAllAgreements(): void {
    this.consentSectionItems.forEach(item => {
      const consentByState = this.getConsent(item.consent);
      if (consentByState) {
        if (this.isConsentHasModal(consentByState)) {
          consentByState.disclosureModals
            .filter(
              modal =>
                !!modal.content && modal.content.some(c => !!c.agreement.type)
            )
            .forEach(modal => {
              const agreements = [];
              modal.content.forEach(content => {
                if (content.agreement.type) {
                  const agreementValue = {
                    isConsentGiven: true,
                    signatureValue: this.getSignatureValue(content.agreement),
                    controlName: SignatureHelper.getSignatureControlName(
                      content.agreement
                    )
                  };
                  if (modal.iscontenthtml) {
                    Object.assign(agreementValue, agreementValue, {
                      documentId: modal.documentContent?.documentId
                    });
                  }
                  agreements.push(agreementValue);
                }
              });
              this.consentsSignatures.set(modal, agreements);
            });
        }
      }
    });
  }

  private getSignatureValue(
    agreement: ConsentModalAgreement
  ): string | boolean {
    switch (agreement.type) {
      case SignatureType.Last4Ssn:
        return this.application?.form?.applicant?.identity?.socialSecurityNumber?.slice(
          -4
        );
      case SignatureType.TypedName:
        return (
          this.application?.form?.applicant?.identity?.firstName?.trim() +
          ' ' +
          this.application?.form?.applicant?.identity?.lastName?.trim()
        );
      case SignatureType.AgreementPhrase:
        return agreement.checkPhrase;
      case SignatureType.CheckBox:
        return true;
      default:
        return '';
    }
  }

  /**
   * Returns signatures which were already signed by user (on current page or on previous pages)
   */
  private getDisclosureModalSignatures(
    consent: Consent,
    consentModal: ConsentModal
  ): SignatureModel[] {
    // try to get agreements that were just signed on the current page and they are not saved in application
    const signatures = this.consentsSignatures.get(consentModal);

    if (signatures?.length > 0) {
      return signatures;
    }
    // try to get agreements that were signed previously and already saved to application.
    const agreements = ConsentHelper.getConsentModalAgreements(consentModal);
    return agreements
      .map(agreement => {
        const existedDisclosure = this.appDataService.getAgreementDisclosure(
          consent,
          agreement
        );
        if (existedDisclosure == null) {
          return null;
        }
        return {
          isConsentGiven: existedDisclosure.consentGiven,
          signatureValue: existedDisclosure.signature,
          controlName: SignatureHelper.getSignatureControlName(agreement)
        } as SignatureModel;
      })
      .filter(x => x != null);
  }

  ngOnDestroy(): void {
    if (this.ngbModalSubscription) {
      this.ngbModalSubscription.unsubscribe();
    }
  }
}
