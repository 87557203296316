import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Environment } from '@environment/environment';
import { Observable } from 'rxjs';

export interface ConfirmationCode {
  otpCode: string;
}

@Injectable({
  providedIn: 'root'
})
export class EmailConfirmationDebugService {
  constructor(private environment: Environment, private http: HttpClient) {}

  public getConfirmationCode(): Observable<HttpResponse<ConfirmationCode>> {
    return this.http.post<ConfirmationCode>(
      `${this.environment.brandApi.url}/api/v1/debug/get-confirmation-code`,
      null,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        observe: 'response'
      }
    );
  }
}
