export const partnerVerificationFormConfig = {
  verificationCodeConfig: {
    id: 'verificationCode',
    required: 'true',
    mask: '000000',
    maxlength: 6,
    attributes: {
      'data-private': 'redact',
      'data-nid-target': 'verificationCode'
    }
  },
  ssnVerificationMethodConfig: {
    id: 'last4SSN',
    mask: '0000',
    maxlength: 4,
    required: 'true',
    type: 'password',
    attributes: {
      'data-private': 'redact',
      'data-nid-target': 'verificationLast4ssnPassword'
    },
    addGenesysCobrowseMaskingClass: 'true'
  },
  contactTypeConfig: {
    name: 'sendCodeMethod',
    direction: 'column',
    required: 'true',
    value: '',
    buttons: [
      {
        id: 'callInputRadioBtn',
        label: 'Call me at XXX-XXX-YYYY',
        value: 'Call',
        disabled: 'false',
        attributes: {
          'data-nid-target': 'verificationCall'
        }
      },
      {
        id: 'smsInputRadioBtn',
        label: 'Text me at XXX-XXX-YYYY',
        value: 'Sms',
        disabled: 'false',
        attributes: {
          'data-nid-target': 'verificationSMS'
        }
      }
    ]
  }
};
