import { Injectable } from '@angular/core';
import { ApplicationFlow } from '@application/application';
import {
  OriginationFeeConfiguration,
  PaymentOptionItem,
  PaymentOptionsResponse
} from '@application/underwriting-decisions/choose-your-terms/choose-your-terms.model';
import { LoanPaymentApiService } from './loan-api.service';
import { lastValueFrom } from 'rxjs';
import { StateEligibilityService } from '@application/getting-started/state-eligibility/state-eligibility.service';
import { StateResourceModel } from '@application/getting-started/state-eligibility/stateResourceModel';

export interface LoanPaymentInitializationData {
  loanAmount: number;
  apr: number;
  requestedAmount: number;
  applicationFlow: ApplicationFlow;
  originationFee?: number;
}

export interface LoanAmountMinMax {
  minimum: number;
  maximum: number;
}

export interface PaymentOptionsState {
  currentSelection: number;
  paymentOptions: PaymentOptionItem[];
}

export interface SelectedPaymentOption {
  fundAmount: number;
  originationFee: number;
  paymentOptions: PaymentOptionItem[];
}

@Injectable({ providedIn: 'root' })
export class LoanPaymentDataService {
  public apr: number;
  public originationFee: number = null;
  public fundedAmount: number;
  public loanAmountMinMax: LoanAmountMinMax = {
    minimum: 0,
    maximum: 0
  };
  public loanAmount = 0;
  public originationConfig: OriginationFeeConfiguration = {
    originationFeePercentage: 0,
    originationFee: 0,
    isloanOriginationFeeApplicable: false,
    originationFeeCapDollarAmount: 0,
    originationFundedAmount: null
  };
  public selectedPaymentOption: PaymentOptionItem;
  public allPaymentOptions: PaymentOptionItem[];
  constructor(
    private loanPaymentApiService: LoanPaymentApiService,
    public stateEligibilityService: StateEligibilityService
  ) {}

  public async initializeLoanAmount(
    initializationData: LoanPaymentInitializationData,
    state?: string
  ): Promise<void> {
    this.loanAmountMinMax.maximum = initializationData.loanAmount;
    this.loanAmount = initializationData.loanAmount;
    this.apr = initializationData.apr;
    if (!this.stateEligibilityService.getProductConfig()) {
      await lastValueFrom(
        this.stateEligibilityService.getStateResource(state)
      ).then((res: StateResourceModel) => {
        this.stateEligibilityService.setProductConfig(res);
        if (res.originationFeeCapDollarAmount > 0) {
          this.setOriginationConfiguration(res);
        }
      });
    } else {
      const productConfig = this.stateEligibilityService.getProductConfig();
      if (productConfig.originationFeeCapDollarAmount > 0) {
        this.setOriginationConfiguration(productConfig);
      }
    }

    await this.loadDefaultPaymentOptions(
      initializationData.loanAmount,
      this.originationFee
    );
  }

  public async loadDefaultPaymentOptions(
    loanAmount: number,
    originationFee: number = null
  ): Promise<void> {
    await lastValueFrom(
      this.loanPaymentApiService.getPaymentOptions(
        loanAmount,
        'Default',
        originationFee
      )
    ).then((response: PaymentOptionsResponse) => {
      this.loanAmountMinMax.minimum = response.minLineAmount;
      this.loanAmount = loanAmount;
      if (originationFee > 0) {
        this.setOriginationConfiguration();
      }
      this.selectedPaymentOption = response.paymentOptions[0];
    });
  }

  public async loadAdditionalOptions(loanAmount: number): Promise<void> {
    await lastValueFrom(
      this.loanPaymentApiService.getPaymentOptions(
        loanAmount,
        'All',
        this.originationFee
      )
    ).then((response: PaymentOptionsResponse) => {
      let options = response.paymentOptions;

      this.allPaymentOptions = [
        ...new Map(options.map(item => [item['signature'], item])).values()
      ];
    });
  }

  private setOriginationConfiguration(config?: StateResourceModel) {
    if (!config) {
      config = this.stateEligibilityService.getProductConfig();
    }
    this.originationFee = this.calculateOriginationFee(
      config.originationFeeCapDollarAmount,
      config.originationFeePercentage,
      this.loanAmount
    );
    this.fundedAmount = this.loanAmount - this.originationFee;

    this.originationConfig = {
      originationFeePercentage: config.originationFeePercentage,
      originationFee: this.originationFee,
      isloanOriginationFeeApplicable: true,
      originationFeeCapDollarAmount: config.originationFeeCapDollarAmount,
      originationFundedAmount: this.fundedAmount
    };
  }

  private calculateOriginationFee(
    originationFeeCap: number,
    originationFeePercentage: number,
    loanAmount: number
  ): number {
    let originationFee = (loanAmount / 100) * originationFeePercentage;
    if (originationFee > originationFeeCap) {
      originationFee = originationFeeCap;
    }
    return originationFee;
  }
}
