import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Environment } from '@environment/environment';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  SessionStorageKeys,
  SessionStorageService
} from '../session-storage/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthValidationInterceptor implements HttpInterceptor {
  constructor(
    private sessionStorageService: SessionStorageService,
    private environment: Environment
  ) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      this.environment.brandApi &&
      req.url.startsWith(this.environment.brandApi.url)
    ) {
      return next.handle(req).pipe(
        catchError(
          (err: any): Observable<any> => {
            if (err instanceof HttpErrorResponse && err.status === 401) {
              this.sessionStorageService.deleteItem(
                SessionStorageKeys.authorizationToken
              );
              this.redirectToUrl(this.environment.sessionExpirationRedirectUrl);

              return of(err.status);
            } else {
              return throwError(err);
            }
          }
        )
      );
    }

    return next.handle(req);
  }

  public redirectToUrl(url: string): void {
    window.location.assign(url);
  }
}
