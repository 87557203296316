<div class="last-4-ssn" [formGroup]="formGroup">
  <div class="last-4-ssn-signature">
    <ecl-number
      class="last-4-ssn-input"
      [formControlName]="controlName"
      [formControlConfig]="formConfig"
      autocomplete="off"
      withStringOutput="true"
      googleAnalyticsFocusOut="last-4-ssn-signature"
    >
      <span [innerHtml]="label | safe: 'html'"></span>
    </ecl-number>
    <div class="last-4-ssn-check-mark">
      <i *ngIf="isValid" class="fa fa-check" aria-hidden="true"></i>
    </div>
  </div>
  <div
    *ngIf="instruction"
    class="last-4-ssn-instruction"
    [innerHtml]="instruction | safe: 'html'"
  ></div>
</div>
