import { Injectable } from '@angular/core';

@Injectable()
export class LocationProxy implements Location {
  public ancestorOrigins: DOMStringList;

  public get hash(): string {
    return window.location.hash;
  }

  public get host(): string {
    return window.location.host;
  }

  public get hostname(): string {
    return window.location.hostname;
  }

  public get href(): string {
    return window.location.href;
  }

  public get origin(): string {
    return window.location.origin;
  }

  public get pathname(): string {
    return window.location.pathname;
  }

  public get port(): string {
    return window.location.port;
  }

  public get protocol(): string {
    return window.location.protocol;
  }

  public get search(): string {
    return window.location.search;
  }

  public assign(url: string): void {
    window.location.assign(url);
  }

  public reload(): void {
    window.location.reload();
  }

  public replace(url: string): void {
    window.location.replace(url);
  }

  public toString(): string {
    return window.location.toString();
  }
}
