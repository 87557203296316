import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Environment } from '@environment/environment';
import { Observable, of } from 'rxjs';
import {
  IsSsnChangedData,
  IsSsnChangedModel,
  PartnerLeadIdModel,
  SendCodeRequestModel,
  VerifiedResponseModel,
  VerifyCodeRequestModel,
  VerifySSNPrepopRequestModel,
  VerifySSNRequestModel
} from './partner-verification.models';

@Injectable({
  providedIn: 'root'
})
export class PartnerVerificationService {
  constructor(private environment: Environment, private http: HttpClient) {}

  public getVerificationOptions(leadId: string): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.environment.brandApi.url}/api/v1/partner/verification-options/${leadId}`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        observe: 'response'
      }
    );
  }

  public getLast4PhoneNo(
    getPhoneNoData: PartnerLeadIdModel
  ): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.environment.brandApi.url}/api/v1/partner/lead/${getPhoneNoData.leadId}/offer/${getPhoneNoData.offerId}/phone`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        observe: 'response'
      }
    );
  }

  public getLeadIdLendingTree(
    gcid: string,
    trackingId: string
  ): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.environment.brandApi.url}/api/v1/partner/get-lead-id/${trackingId}/${gcid}/`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        observe: 'response'
      }
    );
  }

  public getLast4PhoneNoPrepop(leadId: string): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.environment.brandApi.url}/api/v1/partner/lead/${leadId}/phone`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        observe: 'response'
      }
    );
  }
  public sendCode(sendCodeData: SendCodeRequestModel): Observable<any> {
    return this.http.post<HttpResponse<any>>(
      `${this.environment.brandApi.url}/api/v1/partner/otp/send`,
      sendCodeData,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        observe: 'response'
      }
    );
  }

  public verifyCode(
    verificationCodeData: VerifyCodeRequestModel
  ): Observable<HttpResponse<VerifiedResponseModel>> {
    return this.http.post<VerifiedResponseModel>(
      `${this.environment.brandApi.url}/api/v1/partner/otp/verify`,
      verificationCodeData,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        observe: 'response'
      }
    );
  }

  public verifyLast4SSN(
    ssnVerificationData: VerifySSNRequestModel
  ): Observable<HttpResponse<VerifiedResponseModel>> {
    return this.http.post<any>(
      `${this.environment.brandApi.url}/api/v1/partner/prequal-verify`,
      ssnVerificationData,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        observe: 'response'
      }
    );
  }

  public verifyLast4SSNPrepop(
    ssnVerificationData: VerifySSNPrepopRequestModel
  ): Observable<HttpResponse<VerifiedResponseModel>> {
    return this.http.post<any>(
      `${this.environment.brandApi.url}/api/v1/partner/prepop-verify`,
      ssnVerificationData,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        observe: 'response'
      }
    );
  }

  public isSsnChanged(
    ssnChangedData: IsSsnChangedData
  ): Observable<IsSsnChangedModel> {
    if (!ssnChangedData.ssn) {
      return of({ isChanged: false });
    } else {
      return this.http.post<IsSsnChangedModel>(
        `${this.environment.brandApi.url}/api/v1/partner/is-ssn-changed`,
        ssnChangedData,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
        }
      );
    }
  }
}
