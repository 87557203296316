import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { requiredCheckboxValidator } from '@elevate/forms';
import { Checkbox } from '@elevate/ui-components';
import {
  DynamicComponentsService,
  ParsedDisclosureAgreementData
} from '../dynamic-components.service';
import { SignSubmitDisclosureFormService } from '../sign-submit-disclosure.form.service';

export const DINAMIC_CHECKBOX_SELECTOR = 'app-dynamic-checkbox';

@Component({
  selector: DINAMIC_CHECKBOX_SELECTOR,
  styleUrls: ['./dynamic-checkbox.component.scss'],
  template: `
    <div [formGroup]="form" *ngIf="showForm">
      <ecl-checkbox
        [formControlConfig]="checkboxformControlConfig"
        [formControlName]="componentInitializationData.Id"
        >{{ componentInitializationData.Label }}
      </ecl-checkbox>
    </div>
  `
})
export class DynamicCheckboxComponent implements OnInit, AfterViewInit {
  public componentInitializationData: ParsedDisclosureAgreementData;
  public checkboxformControlConfig: Checkbox;
  public showForm = false;

  constructor(
    private elementRef: ElementRef,
    private dynamicComponentsService: DynamicComponentsService,
    private signSubmitDisclosureFormService: SignSubmitDisclosureFormService
  ) {}

  ngOnInit(): void {
    const fieldName = this.elementRef.nativeElement.getAttribute('fieldName');
    this.componentInitializationData = this.dynamicComponentsService.cmsDynamicElementsJsonDataCollection.find(
      cmsJsonObj => fieldName === cmsJsonObj.Id
    );

    this.checkboxformControlConfig = {
      id: this.componentInitializationData.Id,
      required: 'true',
      attributes: {
        'data-nid-target': 'whatYouNeedToKnowConsentSignature'
      }
    };
  }

  ngAfterViewInit(): void {
    this.signSubmitDisclosureFormService.addFormControl(
      this.componentInitializationData.Id,
      new FormControl(null, [requiredCheckboxValidator('Required')])
    );

    this.showForm = true;
  }

  public get form(): FormGroup {
    return this.signSubmitDisclosureFormService.form;
  }
}
