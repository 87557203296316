import { Component, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { patternValidator, requiredValidator } from '@elevate/forms';
import { TextInput } from '@elevate/ui-components';
import {
  DynamicComponentsService,
  ParsedDisclosureAgreementData
} from '../dynamic-components.service';
import { SignSubmitDisclosureFormService } from '../sign-submit-disclosure.form.service';
import { ApplicationDataService } from '../../../application.service';

export const DINAMIC_LAST4SSN_SELECTOR = 'app-dynamic-last4ssn';
@Component({
  selector: DINAMIC_LAST4SSN_SELECTOR,
  styleUrls: ['./dynamic-last4ssn.component.scss'],
  template: `
    <div
      class="ssn-dynamic-input-container"
      [formGroup]="form"
      *ngIf="showForm"
    >
      <div class="ssn-input-element">
        <ecl-text-input       
          [formControlConfig]="last4SsnInputConfig"
          [formControlName]="componentInitializationData.Id"
          >Last 4 SSN</ecl-text-input
        >
      </div>
      <div class="ssn-bottom-text" [innerHtml]="parsedLabel"></div>
    </div>
  `
})
export class DynamicLast4ssnComponent implements OnInit, AfterViewInit {
  public showForm = false;
  public componentInitializationData: ParsedDisclosureAgreementData;
  public parsedLabel: string;
  private last4Ssn: string;

  public last4SsnInputConfig: TextInput = {
    id: 'last4SsnInput',
    minlength: 2,
    maxlength: 4,
    mask: '0000',
    attributes: {
      'data-private': 'redact',
      'data-nid-target': 'termsAndConditionsLast4ssnConsentSignature'
    },
    addGenesysCobrowseMaskingClass: 'true'
  };

  constructor(
    private elementRef: ElementRef,
    private dynamicComponentsService: DynamicComponentsService,
    private signSubmitDisclosureFormService: SignSubmitDisclosureFormService,
    private applicationDataService: ApplicationDataService
  ) {}

  ngOnInit(): void {
    const fieldName = this.elementRef.nativeElement.getAttribute('fieldName');
    this.componentInitializationData = this.dynamicComponentsService.cmsDynamicElementsJsonDataCollection.find(
      cmsJsonObj => fieldName === cmsJsonObj.Id
    );

    this.componentInitializationData.hyperlinks.forEach((element: string) => {
      this.parsedLabel = this.componentInitializationData.Label.replace(
        element,
        `<a class="ecl-font-link-light-bg" href=\"${this.componentInitializationData[element][1]}\" target="_blank">${this.componentInitializationData[element][0]}</a>`
      );
    });

    this.last4Ssn = this.applicationDataService
      .getApplication()
      .form.applicant.identity.socialSecurityNumber.slice(-4);
  }

  ngAfterViewInit(): void {
    this.signSubmitDisclosureFormService.addFormControl(
      this.componentInitializationData.Id,
      new FormControl(null, [
        requiredValidator('Required'),
        patternValidator(
          new RegExp(this.last4Ssn),
          'Must match last four of SSN'
        )
      ])
    );

    this.showForm = true;
  }

  public get form(): FormGroup {
    return this.signSubmitDisclosureFormService.form;
  }
}
