<div [formGroup]="form" class="married-input">
  <div class="form-input">
    <ecl-text-input
      [formControlConfig]="spouseFirstnameConfig"
      formControlName="spouseFirstName"
    >
      {{ spousesFirstNameLabel }}
    </ecl-text-input>
  </div>
  <div class="form-input">
    <ecl-text-input
      [formControlConfig]="spouseLastnameConfig"
      formControlName="spouseLastName"
    >
      {{ spousesLastNameLabel }}
    </ecl-text-input>
  </div>
  <div class="form-input">
    <ecl-email
      [formControlConfig]="spouseEmailConfig"
      formControlName="spouseEmailAddress"
    >
      {{ spousesEmailLabel }}
    </ecl-email>
  </div>
  <div class="form-input">
    <ecl-checkbox
      [formControlConfig]="spouseEmailIsTheSameConfig"
      formControlName="spouseEmailIsTheSame"
      (change)="checkboxChange()"
      >{{ spousesEmailIsTheSameLabel }}
    </ecl-checkbox>
  </div>
</div>
