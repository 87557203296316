import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageFormGroup } from '@core/page-form-group/page-form-group';
import { requiredValidator } from '@elevate/forms';

export interface PaymentMethodFormType {
  paymentMethod: string;
  autopay: boolean;
}

@Injectable()
export class PaymentMethodSelectionForm extends PageFormGroup {

  constructor() {
    super({});
  }

  build(requiredMessage: string): void {
    this.addControl(
      'paymentMethod',
      new FormControl('Electronic', [requiredValidator(requiredMessage)])
    );
    this.addControl('autopay', new FormControl(true));
  }
}
