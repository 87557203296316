import { Injectable } from '@angular/core';
import { AccountSetupFormGroup } from '../account-setup.form';

/* istanbul ignore next */
@Injectable()
export class AccountSetupDebugHudService {
  constructor(private form: AccountSetupFormGroup) {}

  public generateData(): void {
    const mockString = 'L0ans12345678!';
    this.form.get('newPassword').setValue(mockString);
    this.form.get('confirmPassword').setValue(mockString);

    this.form
      .get('username')
      .setValue(`test${this.getRandomLowerCaseString(20)}`);

    this.form.showValidationErrors();
  }

  private getRandomLowerCaseString(length: number): string {
    const aCode = 'a'.charCodeAt(0);
    const zCode = 'z'.charCodeAt(0);
    let randomString = '';
    for (let i = 0; i < length; i++) {
      const randomCharacterCode =
        Math.floor(Math.random() * (zCode - aCode + 1)) + aCode;
      randomString += String.fromCharCode(randomCharacterCode);
    }
    return randomString;
  }
}
