import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageFormGroup } from '@core/page-form-group/page-form-group';
import { fixedLengthValidator, requiredValidator } from '@elevate/forms';

import { Other } from './other';

@Injectable()
export class OtherFormGroup extends PageFormGroup {

  constructor(otherContent: any) {
    super({});

    if (otherContent.isphoneenabled) {
      this.addControl(
        'primaryPhoneNumber',
        new FormControl(null, [
          requiredValidator(otherContent.validation.verifyPhone),
          fixedLengthValidator(10, otherContent.validation.phoneLengthRule)
        ])
      );
      this.addControl('primaryPhoneNumberExtension', new FormControl(null, []));
    }
  }
}
