import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageFormGroup } from '@core/page-form-group/page-form-group';
import { requiredValidator } from '@elevate/forms';
import { ActivatedRoute } from '@angular/router';
import { UnderwritingApprovalPageContent } from './underwriting-approval-content';

@Injectable()
export class UnderwritingApprovalFormGroup extends PageFormGroup {
  get content(): UnderwritingApprovalPageContent {
    return this.route.snapshot.data.cmsContent
      .approval as UnderwritingApprovalPageContent;
  }

  constructor(private route: ActivatedRoute) {
    super({});
    this.addControl(
      'confirmationCode',
      new FormControl(null, [
        requiredValidator(this.content.validationMessages.enterConfirmationCode)
      ])
    );
    this.addControl(
      'maritalStatus',
      new FormControl(null, [
        requiredValidator(
          this.content.maritalStatus.validationMessages.maritalStatusRequired
        )
      ])
    );
  }
}
