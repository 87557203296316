import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Environment } from '@environment/environment';
import { Observable, catchError, lastValueFrom, throwError } from 'rxjs';
import { AppInsightsService } from '@core/app-insights/app-insights.service';
import { Router } from '@angular/router';
import { ApplicationDataService } from '@application/application.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

export interface DecisionFlowResponse {
  decision?: Decision[];
  documentTypeDefinitions?: DocumentTypeDefinitions[];
  documentUploadRFAICodes?: DocumentUploadRFAICodes[];
}

export interface Decision {
  code: string;
  decisionStatus: string;
  underWritingStatus: string;
  requestedDocuments?: string[];
  declineDecisionPage?: string;
  bankRequired: boolean;
}

export interface DocumentTypeDefinitions {
  id: string;
  name: string;
}

export interface DocumentUploadRFAICodes {
  code?: string;
  requestedDocuments?: string[];
}

export interface EnrollmentCodeStatesResponse {
  enabled: boolean;
  name?: string;
  email?: string;
  states: EnrollmentCodeStates[];
}

export interface EnrollmentCodeStates {
  name: string;
}

export enum PlatformConfigFeatures {
  DMCodeFieldRequired = 'DMCodeFieldRequired',
  DecisionFlow = 'DecisionFlow',
  ApplicationFlow = 'GetApplicationFlow',
  Lender = 'Lender'
}

export enum ApplicationFeatures {
  Cobrowse = 'CoBrowse',
  IovationWait = 'IovationWait'
}

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  constructor(
    private environment: Environment,
    private http: HttpClient,
    private router: Router,
    private appInsightsService: AppInsightsService,
    private applicationDataService: ApplicationDataService,
    private modalService: NgbModal
  ) { }

  private decisionFlowStatus: Decision;

  private isApplicationFeatureEnabled(
    featureName: string
  ): Observable<boolean> {
    return this.http
      .get<boolean>(
        `${this.environment.configurationService.url}/api/UIConfiguration/FeatureFlag?flagName=${featureName}`,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Api-Version': this.environment.configurationService.apiVersion
          }),
          observe: 'body'
        }
      )
      .pipe(
        catchError((error: any) => {
          const httpError = error as HttpErrorResponse;
          return throwError(() => error);
        })
      );
  }

  public getConfigFeature<T>(
    featureName: PlatformConfigFeatures
  ): Observable<T> {
    const productId = this.applicationDataService.getApplication()?.product?.id;

    //if product id is defined send it as param
    const options = productId ?
      {
        params: new HttpParams()
          .set('brandId', this.environment.brandId)
          .set("productId", productId)
          .set('featureName', featureName)
      } :
      {
        params: new HttpParams()
          .set('brandId', this.environment.brandId)
          .set('featureName', featureName)
      };

    return this.http
      .get<T>(
        `${this.environment.configurationService.url}/api/PlatformConfig`,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Api-Version': this.environment.configurationService.apiVersion
          }),
          observe: 'body',
          params: options.params
        }
      )
      .pipe(
        catchError((error: any) => {
          this.appInsightsService.trackException(error);
          this.modalService.dismissAll();
          this.router.navigate(['/error']);
          throw new Error();
        })
      );
  }

  //can probably move getConfigFeature into here to pull decision instead of having two calls once all configs get moved to decision flow
  public async getDecisionFlowStatus(code: string): Promise<Decision> {
    if (this.decisionFlowStatus && this.decisionFlowStatus.code === code) {
      return this.decisionFlowStatus;
    }

    try {
      let configuration = await lastValueFrom(
        this.getConfigFeature<DecisionFlowResponse>(
          PlatformConfigFeatures.DecisionFlow
        )
      );

      this.decisionFlowStatus = configuration.decision.find(
        x => x.code === code
      );
    } catch (error) {
      //Route to error page when there is an error on the platform config call
      this.appInsightsService.trackException(error);
      this.router.navigate(['/error']);
      return;
    }

    return this.decisionFlowStatus;
  }

  public IsCoBrowseEnabled(): Observable<boolean> {
    var cobrowse =
      ApplicationFeatures.Cobrowse + '.' + this.environment.brand.trim();
    return this.isApplicationFeatureEnabled(cobrowse);
  }

  public IsIovationWaitEnabled(): Observable<boolean> {
    var iovationWait = `${ApplicationFeatures.IovationWait
      }.${this.environment.brand.trim()}`;
    return this.isApplicationFeatureEnabled(iovationWait);
  }

  public async isBankRequired(code?: string): Promise<boolean> {
    if (code) {
      let decision: Decision = await this.getDecisionFlowStatus(code);
      return decision != undefined && decision.bankRequired;
    }

    return false;
  }

  public async getDeclineDecisionPage(
    defaultDeclineDecisionPage: string,
    code?: string
  ): Promise<string> {
    if (code) {
      let decision: Decision = await this.getDecisionFlowStatus(code);
      return decision != undefined && decision.declineDecisionPage != null
        ? decision.declineDecisionPage
        : defaultDeclineDecisionPage;
    }

    return defaultDeclineDecisionPage;
  }
}
