<ecl-base-modal
  id="disclosureModalWindow"
  [modalConfig]="modalData"
  (closeModal)="
    googleAnalytics.clickModalEvent({
      action: 'Close',
      modal_name: this.consentModal.header
    })
  "
>
  <div class="disclosure-modal-body">
    <h2
      class="disclosure-modal-header"
      ngbAutofocus
      id="disclosureModalTitle"
      [innerHTML]="consentModal.header | safe: 'html'"
    >
      {{ consentModal.header }}
    </h2>
    <div *ngIf="consentModal.isPrintLinkVisible" class="save-print-container">
      <span
        (click)="printDisclosure()"
        class="ecl-font-link-light-bg"
        id="printContent"
        >{{ consentModal.labels.savePrintLabel }}</span
      >
    </div>
    <div
      *ngIf="isScrollDownMandatory"
      class="ecl-font-body-medium scroll-down-container"
    >
      {{ scrollDownFirstLabel }}
      <span
        *ngIf="consentModal.isScrollLinkVisible"
        (click)="scrollDown()"
        class="ecl-font-link-light-bg"
        >{{ consentModal.labels.scrollDownLinkLabel }}</span
      >
      {{ scrollDownSecondLabel }}
    </div>
    <ng-scrollbar
      #scrollbarRef
      class="scrollbar-wrapper"
      [autoHeightDisabled]="false"
    >
      <div
        tabindex="0"
        #contentContainer
        class="content-container"
        id="disclosureModalContent"
      >
        <div *ngIf="consentModal?.documentContent">
          <div [innerHTML]="contentDocumentText | safe: 'html'"></div>
        </div>
        <div *ngFor="let consentModalContent of consentModal?.content">
          <div
            class="agreement-section"
            [innerHTML]="consentModalContent.disclosureText | safe: 'html'"
          >
            {{ consentModalContent.disclosureText }}
          </div>
          <div
            *ngIf="!consentModalContent.agreement?.outOfScroll"
            [ngSwitch]="consentModalContent.agreement?.type"
          >
            <ng-template [ngSwitchCase]="signatureType.TypedName">
              <app-typed-name
                #signature
                [agreement]="consentModalContent.agreement"
                [formGroup]="formGroup"
                [controlName]="
                  getSignatureControlName(consentModalContent.agreement)
                "
                [initValue]="getSignatureValue(consentModalContent.agreement)"
              ></app-typed-name>
            </ng-template>
            <ng-template [ngSwitchCase]="signatureType.Last4Ssn">
              <app-last-four-ssn
                #signature
                [agreement]="consentModalContent.agreement"
                [formGroup]="formGroup"
                [controlName]="
                  getSignatureControlName(consentModalContent.agreement)
                "
                [initValue]="getSignatureValue(consentModalContent.agreement)"
              ></app-last-four-ssn>
            </ng-template>
            <ng-template [ngSwitchCase]="signatureType.AgreementPhrase">
              <app-agreement-phrase
                #signature
                [agreement]="consentModalContent.agreement"
                [formGroup]="formGroup"
                [controlName]="
                  getSignatureControlName(consentModalContent.agreement)
                "
                [initValue]="getSignatureValue(consentModalContent.agreement)"
              ></app-agreement-phrase>
            </ng-template>
            <ng-template [ngSwitchCase]="signatureType.CheckBox">
              <app-signature-checkbox
                #signature
                [agreement]="consentModalContent.agreement"
                [formGroup]="formGroup"
                [controlName]="
                  getSignatureControlName(consentModalContent.agreement)
                "
                [initValue]="getSignatureValue(consentModalContent.agreement)"
                [modalName]="consentModal.header"
              ></app-signature-checkbox>
            </ng-template>
          </div>
        </div>
        <br />
        <div
          *ngIf="
            consentModal?.date?.isDateVisible &&
            !consentModal?.date?.outOfScroll
          "
        >
          <div [innerHTML]="consentModal.date.dateTitle | safe: 'html'">
            {{ consentModal.date.dateTitle }}
          </div>
          <div>
            <p>{{ date }}</p>
          </div>
        </div>
        <div [innerHTML]="updateSignatureData"></div>
      </div>
    </ng-scrollbar>
    <hr *ngIf="isSeparateLineVisible" class="page-standard-hr" />
    <div *ngFor="let consentModalContent of consentModal.content">
      <div
        *ngIf="consentModalContent.agreement?.outOfScroll"
        [ngSwitch]="consentModalContent.agreement?.type"
      >
        <ng-template [ngSwitchCase]="signatureType.TypedName">
          <app-typed-name
            #signature
            [agreement]="consentModalContent.agreement"
            [formGroup]="formGroup"
            [controlName]="
              getSignatureControlName(consentModalContent.agreement)
            "
            [initValue]="getSignatureValue(consentModalContent.agreement)"
          ></app-typed-name>
        </ng-template>
        <ng-template [ngSwitchCase]="signatureType.Last4Ssn">
          <app-last-four-ssn
            #signature
            [agreement]="consentModalContent.agreement"
            [formGroup]="formGroup"
            [controlName]="
              getSignatureControlName(consentModalContent.agreement)
            "
            [initValue]="getSignatureValue(consentModalContent.agreement)"
          ></app-last-four-ssn>
        </ng-template>
        <ng-template [ngSwitchCase]="signatureType.AgreementPhrase">
          <app-agreement-phrase
            #signature
            [agreement]="consentModalContent.agreement"
            [formGroup]="formGroup"
            [controlName]="
              getSignatureControlName(consentModalContent.agreement)
            "
            [initValue]="getSignatureValue(consentModalContent.agreement)"
          ></app-agreement-phrase>
        </ng-template>
        <ng-template [ngSwitchCase]="signatureType.CheckBox">
          <app-signature-checkbox
            #signature
            [agreement]="consentModalContent.agreement"
            [formGroup]="formGroup"
            [controlName]="
              getSignatureControlName(consentModalContent.agreement)
            "
            [initValue]="getSignatureValue(consentModalContent.agreement)"
            [modalName]="consentModal.header"
          ></app-signature-checkbox>
        </ng-template>
      </div>
    </div>
    <div
      *ngIf="
        consentModal?.date?.isDateVisible && consentModal?.date?.outOfScroll
      "
    >
      <div [innerHTML]="consentModal.date.dateTitle | safe: 'html'">
        {{ consentModal.date.dateTitle }}
      </div>
      <div>
        <p>{{ date }}</p>
      </div>
    </div>
    <button
      #disclosureButton
      [disabled]="isBtnDisabled"
      class="ok-button ecl-button-primary"
      (click)="submit()"
      id="disclosureOkButton"
    >
      {{ consentModal.labels.buttonLabel }}
    </button>
    <button
      *ngIf="consentModal.labels.cancelButtonLabel"
      #disclosureCancelButton
      class="cancel-button ecl-button-secondary"
      (click)="cancel()"
      id="disclosureCancelButton"
    >
      {{ consentModal.labels.cancelButtonLabel }}
    </button>
  </div>
</ecl-base-modal>
