import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Disclosure } from '@application/application';
@Injectable({ providedIn: 'root' })
export class SignSubmitDisclosureFormService {
  private formGroup: FormGroup;

  constructor() {}

  public addFormControl(name: string, control: FormControl): void {
    this.formGroup.addControl(name, control);
  }

  public get form(): FormGroup {
    return this.formGroup;
  }

  public createFrom(): void {
    this.formGroup = new FormGroup({});
  }

  public showValidationErrors(): void {
    Object.keys(this.formGroup.controls).forEach((key: string) => {
      this.formGroup.get(key).markAsDirty();
      this.formGroup.get(key).markAsTouched();
    });
  }

  public get valid(): boolean {
    return this.formGroup.valid;
  }

  public patchFormValue(formData: Disclosure[]): void {
    (async () => {
      while (Object.keys(this.formGroup.controls).length < 1) {
        await new Promise((resolve: any) => setTimeout(resolve, 500));
      }
      Object.keys(this.formGroup.controls).forEach((key: string) => {
        const pre = formData.filter((x: any) => {
          return x.key === key;
        });
        this.formGroup
          .get(key)
          .patchValue(
            pre[0].signature ? pre[0].signature : pre[0].consentGiven
          );
      });
    })();
  }
}
