import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CmsService } from '@core/cms';
import { BaseModalConfig } from '@elevate/base-modal';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-loading-modal',
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoadingModalComponent implements OnInit {
  @Input() public modalData: BaseModalConfig = {
    useWrapperOnly: true,
    useWrapperEmpty: true,
    action: {
      allowClose: false
    }
  } as BaseModalConfig;

  constructor(private cmsService: CmsService) {}
  public async ngOnInit(): Promise<void> {
    await lastValueFrom(this.cmsService.getEntries('shared')).then(data => {
      data = Array.isArray(data) ? data[0] : data;
      this.modalData.heading = this.modalData.heading
        ? this.modalData.heading
        : data.processingModal.text;
    });
  }
}
