<div class="modal-body" id="checkingAcctModalWindow">
  <button type="button" ngbAutofocus class="close">
    <i
      class="fal fa-times fa-lg close-icon"
      (click)="close()"
      id="checkingAcctModalCloseButton"
    ></i>
  </button>
  <h1 class="checking-acct-modal-header" id="checkingAcctModalTitle">
    {{ pageTitle }}
  </h1>

  <div *ngIf="accountsSelectForm" class="checking-acct-modal-result-section">
    <p id="userHasAccountMessage">{{ userHasAccountMessage }}</p>

    <form [formGroup]="form" class="checking-acct-modal-form-container">
      <ecl-radio
        [formControlConfig]="checkingAcctRadioFormConfig"
        formControlName="draftFromKey"
      ></ecl-radio>
    </form>
    <div class="checking-acct-modal-submit-button-container">
      <button
        type="submit"
        (click)="submit()"
        id="checkingAcctModalContinueButton"
        class="ecl-button-primary"
      >
        CONTINUE
      </button>
    </div>
  </div>

  <div *ngIf="notExistingAccounts" class="checking-acct-modal-result-section">
    <p id="userDoesNotHaveAccountsMessage">
      {{ userDoesNotHaveAccountsMessage }}
    </p>

    <div class="checking-acct-modal-submit-button-container">
      <button
        type="submit"
        (click)="close()"
        id="checkingAcctModalReturnButton"
        class="ecl-button-primary"
      >
        RETURN
      </button>
    </div>
  </div>
</div>
