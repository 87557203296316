export interface FinicitySuccessMetaData {
  portfolioId: string;
  reportId: string;
  type: string;
}

export interface FinicitySuccessObject {
  metaData: FinicitySuccessMetaData;
}

export interface FinicityInitializeRequest {
  provider: string;
  clientName: string;
  lenderCode: string;
  linkCustomizationName: string;
  redirectUri: string;
}

export interface FinicityEventMetaData {
  appSequenceId: number;
}

export class FinicityEventRequest {
  public provider: string;
  public applicationId: string;
  public eventType: string;
  public timeStamp: string;
  public data?: any;
}
