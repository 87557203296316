import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageFormGroup } from '@core/page-form-group/page-form-group';
import {
  requiredValidator,
  routingNumberValidator,
  patternValidator,
  inputNotMatchValidator,
  inputMatchValidator
} from '@elevate/forms';

export interface BankInformation {
  bankRoutingNumber: number;
  bankAccountNumber: number;
  confirmBankAccountNumber: number;
}

export interface BankInformationValidationMessages {
  enterRoutingNumber: string;
  enterAccountNumber: string;
  invalidAccountNumber: string;
  accountMustNotMatchRouting: string;
  reEnterAccountNumber: string;
  accountNumberNotMatch: string;
  duplicateAccountError: string;
}

@Injectable()
export class BankInformationFormGroup extends PageFormGroup {

  constructor(messages: BankInformationValidationMessages) {
    const regularExpressions = {
      notAllZeros: /^(?!0+$).*$/,
      notBeginningWithFourOrFiveAndSeventeenDigitsLong: /(^(?![45])[0-9]{1,17})(?!.)/
    };

    super({
      bankRoutingNumber: new FormControl(null, [
        requiredValidator(messages.enterRoutingNumber),
        routingNumberValidator()
      ]),
      bankAccountNumber: new FormControl(null, [
        requiredValidator(messages.enterAccountNumber),
        patternValidator(
          regularExpressions.notAllZeros,
          messages.invalidAccountNumber
        ),
        patternValidator(
          regularExpressions.notBeginningWithFourOrFiveAndSeventeenDigitsLong,
          messages.invalidAccountNumber
        ),
        inputNotMatchValidator(
          'bankRoutingNumber',
          messages.accountMustNotMatchRouting
        )
      ]),
      confirmBankAccountNumber: new FormControl(null, [
        requiredValidator(messages.reEnterAccountNumber),
        inputMatchValidator('bankAccountNumber', messages.accountNumberNotMatch)
      ])
    });
  }
}
