import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { ApplicationData } from '@application/application';
import { ApplicationApi } from '@application/application.api';
import { ApplicationDataService } from '@application/application.service';
import { UnderwritingApiService } from '@application/underwriting/underwriting-api.service';
import { ApplicationFlowService } from '@core/application-flow/application-flow.service';
import {
  SessionStorageKeys,
  SessionStorageService
} from '@core/session-storage/session-storage.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ApplicationContinuePathGuard {
  constructor(
    private router: Router,
    private applicationApi: ApplicationApi,
    private sessionStorageService: SessionStorageService,
    private applicationFlowService: ApplicationFlowService,
    private appDataService: ApplicationDataService,
    private underwritingService: UnderwritingApiService
  ) {}

  public canActivate(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const authToken = this.sessionStorageService.getItem(
      SessionStorageKeys.authorizationToken
    );

    if (
      this.router.getCurrentNavigation().extras &&
      this.router.getCurrentNavigation().extras.replaceUrl
    ) {
      if (!authToken) {
        if (state.url.match(/getting-started/gi)) {
          return of(true);
        }
        this.router.navigate(['getting-started'], {
          queryParams: this.router.getCurrentNavigation().extractedUrl
            .queryParams
        });
        return of(false);
      }

      if (
        authToken &&
        state.root.queryParams?.offer &&
        state.url.match(/getting-started/gi)
      ) {
        this.sessionStorageService.clearAll();
        this.appDataService.resetApplication();
        this.underwritingService.resetDecision();
        return of(true);
      }
      return this.applicationApi.get().pipe(
        map((application: ApplicationData) => {
          const continuePath = this.applicationFlowService.getContinuePath(
            null,
            application.applicationFlow
          );
          if (
            continuePath &&
            continuePath !== state.url.replace('/[/]/g', '')
          ) {
            this.router.navigate([continuePath]);
          } else if (!continuePath) {
            if (application.form.continuePath === undefined) {
              this.router.navigate(['getting-started']);
            }
            if (application.form.continuePath !== state.url) {
              this.router.navigate([application.form.continuePath]);
            }
          }
          return true;
        })
      );
    } else {
      return of(true);
    }
  }
}
