import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApplicationData, ApplicationStates } from '@application/application';
import { interval, lastValueFrom, Subject, Subscription, timer } from 'rxjs';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';
import { ApplicationApi } from '../application.api';
import { ApplicationDataService } from '@application/application.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CmsPageContentService } from '@core/cms/services/cms-page-content.service';
import { NeuroIdService } from '@core/neuro-id/neuro-id.service';
import {
  GoogleAnalytics,
  GoogleTagManagerVariables
} from '@core/google-analytics/googleanalytics.service';
import { AppInsightsService } from '@core/app-insights/app-insights.service';

export interface CmsBooking {
  messages: CmsWaitingMessage[];
}

export interface CmsWaitingMessage {
  message: string;
  secondsDelay: number;
}

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss']
})
export class BookingComponent implements OnInit, OnDestroy {
  public bookedCompletedSubject = new Subject<boolean>();
  public bookedSuccessResponse = false;
  public messageInterval: any;
  public pollingSubscription: Subscription;
  public bokingMessage: CmsBooking;

  constructor(
    private activatedRoute: ActivatedRoute,
    private cmsPageContentService: CmsPageContentService,
    private applicationApi: ApplicationApi,
    private applicationDataService: ApplicationDataService,
    private router: Router,
    private neuroIdService: NeuroIdService,
    private googleAnalytics: GoogleAnalytics,
    private appInsightsService: AppInsightsService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.bokingMessage = this.activatedRoute.snapshot.data.cmsContent
      .bookingWaiting as CmsBooking;
    this.cmsPageContentService.updatePageTitle('');

    try {
      const appData = await lastValueFrom(this.applicationApi.get());
      if (appData.state === ApplicationStates.Approved) {
        await lastValueFrom(this.applicationApi.initiateBooking());
        this.continueWithCheck();
      } else if (appData.state === ApplicationStates.Accepted) {
        await lastValueFrom(this.applicationApi.initiateBooking());
        this.continueWithCheck();
      } else if (appData.state === ApplicationStates.Booked) {
        this.router.navigate(['successful-setup']);
      } else {
        this.appInsightsService.trackEvent("Booking-Application-State-Error", { applicationState: appData.state });
        this.router.navigate(['/error']);
      }
    } catch {
      this.appInsightsService.trackEvent("Booking-InitiateBooking-Or-GetApplication-Error");
      this.router.navigate(['/error']);
    }
  }

  private continueWithCheck(): void {
    this.pollApplicationForBookedStatus();

    this.messageInterval = setInterval(() => {
      this.bokingMessage.messages.shift();
    }, this.bokingMessage.messages[0].secondsDelay * 1000);
  }

  private pollApplicationForBookedStatus(): void {
    this.googleAnalytics.formSubmitEvent({ form_submit_text: 'bookingWait' });

    this.pollingSubscription = interval(5000)
      .pipe(
        switchMap(() => this.applicationApi.get()),
        takeUntil(this.bookedCompletedSubject),
        takeUntil(timer(300000)),
        finalize(() => {
          if(this.bookedSuccessResponse) {
            this.router.navigate(['successful-setup']);
          }

          if(!this.bookedSuccessResponse) {
            this.appInsightsService.trackEvent("Booking-Timeout");
            this.router.navigate(['/error']);
          }
        })
      )
      .subscribe((application: ApplicationData) => {
        if (application.state === ApplicationStates.Booked) {
          const googleAnalyticsData: GoogleTagManagerVariables = {
            loan_id: application.loan.loanId
          };
          this.googleAnalytics.setGoogleTagManagerVariables(
            googleAnalyticsData
          );

          var data: any = {
            form_submit_text: 'bookingSuccess',
            loan_id: application.loan.loanId
          };
          this.googleAnalytics.setGoogleTagManagerVariables(data);
          this.googleAnalytics.formSubmitEvent({
            form_submit_text: 'bookingSuccess'
          });

          this.bookedSuccessResponse = true;
          this.bookedCompletedSubject.next(true);
          this.neuroIdService.setCheckpoint('application_booked_checkpoint');
          this.applicationDataService.merge(application);
        }
      });
  }

  public ngOnDestroy(): void {
    clearInterval(this.messageInterval);
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
    }
  }
}
