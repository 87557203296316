import { ModuleWithProviders, NgModule } from '@angular/core';
import { LocationProxy } from '@core/location-proxy/location-proxy.service';
import { GoogleAnalytics } from './googleanalytics.service';
import { GoogleAnalyticsClickDirective } from './directives/googleanalytics-click.directive';
import { GoogleAnalyticsInputFocusDirective } from './directives/googleanalytics-input-focus.directive';
import { GoogleAnalyticsPopupDirective } from './directives/googleanalytics-popup.directive';
import { GoogleAnalyticsSelectChangeDirective } from './directives/googleanalytics-select-change.directive';
import { GoogleAnalyticsFormControlFocusOutDirective}  from './directives/googleanalytics-formcontrol-focusout.directive'
const exports = [

  GoogleAnalyticsClickDirective ,
  GoogleAnalyticsInputFocusDirective,
  GoogleAnalyticsPopupDirective,
  GoogleAnalyticsSelectChangeDirective,
  GoogleAnalyticsFormControlFocusOutDirective
];

@NgModule({
  providers: [LocationProxy],
  declarations: [...exports],
  exports
})



export class GoogleAnalyticsModule {
  public static forRoot(): ModuleWithProviders<GoogleAnalyticsModule> {
    return {
      ngModule: GoogleAnalyticsModule,      
      providers: [GoogleAnalytics]
    };
  }
}
