import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Environment } from '@environment/environment';
import { BannersResponse } from '../models/banner.model';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BannerApi {
  constructor(
    private httpClient: HttpClient,
    private environment: Environment
  ) {}

  public getPublicBanners(): Observable<BannersResponse> {
    const brandId = this.environment.brandId?.toString();
    const url = `${this.environment?.brandApi?.url}/api/getpublicbanners?brandId=${brandId}`;
    const apiVersion = 'v2';
    return this.httpClient.get<BannersResponse>(
      url,
      {
        headers: new HttpHeaders({
          'Api-Version': apiVersion,
          'Ocp-Apim-Subscription-Key': this.environment?.gpc?.apiManagerSubscriptionKey
        })
      }
    );
  }
}
