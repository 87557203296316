import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationApi } from '@application/application.api';
import { ApplicationDataService } from '@application/application.service';
import {
  PrimarySourceDisplayName,
  SecondarySourceDisplayName
} from '@application/income/income';
import {
  PrimaryPaymentFrequencyDisplayName,
  PrimaryPaymentFrequencyValue
} from '@application/income/sources/components/payment-frequency/payment-frequency';
import {
  ApplicantIncomeSourceModel,
  ApplicationData
} from '@application/application';
import { IncomeVerification } from './income-verification';

import { DatePipe } from '@angular/common';
import { VerifyIncomeContent } from './verify-income-content';
import { CmsPageContentService } from '@core/cms/services/cms-page-content.service';
import { ConsentSectionItem } from '@application/consents/consents.content';
import { IncomeVerificationForm } from './income-verification.form';
import { ConsentsComponent } from '@application/consents/consents.component';
import { ApplicationFlowService } from '@core/application-flow/application-flow.service';
import moment from 'moment';
import { Environment, SubmitApplicationFlowOptions } from '@environment/environment';
import { lastValueFrom } from 'rxjs';
import { LoadingModalService } from '@application/loading-modal/loading-modal.service';

@Component({
  selector: 'app-income-verification',
  templateUrl: './income-verification.component.html',
  styleUrls: ['./income-verification.component.scss'],
  providers: [DatePipe]
})
export class IncomeVerificationComponent implements OnInit {
  @ViewChild('consentsComponent') private consentsComponent: ConsentsComponent;
  public income: IncomeVerification;
  public content: VerifyIncomeContent;
  public consentsSection: ConsentSectionItem[];
  public form: IncomeVerificationForm;
  public showPayDates: boolean;
  public callSubmitApplication: boolean;
  public toggleTodayCard: boolean;
  public incomeDates: string[];
  applicationSubmitted = false;

  constructor(
    private applicationDataService: ApplicationDataService,
    private applicationApi: ApplicationApi,
    private router: Router,
    private datepipe: DatePipe,
    private route: ActivatedRoute,
    private loadingService: LoadingModalService,
    private cmsPageContentService: CmsPageContentService,
    private applicationFlowService: ApplicationFlowService,
    private environment: Environment
  ) {}

  public ngOnInit(): void {
    const applicationData = this.applicationDataService.getApplication();
    this.form = new IncomeVerificationForm();
    this.content = this.route.snapshot.data.cmsContent.verifyIncome;
    this.consentsSection = this.content.consentsSection || [];
    this.cmsPageContentService.updatePageTitle(this.content.header);
    const primary = applicationData.form.applicant.income.sources.find(
      s => s.key === 'Primary'
    );
    this.showPayDates =
      primary.hasOwnProperty('nextPayDate') ||
      primary.hasOwnProperty('daysOfMonth');

    this.income = {
      primarySource: PrimarySourceDisplayName[primary.type],
      primaryPaymentFrequency:
        PrimaryPaymentFrequencyDisplayName[primary.frequency],
      primaryNetAmount: primary.netAmount,
      monthlyRentMortgage: applicationData.form.applicant.residences?.find(
        r => r.key === '1'
      )?.monthlyAmount
    };

    if (this.showPayDates) {
      const next3PayDates = this.calculateNext3PayDates(primary);
      this.income.primaryNext3PayDates = next3PayDates;
      this.income.nextPayDate = next3PayDates[0];

      this.incomeDates = [
        this.datepipe.transform(
          this.income.primaryNext3PayDates[0],
          'MM/dd/yyyy'
        ),
        this.datepipe.transform(
          this.income.primaryNext3PayDates[1],
          'MM/dd/yyyy'
        ),
        this.datepipe.transform(
          this.income.primaryNext3PayDates[2],
          'MM/dd/yyyy'
        ),
      ];
    }

    const secondary = applicationData.form.applicant.income.sources.find(
      s => s.key === 'Secondary'
    );

    if (secondary) {
      this.income.secondarySource = SecondarySourceDisplayName[secondary.type];
      this.income.secondaryNetAmount = secondary.netAmount;
    }

    this.callSubmitApplication = this.environment.applicationFlowSettings.applicationSubmitStep ===
      SubmitApplicationFlowOptions.IncomeVerification;
  }

  public edit(): void {
    this.router.navigate(['income']);
  }

  public async submit(): Promise<void> {
    this.applicationSubmitted = true;
    this.form.showValidationErrors();

    if (!this.form.valid) {
      return;
    }
    this.loadingService.open();
    const payload: ApplicationData = this.buildAppData();
    try {
      await this.applicationApi.append(payload.form).toPromise();

      if(this.callSubmitApplication) {
        await lastValueFrom(this.applicationApi.submit());
      }
      this.applicationDataService.merge(payload);
      this.loadingService.close();
      this.router.navigate([payload.form.continuePath]);
    } catch (error) {
      this.loadingService.close();
      this.router.navigate(['/error']);
    }
  }

  private calculateNext3PayDates(source: ApplicantIncomeSourceModel): string[] {
    const endOfDate = source.daysOfMonth?.first === 31;

    const firstDate = this.getFirstPayDate(source);

    const secondDate = this.getNextPayDate(
      PrimaryPaymentFrequencyValue[source.frequency],
      firstDate,
      endOfDate
    );

    const thirdDate = this.getNextPayDate(
      PrimaryPaymentFrequencyValue[source.frequency],
      secondDate,
      endOfDate
    );

    const threePayDates = [
      firstDate.format('YYYY-MM-DD'),
      secondDate.format('YYYY-MM-DD'),
      thirdDate.format('YYYY-MM-DD')
    ];

    return threePayDates;
  }

  private getFirstPayDate(source: ApplicantIncomeSourceModel): moment.Moment {
    if (source.nextPayDate) {
      return moment(source.nextPayDate, 'YYYY-MM-DD');
    }

    const first = source.daysOfMonth.first;

    if (first) {
      let m = moment().utc();

      if (first === 31) {
        m.endOf('month');
      } else {
        m.set('date', first);
      }

      m.startOf('day');

      while (moment().isSameOrAfter(m)) {
        m = this.getNextPayDate(
          PrimaryPaymentFrequencyValue[source.frequency],
          m,
          first === 31
        );
      }

      return m;
    }

    return null;
  }

  private getNextPayDate(
    frequency: PrimaryPaymentFrequencyValue,
    base: moment.Moment | string,
    endOfMonth?: boolean
  ): moment.Moment {
    const m: moment.Moment =
      typeof base === 'string' ? moment.utc(new Date(base)) : base.clone();

    switch (frequency) {
      case PrimaryPaymentFrequencyValue.Weekly:
        return m.add(1, 'week');
      case PrimaryPaymentFrequencyValue.BiWeekly:
        return m.add(2, 'week');
      case PrimaryPaymentFrequencyValue.SemiMonthly:
        const date = m.date();

        if (date === 1) {
          return m.set('date', 16);
        } else if (m.date() === 15) {
          return m.endOf('month').startOf('day');
        } else if (m.date() === 16) {
          return m.add(1, 'month').set('date', 1);
        } else {
          // end of month
          return m.add(1, 'month').set('date', 15);
        }
      case PrimaryPaymentFrequencyValue.Monthly:
        m.add(1, 'month');
        return endOfMonth ? m.endOf('month') : m;
    }
  }

  private buildAppData(): ApplicationData {
    const payload: ApplicationData = {
      form: {
        continuePath: this.applicationFlowService.getContinuePath(),
        disclosures: this.consentsComponent?.disclosures
      }
    };

    if (this.showPayDates) {
      payload.form.applicant = {
        income: {
          sources: [
            {
              key: 'Primary',
              confirmedPayDates: this.income.primaryNext3PayDates.map(
                (d, i) => {
                  return {
                    key: `${i + 1}`,
                    date: d,
                    paycheckAmount: this.income.primaryNetAmount
                  };
                }
              ),
              paymentDate: this.income.primaryNext3PayDates[0]
            }
          ]
        }
      };
    }
    return payload;
  }

  public get formContainsConsents(): boolean {
    return this.consentsSection?.length > 0;
  }

  public debugHudSubmitConsents(): void {
    this.consentsComponent.debugHudSubmitAllConsents();
  }
}
