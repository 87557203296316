import { PageFormGroup } from '@core/page-form-group/page-form-group';
import { Injectable } from '@angular/core';

export interface SignAndSubmitForm {
  consentToWhatYouNeedtoKnow?: boolean;
  consentToTermsConditions?: boolean;
  agreeAndAcknowledge?: string;
}

@Injectable()
export class SignAndSubmitFormGroup extends PageFormGroup {

  constructor() {
    super({});
  }
}
