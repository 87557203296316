import { ApplicationFlow } from '@application/application';
import { PrimaryPaymentFrequencyValue } from '@application/income/sources/components/payment-frequency/payment-frequency';

export interface PaymentOptionItem {
  csoFee?: number;
  tilaApr: number;
  effectiveDate: string;
  disbursementDate?: string;
  interestRate: number;
  paymentSchedule: PaymentScheduleItem[];
  signature?: string;
}

export interface SelectedPaymentOption {
  loanAmount: number;
  fundAmount: number;
  apr: number;
  originationFee?: number;
  paymentOptions: PaymentOptionItem[];
}

export interface SelectedTermsModel{
  fundAmount: number;
  loanAmount: number;
  apr: number;
  originationFee?: number;
  paymentOption: PaymentOptionItem;
}

export interface PaymentScheduleItem {
  principal: number;
  interest: number;
  totalPayment: number;
  dueDate: string;
}

export interface PaymentOptionsResponse {
  minLineAmount: number;
  paymentOptions: PaymentOptionItem[];
  cashAvailableForDeposit?: number;
}

export interface PaymentDefaultOptionsRequest {
  loanAmount: number;
  originationFee: number;
  paymentOptions: string;
}

export interface LoanPaymentInitializationData {
  loanAmount: number;
  apr: number;
  requestedAmount: number;
  applicationFlow: ApplicationFlow;
  originationFee: number;
}

export interface LoanAmountMinMax {
  minimum: number;
  maximum: number;
}

export interface PaymentOptionsState {
  currentSelection: number;
  paymentOptions: PaymentOptionItem[];
}

export interface OptionsItem {
  indexRef: number;
  numPayments: number;
  paymentAmount: number;
}

export interface OriginationFeeConfiguration {
  originationFeePercentage?: number;
  originationFee?: number;
  isloanOriginationFeeApplicable?: boolean;
  originationFeeCapDollarAmount?: number;
  originationFundedAmount?: number;
}

export interface PaymentFrequencyOption {
  value: string;
  text: string;
  monthlyFactor: number;
}

export const paymentFrequencyOptions: PaymentFrequencyOption[] = [
  {
    value: PrimaryPaymentFrequencyValue.Weekly,
    text: 'weekly',
    monthlyFactor: 52 / 12
  },
  {
    value: PrimaryPaymentFrequencyValue.BiWeekly,
    text: 'Bi-weekly',
    monthlyFactor: 26 / 12
  },
  {
    value: PrimaryPaymentFrequencyValue.SemiMonthly,
    text: 'Semi-Monthly',
    monthlyFactor: 2
  },
  {
    value: PrimaryPaymentFrequencyValue.Monthly,
    text: 'once a month',
    monthlyFactor: 1
  }
];

export interface ActiveLoanData {
  loanId: string;
  currentPayoff: number;
}
