import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageFormGroup } from '@core/page-form-group/page-form-group';
import { requiredValidator } from '@elevate/forms';


@Injectable()
export class ChooseYourTermsForm extends PageFormGroup {
  constructor() {
    super({
      loanAmount: new FormControl(null, [requiredValidator('Required')]),
      paymentOptions: new FormControl()
    });
  }
}
