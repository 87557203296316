import { Injectable } from '@angular/core';
import { Environment } from '@environment/environment';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ReCaptchaService {
  private grecaptcha: any;
  private callback: ReplaySubject<string>;

  constructor(private environment: Environment) {
    this.grecaptcha = window['grecaptcha'];
  }

  public initialize(): void {
    this.callback = new ReplaySubject();
  }

  public execute(): Observable<string> {
    this.grecaptcha.execute();
    return this.callback.asObservable();
  }

  public render(element: HTMLElement): void {
    this.grecaptcha.ready(() => {
      this.grecaptcha.render(element, {
        sitekey: this.environment.reCaptcha.siteKey,
        size: 'invisible',
        badge: 'bottomright',
        callback: (token: string) => {
          this.callback.next(token);
          this.callback.complete();
        }
      });
    });
  }
}
