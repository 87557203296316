import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CmsPageContentService {
  public cmsPageTitle = new ReplaySubject<string>(1);
  public pageTitleObservable: Observable<string>;

  constructor() {
    this.pageTitleObservable = this.cmsPageTitle.asObservable();
  }

  public updatePageTitle(title: string): void {
    this.cmsPageTitle.next(title);
  }
}
