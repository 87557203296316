import { Component, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IncomeSourceComponent } from '@application/income/income';

import { PaymentFrequencyComponent } from '../components/payment-frequency/payment-frequency.component';
import { Retirement } from './retirement';

@Component({
  selector: 'app-retirement',
  templateUrl: './retirement.component.html',
  styleUrls: ['../../income.component.scss']
})
export class RetirementComponent implements IncomeSourceComponent {
  @Input() public formGroup: FormGroup;
  @Input() public CMSPaymentFrequency: any;

  @ViewChild(PaymentFrequencyComponent)
  private paymentFrequency: PaymentFrequencyComponent;

  public get value(): Retirement {
    return {
      ...this.paymentFrequency.value
    };
  }

  public patchValue(retirement: Retirement): void {
    this.paymentFrequency.patchValue(retirement);
  }

  public showValidationErrors(): void {
    this.paymentFrequency.showValidationErrors();
  }
}
