import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { CmsPageContentService } from '@core/cms/services/cms-page-content.service';
import {
  DocumentUploadComponent,
  DocumentUploadConfig,
  DUCmsContent
} from '@elevate/document-upload';
import { DocumentService } from '@core/document/document.service';
import { DocumentApi } from '@core/document/document.api';
import { ActivatedRoute, Router } from '@angular/router';
import { Environment } from '@environment/environment';
import {
  SessionStorageKeys,
  SessionStorageService
} from '@core/session-storage/session-storage.service';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { ApplicationDataService } from '@application/application.service';

export interface ProcessingDocsCmsContent {
  header: string;
  subHeader: string;
  content: string;
  documents: UploadDocumentInfo[];
  documentUpload: DUCmsContent;
}

export interface UploadDocumentInfo {
  document: string;
  description: string;
  documentId: string;
}

@Component({
  selector: 'app-processing-docs',
  templateUrl: './processing-docs.component.html',
  styleUrls: ['./processing-docs.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProcessingDocsComponent
  implements AfterViewInit, OnDestroy {
  @ViewChild('processingDocsTextSection', { static: false })
  private rfaiLinkText: ElementRef;
  @ViewChild('documentUploadComponent')
  public documentUploadComponent: DocumentUploadComponent;

  private readonly rfaiDocumentDownloadLinkId = 'rfaiDocumentDownloadLink';
  private readonly documentName = `rfai_${Date.now()}.pdf`;

  public documentUploadConfig: DocumentUploadConfig;
  public cmsContent: ProcessingDocsCmsContent;
  public contentText: string;
  public documents: UploadDocumentInfo[];
  public selectedDocuments: string[] = [];


  constructor(
    private cmsPageContentService: CmsPageContentService,
    private documentApi: DocumentApi,
    private documentService: DocumentService,
    private route: ActivatedRoute,
    private environment: Environment,
    private sessionStorageService: SessionStorageService,
    private router: Router,
    private applicationDataService: ApplicationDataService
  ) {
    this.cmsContent = this.route.snapshot.data.cmsContent
      .processingDocs as ProcessingDocsCmsContent;
    this.cmsPageContentService.updatePageTitle(this.cmsContent.header);
    this.contentText = this.cmsContent.content;
    this.setDUConfig();
    this.documents = this.cmsContent.documents;
    this.documentUploadConfig.documentTypes = this.documents.map(
      d => d.document
    );
  }

  public ngAfterViewInit(): void {
    this.rfaiLinkText?.nativeElement
      .querySelector(`#${this.rfaiDocumentDownloadLinkId}`)
      .addEventListener('click', this.download);
  }

  public ngOnDestroy(): void {
    this.rfaiLinkText?.nativeElement
      .querySelector(`#${this.rfaiDocumentDownloadLinkId}`)
      .removeEventListener('click', this.download);
  }

  public get disableSubmit(): boolean {
    return !this.selectedDocuments || this.selectedDocuments.length === 0;
  }

  public get currentUrl(): string {
    return this.router.url;
  }

  public download = async (): Promise<void> => {
    const blob: Blob = await lastValueFrom(this.documentApi.get('rfai'));
    this.documentService.download(blob, this.documentName);
  };

  public documentTypeChanged(event: string[]): void {
    this.selectedDocuments = event?.filter(d => !!d) ?? [];
  }

  public cancelUploadDocuments(): void {
    this.documentUploadComponent.deleteAllDocuments();
  }

  public submit(): void {
    this.documentUploadComponent.deleteAllDocuments();
    this.documentUploadComponent.viewPrivacyPolicy$ = new BehaviorSubject<
      boolean
    >(false).asObservable();
  }

  public deleteDocument(event: string): void {
    if (event) {
      const index = this.selectedDocuments.indexOf(event);
      this.selectedDocuments.splice(index, 1);
    }
  }

  private setDUConfig(): void {
    const productId = this.applicationDataService.getApplication()?.product?.id;
    const envConfig = this.environment.duConfig;
    const authToken = this.sessionStorageService.getItem(
      SessionStorageKeys.authorizationToken
    );

    this.documentUploadConfig = {
      uploadUrl: `${envConfig.uploadUrl}?brandId=${this.environment.brandId}&productId=${productId}`,
      baseUrl: envConfig.baseUrl,
      acceptedFileTypes: envConfig.acceptedFileTypes,
      maxFileSize: envConfig.maxFileSizeMb,
      maxLimit: envConfig.maxFileCount,
      requireLimit: envConfig.minFileCount,
      shouldSaveDocument: envConfig.shouldSaveDocument,
      shouldDisplayErrorMessage: envConfig.shouldDisplayErrorMessage,
      uploadType: envConfig.uploadType,
      additionalHeaders: envConfig.headers,
      productToken: `Bearer ${authToken}`
    } as DocumentUploadConfig;
  }
}
