import { Injectable, OnDestroy } from '@angular/core';
import { CurrencyInput, Select } from '@elevate/ui-components';
import { Subscription } from 'rxjs';
import { IncomeFormGroup } from './income.form';

@Injectable()
export class IncomeFormConfig implements OnDestroy {
  public primarySource: Select = {
    id: 'primarySelect',
    placeholder: 'Select income source',
    value: '',
    visibleOptions: 5,
    options: [
      {
        label: 'None',
        value: ''
      }
    ],
    attributes: {
      'data-nid-target': 'incomeType'
    }
  };

  public secondarySource: Select = {
    id: 'secondarySelect',
    placeholder: 'Select income source',
    value: '',
    visibleOptions: 5,
    options: [
      {
        label: 'None',
        value: ''
      }
    ],
    attributes: {
      'data-nid-target': 'additionalIncomeSource'
    }
  };

  public secondaryNetAmountConfig: CurrencyInput = {
    id: 'secondaryNetAmountText',
    maxlength: 5,
    notDecimalAllow: true,
    digitSeparator: ',',
    leftAlign: true,
    attributes: {
      'data-nid-target': 'secondaryNetAmount'
    }
  };

  public monthlyRentMortgage: CurrencyInput = {
    id: 'monthlyRentMortgage',
    maxlength: 5,
    notDecimalAllow: true,
    digitSeparator: '',
    leftAlign: true,
    attributes: {
      'data-nid-target': 'monthlyRentalOrMortgageAmount'
    }
  };

  private subscriptions: Subscription[] = [];

  constructor(form: IncomeFormGroup, sources: any) {
    this.populateIncomeDropDowns(
      sources.mainIncome.incomeType,
      this.primarySource
    );
    this.populateIncomeDropDowns(
      sources.secondaryIncome.incomeType,
      this.secondarySource
    );

    const sub = form
      .get('secondarySource')
      .valueChanges.subscribe((value: string) => {
        const i = this.secondarySource.options.findIndex(
          o => o.label === 'None'
        );

        if (value && i === -1) {
          this.secondarySource.options.unshift({
            label: 'None',
            value: ''
          });

          setTimeout(() => {
            this.secondarySource.visibleOptions = 6;
          });
        }

        if (!value && i !== -1) {
          this.secondarySource.options.splice(i, 1);
          this.secondarySource.visibleOptions = 5;
        }
      });

    this.subscriptions.push(sub);
  }
  private populateIncomeDropDowns(content: any, dropDown: Select): void {
    content.forEach(val => {
      dropDown.options.push(val);
    });
    dropDown.options.splice(
      dropDown.options.findIndex(o => o.label === 'None'),
      1
    );
    dropDown.visibleOptions = dropDown.options.length;
  }
  public ngOnDestroy(): void {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }
}
