import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Environment } from '@environment/environment';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  private readonly isEnabled: boolean;

  constructor(
    environment: Environment,
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this.isEnabled = environment.enableQualtrics;
  }

  ngOnInit(): void {
    if (!this.isEnabled) {
      return;
    }

    const feedbackScript = this.renderer.createElement('script');
    feedbackScript.type = `text/javascript`;
    feedbackScript.src = 'assets/js/feedback.js';
    this.el.nativeElement.appendChild(feedbackScript);
  }
}
