import { Environment } from '@environment/environment';
import { EclGpcService, GpcInfo } from '@elevate/gpc';
import { CookieService, CookieEnums } from '../cookie/cookie-service';
import { Injectable } from '@angular/core';
import { ApplicationDataService } from '@application/application.service';

@Injectable({
  providedIn: 'root'
})

// should be called for every page
export class GpcService {
  constructor(
    private environment: Environment,
    private eclGpcService: EclGpcService,
    private cookieService: CookieService,
    private applicationDataService: ApplicationDataService
  ) {}
  public PostGpc() {
    const gpcInfo: GpcInfo = {
      brandId: this.environment.brandId.toString(),
      customerId: this.getCustomerId(),
      clientId: this.getClientId(),
      gpcStatus: (navigator as any).globalPrivacyControl === true,
      sessionId: this.getSessionId(),
      config: {
        apiManagerSubscriptionKey: this.environment.gpc
          .apiManagerSubscriptionKey,
        apiVersion: this.environment.gpc.apiVersion,
        environment: this.environment.gpc.environment,
        postUrl: this.environment.gpc.postUrl
      }
    };
    this.eclGpcService.postGpcInfo(gpcInfo).subscribe();
  }

  private getCustomerId() {
    const applicationData = this.applicationDataService.getApplication();
    return applicationData.sequenceApplicationId?.toString();
  }

  private getSessionId(): string {
    var sessionId = this.cookieService.getSessionId();
    return sessionId ? sessionId : '';
  }

  private getClientId() {
    const clientId = this.cookieService.get(CookieEnums.ga);
    return clientId;
  }
}
