<div class="modal-body" id="thirdpartyDisclaimerModalWindow" *ngIf="cmsContent">
  <p class="thirdparty-disclaimer-modal-header">
    <span class="third-party-disclaimer-header" id="thirdpartyDisclaimerHeader">
      {{ cmsContent.header }}
    </span>
  </p>

  <div
    id="thirdpartyDisclaimerContent"
    class="thirdparty-disclaimer-modal-body"
  >
    <p>{{ cmsContent.content }}</p>
  </div>

  <div class="thirdparty-disclaimer-modal-button-container">
    <button
      id="thirdpartyDisclaimerModalCancelButton"
      class="ecl-button-secondary"
      (click)="close()"
    >
      {{ cmsContent.button.goBack }}
    </button>
    <button
      ngbAutofocus
      id="thirdpartyDisclaimerModalContinueButton"
      class="ecl-button-primary"
      (click)="continue()"
    >
      {{ cmsContent.button.continue }}
    </button>
  </div>
</div>
