import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {CmsService} from '@core/cms';
import { BaseModalConfig } from '@elevate/base-modal';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {lastValueFrom} from 'rxjs';

export interface NavigationConfirmationModalCmsContent {
  header: string;
  content: string;
  buttons: {
    continue: string;
    cancel: string;
  };
}
@Component({
  selector: 'app-restrict-navigation-modal',
  templateUrl: './restrict-navigation-modal.component.html',
  styleUrls: ['./restrict-navigation-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RestrictNavigationModalComponent implements OnInit {
  public modalData: BaseModalConfig = {
    heading: null,
    content: null,
    action: {
      submitButton: null,
      cancelButton: null,
      allowClose: false,
      onSubmit: formValue => this.thisModal.close(true),
      onCancel: () => this.thisModal.close(false)
    }
  } as BaseModalConfig;
  public cmsContent: NavigationConfirmationModalCmsContent;

  constructor(
    private thisModal: NgbActiveModal,
    private cmsService: CmsService,
  ) {}

  public close(): void {
    this.thisModal.close(false);
  }

  public continue(): void {
    this.thisModal.close(true);
  }

  async ngOnInit(): Promise<void> {
    const content =   await lastValueFrom(this.cmsService.getSharedCmsContent());
    this.cmsContent = content.navigationConfirmationModal as NavigationConfirmationModalCmsContent;
    this.modalData.heading = this.cmsContent.header;
    this.modalData.content = this.cmsContent.content;
    this.modalData.action.submitButton = this.cmsContent.buttons.continue;
    this.modalData.action.cancelButton = this.cmsContent.buttons.cancel;
  }
}
